import React, { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getGemaSubmitted } from "../../app_redux/slice/gemaSubmitted";
import { getEvents } from "../../app_redux/slice/gemaEvents";
import { gemaEventDetails } from "../../app_redux/slice/gemaEventDetail";
import LoadListModal from "./gemaLoadlist";
import { getEventDetail } from "../../app_redux/slice/eventDetail";
import DeleteEventSong from "./deleteEventSong";
import { useTranslation } from "react-i18next";

const WithoutAppointmentModal = ({ show, handleShow, pageNo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cookee = new Cookies();
  const token = cookee.get("token");
  const lng = cookee.get("googtrans");
  const { eventDetails } = useSelector((state) => state.eventdetail);
  const [cancelInfo, setCancelInfo] = useState("");
  const [eventName, setEventName] = useState("");
  const [date, setDate] = useState("");
  const [organizer, setOrganizer] = useState("");
  const [organizerStreet, setOrganizerStreet] = useState("");
  const [organizerTown, setOrganizerTown] = useState("");
  const [gemaDetails, setGemaDetails] = useState([]);
  const [locationName, setLocationName] = useState("");
  const [town, setTown] = useState("");
  const [eventid, setEventId] = useState("");
  const [eventType, setEventType] = useState("");
  const [performanceType, setPerformanceType] = useState("");
  const [stageTimeStart, setStageTimeStart] = useState("");
  const [stageTimeEnd, setStageTimeEnd] = useState("");
  // const { gemaDetails, } = useSelector((state) => state.gemaevents)
  const [location, setLocation] = useState("");
  const [showLoadlistModal, setShowLoadlistModal] = useState(false);
  const [showSetlistSong, setShowSetlistSong] = useState(false);
  const [setlistIdentity, setSetlistIdentity] = useState("");
  const [infoOrganizerName, setInfoOrganizerName] = useState("");
  const [infoOrganizerStreet, setInfoOrganizerStreet] = useState("");
  const [infoOrganizerHouseNo, setInfoOrganizerHouseNo] = useState("");
  const [infoOrganizerPostal, setInfoOrganizerPostal] = useState("");
  const [infoOrganizerTown, setInfoOrganizerTown] = useState("");
  const [infoOrganizerNameContact, setInfoOrganizerNameContact] = useState("");
  const [infoOrganizerEmail, setInfoOrganizerEmail] = useState("");
  const [infoOrganizerPhone, setInfoOrganizerPhone] = useState("");
  const [infoOrganizerGemaNo, setInfoOrganizerGemaNo] = useState("");
  const [infoOrganizerCountry, setInfoOrganizerCountry] = useState("");
  const [locationCountry, setLocationCountry] = useState("");
  const [organizerCountry, setOrganizerCountry] = useState("");
  const [locationZip, setLocationZip] = useState("");
  const [locationHouseNo, setLocationHouseNo] = useState("");
  const [works, setWorks] = useState(0);
  const [copyright, setCopyright] = useState(0);
  const [lengthSet, setLengthSet] = useState("00:00:00");
  const [locationStreet, setLocationStreet] = useState("");
  const [deleteShow, setDeleteShow] = useState(false);
  const [songId, setSongId] = useState("");
  const [setlistDetails, setSetlistDetails] = useState([
    eventDetails[0]?.set_list_details,
  ]);
  const {
    tab18,
    tab19,
    tab20,
    tab21,
    tab22,
    tab28,
    tab37,
    tab32,
    tab29,
    tab13,
    tab38,
  } = t("gema");

  useEffect(() => {
    dispatch(getEventDetail({ eventid: "" }));
  }, []);

  const handleEventType = (e) => {
    setEventType(e.target.value);
  };
  const handlePerformanceType = (e) => {
    setPerformanceType(e.target.value);
  };
  const handleDeleteShow = () => {
    setDeleteShow(!deleteShow);
  };
  const handleDelete = (id) => {
    setSongId(id);
    handleDeleteShow();
  };
  const handleChange = (e, setState) => {
    setState(e.target.value);
  };
  const handleEventInfo = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/create-event-info`,
      data: {
        event_name: eventName,
        info_organizer: [
          {
            organizer_name: infoOrganizerName,
            organizer_street: infoOrganizerStreet,
            organizer_house_number: infoOrganizerHouseNo,
            organizer_postal: infoOrganizerPostal,
            organizer_town: infoOrganizerTown,
            contact_person_name: infoOrganizerNameContact,
            email: infoOrganizerEmail,
            phone_number: infoOrganizerPhone,
            gema_customer_number: infoOrganizerGemaNo,
            organizer_country: organizerCountry,
            organizer_other_information: "",
          },
        ],
        info_accommodation: [
          {
            accomodation_name: "",
            accomodation_street: "",
            accomodation_house_number: "",
            accomodation_postal: "",
            accomodation_town: "",
            accomodation_country: "",
            accomodation_phone_number: "",
            accomodation_email: "",
            accomodation_other_information: "",
          },
        ],
        start_time: "",
        end_time: "",
        load_start_time: "",
        load_end_time: "",
        sound_start_time: "",
        sound_end_time: "",
        stage_start_time: stageTimeStart,
        stage_end_time: stageTimeEnd,
        type_of_event: eventType,
        type_of_performance: performanceType,
        start_date: date,
        latitude: 15,
        longitude: 109.77,
        location_name: locationName,
        location_street: locationStreet,
        loc_name_of_person: "",
        location_email: "",
        location_phone_number: "",
        location_other_information: "",
        town: town,
        event_country: locationCountry,
        event_zip_code: locationZip,
        event_house_number: locationHouseNo,
        event_type: 1,
        event_other_info: "",

        parking: "",
        guest_list: "",
        catering: "",
        sonstige_other_info: "",
        is_without_appointment: 1,
        total_number_of_works: works,
        number_of_works_with_copyright: copyright,
        length_set: lengthSet,
      },
    })
      .then((res) => {
        setEventId(res.data.data.event_id);
        if (res.data.message === "Event Created Successfully!.") {
          toast.success("Event Created", {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })

      .catch((error) => {
        //console.log("Error", error.response)
        if (error.response.data.message === "Input Fields Cannot Be Empty!.") {
          toast.error("Event Name and Date Must be Added", {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (error.response.data.message === "Input Fields Required!.") {
          toast.error("Input Fields Required!.", {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  const handleDeleteSong = (id) => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/delete-band-leader-set-list-song`,
      data: {
        set_list_id: eventDetails[0]?.set_list_id,
        song_id: parseInt(id),
      },
    }).then((res) => {
      // console.log("Res", res.data.data)
      if (res.data.message === "Setlist Song Deleted Successfully!.") {
        toast.success("Deleted Successfully!.", {
          theme: "colored",
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(getEventDetail({ eventid: eventid }));
        // getData()
      }
    });
  };
  const handleShowLoadlist = () => {
    setShowLoadlistModal(!showLoadlistModal);
  };
  const handleLoadlist = () => {
    handleShowLoadlist();
  };
  const handleReport = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/band-leader-report-concert-event`,
      data: {
        event_id: eventid || "",
      },
    })
      .then((res) => {
        if (res.data.message === "Concert Reported Successfully") {
          toast.success("Reported Successfully", {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch(getGemaSubmitted({ pageno: pageNo }));
          dispatch(getEvents({ pageno: pageNo }));
          handleShow();
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Event Details Required!.") {
          toast.error("Event Details Required!.", {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  return (
    <>
      <Modal show={show} onHide={handleShow} size="xl">
        <Modal.Body className="p-0">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close close-Icon"
              onClick={handleShow}
            ></button>
            <div className="row">
              <form className="row my-5 py-5 addNAme mx-width">
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={eventName}
                    placeholder={tab37}
                    onChange={(e) => handleChange(e, setEventName)}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => handleChange(e, setStageTimeStart)}
                    placeholder={
                      lng === "/auto/de" || "//en/de"
                        ? "Auftrittbeginn [00:00]"
                        : "Stage Start Time [00:00]"
                    }
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => handleChange(e, setStageTimeEnd)}
                    placeholder={
                      lng === "/auto/de" || "/en/de"
                        ? "Auftrittsende [00:00]"
                        : "Stage End Time [00:00]"
                    }
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5 mt-1">
                  <input
                    type="date"
                    className="form-control"
                    value={date}
                    onChange={(e) => handleChange(e, setDate)}
                    placeholder="Date of Event"
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <p>
                    <select
                      className="form-control  mt-1"
                      onChange={(e) => handlePerformanceType(e)}
                    >
                      <option>
                        {lng === ".auto/de" || "/en/de"
                          ? "Art der Aufführung"
                          : "type of performance"}
                      </option>
                      <option value="Hauptgruppe">Hauptgruppe</option>
                      <option value="Vorgruppe">Vorgruppe</option>
                      <option value="Alleinige Band / Esemble">
                        Alleinige Band
                      </option>
                      <option value="Mehrer gleichberechtigte Bands / Künstler">
                        Mehrer gleichberechtigte Bands / Künstler
                      </option>
                      <option value="DJ-Live Act">DJ-Live Act</option>
                      <option value="Bühnen-/Theateraufführung">
                        Theateraufführung
                      </option>
                    </select>
                  </p>{" "}
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <p>
                    <select
                      className="form-control mt-1"
                      onChange={(e) => handleEventType(e)}
                    >
                      <option>
                        {lng === "/auto/de" || "/en/de"
                          ? "Art der Veranstaltung"
                          : "type of event"}
                      </option>
                      <option value="Fasching/Karneval">
                        Fasching/Karneval
                      </option>
                      <option value="Fest-/Straßenumzüge">
                        Fest-/Straßenumzüge
                      </option>
                      <option value="Hintergrundmusik">Hintergrundmusik</option>
                      <option value="Kabarett">Kabarett</option>
                      <option value=" Klassisches Konzert">
                        {" "}
                        Klassisches Konzert
                      </option>
                      <option value="Konzert">Konzert</option>
                      <option value="OpenAir Konzert">OpenAir Konzert</option>
                      <option value="Party/Feier">Party/Feier</option>
                      <option value="Sonstiges">Sonstiges</option>
                      <option value="Tanz/Bälle">Tanz/Bälle</option>
                      <option value="Bühnen-/Theatermusik">
                        Bühnen-/Theatermusik
                      </option>
                    </select>
                  </p>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5 mt-5">
                  <input
                    type="text"
                    className="form-control notranslate"
                    value={locationName}
                    tabindex="1"
                    onChange={(e) => handleChange(e, setLocationName)}
                    placeholder="Name Location"
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5 mt-5">
                  <input
                    type="text"
                    className="form-control"
                    value={infoOrganizerName}
                    onChange={(e) => handleChange(e, setInfoOrganizerName)}
                    placeholder={
                      lng === "/auto/de" || "/en/de"
                        ? "Name Veranstalter"
                        : "Organizer Name"
                    }
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5 mt-5">
                  {/* <input type="text" value={works} onChange={(e) => handleChange(e, setWorks)} className="form-control" placeholder="Number of Works" /> */}
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    tabindex="2"
                    value={locationStreet}
                    onChange={(e) => handleChange(e, setLocationStreet)}
                    placeholder={tab18}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={infoOrganizerStreet}
                    onChange={(e) => handleChange(e, setInfoOrganizerStreet)}
                    placeholder={tab18}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  {/* <input type="text" className="form-control" value={copyright} onChange={(e) => handleChange(e, setCopyright)} placeholder="Number of Works with Copyright" /> */}
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    value={locationHouseNo}
                    tabindex="3"
                    onChange={(e) => handleChange(e, setLocationHouseNo)}
                    className="form-control"
                    placeholder={tab19}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={infoOrganizerHouseNo}
                    onChange={(e) => handleChange(e, setInfoOrganizerHouseNo)}
                    placeholder={tab19}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  {/* <input type="text" className="form-control" value={lengthSet} onChange={(e) => handleChange(e, setLengthSet)} placeholder="Length Set" /> */}
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    value={locationZip}
                    tabindex="5"
                    onChange={(e) => handleChange(e, setLocationZip)}
                    className="form-control"
                    placeholder={tab20}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={infoOrganizerPostal}
                    onChange={(e) => handleChange(e, setInfoOrganizerPostal)}
                    placeholder={tab20}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5"></div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    value={town}
                    tabindex="6"
                    onChange={(e) => handleChange(e, setTown)}
                    className="form-control"
                    placeholder={tab21}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={infoOrganizerTown}
                    onChange={(e) => handleChange(e, setInfoOrganizerTown)}
                    placeholder={tab21}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5"></div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    tabindex="7"
                    value={locationCountry}
                    onChange={(e) => handleChange(e, setLocationCountry)}
                    placeholder={tab22}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={organizerCountry}
                    onChange={(e) => handleChange(e, setOrganizerCountry)}
                    placeholder={tab22}
                  />
                </div>
              </form>

              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                <div className="d-flex align-items-center lg-justify-content-end">
                  <button
                    className="btn add-new-cancel"
                    onClick={handleEventInfo}
                  >
                    {lng === "/auto/de" || "/en/de" ? "SPEICHERN" : "SAVE"}{" "}
                  </button>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
                <h4>{tab28}</h4>

                <div className="pt-3 mt-3 px-1 bg-white shadow">
                  <div className="table-responsive text-center">
                    <table className="table storetable">
                      <thead className="">
                        <tr>
                          <th scope="col">{tab13}</th>
                          <th scope="col">name</th>
                          <th scope="col">{tab32}</th>
                          <th scope="col">copyright band</th>
                          <th scope="col">{tab29}</th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {eventDetails[0]?.set_list_details?.map((res, i) => {
                          return (
                            <tr>
                              <td className="notranslate">
                                {res?.gemma_number}
                              </td>
                              <td className="notranslate">{res?.song_name}</td>
                              <td>{res?.time_length}</td>
                              <td>
                                <img
                                  src={
                                    res?.is_copyright == 1
                                      ? "assets/images/copyRed.png"
                                      : "assets/images/copyBlack.png"
                                  }
                                  width="25px"
                                  alt=""
                                />
                              </td>
                              <td>
                                <img
                                  src="assets/images/deleteBlack.png"
                                  width="25px"
                                  alt=""
                                  onClick={() =>
                                    handleDelete(res?.event_song_id)
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>

                {eventid ? (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                    <div className="d-flex align-items-center lg-justify-content-end">
                      <button
                        className="btn add-new-cancel"
                        onClick={handleLoadlist}
                      >
                        {tab38}
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 mx-5">
                <div className="text-center my-3 mt-5 mx-5">
                  <button className="btn add-new-cancel " onClick={handleShow}>
                    {lng === "/auto/de" || "/en/de" ? "Abbrechen" : "cancel"}
                  </button>
                  {lng === "/auto/de"}
                  <button
                    className="btn add-new-del notranslate "
                    onClick={handleReport}
                  >
                    {lng === "/auto/de" || "/en/de" ? "SENDEN" : "report"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showLoadlistModal ? (
        <LoadListModal
          show={showLoadlistModal}
          handleShow={handleShowLoadlist}
          setSetlistIdentity={setSetlistIdentity}
          eventid={eventid}
          setShowSetlistSong={setShowSetlistSong}
        />
      ) : (
        ""
      )}
      {deleteShow ? (
        <DeleteEventSong
          show={deleteShow}
          handleShow={handleDeleteShow}
          id={songId}
          eventid={eventid}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default WithoutAppointmentModal;
