import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

const cookee = new Cookies();

export const getSongs = createAsyncThunk("songsall", async (obj) => {
    let token = cookee.get('token');
    // console.log("RED", obj?.page)
    let songs = await axios({
        method: 'post',
        headers: {
            "Accept": "/",
            "Content-Type": "application/json",
            "auth_token": token
        },
        url: `${process.env.REACT_APP_URL}/get-band-leader-set-list-details`,
        data: {
            "set_list_id": obj?.id
        }

    })
        .then(res => res.data)
    return songs
})

const listSetlistSong = createSlice(({
    name: "all songs",
    initialState: {
        allSetlistSongs: [],
        loading: false
    },
    extraReducers: {
        [getSongs.pending]: (state, action) => {
            state.loading = true;
        },
        [getSongs.fulfilled]: (state, action) => {
            state.allSetlistSongs = action.payload
        },
        [getSongs.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listSetlistSong.reducer;