import React from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { signUpSchema } from '../signUpSchema'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'
import { loginSchema } from '../loginSchema'

const initialValues = {
    bandName: "",
    email: "",
    phone: ""
}
const Login = () => {
    let navigate = useNavigate()
    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: loginSchema,
        onSubmit: (values, action) => {
            localStorage.setItem("phone", values.phone)
            navigate('/otp-verification')

        }
    })



    return (
        <>
            <section className="">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-sm-12 col-md-6 col-lg-6 form-rightShift">
                            <div className="">
                                <form className="form-style" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 text-center mb-5">
                                            <div className="logo-bg text-center">
                                                <div className="mt-2"><img src="assets/images/logo.png" className="img-fluid logo-admin" alt="logo" /></div>
                                            </div>
                                            <h5 className="regitraLogo">Login/Signin</h5>
                                        </div>

                                        <div className="col-sm-12 col-md-12 mb-4">
                                            <div className="form-group">
                                                <label className="theme-color fw-bold">Phone</label>
                                                <div className="input-container">
                                                    <input type="text" className="form-control p-0" placeholder="Your number" name="phone" value={values.phone} onChange={handleChange} onBlur={handleBlur} />

                                                </div>
                                                {errors.phone && touched.phone ? <p style={{ color: 'red' }}>{errors.phone}</p> : ""}

                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-12 text-center">
                                            {/* < Link to='/otp-verification' className="btn btn-save w-50">SIGN IN</Link> */}
                                            <button className="btn btn-save w-50" onClick={handleSubmit}>LOGIN</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center mb-5 mt-3">
                                        Login with <Link to="/login-email" className="text-dark"><b>Email</b></Link>
                                    </div>
                                </form>

                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 py-3">
                            <img src="assets/images/long-hair-guitar-guy-singing-low.png" className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default Login