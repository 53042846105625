import React from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

window.Buffer = window.Buffer || require("buffer").Buffer;

const initialValues = {
  songName: "",
  songTime: "",
  bpm: "",
  gemaNumber: "",
  isrc: "",
  composer: "",
};
const AddSetListSong = () => {
  const cookee = new Cookies();
  const token = cookee.get("token");
  const navigate = useNavigate();

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      onSubmit: (values, action) => {
        handleAddSong();
      },
    });
  const handleSelect = (e, setState) => {
    setState(e.target.value);
  };

  const handleAddSong = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/add-song-to-band-leader-set-list`,
      data: {
        set_list_id: 188,
        set_list: [
          {
            song_name: "www-form",
            song_time: "4:00",
            gema_number: "XX2345f",
            is_copyright: 1,
          },
          {
            song_name: "night waves13",
            song_time: "4:00",
            gema_number: "XX2345",
            is_copyright: 1,
          },
        ],
      },
    }).then((res) => {
      // console.log("res", res.data)
      if (res.data.message === "Song Added Successfully!.") {
        alert("Song added");
        navigate("/songs");
      }
    });
  };

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row mt-3 align-items-center">
          {/* <div className="col-12 col-sm-9 col-md-9 col-lg-10">
                        <ul className="nav nav-pills group-nav tabNabs" id="pills-tab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link" href="add-information.html">information</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="add-band.html">band</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="add-transpot.html">transpot</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" href="add-setliste.html">setliste</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="add-attachments.html">attachments</a>
                            </li>
                        </ul>
                    </div> */}
          <div className="col-sm-12">
            <form className="row my-5 py-5 addNAme mx-width">
              <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                <input
                  type="text"
                  name="songName"
                  value={values.songName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                  placeholder="Name Of Song"
                />
                {errors.songName && touched.songName ? (
                  <p style={{ color: "red" }}>{errors.songName}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                <input
                  type="text"
                  name="songTime"
                  value={values.songTime}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                  placeholder="Time Of Song [00:00]"
                />
                {/* {errors.songTime && touched.songTime ? <p style={{ color: 'red' }}>{errors.songTime}</p> : ""} */}
              </div>

              <div className="col-sm-6 col-md-3 col-lg-3 mb-5 my-4">
                <input
                  type="checkbox"
                  style={{ width: "50px", height: "20px" }}
                />
                <label className="">Copyrights in band</label>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <div className="d-flex justify-content-center my-3">
                  <button className="btn add-new" onClick={handleSubmit}>
                    add song
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </article>
  );
};

export default AddSetListSong;
