import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getAllChats = createAsyncThunk("all chats", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('token')
    let chats =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/get-band-leader-unread-notification-count`,
            data: {
                "user_type": 2,
                "notification_type": "8",
                "is_chat": 1
            }
        })
            .then(res => res.data.data.count)
    return chats
})

const listChats = createSlice(({
    name: "all chat count",
    initialState: {
        allChatCount: "",
        loading: false
    },
    extraReducers: {
        [getAllChats.pending]: (state, action) => {
            state.loading = true;
        },
        [getAllChats.fulfilled]: (state, action) => {
            state.allChatCount = action.payload
        },
        [getAllChats.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listChats.reducer;