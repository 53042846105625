import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getPassengers = createAsyncThunk("setup", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('token')
    let setup =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/bandleader-event-transport-passenger-details`,
            data:{
                "transport_id":obj?.id
            }
        })
            .then(res => res.data.data)
    return setup
})

const listPassengers = createSlice(({
    name: "all passengers",
    initialState: {
        passengers: [],
        loading: false
    },
    extraReducers: {
        [getPassengers.pending]: (state, action) => {
            state.loading = true;
        },
        [getPassengers.fulfilled]: (state, action) => {
            state.passengers = action.payload
        },
        [getPassengers.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listPassengers.reducer;