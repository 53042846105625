import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getEventDetail } from "../../app_redux/slice/eventDetail";
import { useTranslation } from "react-i18next";

const LoadListModal = ({
  show,
  handleShow,
  id,
  setShowSetlistSong,
  setSetlistIdentity,
  eventid,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const cookee = new Cookies();
  const token = cookee.get("token");
  const lng = cookee.get("googtrans");
  const { tab29, tab32, tab42, tab43, tab44, tab45 } = t("gema");

  const [setList, setSetList] = useState([]);
  useEffect(() => {
    getSetList();
  }, []);
  const getSetList = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-set-list-by-band-id`,
      data: {
        page_no: 1,
      },
    }).then((res) => {
      setSetList(res.data.data);
    });
  };
  const handleSetlistClick = (id) => {
    setShowSetlistSong(true);
    setSetlistIdentity(id);
    handleShow();
  };
  const addList = (id) => {
    // setSetlistIdentity(id)
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/add-setlist-to-event`,
      data: {
        event_id: eventid,
        event_type: 1,
        set_list: [
          {
            set_list_id: id,
          },
        ],
      },
    }).then((res) => {
      if (res.data.message === "SetList Added Successfully!.") {
        toast.success("SetList Added Successfully", {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(getEventDetail({ eventid: eventid }));
        handleShow();
      }
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleShow} size="lg">
        <Modal.Body className="p-0">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close close-Icon"
              onClick={handleShow}
            ></button>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="pt-3 px-1 bg-white">
                  <div className="table-responsive">
                    <table className="table storetable text-center">
                      <thead className="">
                        <tr>
                          <th scope="col">name setlist</th>
                          <th scope="col">{tab32}</th>
                          <th scope="col">{tab29}</th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {setList?.length !== 0 ? (
                          setList.map((res, key) => {
                            return (
                              <tr>
                                <td
                                  onClick={() =>
                                    handleSetlistClick(res.set_list_id)
                                  }
                                >
                                  {res.set_list_name}
                                </td>
                                <td>{res.total_length}</td>
                                <td>
                                  <div className="d-flex gap-3 justify-content-center">
                                    <button
                                      className="btn add-new notranslate"
                                      onClick={() => addList(res.set_list_id)}
                                    >
                                      {tab42}
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <p className="mt-5">No Setlist Found!!</p>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default LoadListModal;
