import React from 'react'
import { Modal } from 'react-bootstrap'

const InformationModal = ({ show, handleShow, information }) => {

    return (
        <Modal show={show} onHide={handleShow} size="md" centered >
            <Modal.Body className="p-0">
                <div className="modal-body">
                    <button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <div className="text-center mx-2 mb-4">
                                <h4 className="mb-0 theme-color">Information</h4>
                            </div>
                            <div className=' text-center'>
                                <h6 className='notranslate'>{information || "NA"}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default InformationModal