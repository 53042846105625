import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getEvents } from "../../app_redux/slice/gemaEvents";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const DeleteEventModal = ({ show, handleShow, id, pageNo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cookee = new Cookies();
  const token = cookee.get("token");
  const [cancelInfo, setCancelInfo] = useState("");
  const { tab39, tab40, tab41 } = t("gema");

  const handleTextArea = (e) => {
    setCancelInfo(e.target.value);
  };
  const handleDeleteEvent = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      data: {
        event_id: id,
      },
      url: `${process.env.REACT_APP_URL}/delete-band-leader-event`,
    }).then((res) => {
      //console.log(res.data)
      if (res.data.message === "Event Deleted!.") {
        toast.success("Deleted Successfully", {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(getEvents({ pageno: pageNo }));
        handleShow();
      }
    });
  };
  return (
    <>
      <Modal show={show} onHide={handleShow} size="md" centered>
        <Modal.Body className="p-0">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close close-Icon"
              onClick={handleShow}
            ></button>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div className="text-center mx-2">
                  <h6>{tab39}</h6>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                <div className="text-center my-3 mt-5">
                  <button className="btn add-new-cancel" onClick={handleShow}>
                    {tab40}
                  </button>
                  <button
                    className="btn add-new-del"
                    onClick={handleDeleteEvent}
                  >
                    {tab41}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default DeleteEventModal;
