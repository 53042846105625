import React, { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { signUpSchema } from '../signUpSchema'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';
import countryList from 'react-select-country-list'
import Select from 'react-select'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import OtpEmailModal from './emailOtpModal'
import OtpPhoneModal from './phoneOtpModal'

const initialValues = {
    bandName: "",
    email: "",
    phone: "",
    password: "",
    confirmpassword: "",
    housenumber: "",
    street: "",
    zipcode: "",
    town: "",
    country: ""
}
const Signup = () => {
    const cookee = new Cookies();
    const [show, setShow] = useState(false)
    const [show1, setShow1] = useState(false)
    const options = useMemo(() => countryList().getData(), [])
    const [value, setValue] = useState("")
    const [showEmailOtp, setShowEmailOtp] = useState(false)
    const [showPhoneOtp, setShowPhoneOtp] = useState(false)

    let navigate = useNavigate()
    const { values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: signUpSchema,
        onSubmit: (values) => {
            console.log("Values", values)
            handleEmailOtp()
            //action.resetForm();
        }
    })
    const handleShowEmailOtp = () => {
        setShowEmailOtp(!showEmailOtp)
    }
    const showOtp = () => {
        handleShowEmailOtp()
    }
    const handleShowPhoneOtp = () => {
        setShowPhoneOtp(!showPhoneOtp)
    }
    const handleEmailOtp = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/send-musician-otp-with-email`,
            data:
            {
                "email": values.email,
            }
        })
            .then(res => {
                console.log("RESPONSE", res.data)

                if (res.data.message === "OTP Sent on Registered Email") {
                    showOtp()
                }

            })
            .catch((error) => {
                console.log("Error", error.response)
                if (error.response.data.message === 'Invalid Phone Number! Retry.') {
                    toast.error("Invalid Phone Number.", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
    }
    const handleShow = () => {
        setShow(!show)
    }
    const handleShow1 = () => {
        setShow1(!show1)
    }
    const changeHandler = value => {
        console.log("country", value)
        // setValue1(value)
        setFieldValue("country", value.label)
    }
    const handleSignup = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/band-leader-sign-up`,
            data:
            {
                "band_name": values.bandName,
                "profile_pic": "",
                "email": values.email,
                "country_code": value?.substr(0, 3),
                "phone_number": value?.substr(3, 12),
                "is_active": 1,
                "password": values.password,
                "language": "EN",
                "street": values.street,
                "house_number": values.housenumber,
                "zip_code": values.zipcode,
                "town": values.town,
                "country": values.country,
            }
        })
            .then(res => {
                console.log("RESPONSE", res.data)
                if (res.data.message === "Band Registered Successfully!.") {
                    cookee.set('token', res.data.data.auth_token)
                    cookee.set('email', res.data.data.email)
                    cookee.set('phonenumber', res.data.data.phone_number)
                    cookee.set('bandname', res.data.data.band_name)
                    cookee.set('countrycode', res.data.data.country_code)
                    alert("Sign up successfully!!")
                    toast.success("Band Registered Successfully..", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    navigate("/dashboard")
                }

            })
            .catch((error) => {
                console.log("Error", error.response)
                if (error.response.data.message === "Band Already Registered") {
                    toast.error("Band Already Registered !!", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                if (error.response.data.message === "Phone Number Already Registered") {
                    toast.error("Phone Number Already Registered", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                if (error.response.data.message === "Email Already Registered") {
                    toast.error("Email Already Registered", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                if (error.response.data.message === "We are currently able to offer the App to German Users. We ask for your understading!.") {
                    toast.error("We are currently able to offer the App to German Users. We ask for your understanding!.", {
                        theme: 'colored',
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })

    }
    return (
        <>
            <section className="">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-sm-12 col-md-6 col-lg-6 form-rightShift">
                            <div className="">
                                <form className="form-style" >
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 text-center mb-5">
                                            <div className="logo-bg text-center">
                                                <div className="mt-2"><img src="assets/images/logo.png" className="img-fluid logo-admin" alt="logo" /></div>
                                            </div>
                                            <h5 className="regitraLogo">Registration / Sign Up!</h5>
                                        </div>
                                        <div className="col-sm-12 col-md-12 mb-4">
                                            <div className="form-group">
                                                <label className="theme-color fw-bold">Band Name</label>
                                                <div className="input-container">
                                                    <input type="text" className="form-control p-0" placeholder="Name" name="bandName" value={values.bandName} onChange={handleChange} onBlur={handleBlur} />
                                                    <img src="assets/images/userName.png" className="input-img" alt="" />
                                                </div>
                                                {errors.bandName && touched.bandName ? <p style={{ color: 'red' }}>{errors.bandName}</p> : ""}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 mb-4">
                                            <div className="form-group">
                                                <label className="theme-color fw-bold">Email</label>
                                                <div className="input-container">
                                                    <input type="text" className="form-control p-0" placeholder="Your email address" name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} />
                                                    <img src="assets/images/arroba.png" className="input-img" alt="" />
                                                </div>
                                                {errors.email && touched.email ? <p style={{ color: 'red' }}>{errors.email}</p> : ""}

                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 mb-4">
                                            <div className="form-group">
                                                <label className="theme-color fw-bold">Phone Number</label>
                                                {/* <div className="input-container">
                                                    <input type="text" className="form-control p-0" placeholder="Mobile No." name="phone" value={values.phone} onChange={handleChange} onBlur={handleBlur} />
                                                    <img src="assets/images/phone-call.png" className="input-img" alt="" />
                                                </div> */}
                                                <PhoneInput defaultCountry="DE"
                                                    value={value} className="form-control" placeholder="Phone*" onChange={setValue} />
                                                {/* {errors.phone && touched.phone ? <p style={{ color: 'red' }}>{errors.phone}</p> : ""} */}

                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 mb-4">
                                            <div className="form-group">
                                                <label className="theme-color fw-bold">Street</label>
                                                <div className="input-container">
                                                    <input type="text" className="form-control p-0" placeholder="Street" name="street" value={values.street} onChange={handleChange} onBlur={handleBlur} />
                                                </div>
                                                {errors.street && touched.street ? <p style={{ color: 'red' }}>{errors.street}</p> : ""}

                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 mb-4">
                                            <div className="form-group">
                                                <label className="theme-color fw-bold">House Number</label>
                                                <div className="input-container">
                                                    <input type="text" className="form-control p-0" placeholder="House No." name="housenumber" value={values.housenumber} onChange={handleChange} onBlur={handleBlur} />
                                                </div>
                                                {errors.housenumber && touched.housenumber ? <p style={{ color: 'red' }}>{errors.housenumber}</p> : ""}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 mb-4">
                                            <div className="form-group">
                                                <label className="theme-color fw-bold">Zip Code</label>
                                                <div className="input-container">
                                                    <input type="text" className="form-control p-0" placeholder="Zip Code" name="zipcode" value={values.zipcode} onChange={handleChange} onBlur={handleBlur} />
                                                </div>
                                                {errors.zipcode && touched.zipcode ? <p style={{ color: 'red' }}>{errors.zipcode}</p> : ""}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 mb-4">
                                            <div className="form-group">
                                                <label className="theme-color fw-bold">Town</label>
                                                <div className="input-container">
                                                    <input type="text" className="form-control p-0" placeholder="Town" name="town" value={values.town} onChange={handleChange} onBlur={handleBlur} />
                                                </div>
                                                {errors.town && touched.town ? <p style={{ color: 'red' }}>{errors.town}</p> : ""}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 mb-4">
                                            <div className="form-group">
                                                <label className="theme-color fw-bold">Country</label>
                                                <div className="input-container">
                                                    <Select className="form-control p-0" options={options} placeholder="Country" onChange={changeHandler} />
                                                </div>
                                                {errors.country && touched.country ? <p style={{ color: 'red' }}>{errors.country}</p> : ""}

                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 mb-4">
                                            <div className="form-group">
                                                <label className="theme-color fw-bold">Password</label>
                                                <div className="input-container">
                                                    <input type={show ? "text" : "password"} className="form-control p-0" placeholder="Password." name="password" value={values.password} onChange={handleChange} onBlur={handleBlur} />
                                                    <img src="assets/images/view.png" className="input-img" alt="" onClick={handleShow} />
                                                </div>
                                                {errors.password && touched.password ? <p style={{ color: 'red' }}>{errors.password}</p> : ""}

                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 mb-5">
                                            <div className="form-group">
                                                <label className="theme-color fw-bold">Confirm Password</label>
                                                <div className="input-container">
                                                    <input type={show1 ? "text" : "password"} className="form-control p-0" placeholder="Confirm Password." name="confirmpassword" value={values.confirmpassword} onChange={handleChange} onBlur={handleBlur} />
                                                    <img src="assets/images/view.png" className="input-img" alt="" onClick={handleShow1} />
                                                </div>
                                                {errors.confirmpassword && touched.confirmpassword ? <p style={{ color: 'red' }}>{errors.confirmpassword}</p> : ""}

                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 text-center">
                                            {/* < Link to='/event-dates-info' className="btn btn-save w-50">SIGN UP</Link> */}
                                            <button className="btn btn-save w-50" onClick={handleSubmit}>SIGN UP</button>
                                            
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center mb-5 mt-3">
                                        have an account? <Link to="/login-email" className="text-dark"><b>Log In!</b></Link>
                                    </div>
                                </form>

                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 py-3">
                            <img src="assets/images/long-hair-guitar-guy-singing-low.png" className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            {showEmailOtp ? <OtpEmailModal show={showEmailOtp} handleShow={handleShowEmailOtp} email={values.email} setShowPhoneOtp={setShowPhoneOtp} number={value} /> : ""}
            {showPhoneOtp ? <OtpPhoneModal show={showPhoneOtp} handleShow={handleShowPhoneOtp} number={value} handleSignUp={handleSignup}/> : ""}
        </>
    )
}

export default Signup