import React, { useState } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addBandStaffSchema } from '../addBandStaffSchema';
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import de from 'react-phone-input-2/lang/de.json'


const initialValues = {
    email: "",
    mobile: "",
    countryCode: "+49",
    description: "",
}
const AddBandStaff = () => {
    let navigate = useNavigate()
    const cookee = new Cookies();
    const token = cookee.get('token')
    const [value, setValue] = useState("")
    const [countryData, setCountryData] = useState([])
    const [allBandleader, setAllBandleader] = useState([])
    const [permission, setPermission] = useState("")
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: addBandStaffSchema,
        onSubmit: (values, action) => {
            handleAdd()
        }
    })
    console.log(values.countryCode)
    console.log(values.mobile)

    const handlePermission = (e) => {
        setPermission(e.target.value)
    }

    const handleAdd = (e) => {
        e.preventDefault()
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/add-band-leader-staff`,
            data:
            {
                "description": values.description,
                "email": values.email,
                "country_code": values.countryCode,
                "phone_number": values.mobile,
                "permission_type": permission ? permission : ""
            }
        })
            .then(res => {
                if (res.status === 200) {
                    toast.success(res.data.message, {
                        theme: 'colored',
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    navigate("/band-staff")
                }

            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    theme: "colored",
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }
    return (
        <article className="col-lg-9">
            <div className="article-body store-bd-gy">

                <div className="row mt-3">
                    <div className="col-sm-12">
                        <form className="row mt-5 addNAme mx-width">

                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <input type="text" className="form-control" name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} placeholder="email" />
                                {errors.email && touched.email ? <p style={{ color: 'red' }}>{errors.email}</p> : ""}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <PhoneInput country="DE"
                                    inputProps={{
                                        name: "mobile",
                                    }}
                                    enableSearch
                                    value={
                                        values.countryCode + values.mobile
                                    }
                                    onChange={(phone, country, e, formattedValue) => {
                                        setCountryData({ formattedValue, country });
                                        if (phone.length > 0) {
                                            const raw = phone.slice(
                                                country?.dialCode?.length
                                            );
                                            setFieldValue("mobile", raw);
                                            setFieldValue(
                                                "countryCode",
                                                formattedValue?.split(" ")[0]
                                            );
                                        } else {
                                            setFieldValue("mobile", "");

                                            setFieldValue("countryCode", "");
                                        }
                                    }}
                                    onBlur={handleBlur}
                                    placeholder="Contact number"
                                    containerclassName=" signupClass"
                                    localization={de}


                                />
                               
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <p><select className="form-control  mt-1" onChange={(e) => handlePermission(e)}>
                                    <option>Select Permission</option>
                                    <option value="1">Musician</option>
                                    <option value="2">Bandleader</option>
                                    <option value="3">Staff</option>
                                    <option value="4">Manager
                                    </option>z
                                    <option value="5">Booker</option>


                                </select></p>                        </div>

                            <div className="col-sm-6 col-md-6 col-lg-12 mb-5">
                                {/* <input type="text" className="form-control" placeholder="Description/Information" /> */}
                                <textarea className='form-control' name="description" value={values.description} onChange={handleChange} onBlur={handleBlur} placeholder='Description' />
                                {errors.description && touched.description ? <p style={{ color: 'red' }}>{errors.description}</p> : ""}
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                <div className="d-flex justify-content-center my-3">
                                    <button className="btn add-new" onClick={handleAdd}>Add</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </article>
    )
}

export default AddBandStaff