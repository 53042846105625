import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddSongModal from "./addSong";
import { gemaEventDetails } from "../../app_redux/slice/gemaEventDetail";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import LoadListModal from "./gemaLoadlist";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { getEventDetail } from "../../app_redux/slice/eventDetail";
import DeleteEventSong from "./deleteEventSong";
import { useTranslation } from "react-i18next";

const GemaEdit = ({
  show,
  handleShow,
  id,
  setShowSetlistSong,
  setSetlistIdentity,
  eventid,
}) => {
  const { t } = useTranslation();
  const cookee = new Cookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lng = cookee.get("googtrans");
  const { eventDetails } = useSelector((state) => state.eventdetail);
  const token = cookee.get("token");
  const { gemaDetails, loading } = useSelector((state) => state.gemaevents);
  const [allData, setAllData] = useState([]);
  const [eventName, setEventName] = useState(eventDetails[0]?.event_name);
  const [location, setLocation] = useState("");
  const [town, setTown] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [date, setDate] = useState("");
  const [stageStartTime, setStageStartTime] = useState("");
  const [stageEndTime, setStageEndTime] = useState("");
  const [organizerName, setOrganizerName] = useState("");
  const [organizerStreet, setOrganizerStreet] = useState("");
  const [organizerHouseNo, setOrganizerHouseNo] = useState("");
  const [organizerTown, setOrganizerTown] = useState("");
  const [organizerZipcode, setOrganizerZipcode] = useState("");
  const [organizerId, setOrganizerId] = useState("");
  const [organizerCountry, setOrganizerCountry] = useState("");
  const [organizerPerson, setOrganizerPerson] = useState("");
  const [organizerEmail, setOrganizerEmail] = useState("");
  const [organizerGemano, setOrganizerGemano] = useState("");
  const [organizerPhone, setOrganizerPhone] = useState("");

  const [showAddModal, setShowAddModal] = useState(false);
  const [showLoadlistModal, setShowLoadlistModal] = useState(false);
  const [works, setworks] = useState("");
  const [copyright, setCopyright] = useState("");
  const [lengthSet, setLengthSet] = useState("");
  const [eventType, setEventType] = useState("");
  const [performanceType, setPerformanceType] = useState("");
  const [eventCountry, setEventCountry] = useState("");
  const [eventHouseNo, setEventHouseNo] = useState("");
  const [eventZipcode, setEventZipcode] = useState("");
  const [infoAccommodationId, setInfoAccommodationId] = useState("");
  const [infoAccommodationName, setInfoAccommodationName] = useState("");
  const [infoAccommodationStreet, setInfoAccommodationStreet] = useState("");
  const [infoAccommodationHouseNo, setInfoAccommodationHouseNo] = useState("");
  const [infoAccommodationPostal, setInfoAccommodationPostal] = useState("");
  const [infoAccommodationTown, setInfoAccommodationTown] = useState("");
  const [infoAccommodationEmail, setInfoAccommodationEmail] = useState("");
  const [infoAccommodationPhone, setInfoAccommodationPhone] = useState("");
  const [infoAccommodationOther, setInfoAccommodationOther] = useState("");
  const [infoAccommodationCountry, setInfoAccommodationCountry] = useState("");
  const [locationEmail, setLocationEmail] = useState("");
  const [locationPhone, setLocationPhone] = useState("");
  const [locationStreet, setLocationStreet] = useState("");
  const [locationHouseNo, setLocationHouseNo] = useState("");
  const [locationOther, setLocationOther] = useState("");
  const [parking, setParking] = useState("");
  const [date2, setDate2] = useState("");
  const [setlistId, setSetlistId] = useState(eventDetails[0]?.event_setlist_id);
  const [deleteShow, setDeleteShow] = useState(false);
  const [songId, setSongId] = useState("");

  const {
    tab26,
    tab27,
    tab16,
    tab17,
    tab18,
    tab19,
    tab20,
    tab21,
    tab22,
    tab23,
    tab24,
    tab28,
    tab29,
    tab30,
    tab31,
    tab32,
    tab38,
  } = t("gema");

  useEffect(() => {
    setTimeout(getData, 1000);
  }, []);
  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }
  const handleEventType = (e) => {
    setEventType(e.target.value);
  };
  const handlePerformanceType = (e) => {
    setPerformanceType(e.target.value);
  };
  const handleShowAdd = () => {
    setShowAddModal(!showAddModal);
  };
  const handleShowLoadlist = () => {
    setShowLoadlistModal(!showLoadlistModal);
  };
  const handleAdd = () => {
    handleShowAdd();
  };
  const handleLoadlist = () => {
    handleShowLoadlist();
  };
  const handleChange = (e, setState) => {
    setState(e.target.value);
  };
  const getData = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-event-details-event-id`,
      data: {
        event_id: eventid,
      },
    }).then((res) => {
      dispatch(getEventDetail({ eventid: eventid }));
      setAllData(res.data.data);
      setEventName(res.data.data[0].event_name);
      setLocation(res.data.data[0].location_name);
      setLocationEmail(res.data.data[0].location_email);
      setLocationPhone(res.data.data[0].location_phone_number);
      setLocationStreet(res.data.data[0].location_street);
      setLocationOther(res.data.data[0].location_other_information);
      setDate(formatDate(res.data.data[0].start_date?.substr(0, 10)));
      setDate2(res.data.data[0].start_date);
      setSetlistId(res.data.data[0].set_list_id);
      setStageStartTime(res.data.data[0].stage_start_time);
      setTown(res.data.data[0].town);
      setZipcode(res.data.data[0].event_zip_code);
      setStageEndTime(res.data.data[0].stage_end_time);
      setOrganizerName(res.data.data[0]?.info_organizer[0]?.organizer_name);
      setOrganizerTown(res.data.data[0]?.info_organizer[0]?.organizer_town);
      setOrganizerStreet(res.data.data[0]?.info_organizer[0]?.organizer_street);
      setOrganizerHouseNo(
        res.data.data[0]?.info_organizer[0]?.organizer_house_number
      );
      setOrganizerZipcode(
        res.data.data[0]?.info_organizer[0]?.organizer_postal
      );

      setOrganizerId(res.data.data[0]?.info_organizer[0]?.event_organizer_id);
      setOrganizerCountry(
        res.data.data[0]?.info_organizer[0]?.organizer_country
      );
      setOrganizerPerson(
        res.data.data[0]?.info_organizer[0]?.contact_person_name
      );
      setOrganizerEmail(res.data.data[0]?.info_organizer[0]?.email);
      setOrganizerPhone(res.data.data[0]?.info_organizer[0]?.phone_number);
      setOrganizerGemano(
        res.data.data[0]?.info_organizer[0]?.gema_customer_number
      );
      setInfoAccommodationId(
        res.data.data[0]?.info_accommodation[0]?.event_accomodation_id
      );
      setInfoAccommodationName(
        res.data.data[0]?.info_accommodation[0]?.accomodation_name
      );
      setInfoAccommodationEmail(
        res.data.data[0]?.info_accommodation[0]?.accomodation_email
      );
      setInfoAccommodationPhone(
        res.data.data[0]?.info_accommodation[0]?.accomodation_phone_number
      );
      setInfoAccommodationPostal(
        res.data.data[0]?.info_accommodation[0]?.accomodation_postal
      );
      setInfoAccommodationStreet(
        res.data.data[0]?.info_accommodation[0]?.accomodation_street
      );
      setInfoAccommodationTown(
        res.data.data[0]?.info_accommodation[0]?.accomodation_town
      );
      setInfoAccommodationHouseNo(
        res.data.data[0]?.info_accommodation[0]?.accomodation_house_number
      );
      setInfoAccommodationCountry(
        res.data.data[0]?.info_accommodation[0]?.accomodation_country
      );
      setInfoAccommodationOther(
        res.data.data[0]?.info_accommodation[0]?.accomodation_other_information
      );
      setworks(res.data.data[0]?.total_number_of_works);
      setLengthSet(res.data.data[0]?.length_set);
      setCopyright(res.data.data[0]?.number_of_works_with_copyright);
      setEventType(res.data.data[0]?.type_of_event);
      setPerformanceType(res.data.data[0]?.type_of_performance);
      setEventCountry(res.data.data[0]?.event_country);
      setLocationHouseNo(res.data.data[0]?.event_house_number);
      setEventZipcode(res.data.data[0]?.event_zip_code);
    });
  };
  const handleDeletelist = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/delete-band-leader-event-setlist`,
      data: {
        event_id: eventDetails[0]?.event_id || "",
        event_type: 1,
        set_list_id: eventDetails[0]?.set_list_id || "",
      },
    }).then((res) => {
      if (res.data.message === "Event SetList Deleted Successfully!.") {
        toast.success("Deleted Setlist", {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(getEventDetail({ eventid: eventDetails[0]?.event_id || "" }));
      }
    });
  };
  const updateData = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/update-band-leader-event-info`,
      data: {
        event_id: gemaDetails[0]?.event_id,
        event_name: eventName,
        info_organizer: [
          {
            event_organizer_id: 12,
            organizer_name: "zx  edir services",
            organizer_street: "sc/45/13",
            organizer_postal: "cu1245",
            organizer_town: "germany 1",
            contact_person_name: "sk sa",
            email: "germany",
            phone_number: "23454768",
            gema_customer_number: "accomation@gmail.com",
          },
        ],
        info_accommodation: [
          {
            event_accomodation_id: 9,
            accomodation_name: "zx edit  services",
            accomodation_street: "sc/45/13",
            accomodation_postal: "cu1245",
            accomodation_town: "germany 1",
            accomodation_country: "germany",
            accomodation_phone_number: "23454768",
            accomodation_email: "accomation@gmail.com",
            accomodation_other_information: "other information",
          },
        ],
        start_time: "13:00:00",
        start_date: date,
        latitude: 102.88,
        longitude: 109.77,
        town: location,
        priority: 1,
        event_type: 1,
      },
    }).then((res) => {
      if (res.data.message === "Event Info Successfully!.") {
        alert("updated..");
        navigate("/gema");
      }
    });
  };
  const handleDeleteShow = () => {
    setDeleteShow(!deleteShow);
  };
  const handleDeleteSong = (id) => {
    setSongId(id);
    handleDeleteShow();
  };
  const handleEventInfo = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/update-band-leader-event-info`,
      data: {
        event_id: eventDetails[0]?.event_id || "",
        event_name: eventName,
        info_organizer: [
          {
            event_organizer_id: organizerId,
            organizer_name: organizerName,
            organizer_street: organizerStreet,
            organizer_house_number: organizerHouseNo,
            organizer_postal: organizerZipcode,
            organizer_town: organizerTown,
            contact_person_name: organizerPerson,
            email: organizerEmail,
            phone_number: organizerPhone,
            gema_customer_number: organizerGemano,
            organizer_country: organizerCountry,
          },
        ],
        info_accommodation: [
          {
            event_accomodation_id: infoAccommodationId,
            accomodation_name: infoAccommodationName,
            accomodation_street: infoAccommodationStreet,
            accomodation_house_number: infoAccommodationHouseNo,
            accomodation_postal: infoAccommodationPostal,
            accomodation_town: infoAccommodationTown,
            accomodation_country: infoAccommodationCountry,
            accomodation_phone_number: infoAccommodationPhone,
            accomodation_email: infoAccommodationEmail,
            accomodation_other_information: infoAccommodationOther,
          },
        ],
        start_time: "20:00:00",
        end_time: "20:00:00",
        load_start_time: "",
        load_end_time: "",
        sound_start_time: "",
        sound_end_time: "",
        stage_start_time: stageStartTime,
        stage_end_time: stageEndTime,
        type_of_event: eventType,
        type_of_performance: performanceType,
        start_date: date2,
        latitude: 15,
        longitude: 109.77,
        location_name: location,
        location_street: locationStreet,
        loc_name_of_person: "",
        location_email: locationEmail,
        location_phone_number: locationPhone,
        location_other_information: locationOther,
        town: town,
        event_country: eventCountry,
        event_zip_code: zipcode,
        event_house_number: locationHouseNo,
        event_type: 1,
        parking: "",
        guest_list: "",
        catering: "",
        sonstige_other_info: "",
        total_number_of_works: works,
        number_of_works_with_copyright: copyright,
        length_set: lengthSet,
      },
    }).then((res) => {
      //  console.log("RESPONSE", res.data)
      if (res.status === 200) {
        toast.success(res.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        //    setEvent_Id(res.data.data.event_id)
      }
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleShow} size="xl">
        <Modal.Body className="p-0">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close close-Icon"
              onClick={handleShow}
            ></button>
            <article className="col-lg-9">
              <div className="article-body store-bd-gy">
                <form className="row my-5 py-5 addNAme mx-width">
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                    <input
                      type="text"
                      className="form-control"
                      value={eventName}
                      placeholder="Name of Event"
                      onChange={(e) => handleChange(e, setEventName)}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                    <p>
                      <select
                        className="form-control mt-1 notranslate"
                        value={eventType}
                        onChange={(e) => handleEventType(e)}
                      >
                        <option>{tab26}</option>
                        <option value="Fasching/Karneval">
                          Fasching/Karneval
                        </option>
                        <option value="Fest-/Straßenumzüge">
                          Fest-/Straßenumzüge
                        </option>
                        <option value="Hintergrundmusik">
                          Hintergrundmusik
                        </option>
                        <option value="Kabarett">Kabarett</option>
                        <option value=" Klassisches Konzert">
                          {" "}
                          Klassisches Konzert
                        </option>
                        <option value="Konzert">Konzert</option>
                        <option value="OpenAir Konzert">OpenAir Konzert</option>
                        <option value="Party/Feier">Party/Feier</option>
                        <option value="Sonstiges">Sonstiges</option>
                        <option value="Tanz/Bälle">Tanz/Bälle</option>
                        <option value="Bühnen-/Theatermusik">
                          Bühnen-/Theatermusik
                        </option>
                      </select>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-2 col-lg-2 mb-5">
                    <input
                      type="text"
                      className="form-control"
                      value={stageStartTime?.substr(0, 5)}
                      onChange={(e) => handleChange(e, setStageStartTime)}
                      placeholder="Stage Start Time"
                    />
                  </div>
                  <div className="col-sm-6 col-md-2 col-lg-2 mb-5">
                    <input
                      type="text"
                      className="form-control"
                      value={stageEndTime?.substr(0, 5)}
                      onChange={(e) => handleChange(e, setStageEndTime)}
                      placeholder="Stage End Time"
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                    <input
                      type="text"
                      className="form-control"
                      value={date}
                      onChange={(e) => handleChange(e, setDate)}
                      placeholder="Date of Event"
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                    <p>
                      <select
                        className="form-control  mt-1 notranslate"
                        value={performanceType}
                        onChange={handlePerformanceType}
                      >
                        <option>{tab27}</option>
                        <option value="Hauptgruppe">Hauptgruppe</option>
                        <option value="Vorgruppe">Vorgruppe</option>
                        <option value="Alleinige Band / Esemble">
                          Alleinige Band
                        </option>
                        <option value="Mehrer gleichberechtigte Bands / Künstler">
                          Mehrer gleichberechtigte Bands / Künstler
                        </option>
                        <option value="DJ-Live Act">DJ-Live Act</option>
                        <option value="Bühnen-/Theateraufführung">
                          Theateraufführung
                        </option>
                      </select>
                    </p>{" "}
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5"></div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5 mt-5">
                    <h6 className="notranslate">Location</h6>
                    <input
                      type="text"
                      className="form-control"
                      value={location}
                      onChange={(e) => handleChange(e, setLocation)}
                      placeholder={
                        lng === "/auto/de" || "/en/de"
                          ? "Name Location"
                          : "Location Name"
                      }
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5 mt-5">
                    <h6>
                      {lng === "/auto/de" || "/en/de"
                        ? "Veranstalter"
                        : "Organizer Information"}
                    </h6>
                    <input
                      type="text"
                      className="form-control"
                      value={organizerName}
                      onChange={(e) => handleChange(e, setOrganizerName)}
                      placeholder={
                        lng === "/auto/de" || "/en/de"
                          ? "Name Veranstalter"
                          : "Organizer Name"
                      }
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5 mt-5">
                    <p className="mt-4"></p>
                    {/* <input type="text" className="form-control" value={works} placeholder="Number of Works" onChange={(e) => handleChange(e, setworks)} /> */}
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                    <input
                      type="text"
                      className="form-control"
                      value={locationStreet}
                      onChange={(e) => handleChange(e, setLocationStreet)}
                      placeholder={tab18}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                    <input
                      type="text"
                      className="form-control"
                      value={organizerStreet}
                      onChange={(e) => handleChange(e, setOrganizerStreet)}
                      placeholder={tab18}
                    />
                  </div>

                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                    {/* <input type="text" className="form-control" value={copyright} placeholder="Number of Works with Copyright" onChange={(e) => handleChange(e, setCopyright)} /> */}
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                    <input
                      type="text"
                      className="form-control"
                      value={locationHouseNo}
                      onChange={(e) => handleChange(e, setLocationHouseNo)}
                      placeholder={
                        lng === "/auto/de" || "/en/de"
                          ? "Hausnr"
                          : "House-number"
                      }
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                    <input
                      type="text"
                      className="form-control"
                      value={organizerHouseNo}
                      onChange={(e) => handleChange(e, setOrganizerHouseNo)}
                      placeholder={
                        lng === "/auto/de" || "/en/de"
                          ? "Hausnr"
                          : "House-number"
                      }
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                    {/* <input type="text" className="form-control" value={lengthSet?.substr(0, 5)} placeholder="Long Set" onChange={(e) => handleChange(e, setLengthSet)} /> */}
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                    <input
                      type="text"
                      className="form-control"
                      value={zipcode}
                      onChange={(e) => handleChange(e, setZipcode)}
                      placeholder={tab20}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                    <input
                      type="text"
                      className="form-control"
                      value={organizerZipcode}
                      onChange={(e) => handleChange(e, setOrganizerZipcode)}
                      placeholder={tab20}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5"></div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                    <input
                      type="text"
                      className="form-control"
                      value={town}
                      onChange={(e) => handleChange(e, setTown)}
                      placeholder={tab21}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                    <input
                      type="text"
                      className="form-control"
                      value={organizerTown}
                      onChange={(e) => handleChange(e, setOrganizerTown)}
                      placeholder={tab21}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5"></div>

                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                    <input
                      type="text"
                      className="form-control"
                      value={eventCountry}
                      onChange={(e) => handleChange(e, setEventCountry)}
                      placeholder={tab22}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                    <input
                      type="text"
                      value={organizerCountry}
                      onChange={(e) => handleChange(e, setOrganizerCountry)}
                      className="form-control"
                      placeholder={tab22}
                    />
                  </div>
                </form>

                <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
                  <h4>{tab28}</h4>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="d-flex align-items-center lg-justify-content-end">
                      {/* <button className="btn add-new" onClick={handleAdd}>add song</button> */}
                    </div>
                  </div>
                  <div className="pt-3 mt-3 px-1 bg-white shadow">
                    <div className="table-responsive text-center">
                      <table className="table storetable">
                        <thead className="">
                          <tr>
                            <th scope="col">GEMA number</th>
                            <th scope="col">name of song</th>
                            <th scope="col">{tab32}</th>
                            <th scope="col">{tab30}</th>
                            <th scope="col">{tab29}</th>
                          </tr>
                          <tr className="border-0 bg-none invisible">
                            <td className="border-0 bg-none"></td>
                          </tr>
                        </thead>
                        <tbody>
                          {eventDetails[0]?.set_list_details?.length > 0
                            ? eventDetails[0]?.set_list_details.map(
                                (res, i) => {
                                  return (
                                    <tr>
                                      <td>{res.gemma_number}</td>
                                      <td className="notranslate">
                                        {res.song_name}
                                      </td>
                                      <td>{res.time_length}</td>
                                      <td>
                                        {res.is_copyright === 0 ? (
                                          <img
                                            src="assets/images/copyBlack.png"
                                            width="25px"
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            src="assets/images/copyRed.png"
                                            width="25px"
                                            alt=""
                                          />
                                        )}
                                      </td>
                                      <td>
                                        <img
                                          src="assets/images/deleteBlack.png"
                                          width="25px"
                                          alt=""
                                          onClick={() =>
                                            handleDeleteSong(res.event_song_id)
                                          }
                                        />
                                      </td>
                                    </tr>
                                  );
                                }
                              )
                            : ""}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                    <div className="d-flex align-items-center lg-justify-content-end">
                      {eventDetails[0]?.set_list_id !== null ? (
                        <button
                          className="btn add-new-cancel"
                          onClick={handleDeletelist}
                        >
                          {tab31}
                        </button>
                      ) : (
                        <button
                          className="btn add-new-cancel"
                          onClick={handleLoadlist}
                        >
                          {tab38}
                        </button>
                      )}{" "}
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                    <div className="d-flex align-items-center lg-justify-content-end">
                      <button className="btn add-new" onClick={handleEventInfo}>
                        {lng === "/auto/de" || "/en/de" ? "Speichern" : "safe"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showAddModal ? (
        <AddSongModal
          show={showAddModal}
          handleShow={handleShowAdd}
          id={eventDetails[0]?.event_setlist_id}
          eventid={eventid}
        />
      ) : (
        ""
      )}
      {showLoadlistModal ? (
        <LoadListModal
          show={showLoadlistModal}
          handleShow={handleShowLoadlist}
          id={setlistId}
          eventid={eventid}
        />
      ) : (
        ""
      )}
      {deleteShow ? (
        <DeleteEventSong
          show={deleteShow}
          handleShow={handleDeleteSong}
          id={songId}
          eventid={eventDetails[0]?.event_id}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default GemaEdit;
