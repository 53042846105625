import React, { useEffect } from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getLoadSetup } from '../../../app_redux/slice/setupData'
import { useDispatch, useSelector } from 'react-redux'



const LoadSetup = ({ show, handleShow, id, personList, setLoadsetupPersonList, setNewLoad }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const cookee = new Cookies();
    const token = cookee.get('token')
    // const [loadSetup, setLoadSetup] = useState("")
    const { loadSetup, loading } = useSelector((state) => state.loadSetup)
    useEffect(() => {
        dispatch(getLoadSetup());
    }, [])

    const deleteSetup = (id) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/delete-load-set-up-by-load-set-up-id`,
            data:
            {
                "load_setup_id": id
            }
        })
            .then(res => {
                if (res.data.message === "Load Setup Deleted Successfully!.") {
                    toast.success(`Setup Deleted Successfully`, {
                        theme: "colored",
                        position: "bottom-center",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    dispatch(getLoadSetup());
                }
            })
    }
    const handleAdd = (id) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-load-set-up-details-by-load-set-up-id`,
            data:
            {
                "load_setup_id": id
            }
        })
            .then(res => {
                // console.log("res", res.data)
                setLoadsetupPersonList(res.data.data.band_details)
                setNewLoad(res.data.data.band_details.map((res, i) => {
                    return { role: res.role, person: [{ user_id: res.user_id, priority: res.priority, amount: res.amount, additional_information: res.additional_information }] }
                }))
                toast.success(`Setup Added`, {
                    theme: "colored",
                    position: "bottom-center",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                handleShow()
            })
    }
    return (
        <>
            <Modal show={show} onHide={handleShow} size="lg" >
                <Modal.Body className="p-0">
                    <div className="modal-body">
                        <button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                        <h4 className='mt-2'>List Setup</h4>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="pt-3 px-1 bg-white">
                                    <div className="table-responsive">
                                        <table className="table storetable text-center">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col">name setup</th>
                                                    <th scope="col">event type</th>
                                                    <th></th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                                <tr className="border-0 bg-none invisible"><td className="border-0 bg-none"></td></tr>
                                            </thead>
                                            <tbody>
                                                {loadSetup !== undefined && loadSetup.length !== 0 ? loadSetup?.map((res, key) => {
                                                    return <tr>
                                                        <td className='notranslate'>{res.set_up_name}</td>
                                                        <td>{res.event_type === 1 ? "Concert" : ""}</td>
                                                        <td></td>
                                                        <td>
                                                            <div className="d-flex gap-3 justify-content-center">
                                                                {/* <img src="assets/images/deleteBlack.png" width="25px" alt="" /><img src="assets/images/printing.png" width="25px" alt="" /> */}
                                                                <button className="btn add-new" onClick={() => handleAdd(res.load_setup_id
                                                                )} >Add</button>
                                                                <button className="btn add-new" onClick={() => deleteSetup(res.load_setup_id
                                                                )}>Delete</button>
                                                                <input type="checkbox" id="vehicle1" name="vehicle1" value="" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }) : <p className='mt-5'>No Setup Found!!</p>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </Modal.Body>
            </Modal>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default LoadSetup;