import React, { useState, useEffect } from 'react'
import OTPInput from "otp-input-react";
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap'




const OtpVerify = ({ show, handleShow, handleDelete }) => {
    const navigate = useNavigate();
    const cookee = new Cookies();
    const [OTP, setOTP] = useState("");
    const phone = localStorage.getItem("phone")
    const email = cookee.get("email")
    const [counter, setCounter] = useState(60)

    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer)
    }, [counter])

    const handleOtpVerify = (e) => {
        e.preventDefault();
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/verify-musician-otp-with-email`,
            data:
            {

                "email": email,
                "verify_otp": OTP
            }
        })
            .then(res => {
                if (res.status === 200) {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    handleDelete()
                }

            })
            .catch((error) => {
                // console.log("Error", error.response)
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })

            })
    }
    const handleResendOtp = (e) => {
        e.preventDefault()
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/send-musician-otp-with-email`,
            data:
            {
                "email": email,
            }
        })
            .then(res => {

                if (res.status === 200) {
                    setCounter(60)
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }

            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })

            })
    }

    return (
        <Modal show={show} onHide={handleShow} size="lg" >
            <Modal.Body className="p-0">
                <button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                <div className="row align-items-center">
                    <div className="col-sm-12 col-md-12 col-lg-12 mr-0 pr-0">
                        <div className="form-bg">
                            <form className="row form-style">
                                <div className="col-sm-12 col-md-12 text-center mb-4">
                                    <div className="logo-bg text-center">
                                        <div className="mt-2"><img src="assets/images/logo.png" className="img-fluid logo-admin" alt="logo" /></div>
                                    </div>
                                </div>
                                <div className="col-sm-12 text-center">
                                    <img src="assets/images/otp.png" className="img-fluid  iconIMg my-3" alt="" />
                                    <h2 className="mdl-ttl">OTP Verification</h2>
                                    <p className="mdl-sbttl">Please enter 6 digit code, which has been sent on your registered email address</p>
                                </div>
                                <div className="form-group col-sm-12">
                                    <div className="passcode-wrapper mx-4 ">

                                        <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} placeholder="000000" />

                                    </div>
                                </div>
                                {/* <div className="col-sm-12 text-center">
                                    <span>00:{counter}</span>
                                </div> */}
                                <div className="col-sm-12 mt-4 text-center">
                                    <button className="btn btn-save w-50" onClick={handleOtpVerify}>Verify</button>
                                </div>
                                {counter === 0 ?
                                    <div className="col-sm-12 text-center mt-4">
                                        <div><a href="#" className="forgot-pass p-0" onClick={handleResendOtp}>Resend Code</a></div>
                                    </div> : ""}
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />
            </Modal.Body>
        </Modal>

    )
}

export default OtpVerify