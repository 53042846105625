import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { getEventDetail } from '../../app_redux/slice/eventDetail'


const AddSongModal = ({ show, handleShow, id,eventid }) => {
    const dispatch = useDispatch()
    const cookee = new Cookies();
    const token = cookee.get('token')
    const [songs, setSongs] = useState([])


    useEffect(() => {
        getSongs()
        //dispatch(getSongs())
    }, [])
    const getSongs = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/band-leader-song-list`,
            data:
            {
                "page_no": 1
            }
        })
            .then(res => {
                setSongs(res.data.data)
            })

    }
    const addSongToList = (songid) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/add-song-to-band-leader-set-list`,
            data:
            {
                "set_list_id": id,
                "set_list": [
                    {
                        "song_id": songid
                    }
                ]
            }
        })
            .then(res => {
                if (res.status === 200) {
                    toast.success(res.data.message, {
                        theme: "colored",
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    dispatch(getEventDetail({ eventid: eventid }))
                    handleShow()
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    theme: "colored",
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })

    }
    return (
        <>
            <Modal show={show} onHide={handleShow} size="lg" >
                <Modal.Body className="p-0">
                    <div className="modal-body"><button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                        <div className="row mt-3 align-items-center">
                            <div className="col-sm-12">
                                <div className="pt-3 px-1 bg-white">
                                    <div className="table-responsive">
                                        <table className="table storetable text-center">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col">song name</th>
                                                    <th scope="col">time length</th>
                                                    <th scope="col">action</th>
                                                    <th scope="col"></th>
                                                </tr>
                                                <tr className="border-0 bg-none invisible"><td className="border-0 bg-none"></td></tr>
                                            </thead>
                                            <tbody>
                                                {songs?.length !== 0 ? songs.map((res, key) => {
                                                    return <tr>
                                                        <td>{res.song_name}</td>
                                                        <td>{res.time_length}</td>

                                                        <td><img src="assets/images/delete.png" width="25px" alt="" /></td>
                                                        <td><button className="btn add-new" onClick={() => addSongToList(res.m_song_id)}>add</button></td>
                                                    </tr>
                                                }) : <p className='mt-3'>No Record Found!!</p>}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default AddSongModal