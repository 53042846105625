import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import DeleteTask from "./deleteTask";
import { useSelector, useDispatch } from "react-redux";
import { getAllTasks } from "../../app_redux/slice/allTask";
import { useTranslation } from "react-i18next";

const Tasklist = () => {
  const { t } = useTranslation();
  const cookee = new Cookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = cookee.get("token");
  const [newTask, setNewTask] = useState([]);
  const [assignTask, setAssignTask] = useState([]);
  const [adminTask, setAdminTask] = useState([]);
  const [showOwnTask, setShowOwnTask] = useState(false);
  const [showBandTask, setShowBandTask] = useState(false);
  const [showAllTask, setShowAllTask] = useState(true);
  const [showAdminTask, setShowAdminTask] = useState(false);
  const [deleteTask, setDeleteTask] = useState(false);
  const [taskId, setTaskid] = useState("");
  const [taskStatus, setTaskStatus] = useState();
  const { allTasks, loading } = useSelector((state) => state.alltask);
  const { tab1, tab2, tab3, tab4 } = t("tasks");

  useEffect(() => {
    getNewTasks();
    getAssignTasks();
    getAdminTasks();
    dispatch(getAllTasks());
  }, []);
  const handleAllTask = () => {
    setShowAllTask(true);
    setShowBandTask(false);
    setShowOwnTask(false);
    setShowAdminTask(false);
  };
  const handleBandTask = () => {
    setShowBandTask(true);
    setShowOwnTask(false);
    setShowAllTask(false);
    setShowAdminTask(false);
  };
  const handleOwnTask = () => {
    setShowOwnTask(true);
    setShowBandTask(false);
    setShowAllTask(false);
    setShowAdminTask(false);
  };
  const handleAdminTask = () => {
    setShowOwnTask(false);
    setShowBandTask(false);
    setShowAllTask(false);
    setShowAdminTask(true);
  };
  const handleDeleteTask = () => {
    setDeleteTask(!deleteTask);
  };
  const handleDelete = (id, i) => {
    setTaskid(id);
    if (i !== undefined) {
      setTaskStatus(i);
      handleDeleteTask();
    } else {
      setTaskStatus(1);
      handleDeleteTask();
    }
  };
  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];

    return day + "." + month + "." + year;
  }
  const getNewTasks = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-task-list`,
      data: {
        page_no: 1,
        is_self: 1,
        status: 1,
      },
    }).then((res) => {
      setNewTask(res.data.data);
    });
  };
  const getAssignTasks = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-task-list`,
      data: {
        page_no: 1,
        is_self: 2,
        status: 1,
      },
    }).then((res) => {
      setAssignTask(res.data.data);
    });
  };
  const getAdminTasks = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-task-list`,
      data: {
        page_no: 1,
        is_self: 0,
        status: 1,
      },
    }).then((res) => {
      setAdminTask(res.data.data);
    });
  };
  const handleClick = (id) => {
    localStorage.setItem("taskid", id);
    navigate("/task-overview");
  };
  const handleClickTask = (id) => {
    localStorage.setItem("taskid", id);
    navigate("/task-overview-assigned");
  };
  const handleClickTaskAdmin = (id) => {
    localStorage.setItem("taskid", id);
    navigate("/task-overview-admin");
  };
  const handleAllTaskDetail = (id, id2, id3) => {
    if (id !== undefined) {
      handleClickTask(id);
    } else if (id3 !== undefined) {
      handleClickTaskAdmin(id3);
    } else {
      handleClick(id2);
    }
  };
  const handleChat = (id) => {
    navigate("/chat-task", { state: { taskid: id || "" } });
  };
  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row mt-3">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
              <h3>Task</h3>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
              <div className="d-flex align-items-center lg-justify-content-end gap-3 mx-2">
                <Link
                  to="/add-new-task"
                  className="btn add-new notiIcon filterIcon"
                >
                  {tab4}
                </Link>
                <div className="position-relative filterDrop mx-2">
                  <img src="assets/images/Checklist.png" alt="" width="" />
                  <div className="filterDropSelect ChecklistDrop">
                    <p
                      className="text-uppercase fw-bold"
                      onClick={handleBandTask}
                    >
                      musician task
                    </p>
                    <p
                      className="text-uppercase fw-bold"
                      onClick={handleOwnTask}
                    >
                      own task
                    </p>
                    <p
                      className="text-uppercase fw-bold"
                      onClick={handleAdminTask}
                    >
                      admin task
                    </p>
                    <p
                      className="text-uppercase fw-bold"
                      onClick={handleAllTask}
                    >
                      all task
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <ul
                className="nav nav-pills group-nav tabNabs taskListTab"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <Link to="/task-list" className="nav-link active">
                    {tab1}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/task-list-inprogress" className="nav-link">
                    {tab2}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/task-list-completed"
                    className="nav-link"
                    href="task-list-completed.html"
                  >
                    {tab3}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-sm-12">
              {showAllTask && allTasks?.length > 0
                ? allTasks.map((item, i) => {
                    let col = item?.label_color;
                    return (
                      <div
                        className="taskListBox"
                        style={{ border: `2px solid ${col}` }}
                      >
                        <div className="d-flex align-items-center lg-justify-content-end">
                          <button
                            className="btn notiIcon filterIcon "
                            onClick={() => handleChat(item?.users_task_id)}
                          >
                            <img src="assets/images/chatBlackRed.png" alt="" />
                          </button>
                          {item?.is_self === 0 ? (
                            <button className="btn notiIcon filterIcon ">
                              <img
                                src="assets/images/delete.png"
                                alt=""
                                onClick={() =>
                                  handleDelete(
                                    item?.users_task_id,
                                    item?.is_self
                                  )
                                }
                              />
                            </button>
                          ) : (
                            <button className="btn notiIcon filterIcon ">
                              <img
                                src="assets/images/delete.png"
                                alt=""
                                onClick={() =>
                                  handleDelete(item?.band_leader_task_id)
                                }
                              />
                            </button>
                          )}
                        </div>
                        <div className="row align-items-center">
                          <div className="col-sm-8 col-md-8 col-lg-9">
                            <h3
                              onClick={() =>
                                handleAllTaskDetail(
                                  item?.users_task_id,
                                  item?.band_leader_task_id,
                                  item?.task_id
                                )
                              }
                              className="notranslate"
                            >
                              {item?.task_name}
                            </h3>
                            <p
                              onClick={() =>
                                handleAllTaskDetail(
                                  item?.users_task_id,
                                  item?.band_leader_task_id,
                                  item?.task_id
                                )
                              }
                              className="notranslate"
                            >
                              {item?.description_info}
                            </p>
                          </div>
                          <div className="col-sm-4 col-md-4 col-lg-3">
                            <h6>
                              {item?.dead_line
                                ? formatDate(item?.dead_line?.substr(0, 10))
                                : ""}
                            </h6>
                            <span
                              className="StatusBtnAbsolate StatusBtn inProgressStatus notranslate"
                              style={{ backgroundColor: col }}
                            >
                              {item?.labels}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
              {showOwnTask && newTask?.length > 0
                ? newTask.map((item, i) => {
                    let col = item?.label_color;
                    return (
                      <div
                        className="taskListBox"
                        style={{ border: `2px solid ${col}` }}
                        onClick={() => handleClick(item?.band_leader_task_id)}
                      >
                        <div className="row align-items-center">
                          <div className="col-sm-8 col-md-8 col-lg-9">
                            <h3>{item?.task_name}</h3>
                            <p>{item?.description_info}</p>
                          </div>
                          <div className="col-sm-4 col-md-4 col-lg-3">
                            <h6>
                              {item?.dead_line
                                ? formatDate(item?.dead_line?.substr(0, 10))
                                : ""}
                            </h6>
                            <span
                              className="StatusBtnAbsolate StatusBtn inProgressStatus"
                              style={{ backgroundColor: col }}
                            >
                              {item?.labels}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
              {showBandTask && adminTask?.length > 0
                ? assignTask.map((item, i) => {
                    let col = item?.label_color;
                    return (
                      <div
                        className="taskListBox"
                        style={{ border: `2px solid ${col}` }}
                      >
                        <div className="d-flex align-items-center lg-justify-content-end">
                          <button
                            className="btn notiIcon filterIcon "
                            onClick={() => handleChat(item?.users_task_id)}
                          >
                            <img src="assets/images/chatBlackRed.png" alt="" />
                          </button>
                        </div>

                        <div
                          className="row align-items-center"
                          onClick={() => handleClickTask(item?.users_task_id)}
                        >
                          <div className="col-sm-8 col-md-8 col-lg-9">
                            <h3>{item?.task_name}</h3>
                            <p>{item?.description_info}</p>
                          </div>
                          <div className="col-sm-4 col-md-4 col-lg-3">
                            <h6>
                              {item?.dead_line
                                ? formatDate(item?.dead_line?.substr(0, 10))
                                : ""}
                            </h6>
                            <span
                              className="StatusBtnAbsolate StatusBtn inProgressStatus"
                              style={{ backgroundColor: col }}
                            >
                              {item?.labels}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </article>
      {deleteTask ? (
        <DeleteTask
          show={deleteTask}
          handleShow={handleDeleteTask}
          id={taskId}
          status={taskStatus}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Tasklist;
