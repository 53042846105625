import React from "react";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeletePassenger from "./deletePassenger";
import { useTranslation } from "react-i18next";

const PassengersList = ({
  show,
  handleShow,
  vehicleId,
  driverList,
  transportId,
  eventId,
}) => {
  const cookee = new Cookies();
  const { t } = useTranslation();
  const token = cookee.get("token");
  const [passenger, setPassenger] = useState([]);
  const [deletePassenger, setDeletePassenger] = useState(false);
  const [id, setId] = useState("");
  const { tab37, tab68 } = t("event");

  useEffect(() => {
    getAllPassenger();
  }, []);

  const handleDelete = (id) => {
    setId(id);
    setDeletePassenger(!deletePassenger);
  };

  const getAllPassenger = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/bandleader-event-transport-passenger-details`,
      data: {
        transport_id: vehicleId,
      },
    })
      .then((res) => {
        setPassenger(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Modal show={show} onHide={handleShow} size="md" centered>
        <Modal.Body className="p-0">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close close-Icon"
              onClick={handleShow}
            ></button>
            <h4 className="mt-2 text-center">{tab68}</h4>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
                {passenger?.length > 0 ? (
                  passenger?.map((res) => {
                    return (
                      <div className="d-flex justify-content-center">
                        <img
                          src="assets/images/delete.png"
                          alt=""
                          style={{
                            width: "25px",
                            height: "25px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleDelete(res?.transport_det_id)}
                        />
                        <p className="mx-5">{res?.passenger_name}</p>
                      </div>
                    );
                  })
                ) : (
                  <p className="text-center">{tab37}</p>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {deletePassenger ? (
        <DeletePassenger
          show={deletePassenger}
          handleShow={handleDelete}
          passengerId={id}
          eventid={eventId}
          list={handleShow}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default PassengersList;
