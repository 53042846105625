import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import { getEventDetail } from '../../../app_redux/slice/eventDetail'
import { getAttachmentPermission } from '../../../app_redux/slice/getAttachments'
import S3FileUpload from 'react-s3';
window.Buffer = window.Buffer || require("buffer").Buffer;


const EditOtherAttachments = ({ show, handleShow, eventId, showtext }) => {
    const dispatch = useDispatch()
    const cookee = new Cookies();
    const token = cookee.get('token')
    const [members, setMembers] = useState([])
    const [image, setImage] = useState("")
    const [loader, setLoader] = useState(false)
    const [userId, setUserId] = useState("")
    const [assignTo, setAssignTo] = useState([])
    const [allasign, setAllAsign] = useState([])
    const [assignDownload, setAssignDownload] = useState([])
    const [allasignDownload, setAllAsignDownload] = useState([])
    const [bandperson, setBandPerson] = useState([])
    const [uploadShow, setUploadShow] = useState(false)
    const [downloadShow, setDownloadShow] = useState(false)
    const { allFile } = useSelector((state) => state.file)
    // const [filesize, setFileSize] = useState("")
    const [showMusicfilesUser, setShowMusicfilesUser] = useState(false)
    const { eventDetails } = useSelector((state) => state.eventdetail)
    const { Permissions } = useSelector((state) => state.attachmentpermission)
    var filesize = ""

    useEffect(() => {
        getMembers();
        dispatch(getEventDetail({ eventid: eventId }))
        dispatch(getAttachmentPermission({ id: eventId }))
    }, [])

    const handleShowMusicfileUser = () => {
        setShowMusicfilesUser(!showMusicfilesUser)
    }
    const handleClickUser = (e, id) => {
        e.preventDefault();
        setUserId(id)
        handleShowMusicfileUser()
    }
    const getMembers = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-band-leader-staff-members-with-membership`,
            data:
            {
                "page_no": 1
            }
        })
            .then(res => {
                setBandPerson(res.data.data)
            })
    }
    function bytesToSize(bytes) {
        var sizes = ['B', 'K', 'M', 'G', 'T', 'P'];
        for (var i = 0; i < sizes.length; i++) {
            if (bytes <= 1024) {
                return bytes + ' ' + sizes[i];
            } else {
                bytes = parseFloat(bytes / 1024).toFixed(2)
                filesize = `${bytes} kb`
            }
        }
        return bytes + ' P';
    }

    const handleChangePerson = (e) => {
        var data = JSON.parse(e.target.value)
        setAssignTo([...assignTo, data?.user_id])
        setAllAsign([...allasign, data])
        setUploadShow(true)

    }
    const handleChangePersonDownload = (e) => {
        var data = JSON.parse(e.target.value)
        setAssignDownload([...assignDownload, data?.user_id])
        setAllAsignDownload([...allasignDownload, data?.first_name])
        setDownloadShow(true)

    }
    const handleRemove = (res) => {
        const list = [...allasign]
        list.splice(res, 1)
        setAllAsign(list);
    }
    const handleRemoveDownload = (res) => {
        const list = [...allasignDownload]
        list.splice(res, 1)
        setAllAsignDownload(list);
    }
    const handleFile = (e, ids) => {
        setLoader(true)
        bytesToSize(e.target.files[0].size)
        let file = e.target.files[0];
        const config = {
            bucketName: 'clever-band-assist-s3',
            region: 'eu-west-3',
            accessKeyId: 'AKIASTLKHDND66NQZXFJ',
            secretAccessKey: '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',
        }
        S3FileUpload
            .uploadFile(file, config)
            .then(data => {
                // console.log("RES file", data)
                setImage(data.location)
                setLoader(false)
                handleAdd(data.location)
            })
            .catch(err => console.error("ERR", err))
    }
    const handleAdd = (data) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/add-attachment-to-event`,
            data:
            {
                "event_id": eventId,
                "event_type": 1,
                "event_attachments": [
                    {
                        "attachment_type": 7,
                        "attachment_url": data,
                        "size": filesize ? filesize : ""
                    },
                ]
            }
        })
            .then(res => {
                //  console.log("RESPONSE", res.data)
                if (res.data.message === "Attachements Added Successfully!.") {
                    alert("Added!!")
                    dispatch(getEventDetail({ eventid: eventId }))

                }
            })
    }
    const deletePermissionsUpload = (id, d) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/band-update-attachement-permission`,
            data:
            {
                "event_attachment_permission_id": id,
                "attachment_permission_type_view": d,
                "attachment_permission_type_upload": 0
            }
        })
            .then(res => {
                //console.log("res", res.data)
                if (res.status === 200) {
                    toast.success(res.data.message, {
                        theme: 'colored',
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    dispatch(getAttachmentPermission({ id: eventId }))
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    theme: "colored",
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }
    const deletePermissions = (id, u) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/band-update-attachement-permission`,
            data:
            {
                "event_attachment_permission_id": id,
                "attachment_permission_type_view": 0,
                "attachment_permission_type_upload": u
            }
        })
            .then(res => {
                //console.log("res", res.data)
                if (res.status === 200) {
                    toast.success(res.data.message, {
                        theme: 'colored',
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    dispatch(getAttachmentPermission({ id: eventId }))
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    theme: "colored",
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }

    const handleDelete = (id) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/delete-event-attachment`,
            data:
            {

                "id": id,
            }
        })
            .then(res => {
                //console.log("res", res.data)
                if (res.data.message ===
                    "Attachment Deleted Successfully!.") {
                    toast.success("Deleted Successfully ", {
                        theme: 'colored',
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    dispatch(getEventDetail({ eventid: eventId }))
                }
            })
    }

    const handleUpload = (id) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/band-add-attachment-permissions`,
            data:
            {
                "event_id": eventId,
                "event_type": 1,
                "users_id": assignTo ? assignTo.toString() : "",
                "attachment_type": 7,
                "attachment_permission_type_view": 2,
                "attachment_permission_type_upload": 1
            }
        })
            .then(res => {
                if (res.data.message === "Attachment Permission Added Successfully!.") {
                    toast.success('Permission Added Successfully!.', {
                        theme: 'colored',
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    //dispatch(getFiles({ songid: id, userid: ids }))
                    setUploadShow(true)
                }
            })
    }
    const handleDownload = (id) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/band-add-attachment-permissions`,
            data:
            {
                "event_id": eventId,
                "event_type": 1,
                "users_id": assignDownload ? assignDownload.toString() : "",
                "attachment_type": 7,
                "attachment_permission_type_view": 1,
                "attachment_permission_type_upload": 2
            }
        })
            .then(res => {
                if (res.data.message === "Attachment Permission Added Successfully!.") {
                    toast.success('Permission Added Successfully!.', {
                        theme: 'colored',
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    setDownloadShow(true)
                    //dispatch(getFiles({ songid: id, userid: ids }))
                }
            })
    }
    return (
        <>
            <Modal show={show} onHide={handleShow} size="lg" >
                <Modal.Body className="p-0">
                    <div className="modal-body">
                        <button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className=" text-center">{showtext}</h4>
                                <div className="pt-3 px-1 bg-white">
                                    <div className="table-responsive">
                                        <table className="table storetable text-center">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col">upload</th>
                                                    <th scope="col">download</th>
                                                    <th scope="col">size</th>
                                                    <th scope="col">action</th>

                                                </tr>
                                                <tr className="border-0 bg-none invisible"><td className="border-0 bg-none"></td></tr>
                                            </thead>
                                            {loader ?
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div> : ""}
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="upload-btn-wrapper Attachment" >
                                                            <img src="assets/images/gallery.png" width="25px" alt="" />
                                                            <input type="file" onChange={(e) => handleFile(e)} />
                                                        </div>
                                                    </td>

                                                    {eventDetails[0]?.attachment_details.map((res) => {
                                                        return <>

                                                            {res.attachment_type == 7 ?
                                                                <>
                                                                    <td><div className="d-flex gap-3 align-items-center justify-content-center">

                                                                        <a href={res.attachment_url} target="_blank" download={res.attachment_url}>
                                                                            <img src="assets/images/live-file.png" width="25px" alt="" />
                                                                        </a>
                                                                    </div></td>
                                                                    <td>{res.size}</td>
                                                                    <td> <div className="d-flex gap-3 justify-content-center">
                                                                        <img src="assets/images/delete.png" alt="" onClick={() => handleDelete(res.id)} />
                                                                    </div> </td> </> : ""}

                                                        </>
                                                    })}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5 mt-3">
                                <h6>Permission Upload</h6>
                                <select className="form-control form-select" isMulti onChange={handleChangePerson}>
                                    <option>-- Select --</option>
                                    {bandperson.map((res, key) => {
                                        return <option value={JSON.stringify(res)}>{res.first_name}</option>
                                    })}
                                </select>

                                {allasign?.length !== 0 ? allasign.map((res, i) => {
                                    return <div>
                                        <button className="btn add-new mt-4  mx-5">{res.first_name}</button>
                                        <img className="mt-4" src="assets/images/deleteBlack.png" onClick={() => handleRemove(i)} width="20px" alt="" />
                                        <button className="btn add-new mt-4" onClick={() => handleUpload(res.user_id)} >save</button>
                                    </div>
                                }) : ""}
                                {Permissions?.length !== 0 ? Permissions.map((res, i) => {
                                    return <>
                                        {res.attachment_type == 7 && res.attachment_permission_type_upload == 1 || res.attachment_permission_type_upload == 1 ?
                                            <div>
                                                <button className="btn add-new mt-4  mx-5" style={{ width: "150px" }}>{res.first_name}</button>
                                                <img className="mt-4" src="assets/images/deleteBlack.png" onClick={() => deletePermissionsUpload
                                                    (res.event_attachment_permission_id, res.attachment_permission_type_view
                                                    )} width="20px" alt="" />
                                            </div> : ""}
                                    </>
                                }) : ""}
                            </div>

                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5 mt-3">
                                <h6>Permission Download</h6>
                                <select className="form-control form-select" isMulti onChange={handleChangePersonDownload}>
                                    <option>-- Select --</option>
                                    {bandperson.map((res, key) => {
                                        return <option value={JSON.stringify(res)}>{res.first_name}</option>
                                    })}
                                </select>

                                {allasignDownload?.length !== 0 ? allasignDownload.map((res, i) => {
                                    return <div>
                                        <button className="btn add-new mt-4  mx-5">{res}</button>
                                        <img className="mt-4" src="assets/images/deleteBlack.png" onClick={() => handleRemoveDownload(i)} width="20px" alt="" />
                                        <button className="btn add-new mt-4" onClick={handleDownload}>save</button>
                                    </div>
                                }) : ""}
                                {Permissions?.length !== 0 ? Permissions.map((res, i) => {
                                    return <>
                                        {res.attachment_type == 7 && res.attachment_permission_type_view == 1 || res.attachment_permission_type_view == 1 ?
                                            <div>
                                                <button className="btn add-new mt-4  mx-5" style={{ width: "150px" }}>{res.first_name}</button>
                                                <img className="mt-4" src="assets/images/deleteBlack.png" onClick={() => deletePermissions(res.event_attachment_permission_id, res.attachment_permission_type_upload

                                                )} width="20px" alt="" />
                                            </div> : ""}
                                    </>
                                }) : ""}
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default EditOtherAttachments