import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Cookies from 'universal-cookie'


const BandstaffDetail = () => {
    const cookee = new Cookies();
    const [bandstaffDetail, setBandStaffDetail] = useState("")
    const token = cookee.get('token')

    useEffect(() => {
        getBandStaff();
    },[])
    const getBandStaff = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-staff-member-details`,
            data: {
                "staff_id":localStorage.getItem("staffid")
            }
        })
            .then(res => {
               setBandStaffDetail(res.data.data)
            })
    }
    return (
        <article className="col-lg-9">
            <div className="article-body store-bd-gy">
                {/* <div className="row mt-3">
                    <form className="has-search"><span className="form-search-icon form-control-feedback"></span><input type="text" className="form-control" placeholder="Search here.." value="" /></form>
                    <form className="row mt-5 addNAme mx-width">
                        <h4>Band-Address</h4>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                            <input type="text" className="form-control" placeholder="Name" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                            <input type="text" className="form-control" placeholder="Street" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                            <input type="text" className="form-control" placeholder="Zip-Code" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                            <input type="text" className="form-control" placeholder="Country" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                            <input type="text" className="form-control" placeholder="Name suffix" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                            <input type="text" className="form-control" placeholder="House-number" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                            <input type="text" className="form-control" placeholder="Town" />
                        </div>
                    </form>
                    <form className="row mt-2 addNAme mx-width">
                        <h4>Bandleader</h4>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                            <input type="text" className="form-control" placeholder="Name" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                            <input type="text" className="form-control" placeholder="Zip-Code" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                            <input type="text" className="form-control" placeholder="Town" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                            <input type="text" className="form-control" placeholder="Email" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                            <input type="text" className="form-control" placeholder="Street" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                            <input type="text" className="form-control" placeholder="House-number" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                            <input type="text" className="form-control" placeholder="Country" />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                            <input type="text" className="form-control" placeholder="Phone" />
                        </div>
                    </form>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                        <div className="d-flex align-items-center lg-justify-content-end">
                            <Link to="/add-band-staff" className="btn add-new">add/invite new musician</Link>
                        </div>
                    </div>
                </div> */}
                
                <form className="row mt-5 addNAme mx-width">
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                        <input type="text" className="form-control" value={bandstaffDetail?.staff_name} placeholder="Staff name" />
                    </div>
                    {/* <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                        <input type="text" className="form-control" value={bandstaffDetail?.role} placeholder="Role" />
                    </div> */}
                    <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                        <input type="text" className="form-control" placeholder="Street" />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                        <input type="text" className="form-control" placeholder="Date of birth place" />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                        <input type="text" className="form-control" placeholder="GEMA membership number" />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                        <input type="text" className="form-control" placeholder="Location" />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                        <input type="text" className="form-control" value={bandstaffDetail?.phone_number} placeholder="Phone" />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                        <input type="text" className="form-control" placeholder="Country" />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                        <input type="text" className="form-control" value={bandstaffDetail?.email} placeholder="Email" />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                        <input type="text" className="form-control" placeholder="GEMA IPI number" />
                    </div>

                </form>
            </div>

        </article>
    )
}

export default BandstaffDetail