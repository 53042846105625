import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getSetlist = createAsyncThunk("setlist", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('token')
    let setlist =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/get-band-leader-set-list-by-band-id`,
            data: {
                "page_no": obj?.pageno
            }
        })
            .then(res => res.data.data)
    return setlist
})

const setlist = createSlice(({
    name: "all setlist",
    initialState: {
        allSetlist: [],
        loading: false
    },
    extraReducers: {
        [getSetlist.pending]: (state, action) => {
            state.loading = true;
        },
        [getSetlist.fulfilled]: (state, action) => {
            state.allSetlist = action.payload;
            state.loading = false;
        },
        [getSetlist.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default setlist.reducer;