import React from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { getPastEvents } from '../../app_redux/slice/pastEvents'


const DeleteEvent = ({ show, handleShow,pageNo,eventId }) => {
    const dispatch = useDispatch()
    const cookee = new Cookies();
    const token = cookee.get('token')
   
    const handleDeleteEvent = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/delete-band-leader-event`,
            data:
            {
                "event_id": eventId
            }
        })
            .then(res => {
                //console.log("res", res.data)
                if (res.data.message === "Event Deleted!.") {
                    toast.success("Deleted Successfully ", {
                        theme:'colored',
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    dispatch(getPastEvents({ pageno: pageNo }))
                    handleShow()

                }
            })
    }
    return (
        <>
            <Modal show={show} onHide={handleShow} size="md" centered >
                <Modal.Body className="p-0">
                    <div className="modal-body">
                        <button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
                                <div className="text-center mx-2">
                                    <p>Are you sure, you want to delete this event?</p>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                                <div className=" text-center my-3 mt-5">
                                    <button className="btn add-new-cancel" onClick={handleShow}>cancel</button>
                                    <button className="btn add-new-del" onClick={handleDeleteEvent} >delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default DeleteEvent