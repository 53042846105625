import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { gemaEventDetails } from '../../app_redux/slice/gemaEventDetail';



const AddSongSetlist = ({ show, handleShow, id, setlistIdentity, setSetlistIdentity }) => {
    const dispatch = useDispatch()
    const cookee = new Cookies();
    const token = cookee.get('token')
    const [songs, setSongs] = useState([])


    useEffect(() => {
        getSongs()
        //dispatch(getSongs())
    }, [])
    const getSongs = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-band-leader-set-list-details`,
            data:
            {
                "set_list_id": setlistIdentity
            }
        })
            .then(res => {
                setSongs(res.data.data?.song_details)
            })

    }
    const addSongToList = (songid) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/add-song-to-band-leader-set-list`,
            data:
            {
                "set_list_id": id,
                "set_list": [
                    {
                        "song_id": songid
                    }
                ]
            }
        })
            .then(res => {
                if (res.data.message === "Song Added Successfully!.") {
                    toast.success("Song Added", {
                        theme: "colored",
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    dispatch(gemaEventDetails())
                    handleShow()
                }

            })

    }
    return (
        <>
            <Modal show={show} onHide={handleShow} size="lg" >
                <Modal.Body className="p-0">
                    <div className="modal-body"><button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                        <div className="row mt-3 align-items-center">
                            <div className="col-sm-12">
                                <div className="pt-3 px-1 bg-white">
                                    <div className="table-responsive">
                                        <table className="table storetable text-center">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col">song name</th>
                                                    <th scope="col">time length</th>
                                                    <th scope="col">action</th>

                                                </tr>
                                                <tr className="border-0 bg-none invisible"><td className="border-0 bg-none"></td></tr>
                                            </thead>
                                            <tbody>
                                                {songs?.length !== 0 ? songs.map((res, key) => {
                                                    return <tr>
                                                        <td>{res.song_name}</td>
                                                        <td>{res.song_time}</td>
                                                        <td><button className="btn add-new" onClick={() => addSongToList(res.song_id)}>add</button></td>
                                                    </tr>
                                                }) : <p className='mt-3'>No Record Found!!</p>}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default AddSongSetlist