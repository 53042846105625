import React, { useRef } from 'react'
import { useDrag, useDrop } from "react-dnd";
import axios from 'axios'
import Cookies from 'universal-cookie'
import { getSetlistDetail } from '../../app_redux/slice/setlistDetail'
import { useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';



const AllSelectedSong = ({ item, playerType, moveCard, index, setExistingSongs, existingSongs, moveSongs, count, setCount, sumTime, setTotaltime, addedSongs, setAddedSongs }) => {
  const cookee = new Cookies();
  const token = cookee.get('token')
  const dispatch = useDispatch()


  const ref = useRef(null)
  // checkc

  const [{ handlerId }, drop] = useDrop({
    accept: "team",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      console.log("here call", item)

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: "team",
    item: () => {
      return { index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  function sumTime(count) {
    let sumSeconds = 0;

    count.forEach(time => {
      let a = time.split(":");
      let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      sumSeconds += seconds;
    });

    return new Date(sumSeconds * 1000).toISOString().substr(11, 8);
  }
  const handleDelete = (i, id, len) => {
    const list = [...existingSongs]
    list.splice(i, 1)
    setExistingSongs(list);
    axios({
      method: 'post',
      headers: {
        "Accept": "/",
        "Content-Type": "application/json",
        "auth_token": token
      },
      url: `${process.env.REACT_APP_URL}/delete-band-leader-set-list-song`,
      data: {
        "id": id,
        "song_delete_type": 1
      }

    })
      .then(res => {
        if (res.data.message === "Setlist Song Deleted Successfully!.") {
          toast.success("Song Deleted Successfully ", {
            theme: 'colored',
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          dispatch(getSetlistDetail())
        }

      })
    const list2 = [...count]
    list2.splice(i, 1)
    setCount(list2)
    setTotaltime(sumTime(list2))
    const list1 = [...addedSongs]
    list1.splice(i, 1)
    setAddedSongs(list1)
  }
  return (
    <>
      {item ?
        <tr ref={ref} data-handler-id={handlerId} >
          <td className='notranslate' style={{ cursor: "pointer" }}>{item?.song_name}</td>
          <td>{item?.time_length?.substr(3, 6)}</td>
          <td onClick={() => handleDelete(index, item.id, item.time_length)} style={{ cursor: "pointer" }}><img src="assets/images/delete.png" width="25px" alt="" /></td>
        </tr> : ""}
      <ToastContainer position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default AllSelectedSong