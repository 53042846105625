import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

const cookee = new Cookies();

export const getSetlistDetail = createAsyncThunk("setlistdata", async (obj) => {
    let token = cookee.get('token');
    // console.log("RED", obj?.page)
    let data = await axios({
        method: 'post',
        headers: {
            "Accept": "/",
            "Content-Type": "application/json",
            "auth_token": token
        },
        url: `${process.env.REACT_APP_URL}/get-band-leader-set-list-details`,
        data: {
            "set_list_id": localStorage.getItem("setlist_id") || ""
        }
    })
        .then(res => (res.data.data))
    return data
})

const setlistDetail = createSlice(({
    name: "setlist details",
    initialState: {
        setlistDetails: [],
        loading: false
    },
    extraReducers: {
        [getSetlistDetail.pending]: (state, action) => {
            state.loading = true;
        },
        [getSetlistDetail.fulfilled]: (state, action) => {
            state.setlistDetails = action.payload
        },
        [getSetlistDetail.rejected]: (state, action) => {
            state.loading = false;
        }
    }
}))

export default setlistDetail.reducer;