import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const AddNewEvent = () => {
    const { t } = useTranslation();
    const { tab39, tab40, tab41, tab42 } = t("event")

    return (
        <article className="col-lg-9">
            <div className="article-body store-bd-gy">

                <div className="row align-items-center col-rever">
                    <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
                        <div className="py-2 px-3">
                            <h4 className="mb-0">{tab39}</h4>
                        </div>
                    </div>
                </div>
                <div className="row mt-3 align-items-center">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 text-center mb-3">
                        <div className="position-relative">
                            <Link to="/event-dates-info">
                                <img src="assets/images/concertImg.png" className="img-fluid" alt="" />
                                <span className="concertImgText">{tab40}</span>
                            </Link>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 text-center mb-3">
                        <div className="position-relative">
                            <Link to="/add-rehearsal-event">
                                <img src="assets/images/rehearsalImg.png" className="img-fluid" alt="" />
                                <span className="concertImgText">{tab41}</span>
                            </Link>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 text-center mb-3">
                        <div className="position-relative">
                            <Link to="/add-other-event">
                                <img src="assets/images/otherAppointmentImg.png" className="img-fluid" alt="" />
                                <span className="concertImgText">{tab42}</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </article>
    )
}

export default AddNewEvent;