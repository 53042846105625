import React, { useState } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import { useFormik } from 'formik'
import { changePasswordSchema } from '../changePasswordSchema';
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom';


const initialValues = {
    currentpassword: "",
    newpassword: "",
    confirmpassword: ""
}
const ChangePassword = () => {
    const cookee = new Cookies();
    const email = localStorage.getItem("email")
    const token = cookee.get('token')
    const [newShow, setNewShow] = useState(false)
    const [currentShow, setCurrentShow] = useState(false)
    const [confirmShow, setConfirmShow] = useState(false)

    let navigate = useNavigate()
    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: changePasswordSchema,
        onSubmit: (values, action) => {
            //  console.log("data", values)
            handleChangePassword()
        }
    })
    const handleNew = () => {
        setNewShow(!newShow)
    }
    const handleConfirm = () => {
        setConfirmShow(!confirmShow)
    }
    const handleCurrent = () => {
        setCurrentShow(!currentShow)
    }
    const handleChangePassword = () => {
        //  e.preventDefault();
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/band-leader-change-password`,
            data:
            {
                "current_password": values.currentpassword,
                "new_password": values.newpassword
            }
        })
            .then(res => {

                if (res.data.message === "Password Changed Successfully!.") {
                    alert("Password Change Successfully")
                    toast.success("Password Change Successfully!.", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    navigate("/login-email")
                }

            })
            .catch((error) => {
                if (error.response.data.message === "current password incorrect!.") {
                    toast.error("Please Enter Correct Current Password.", {
                        theme: 'colored',
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
    }
    return (
        <section className="bg-image">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-sm-12 col-md-12 col-lg-12 mr-0 pr-0">
                        <div className="form-bg">
                            <form className="form-style">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 text-center mb-4">
                                        <div className="logo-bg text-center">
                                            <div className="mt-2"><img src="assets/images/logo.png" className="img-fluid logo-admin" alt="logo" /></div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center mb-4">
                                        <img src="assets/images/change-password.png" className="img-fluid iconIMg" alt="" />
                                        <h2 className="mdl-ttl">Change Password</h2>
                                        <p className="mdl-sbttl">Please change your password here</p>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                        <div className="form-group">
                                            <div className="input-container">
                                                <input type={currentShow ? "text" : "password"} className="form-control pl-5" placeholder="Current Password" name="currentpassword" value={values.currentpassword} onChange={handleChange} onBlur={handleBlur} />
                                                <img src="assets/images/password.png" className="input-img" alt="" onClick={handleCurrent} />
                                            </div>
                                            {errors.currentpassword && touched.currentpassword ? <p style={{ color: 'red' }}>{errors.currentpassword}</p> : ""}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                        <div className="form-group">
                                            <div className="input-container">
                                                <input type={newShow ? "text" : "password"} className="form-control pl-5" placeholder="Set New Password" name="newpassword" value={values.newpassword} onChange={handleChange} onBlur={handleBlur} />
                                                <img src="assets/images/password.png" className="input-img" alt="" onClick={handleNew}/>
                                            </div>
                                            {errors.newpassword && touched.newpassword ? <p style={{ color: 'red' }}>{errors.newpassword}</p> : ""}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                        <div className="form-group">
                                            <div className="input-container">
                                                <input type={confirmShow ? "text" : "password"} className="form-control pl-5" placeholder="Set Confirm New Password" name='confirmpassword' value={values.confirmpassword} onChange={handleChange} onBlur={handleBlur} />
                                                <img src="assets/images/password.png" className="input-img" alt="" onClick={handleConfirm} />
                                            </div>
                                            {errors.confirmpassword && touched.confirmpassword ? <p style={{ color: 'red' }}>{errors.confirmpassword}</p> : ""}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center">
                                        <button className="btn btn-save w-50" onClick={handleSubmit}>CHANGE</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </section>
    )
}

export default ChangePassword