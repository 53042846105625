import React, { useEffect } from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getLoadSetup } from '../../../app_redux/slice/setupData'
import { useDispatch, useSelector } from 'react-redux'
import { getEventDetail } from '../../../app_redux/slice/eventDetail'
import DeletePassenger from './deletePassenger'



const InvitationModal = ({ show, handleShow, eventid }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const cookee = new Cookies();
    const token = cookee.get('token')
    const [passengerId, setPassengerId] = useState("")
    const [disabled, setDisabled] = useState([])
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const { passengers } = useSelector((state) => state.passenger)
    const { eventDetails, loading } = useSelector((state) => state.eventdetail)

    useEffect(() => {
        setTimeout(handleTime, 1000)
    }, [])
    const handleShowDelete = () => {
        setShowDeleteModal(!showDeleteModal)
    }
    const handleTime = () => {
        dispatch(getEventDetail({ eventid: eventid }))
    }

    const handleDelete = (id) => {
        setPassengerId(id)
        handleShowDelete()

    }
    const handleSendInvite = (id, i) => {
        // setDisabled([...disabled, i])
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/band-leader-event-send-invite-musician`,
            data:
            {
                "event_id": eventDetails[0]?.event_id || "",
                "user_id": id
            }
        })
            .then(res => {
                //  console.log("RESPONSE", res.data)
                if (res.data.message === "Invitation Sent Successfully!.") {
                    toast.success("Invitation Sent", {
                        theme: "colored",
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    dispatch(getEventDetail({ eventid: eventid }))
                }
            })
    }

    const handleSendInviteBandstaff = (id, i) => {
        // setDisabledstaff([...disabledstaff, i])
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/band-leader-event-send-invite-staff-member`,
            data:
            {
                "event_id": eventDetails[0]?.event_id || "",
                "staff_id": id
            }
        })
            .then(res => {
                //  console.log("RESPONSE", res.data)
                if (res.status === 200) {
                    toast.success(res.data.message, {
                        theme: "colored",
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    dispatch(getEventDetail({ eventid: eventid }))
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    theme: "colored",
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }


    const handleSendinviteAll = (id) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/band-leader-event-send-invite-all`,
            data:
            {
                "event_id": eventDetails[0]?.event_id || "",
            }
        })
            .then(res => {
                //  console.log("RESPONSE", res.data)
                if (res.status === 200) {
                    toast.success(res.data.message, {
                        theme: "colored",
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    dispatch(getEventDetail({ eventid: eventid }))
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    theme: "colored",
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }


    return (
        <>
            <Modal show={show} onHide={handleShow} size="lg" >
                <Modal.Body className="p-0">
                    <div className="modal-body">
                        <button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                        <h4 className='mt-2 text-center'>Persons</h4>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="pt-3 px-1 bg-white">
                                    <div className="table-responsive">
                                        <table className="table storetable text-center">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col">name</th>
                                                    <th scope="col">priority</th>
                                                    <th scope="col">role</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                                <tr className="border-0 bg-none invisible"><td className="border-0 bg-none"></td></tr>
                                            </thead>
                                            {loading === true ? <div className="d-flex justify-content-center">
                                                <div className="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div> :
                                                <tbody>
                                                    {eventDetails[0]?.band_users_details
                                                        !== undefined && eventDetails[0]?.band_users_details.length !== 0 ? eventDetails[0]?.band_users_details?.map((res, i) => {
                                                            return <tr>
                                                                <td className='notranslate'>{res.first_name}</td>
                                                                <td>{res.priority}</td>
                                                                <td className='notranslate'>{res.role}</td>
                                                                <td>
                                                                    {res.priority === 1 ?
                                                                        <div className="">
                                                                            {res.is_invited === 0 ?
                                                                                <button className="btn add-new" disabled={disabled.indexOf(i) !== -1} onClick={() => handleSendInvite(res.user_id, i)} style={{ width: "200px" }}>Send Invitation</button> : ""}
                                                                            {res.is_invited === 1 && res.is_rejected === 0 && res.is_accepted === 0 ?
                                                                                <button className="btn add-new" style={{ width: "220px", background: "orange", border: 'none' }}> Invitation was Send</button> : ""}
                                                                            {res.is_accepted === 1 ?
                                                                                <button className="btn add-new" style={{ width: "200px", background: "green", border: 'none' }}>Confirmed</button> : ""}
                                                                            {res.is_rejected === 1 ?
                                                                                <button className="btn add-new" style={{ width: "200px", background: "black", border: 'none' }}>Cancelled</button> : ""}
                                                                        </div> : <div className=" my-3">
                                                                            {res.is_invited === 1 && res.is_rejected === 0 && res.is_accepted === 0 ?
                                                                                <button className="btn add-new" style={{ width: "220px", background: "orange", border: 'none' }}> Invitation was Send</button> : ""}
                                                                            {res.is_accepted === 1 ?
                                                                                <button className="btn add-new" style={{ width: "200px", background: "green", border: 'none' }}>Confirmed</button> : ""}
                                                                            {res.is_rejected === 1 ?
                                                                                <button className="btn add-new" style={{ width: "200px", background: "black", border: 'none' }}>Cancelled</button> : ""}
                                                                        </div>}
                                                                </td>
                                                            </tr>
                                                        }) : <p className='mt-5'>No Record Found!!</p>}
                                                </tbody>}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 className='mt-2 text-center'>Staff</h4>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="pt-3 px-1 bg-white">
                                    <div className="table-responsive">
                                        <table className="table storetable text-center">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col">name</th>
                                                    <th scope="col">role</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                                <tr className="border-0 bg-none invisible"><td className="border-0 bg-none"></td></tr>
                                            </thead>
                                            <tbody>
                                                {eventDetails[0]?.band_staff_details
                                                    !== undefined && eventDetails[0]?.band_staff_details.length !== 0 ? eventDetails[0]?.band_staff_details?.map((res, i) => {
                                                        return <tr>
                                                            <td className='notranslate'>{res.first_name}</td>
                                                            <td className='notranslate'>{res.role}</td>
                                                            <td>
                                                                <div className="">
                                                                    {res.is_invited === 0 ?
                                                                        <button className="btn add-new" onClick={() => handleSendInviteBandstaff(res.staff_id, i)} style={{ width: "200px" }}>Send Invitation</button> : ""}
                                                                    {res.is_invited === 1 && res.is_rejected === 0 && res.is_accepted === 0 ?
                                                                        <button className="btn add-new" style={{ width: "220px", background: "orange", border: 'none' }}> Invitation was Send</button> : ""}
                                                                    {res.is_accepted === 1 ?
                                                                        <button className="btn add-new" style={{ width: "200px", background: "green", border: 'none' }}>Confirmed</button> : ""}
                                                                    {res.is_rejected === 1 ?
                                                                        <button className="btn add-new" style={{ width: "200px", background: "black", border: 'none' }}>Cancelled</button> : ""}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }) : <p className='mt-5'>No Record Found!!</p>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end my-3">
                            <button className="btn add-new" onClick={handleSendinviteAll}>Send Invitation To All</button>
                        </div>
                    </div>



                </Modal.Body>
            </Modal>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />

        </>
    )
}

export default InvitationModal;