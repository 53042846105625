import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";
import { getSetlist } from "../../app_redux/slice/setlistData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "jspdf-autotable";
import DeleteSetlistModal from "./deleteSetlist";
import { useTranslation } from "react-i18next";
import DataLoader from "../Loader/dataLoader";

const SetList2 = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const cookee = new Cookies();
  const token = cookee.get("token");
  const { allSetlist, loading } = useSelector((state) => state.setlist);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [setlistId, setSetlistId] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const { tab1, tab2, tab3, tab4, tab5, tab6, tab11, tab10 } = t("setlist");

  useEffect(() => {
    dispatch(getSetlist({ pageno: pageNo }));
  }, []);
  const handleNext = (e) => {
    e.preventDefault();
    setPageNo(pageNo + 1);
    dispatch(getSetlist({ pageno: pageNo + 1 }));
  };
  const handlePrevious = (e) => {
    e.preventDefault();
    setPageNo(pageNo - 1);
    dispatch(getSetlist({ pageno: pageNo - 1 }));
  };
  const handleShowDelete = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  const deleteSetList = (id) => {
    setSetlistId(id);
    handleShowDelete();
  };
  const handleEdit = (id) => {
    localStorage.setItem("setlist_id", id);
    navigate("/edit-setlist");
  };
  const handlePrintClick = (id, name) => {
    localStorage.setItem("setlist_id", id);
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-set-list-details`,
      data: {
        set_list_id: id,
      },
    })
      .then((res) => {
        localStorage.setItem("setlistdetails", JSON.stringify(res.data.data2));
        if (res.status === 200) {
          navigate("/setlist-print", { state: { name: name } });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    // navigate("/setlist-print")
  };

  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row mt-3">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
              <div className="d-flex align-items-center lg-justify-content-end">
                <Link to="/add-setlist" className="btn add-new">
                  {tab11}
                </Link>
              </div>
            </div>
          </div>
          {loading ? (
            <DataLoader />
          ) : (
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="pt-3 px-1 bg-white">
                  <div className="table-responsive">
                    <table className="table storetable text-center">
                      <thead className="">
                        <tr>
                          <th scope="col">setlist</th>
                          <th scope="col">{tab1}</th>
                          <th scope="col">{tab10}</th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {allSetlist?.length !== 0 ? (
                          allSetlist.map((res, key) => {
                            return (
                              <tr>
                                <td
                                  className="notranslate"
                                  onClick={() => handleEdit(res.set_list_id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {res.set_list_name}
                                </td>
                                <td>
                                  {res.total_length?.substr(0, 2) === "00"
                                    ? res.total_length?.substr(3, 6)
                                    : res.total_length}
                                </td>
                                <td style={{ cursor: "pointer" }}>
                                  <div className="d-flex gap-3 justify-content-center">
                                    <img
                                      src="assets/images/deleteBlack.png"
                                      width="25px"
                                      alt=""
                                      onClick={() =>
                                        deleteSetList(res.set_list_id)
                                      }
                                    />
                                    <img
                                      src="assets/images/printing.png"
                                      width="25px"
                                      alt=""
                                      onClick={() =>
                                        handlePrintClick(
                                          res.set_list_id,
                                          res.set_list_name
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <p className="mt-5">{tab5}</p>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {pageNo === 1 && allSetlist?.length <= 9 ? (
                ""
              ) : (
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                  <div className="d-flex align-items-center lg-justify-content-end">
                    <nav aria-label="...">
                      <ul className="pagination">
                        <li
                          className={
                            pageNo === 1 ? "page-item disabled" : "page-item"
                          }
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={handlePrevious}
                          >
                            Previous
                          </a>
                        </li>
                        <li class="page-item active">
                          <a className="page-link" href="#">
                            {pageNo} <span className="sr-only">(current)</span>
                          </a>
                        </li>
                        <li
                          class={
                            allSetlist?.length <= 9
                              ? "page-item disabled"
                              : "page-item"
                          }
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={handleNext}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </article>
      {showDeleteModal ? (
        <DeleteSetlistModal
          show={showDeleteModal}
          handleShow={handleShowDelete}
          id={setlistId}
          pageNo={pageNo}
          tab3={tab3}
          tab2={tab2}
          tab4={tab4}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default SetList2;
