import React, { useEffect, useState, useMemo } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie'
import S3FileUpload from 'react-s3';
import Select from 'react-select'
import countryList from 'react-select-country-list'


window.Buffer = window.Buffer || require("buffer").Buffer;


const Profile = () => {
    const cookee = new Cookies();
    const token = cookee.get('token')
    const [profileData, setProfileData] = useState("")
    const [bandName, setBandName] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [street, setStreet] = useState("")
    const [city, setCity] = useState("")
    const [town, setTown] = useState("")
    const [country, setCountry] = useState("")
    const [dob, setDob] = useState("")
    const [vehicle, setVehicle] = useState("")
    const [freeseat, setFreeseat] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [profilePic, setProfilePic] = useState("")
    const [image, setImage] = useState("")
    const [zipcode, setZipcode] = useState("")
    const [housenumber, setHousenumber] = useState("")
    const options = useMemo(() => countryList().getData(), [])


    useEffect(() => {
        getMusicianInfo();
    }, [])

    const changeHandler = value => {
        setCountry(value.label)
    }

    const handleFile = (e) => {
        let file = e.target.files[0];
        const config = {
            bucketName: 'media-appsinvo',
            region: 'us-east-2',
            accessKeyId: 'AKIA6LSDBEL3U2HOJWLW',
            secretAccessKey: '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',
        }
        S3FileUpload
            .uploadFile(file, config)
            .then(data => {
                setImage(data.location)
            })
            .catch(err => console.error("ERR", err))
    }
    const getMusicianInfo = () => {
        axios({
            method: 'get',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: 'http://3.131.5.153:4050/v1/get-band-leader-profile',
        })
            .then(res => {
                setProfileData(res.data.data)
                setBandName(res.data.data.band_name)
                setEmail(res.data.data.email)
                setPhone(res.data.data.phone_number)
                setCity(res.data.data.city)
                setTown(res.data.data.town)
                setCountry(res.data.data.country)
                setDob(res.data.data.dob)
                setStreet(res.data.data.street)
                setFreeseat(res.data.data.free_seats)
                setVehicle(res.data.data.vehicle)
                setProfilePic(res.data.data.profile_pic)
                setStreet(res.data.data.street)
                setZipcode(res.data.data.zip_code)
                setHousenumber(res.data.data.house_number)

            })
    }
    // console.log("Pro", profileData)

    const handleChange = (e, setState) => {
        setState(e.target.value)
    }
    const handleUpdateProfile = (e) => {
        e.preventDefault();
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: 'http://3.131.5.153:4050/v1/update-band-leader-profile',
            data:
            {
                "band_name": bandName,
                "profile_pic": image ? image : profilePic,
                "email": email,
                "country_code": "+91",
                "phone_number": phone,
                "city": "Noida",
                "country": country,
                "street": street,
                "town": town,
                "zip_code": zipcode,
                "house_number": housenumber,
                "dob": dob,
                "gema_ipi_number": "123456XXXX",
                "gema_membership_number": "gema_mem123XX",
                "language": "EN"
            }
        })
            .then(res => {
                if (res.data.message === "Bandleader Profile Updated Successfully!.") {
                    toast.success('Profile Updated Successfully!.', {
                        theme: 'colored',
                        position: "top-center",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
            .catch((error)=>{
                if (error.response.data.message ===
                    "We are currently able to offer the App to German Users. We ask for your understading!.") {
                    toast.error(
                        "We are currently able to offer the App to German Users. We ask for your understanding!.", {
                        theme: 'colored',
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
    }


    return (
        <article className="col-lg-9">
            <div className="position-relative mt-56">
                <img src="assets/images/profileBanner.png" className="img-fluid w-100" alt="" />
            </div>
            <div className="article-body store-bd-gy mt-0">
                <div className="ProfilBox">
                    <h4 className="text-white mb-3">Personal Setting</h4>
                    <div className="bg-white p-4 rounded">
                        <form className="row mt-5 addNAme mx-width">
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-5">
                                <div className="profilePhoto position-static">
                                    <div className="d-flex gap-2 align-items-center">
                                        <img src={image ? image : profilePic} className="profileIMg" alt="" />
                                        <span>
                                            <h6 className="text-capitalize mb-0">michella barkin</h6>
                                            <p className="mb-0">michellabarkin@gmail.com</p>
                                        </span>
                                    </div>
                                    <div className="file-input">
                                        <input type="file" name="file-input" onChange={handleFile} id="file-input" className="file-input__input" />
                                        {/* <label className="file-input__label add-new" for="file-input">
                                            Update</label> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <input type="text" className="form-control" name="bandname" value={bandName} onChange={(e) => handleChange(e, setBandName)} placeholder="First Name" />
                            </div>
                          
                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <div className="position-relative">
                                    <input type="text" className="form-control" name="city" value={town} onChange={(e) => handleChange(e, setTown)} placeholder="Town" />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <div className="position-relative">
                                    <input type="text" className="form-control" name="street" value={street} onChange={(e) => handleChange(e, setStreet)} placeholder="Street" />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <div className="position-relative">
                                    <input type="text" className="form-control" name="housenumber" value={housenumber} onChange={(e) => handleChange(e, setHousenumber)} placeholder="House Number" />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <div className="position-relative">
                                    <input type="text" className="form-control" name="zipcode" value={zipcode} onChange={(e) => handleChange(e, setZipcode)} placeholder="Zip Code" />
                                </div>
                            </div>

                           
                            <div className="col-sm-3 col-md-3 col-lg-3 mb-5">
                                <div className="position-relative">
                                    <input type="text" className="form-control" name="country" value={country} placeholder="Country" />
                                </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3 mb-5">
                                <div className="position-relative">
                                    <Select className="form-control p-0" options={options} placeholder="Country" onChange={changeHandler} />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <div className="position-relative">
                                    <input type="text" className="form-control" name="phone" value={phone} onChange={(e) => handleChange(e, setPhone)} placeholder="Phone" />
                                    <img src="assets/images/phone-call.png" alt="" className="inputImgRight" />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <div className="position-relative">
                                    <input type="text" className="form-control" name="email" value={email} onChange={(e) => handleChange(e, setEmail)} placeholder="Email" />
                                    <img src="assets/images/arroba.png" alt="" className="inputImgRight" />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <div className="position-relative">
                                    <input type="date" className="form-control" name="dob" value={dob} onChange={(e) => handleChange(e, setDob)} placeholder="Date of Birth" />
                                    {/* <img src="assets/images/calendarGrey.png" alt="" className="inputImgRight" /> */}
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <div className="position-relative">
                                    <input type="text" className="form-control" name="vehicle" value={vehicle} onChange={(e) => handleChange(e, setVehicle)} placeholder="Vehicle" />
                                    <img src="assets/images/car.png" alt="" className="inputImgRight" />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                <div className="position-relative">
                                    <input type="text" className="form-control" name="freeseat" value={freeseat} onChange={(e) => handleChange(e, setFreeseat)} placeholder="Passenger Seats" />
                                    <img src="assets/images/armchair.png" alt="" className="inputImgRight" />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                <div className="d-flex justify-content-center my-3">
                                    <button className="btn add-new" onClick={handleUpdateProfile}>save changes</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </article>
    )
}

export default Profile