import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate, Link } from "react-router-dom";
import CheckList from "./checklistModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getTaskDetailAdmin } from "../../app_redux/slice/getTaskDetailAdmin";
import { useDispatch, useSelector } from "react-redux";
import ChecklistView from "./checklistViewModal";

const TaskOverviewAdmin = () => {
  let navigate = useNavigate();
  const cookee = new Cookies();
  const dispatch = useDispatch();
  const token = cookee.get("token");
  const { taskAdminDetail, loading } = useSelector((state) => state.taskadmin);
  const [showChecklistModal, setShowChecklistModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [dateData, setDateData] = useState("");
  const lng = cookee.get("googtrans");

  useEffect(() => {
    dispatch(getTaskDetailAdmin());
  }, []);
  const handleShowCancel = () => {
    setShowChecklistModal(!showChecklistModal);
  };
  const handleChecklist = (e, id) => {
    e.preventDefault();
    setUserId(id);
    handleShowCancel();
  };
  const updateInprogressTask = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/update-task-status`,
      data: {
        task_id: taskAdminDetail?.task_id,
        status: 2,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const updateCompletedTask = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/update-task-status`,
      data: {
        task_id: taskAdminDetail?.task_id,
        status: 3,
      },
    })
      .then((res) => {
        //console.log(res.data)
        if (res.status === 200) {
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];

    return day + "." + month + "." + year;
  }

  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <div className="d-flex align-items-center  gap-3 mx-2">
              <Link to="/task-list" className="btn add-new notiIcon filterIcon">
                back
              </Link>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12">
              <form className="row mt-5 addNAme mx-width">
                <div className="col-sm-9 col-md-9 col-lg-9 mb-5">
                  <h4 className="notranslate">
                    {taskAdminDetail?.task_name?.toUpperCase()}
                  </h4>
                  <p className="mt-5 notranslate">
                    {taskAdminDetail?.description_info}
                  </p>
                </div>
                <div className="col-sm-3 col-md-3 col-lg-3 mb-5">
                  <h5>Deadline </h5>
                  <p>
                    {taskAdminDetail?.dead_line
                      ? formatDate(taskAdminDetail?.dead_line?.substr(0, 10))
                      : ""}
                  </p>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <div className="upload-btn-wrapper Attachment">
                    <button className="btn">Attachment</button>
                    <input type="file" name="myfile" />
                    <img
                      src="assets/images/attachment.png"
                      alt=""
                      className="inputImgRight"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5"></div>
                <div className="col-sm-12 col-md-12 col-lg-12 mt-4 mb-5">
                  <div className="table-responsive">
                    <table className="table storetable text-center">
                      <thead className="">
                        <tr>
                          <th scope="col">Assigned to</th>
                          <th scope="col">Current Status</th>
                          <th scope="col">Deadline</th>
                          <th scope="col">Checklist status</th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {taskAdminDetail?.task_users_allocated?.length > 0 ? (
                          taskAdminDetail?.task_users_allocated?.map(
                            (res, i) => {
                              return (
                                <tr>
                                  <td>
                                    {res.first_name} {res.last_name}
                                  </td>
                                  <td>
                                    {res.status === "1" ? (
                                      <button
                                        className="btn add-new"
                                        style={{ width: "180px" }}
                                      >
                                        Not in progress
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                    {res.status === "2" ? (
                                      <button
                                        className="btn add-new"
                                        style={{ width: "150px" }}
                                      >
                                        in progress
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                    {res.status === "3" ? (
                                      <button
                                        className="btn add-new"
                                        style={{
                                          background: "#30ab21",
                                          border: "none",
                                          width: "150px",
                                        }}
                                      >
                                        completed
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {taskAdminDetail?.dead_line?.substr(0, 10)}
                                  </td>
                                  <td
                                    onClick={(e) =>
                                      handleChecklist(e, res.user_id)
                                    }
                                    style={{
                                      color: "blue",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    view
                                  </td>
                                  <td>
                                    {res?.check_list_item_dead_line?.substr(
                                      0,
                                      10
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td colSpan={4} style={{ padding: "10px" }}>
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div className="d-flex justify-content-end my-3">
                    <button
                      className="btn add-new"
                      onClick={updateInprogressTask}
                    >
                      inprogress
                    </button>
                    <button
                      className="btn add-new"
                      style={{ background: "#30ab21", border: "none" }}
                      onClick={updateCompletedTask}
                    >
                      done
                    </button>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="d-flex align-items-center lg-justify-content-end gap-3 mx-2">
                    <Link
                      to="/task-list"
                      className="btn add-new notiIcon filterIcon"
                    >
                      {lng === "/auto/de" || "/en/de" ? "Speichern" : "safe"}
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {showChecklistModal ? (
          <ChecklistView
            show={showChecklistModal}
            handleShow={handleShowCancel}
            data={taskAdminDetail?.task_checklist_details}
            userid={userId}
            taskid={taskAdminDetail?.users_task_id}
          />
        ) : (
          ""
        )}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </article>
    </>
  );
};

export default TaskOverviewAdmin;
