import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { addTaskSchema } from "../addTaskSchema";
import axios from "axios";
import Cookies from "universal-cookie";
import { SketchPicker, BlockPicker } from "react-color";
import { useNavigate } from "react-router-dom";
import CheckList from "./checklistModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import S3FileUpload from "react-s3";
import { useTranslation } from "react-i18next";
window.Buffer = window.Buffer || require("buffer").Buffer;

const initialValues = {
  name: "",
  description: "",
  checklist: "",
  labels: "",
  lables_color: "",
  deadline: "",
};
const AddNewTask = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const cookee = new Cookies();
  const token = cookee.get("token");
  const [bandperson, setBandPerson] = useState([]);
  const [showChecklistModal, setShowChecklistModal] = useState(false);
  const [person, setPerson] = useState([]);
  const [assignTo, setAssignTo] = useState([]);
  const [allasign, setAllAsign] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [loader, setLoader] = useState(false);
  const [fileData, setFileData] = useState("");
  const [checklist, setCheckList] = useState([
    {
      check_list_item_name: "",
      checklist_item_information: "",
      status: "1",
      check_list_item_dead_line: "",
    },
  ]);
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: addTaskSchema,
      onSubmit: () => {
        addNewTasks();
      },
    });
  useEffect(() => {
    getEvents();
  }, []);
  const [blockPickerColor, setBlockPickerColor] = useState("");
  const { tab5, tab8, tab9 } = t("band");
  const { tab10, tab11, tab19, tab20 } = t("tasks");

  function bytesToSize(bytes) {
    var sizes = ["B", "K", "M", "G", "T", "P"];
    for (var i = 0; i < sizes.length; i++) {
      if (bytes <= 1024) {
        return bytes + " " + sizes[i];
      } else {
        bytes = parseFloat(bytes / 1024).toFixed(2);
        //filesize=`${bytes} kb`
      }
    }
    return bytes + " P";
  }
  const handleFile = (e) => {
    setLoader(true);
    bytesToSize(e.target.files[0].size);
    let file = e.target.files[0];
    const config = {
      bucketName: "clever-band-assist-s3",
      region: "eu-west-3",
      accessKeyId: "AKIASTLKHDND66NQZXFJ",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        // console.log("RES file", data)
        setFileData(data.location);
        setLoader(false);
        // uploadMusicfile(data.location, ids)
      })
      .catch((err) => console.error("ERR", err));
  };

  const handleShowCancel = () => {
    setShowChecklistModal(!showChecklistModal);
  };
  const handleChecklist = (e) => {
    e.preventDefault();
    handleShowCancel();
  };

  const handleChangePerson = (e) => {
    var data = JSON.parse(e.target.value);
    setAssignTo([...assignTo, data?.user_id]);
    setAllAsign([...allasign, data?.first_name]);
  };

  const handleRemove = (res) => {
    const list = [...allasign];
    list.splice(res, 1);
    setAllAsign(list);
  };
  const getEvents = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-staff-members-with-membership`,
      data: {
        page_no: pageNo,
      },
    }).then((res) => {
      // console.log(res.data)
      setBandPerson(res.data.data);
    });
  };

  const addNewTasks = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/bandleader-create-task`,
      data: {
        task_name: values.name,
        description_info: values.description,
        attachment_url: fileData ? fileData : "",
        check_list: checklist[0]?.check_list_item_name === "" ? [] : checklist,
        labels: values.labels,
        member_id: assignTo ? assignTo.toString() : "",
        is_self: assignTo?.length !== 0 ? 0 : 1,
        label_color: blockPickerColor,
        dead_line: values.deadline,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate("/task-list");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row mt-3">
            <div className="col-sm-12">
              <form className="row mt-5 addNAme mx-width">
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Name"
                  />
                  {errors.name && touched.name ? (
                    <p style={{ color: "red" }}>{errors.name}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Information"
                  />
                  {errors.description && touched.description ? (
                    <p style={{ color: "red" }}>{errors.description}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <button className="btn add-new" onClick={handleChecklist}>
                    check list
                  </button>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <input
                    type="date"
                    className="form-control"
                    name="deadline"
                    value={values.deadline}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Deathline"
                    style={{ cursor: "pointer" }}
                  />
                  {errors.deadline && touched.deadline ? (
                    <p style={{ color: "red" }}>{errors.deadline}</p>
                  ) : (
                    ""
                  )}
                </div>
                {loader ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                    <div
                      className="upload-btn-wrapper Attachment"
                      style={{ cursor: "pointer" }}
                    >
                      <button className="btn">{tab10}</button>
                      <input
                        type="file"
                        name="myfile"
                        onChange={(e) => handleFile(e)}
                      />
                      <img
                        src="assets/images/attachment.png"
                        alt=""
                        className="inputImgRight"
                      />
                    </div>
                    <p>{fileData ? fileData : ""}</p>
                  </div>
                )}
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <input
                    type="text"
                    className="form-control notranslate"
                    name="labels"
                    value={values.labels}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Label"
                  />
                  {errors.labels && touched.labels ? (
                    <p style={{ color: "red" }}>{errors.labels}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <h6>{tab20}</h6>
                  <select
                    className="form-control form-select"
                    isMulti
                    onChange={handleChangePerson}
                    style={{ cursor: "pointer" }}
                  >
                    <option>-- {tab11} --</option>
                    {bandperson.map((res, key) => {
                      return (
                        <option value={JSON.stringify(res)}>
                          {res.first_name}
                        </option>
                      );
                    })}
                  </select>

                  {allasign?.length !== 0
                    ? allasign.map((res, i) => {
                        return (
                          <div>
                            <button className="btn add-new mt-4  mx-5">
                              {res}
                            </button>
                            <img
                              className="mt-4"
                              src="assets/images/deleteBlack.png"
                              onClick={() => handleRemove(i)}
                              width="20px"
                              alt=""
                            />
                          </div>
                        );
                      })
                    : ""}
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <BlockPicker
                    color={blockPickerColor}
                    onChange={(color) => {
                      setBlockPickerColor(color.hex);
                    }}
                  />
                  <input
                    type="text"
                    className="form-control"
                    value={blockPickerColor}
                    placeholder={tab5}
                  />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div className="d-flex justify-content-center my-3">
                    <button className="btn add-new" onClick={handleSubmit}>
                      {tab19}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </article>
      {showChecklistModal ? (
        <CheckList
          show={showChecklistModal}
          handleShow={handleShowCancel}
          setCheckList={setCheckList}
          checklist={checklist}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default AddNewTask;
