import React, { useState, useEffect } from 'react'
import OTPInput from "otp-input-react";
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'



const OtpForgotPassword = () => {
    const navigate = useNavigate();
    const cookee = new Cookies();
    const [OTP, setOTP] = useState("");
    const email = localStorage.getItem("email")
    const [counter, setCounter] = useState(60)

    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer)
    }, [counter])

    const handleResend = () => {
        setCounter(60)
    }
    const handleOtpVerify = (e) => {
        e.preventDefault();
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/verify-musician-otp-with-email`,
            data:
            {
                "email": email,
                "verify_otp": OTP
            }
        })
            .then(res => {
                if (res.data.message === "OTP Verified Successfully!") {
                    alert("Otp verified..")
                    toast.success('OTP Verified Successfully!', {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    navigate('/reset-password')
                }
                if (res.data.message === "OTP Verfied Successfully!.") {
                    alert("Otp verified..")
                    toast.success('OTP Verified Successfully!', {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    navigate('/reset-password')
                }
            })
            .catch((error) => {
                if (error.response.data.message === "OTP Required") {
                    toast.error("Enter valid otp", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                if (error.response.data.message === "OTP Incorrect") {
                    toast.error("Enter valid otp", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                if (error.response.data.message === "OTP Icorrect!.") {
                    toast.error("Enter valid otp", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }

            })
    }

    return (
        <section className="bg-image">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-sm-12 col-md-12 col-lg-12 mr-0 pr-0">
                        <div className="form-bg">
                            <form className="row form-style">
                                <div className="col-sm-12 col-md-12 text-center mb-4">
                                    <div className="logo-bg text-center">
                                        <div className="mt-2"><img src="assets/images/logo.png" className="img-fluid logo-admin" alt="logo" /></div>
                                    </div>
                                </div>
                                <div className="col-sm-12 text-center">
                                    <img src="assets/images/otp.png" className="img-fluid  iconIMg my-3" alt="" />
                                    <h2 className="mdl-ttl">OTP Verification</h2>
                                    <p className="mdl-sbttl">Please enter 6 digit code, which has been sent on your registered email address</p>
                                </div>
                                <div className="form-group col-sm-12">
                                    <div className="passcode-wrapper mx-4 ">
                                        {/* <input id="codeBox1" type="text" maxlength="1" placeholder="0" />
                                        <input id="codeBox2" type="text" maxlength="1" placeholder="0" />
                                        <input id="codeBox3" type="text" maxlength="1" placeholder="0" />
                                        <input id="codeBox4" type="text" maxlength="1" placeholder="0" />
                                        <input id="codeBox5" type="text" maxlength="1" placeholder="0" />
                                        <input id="codeBox6" type="text" maxlength="1" placeholder="0" /> */}
                                        <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} placeholder="000000" />

                                    </div>
                                </div>
                                <div className="col-sm-12 text-center">
                                    <span>00:{counter}</span>
                                </div>

                                <div className="col-sm-12 mt-4 text-center">
                                    <button className="btn btn-save w-50" onClick={handleOtpVerify}>Verify</button>
                                </div>
                                {counter === 0 ?
                                    <div className="col-sm-12 text-center mt-4">
                                        <div><Link to="#" className="forgot-pass p-0" onClick={handleResend}>Resend Code</Link></div>
                                    </div> : ""}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </section>

    )
}

export default OtpForgotPassword