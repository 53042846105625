import React, { useEffect } from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { getGemaSubmitted } from '../../app_redux/slice/gemaSubmitted'
import { getEvents } from '../../app_redux/slice/gemaEvents'

const StaffDetailModal = ({ show, handleShow, id }) => {
    const dispatch = useDispatch()
    const cookee = new Cookies();
    const token = cookee.get('token')
    const lng = cookee.get("googtrans")
    const [town, setTown] = useState("")
    const [eventid, setEventId] = useState("")

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [country, setCountry] = useState("Germany")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [gemaMembership, setGemaMembership] = useState("")
    const [ipiNumber, setIpiNumber] = useState("")
    const [diet, setDiet] = useState("")
    const [housenumber, setHouseNumber] = useState("")
    const [zipcode, setZipcode] = useState("")
    const [street, setStreet] = useState("")
    const [dob, setDob] = useState("")

    useEffect(() => {
        handleBandstaffClick()
    }, [])

    const handleBandstaffClick = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-staff-member-details`,
            data: {
                "staff_id": id
            }
        })
            .then(res => {
                // console.log(res.data.data)
                setEmail(res.data.data.email)
                setFirstName(res.data.data.first_name)
                setLastName(res.data.data.last_name)
                setTown(res.data.data.city)
                setStreet(res.data.data.street)
                setGemaMembership(res.data.data.gema_membership_number)
                setIpiNumber(res.data.data.gema_ipi_number)
                setHouseNumber(res.data.data.house_number)
                setDiet(res.data.data.nutrition)
                setZipcode(res.data.data.zip_code)
                setPhone(`${res.data.data.country_code} ${res.data.data.phone_number}`)
                setDob(res.data.data.dob)
            })
    }

    const handleReport = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: 'http://3.131.5.153:4050/v1/band-leader-report-concert-event',
            data:
            {
                "event_id": eventid || ""
            }
        })
            .then(res => {
                if (res.data.message === "Concert Reported Successfully") {
                    toast.success("Reported Successfully", {
                        theme: "colored",
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    dispatch(getGemaSubmitted())
                    dispatch(getEvents())

                    handleShow()

                }
            })
    }
    return (
        <>
            <Modal show={show} onHide={handleShow} size="xl" >
                <Modal.Body className="p-0">
                    <div className="modal-body">
                        <button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                        <div className="row">
                            <form className="row my-5 py-5 addNAme mx-width">
                                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                    <input type="text" className="form-control" value={firstName} placeholder="First Name" readOnly style={{ cursor: " context-menu" }} />
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                    <input type="text" className="form-control" value={lastName} placeholder="Last Name" readOnly style={{ cursor: " context-menu" }} />
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                    <input type="text" className="form-control" value={street} placeholder="Street" readOnly style={{ cursor: " context-menu" }} />
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                    <input type="text" className="form-control" value={housenumber} placeholder="House Number" readOnly style={{ cursor: " context-menu" }} />
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 mb-5 mt-1">
                                    <input type="text" className="form-control" value={zipcode} placeholder="Zip code" readOnly style={{ cursor: " context-menu" }} />
                                </div>

                                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                    <input type="text" className="form-control" value={town} placeholder="Town" readOnly style={{ cursor: " context-menu" }} />
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-6 mb-5">
                                    <input type="text" className="form-control" value={country} placeholder="Country" readOnly style={{ cursor: " context-menu" }} />
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-6 mb-5">
                                    <input type="text" className="form-control" value={phone} placeholder="Phone" readOnly style={{ cursor: " context-menu" }} />
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-6 mb-5">
                                    <input type="text" className="form-control" value={email} placeholder="Email" readOnly style={{ cursor: " context-menu" }} />
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-6 mb-5">
                                    <input type="text" className="form-control" value={dob?.substr(0, 10)} placeholder="Dob" readOnly style={{ cursor: " context-menu" }} />
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 mb-5">
                                    <label className='mt-4'> {lng==="/auto/de" || "/en/de" ? "GEMA-Mitgliednummer":"Gema Membership Number"}: </label>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 mb-5">
                                    <input type="text" className="form-control" value={gemaMembership} readOnly style={{ cursor: " context-menu" }} />
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-6 mb-5">
                                    <div class="nutritionRadio" style={{ cursor: " context-menu" }}>
                                        <label>Nutrition:</label>
                                        <p class="mb-0">
                                            <input type="radio" id="test1" name="radio-group" value="Vegetarian" checked={diet === "vegetarian"} />
                                            <label for="test1">Vegetarian</label>
                                        </p>
                                        <p class="mb-0">
                                            <input type="radio" id="test2" name="radio-group" value="Vegan" checked={diet === "vegan"} />
                                            <label for="test2">Vegan</label>
                                        </p>
                                        <p class="mb-0">
                                            <input type="radio" id="test3" name="radio-group" value="Meat" checked={diet === "fish/meat"} />
                                            <label for="test3">Meat/Fish</label>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 mb-5">
                                    <label className='mt-4'> {lng==="/auto/de" || "/en/de" ? "Ipi-Nummer":"Ipi-Number"}: </label>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 mb-5">
                                    <input type="text" className="form-control" value={ipiNumber} readOnly style={{ cursor: " context-menu" }} />
                                </div>

                            </form>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default StaffDetailModal