import React from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import { getEventDetail } from '../../../app_redux/slice/eventDetail'
import { useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ButtonLoader from '../../Loader/loading'


const AddPassengers = ({ show, handleShow, id, driverList, transportId, eventid }) => {
    const navigate = useNavigate();
    const cookee = new Cookies();
    const dispatch = useDispatch();
    const token = cookee.get('token')
    const [setupName, setSetupName] = useState("")
    const [passengerName, setPassengerName] = useState("")
    const [loader, setLoader] = useState(false)

    const handleChange = (e) => {
        setSetupName(e.target.value)
    }
    const handlePassengerName = (e) => {
        setPassengerName(e.target.value)
    }
    const handleAddPassenger = (e) => {
        e.preventDefault();
        setLoader(true)
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/bandleader-add-passenger-to-transport`,
            data:
            {
                "transport_id": transportId,
                "passengers": [
                    {
                        "passenger_name": passengerName
                    }
                ]

            }
        })
            .then(res => {
                //  console.log("RESPONSE", res.data)
                if (res.data.message === "Passengers Added Successfully!.") {
                    toast.success("Added Sucessfully", {
                        theme: "colored",
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    setLoader(false)
                    setPassengerName("")
                    dispatch(getEventDetail({ eventid: eventid }))
                    handleShow()

                }

            })
            .catch((error) => {
                // console.log("Error", error.response)
                if (error.response.data.message === "Internal server Error!") {
                    toast.error("Please Add Setlist First", {
                        theme: 'colored',
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
    }
    return (
        <>
            <Modal show={show} onHide={handleShow} size="md" centered >
                <Modal.Body className="p-0">
                    <div className="modal-body">
                        <button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                        <h4 className='mt-2 text-center'>Add Passenger</h4>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
                                <div className="d-flex align-items-center lg-justify-content-end mx-3">
                                    <input type="text" className="form-control p-0 ml-3" value={passengerName} onChange={handlePassengerName} placeholder='Person Name' style={{ height: '40px', borderRadius: "10px" }} />
                                </div>


                                <div className="align-items-center my-3">
                                    {loader ? <ButtonLoader view="btn add-new" style={{ marginLeft: "160px" }} /> :
                                        <button className="btn add-new" style={{ marginLeft: "160px" }} onClick={handleAddPassenger}>Safe</button>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>


                </Modal.Body>
            </Modal>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default AddPassengers;