import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useSelector, useDispatch } from 'react-redux'
import { getEventChats } from '../../app_redux/slice/eventChat'
import { getBandChats } from '../../app_redux/slice/bandChat'
import { useTranslation } from 'react-i18next'
import S3FileUpload from 'react-s3';
window.Buffer = window.Buffer || require("buffer").Buffer;




const AllChats = ({ allBandChats }) => {
    const { t } = useTranslation();
    const { tab1, tab2 } = t("chat")
    const [message3, setMessage3] = useState()
    const [loading1, setLoading1] = useState(false)
    const cookee = new Cookies();
    const dispatch = useDispatch()
    const token = cookee.get('token')


    const handleMessage3 = (e) => {
        setMessage3(e.target.value)
    }
    const onKeyPressBand = (e) => {
        if (e.which === 13) {
            handleBandSendMessage();
        }
    }

    const validBand = () => {
        if (message3.length === 0) {
            return false
        }
        else {
            return true
        }
    }
    const handleBandSendMessage = () => {
        if (validBand()) {
            axios({
                method: 'post',
                headers: {
                    "Accept": "/",
                    "Content-Type": "application/json",
                    "auth_token": token
                },
                url: `${process.env.REACT_APP_URL}/band-leader-send-message`,
                data:
                {
                    "user_type": 2,
                    "message": message3,
                    "message_type": "T",
                    "media_url": "",
                }
            })
                .then(res => {
                    //  console.log("res", res.data)
                    if (res.status === 200) {
                        dispatch(getBandChats())
                        setMessage3("")
                    }
                })
        }

    }


    const handleFile1 = (e) => {
        setLoading1(true)
        let file = e.target.files[0];
        const config = {
            bucketName: 'clever-band-assist-s3',
            region: 'eu-west-3',
            accessKeyId: 'AKIASTLKHDND66NQZXFJ',
            secretAccessKey: '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',
        }
        S3FileUpload
            .uploadFile(file, config)
            .then(data => {
                console.log("RES", data.location)
                // setImage(data.location)
                setLoading1(false)
                handleBandSendMessageImage(data.location)
            })
            .catch(err => console.error("ERR", err))
    }

    const handleBandSendMessageImage = (image) => {
        if (image) {
            axios({
                method: 'post',
                headers: {
                    "Accept": "/",
                    "Content-Type": "application/json",
                    "auth_token": token
                },
                url: `${process.env.REACT_APP_URL}/band-leader-send-message`,
                data:
                {
                    "user_type": 2,
                    "message": "",
                    "message_type": "I",
                    "media_url": image,
                }
            })
                .then(res => {
                    //  console.log("res", res.data)
                    if (res.status === 200) {
                        dispatch(getBandChats())
                        setMessage3("")
                    }
                })
        }

    }

    function formatDate(input) {
        var datePart = input.match(/\d+/g),
            year = datePart[0],
            month = datePart[1], day = datePart[2];

        return day + '.' + month + '.' + year;
    }



    return (

        <div className="col-sm-12 col-md-12 col-lg-8 ps-0 position-relative">
            <h5 className="d-flex align-items-center justify-content-center theme-color px-2 pt3 mb-0">{tab1}</h5>
            <hr />
            <div>
                {
                    allBandChats?.length > 0 ? allBandChats?.map((res, i) => {
                        return <div className="" key={i} >
                            {res?.user_type === 1 ?
                                <div>
                                    <img src={res?.profile_pic ? res?.profile_pic : "assets/images/dummy.png"} alt="" className="chatImg mx-2" /> <b className='notranslate'>{res?.first_name}</b>
                                    <div className="chatText chatTextWidthWhite">
                                        {res.media_url ? <img className="mb-0 d-flex align-items-center gap-3 justify-content-end" src={res.media_url} alt="" style={{ width: "300px" }} /> : ""}
                                        <p className='notranslate'>{res?.message}</p>
                                        <p className="mb-0 d-flex align-items-center gap-3 justify-content-end">{res?.created_at
                                            ? new Date(
                                                res?.created_at
                                            ).toLocaleString()
                                            : "-"} <img src="assets/images/blackDots.png" alt="" className="" /></p>
                                    </div>
                                </div> :
                                <div className="chatText chatTextWidthRed mb-3">
                                    <b style={{ textTransform: "uppercase" }} className='notranslate'>{res.band_name} </b>                                    <p className='notranslate mt-3'>{res?.message}</p>
                                    {res.media_url ? <img className="mb-0 d-flex align-items-center gap-3 justify-content-end" src={res.media_url} alt="" style={{ width: "300px" }} /> : ""}
                                    <p className="mb-0 d-flex align-items-center gap-3 justify-content-end">{res?.created_at
                                        ? new Date(
                                            res?.created_at
                                        ).toLocaleString()
                                        : "-"} <img src="assets/images/whiteDots.png" alt="" className="" /></p>
                                </div>
                            }

                        </div>
                    }) : "No Chat Found"}
            </div>
            {/* <p className='text-center'>No Chat Found</p> */}
            {loading1 ? <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div> : ""}
            <div className="ChatSendInput">
                <div className="position-relative">
                    <input type="text" className="form-control" value={message3} placeholder={tab2} onChange={handleMessage3} onKeyPress={onKeyPressBand} />
                    {/* <img src="assets/images/laugh.png" alt="" className="laughIcon" /> */}
                    <div className="d-flex InputIconRight">
                        <div className="upload-btn-wrapper Attachment mx-2" >
                            <img src="assets/images/gallery.png" width="25px" alt="" />
                            <input type="file" onChange={handleFile1} />
                        </div>
                        <img src="assets/images/send-message.png" alt="" onClick={handleBandSendMessage} className="iconImages" />
                    </div>
                </div>
            </div>

        </div>



    )
}

export default AllChats