import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

const cookee = new Cookies();

export const getSongDetail = createAsyncThunk("songdetail", async (obj) => {
    let token = cookee.get('token');
    // console.log("RED", obj?.page)
    let data = await axios({
        method: 'post',
        headers: {
            "Accept": "/",
            "Content-Type": "application/json",
            "auth_token": token
        },
        url: `${process.env.REACT_APP_URL}/band-leader-song-details`,
        data: {

            "song_id": obj?.id
        }
    })
        .then(res => (res.data.data))
    return data
})

const songDetail = createSlice(({
    name: "song details",
    initialState: {
        songDetails: "",
        loading: false
    },
    extraReducers: {
        [getSongDetail.pending]: (state, action) => {
            state.loading = true;
        },
        [getSongDetail.fulfilled]: (state, action) => {
            state.songDetails = action.payload
        },
        [getSongDetail.rejected]: (state, action) => {
            state.loading = false;
        }
    }
}))

export default songDetail.reducer;