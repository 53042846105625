import React from 'react'
import { useDrag } from "react-dnd";


const AllSong = ({ item, playerType, onDropPlayer, index }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: playerType,
        item: () => ({ ...item, index }),
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (dropResult) {
                onDropPlayer(item, index);
            }
        },
        collect: (monitor) => ({
            // isDragging: monitor.isDragging(),
            isDragging: !!monitor.getItem(),

        }),
    });
    return (
        <tr ref={dragRef} >
            <td className='notranslate' style={{ cursor: "pointer" }}>{item.song_name}</td>
            <td>{item.time_length?.substr(3, 6)}</td>
        </tr>
    )
}

export default AllSong