import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'


const cookee = new Cookies();

export const getEventDetail = createAsyncThunk("eventdata", async (obj) => {
    let token = cookee.get('token');
    // console.log("RED", obj?.page)
    let data = await axios({
        method: 'post',
        headers: {
            "Accept": "/",
            "Content-Type": "application/json",
            "auth_token": token
        },
        url: `${process.env.REACT_APP_URL}/get-event-details-event-id`,
        data: {
            "event_id": obj?.eventid,
        }
    })
        .then(res => (res.data.data))
    return data
})

const eventDetail = createSlice(({
    name: "event details",
    initialState: {
        eventDetails: [],
        loading: false
    },
    extraReducers: {
        [getEventDetail.pending]: (state, action) => {
            state.loading = true;
        },
        [getEventDetail.fulfilled]: (state, action) => {
            state.eventDetails = action.payload;
            state.loading = false;

        },
        [getEventDetail.rejected]: (state, action) => {
            state.loading = false;
        }
    }
}))

export default eventDetail.reducer;