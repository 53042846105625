import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { getSongs } from "../../../app_redux/slice/setlistSong";
import { getEventDetail } from "../../../app_redux/slice/eventDetail";
import { useTranslation } from "react-i18next";

const SetListModal = ({
  setListData,
  setSetListData,
  setSetlistId,
  setSetlistLength,
  event,
}) => {
  const { t } = useTranslation();
  const cookee = new Cookies();
  const dispatch = useDispatch();
  const token = cookee.get("token");
  const [setList, setSetList] = useState([]);
  const { allSetlistSongs, loading } = useSelector(
    (state) => state.setlistsong
  );
  const { tab52, tab67, tab69 } = t("event");
  useEffect(() => {
    getSetList();
  }, []);
  const getSetList = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-set-list-by-band-id`,
      data: {
        page_no: 1,
      },
    }).then((res) => {
      setSetList(res.data.data);
    });
  };
  const handleAdd = (name, id, length) => {
    console.log(id);
    setSetlistId(id);
    dispatch(getSongs({ id: id }));
    // dispatch(getEventDetail({ eventid: event }))
    // setSetlistLength(length)
    toast.success(`${name} Setlist Added`, {
      theme: "colored",
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setSetListData([...setListData, { song_name: "XYZ", Song_time: "12" }]);
  };
  return (
    <article className="col-lg-9">
      <div className="setlist-body store-bd-gy">
        <div className="row mt-3"></div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="pt-3 px-1 bg-white">
              <div className="table-responsive">
                <table className="table storetable text-center">
                  <thead className="">
                    <tr>
                      <th scope="col">setlist</th>
                      <th scope="col">{tab69}</th>
                      <th scope="col">{tab52}</th>
                    </tr>
                    <tr className="border-0 bg-none invisible">
                      <td className="border-0 bg-none"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {setList !== undefined && setList?.length !== 0 ? (
                      setList?.map((res, key) => {
                        return (
                          <tr>
                            <td className="notranslate">
                              {res?.set_list_name}
                            </td>
                            <td>{res?.total_length?.substr(3, 6)}</td>
                            <td>
                              <div className="d-flex gap-3 justify-content-center">
                                {/* <img src="assets/images/deleteBlack.png" width="25px" alt="" /><img src="assets/images/printing.png" width="25px" alt="" /> */}
                                <button
                                  className="btn add-new"
                                  onClick={() =>
                                    handleAdd(
                                      res?.set_list_name,
                                      res?.set_list_id,
                                      res?.total_length
                                    )
                                  }
                                >
                                  {tab67}
                                </button>
                                <input
                                  type="checkbox"
                                  id="vehicle1"
                                  name="vehicle1"
                                  value=""
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <p className="mt-5">No SetList Found!!</p>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </article>
  );
};

export default SetListModal;
