import React, { useState, useEffect } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { getEventDetail } from '../../app_redux/slice/eventDetail'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
require('moment/locale/de.js')
const localizer = momentLocalizer(moment)

const AbsenceCalendarView = () => {
    const cookee = new Cookies();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const token = cookee.get('token')
    const [absenceData, setAbsenceData] = useState([])
    useEffect(() => {
        getAbsenceData()
    }, [])



    const getAbsenceData = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-band-leader-absence-staff-members`,
            data: {
                "page_no": 1
            }
        })
            .then(res => {
                setAbsenceData(res.data.data.map((res, i) => {
                    return { id: res.staff_id, title: `${res.first_name} ${res.last_name}`, start: res.from_absence_date, end: res.to_absence_date }
                }))
            })
    }

    return (
        <div className='notranslate'>
            <Calendar
                localizer={localizer}
                events={absenceData}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 800 }}
                messages={{
                    next: "nachste",
                    previous: "zuruck",
                    today: "Heute",
                    month: "monat",
                    week: "woche",
                    day: "tag"
                }}
            // onSelectEvent={(e) => handleSelectedEvent(e)}
            />
        </div>
    )
}

export default AbsenceCalendarView