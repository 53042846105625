import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getPermissionInfo = createAsyncThunk("permission", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('token')
    let detail =
        await axios({
            method: 'get',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/get-band-leader-profile?user_id=${obj?.id}`,
        })
            .then(res => res.data.data2)
    return detail
})

const musicianDetail = createSlice(({
    name: "permission detail",
    initialState: {
        permissionDetail: "",
        loading: false
    },
    extraReducers: {
        [getPermissionInfo.pending]: (state, action) => {
            state.loading = true;
        },
        [getPermissionInfo.fulfilled]: (state, action) => {
            state.permissionDetail = action.payload
        },
        [getPermissionInfo.rejected]: (state, action) => {
            state.loading = false;
        }
    }
}))

export default musicianDetail.reducer;