import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { getSetlistDetail } from "../../app_redux/slice/setlistDetail";

const SetlistPrintDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const localsetlist = JSON.parse(localStorage.getItem("setlistdetails"));

  useEffect(() => {
    dispatch(getSetlistDetail());
  }, []);

  const generatePDF = () => {
    const doc = new jsPDF("p", "pt", "a4");
    doc.setFontSize(22);
    doc.text(location.state?.name, 25, 25);
    //  doc.text(setlistDetails.band_name, 45, 45)
    doc.autoTable({ html: "#content", styles: { fontSize: 22 } });
    doc.save("setlist.pdf");
  };

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row mt-3">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <button className="btn add-new" onClick={generatePDF}>
                print
              </button>
              <Link to="/gema-setlist">
                {" "}
                <button className="btn add-new">Gema Export</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="pt-3 px-1 bg-white">
              <div className="table-responsive">
                {/* <h5>{setlistDetails?.set_list_name?.toUpperCase()}</h5> */}
                <table className="table storetable text-center" id="content">
                  <thead className="">
                    <tr>
                      <th scope="col">Number</th>
                      <th scope="col">Name</th>
                      <th scope="col">BPM</th>
                      <th scope="col">Length</th>
                    </tr>
                    <tr className="border-0 bg-none invisible">
                      <td className="border-0 bg-none"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {localsetlist ? (
                      localsetlist.map((res, key) => {
                        return (
                          <tr>
                            <td>{res.display_seq}</td>
                            <td className="notranslate">{res.song_name}</td>
                            <td>{res.bpm}</td>
                            <td>{res.time_length?.substr(3, 6)}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <p className="mt-5">Song Not Available !!</p>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </article>
  );
};

export default SetlistPrintDetails;
