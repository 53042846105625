import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getBandleaderProfile = createAsyncThunk("profile", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('token')
    let profile =
        await axios({
            method: 'get',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/get-band-leader-profile`,
        })
            .then(res => res.data.data)
    return profile
})

const listBandProfile = createSlice(({
    name: "all tasks",
    initialState: {
        bandProfile: [],
        loading: false
    },
    extraReducers: {
        [getBandleaderProfile.pending]: (state, action) => {
            state.loading = true;
        },
        [getBandleaderProfile.fulfilled]: (state, action) => {
            state.bandProfile = action.payload
        },
        [getBandleaderProfile.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listBandProfile.reducer;