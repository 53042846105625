import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getNotification = createAsyncThunk("notification", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('token')
    let notification =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/get-band-leader-notification-list`,
            data:{
                "page_no":obj?.pageno,
                "user_type":2
            }
        })
            .then(res => res.data.data)
    return notification
})

const notification = createSlice(({
    name: "all setlist",
    initialState: {
        allNotification: [],
        loading: false
    },
    extraReducers: {
        [getNotification.pending]: (state, action) => {
            state.loading = true;
        },
        [getNotification.fulfilled]: (state, action) => {
            state.allNotification = action.payload
        },
        [getNotification.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default notification.reducer;