import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getBandStaff = createAsyncThunk("bandstaffs", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('token')
    let bandstaff =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            data: {
                "page_no": obj?.pageno,
                "search_text": obj?.search,
            },
            url: `${process.env.REACT_APP_URL}/get-all-band-leader-staff-list`,
        })
            .then(res => res.data)
    return bandstaff
})

const listBandstaff = createSlice(({
    name: "all band staff",
    initialState: {
        allBandStaff: [],
        loading: false
    },
    extraReducers: {
        [getBandStaff.pending]: (state, action) => {
            state.loading = true;
        },
        [getBandStaff.fulfilled]: (state, action) => {
            state.allBandStaff = action.payload;
            state.loading = false;
        },
        [getBandStaff.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listBandstaff.reducer;