import React, { useEffect, useState } from 'react'
import { getSongs } from '../../app_redux/slice/songList'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { getEventChats } from '../../app_redux/slice/eventChat'
import { getBandChats } from '../../app_redux/slice/bandChat'
import { getTaskChats } from '../../app_redux/slice/taskChat'

const Chat = () => {
    const dispatch = useDispatch()
    const cookee = new Cookies();
    const token = cookee.get('token')
    const [message, setMessage] = useState("")
    const [message2, setMessage2] = useState("")
    const [message3, setMessage3] = useState("")
    const [chatlist, setChatlist] = useState([])
    const [bandMusician, setBandMusician] = useState([])
    const [eventId, setEventId] = useState("")
    const [taskid, setTaskid] = useState("")
    const [userId, setUserId] = useState("")
    const [userId2, setUserId2] = useState("")
    const [taskChatlist, setTaskChatlist] = useState([])
    const { allEventChats, loading } = useSelector((state) => state.eventchat)
    const { allTaskChats } = useSelector((state) => state.taskchat)
    const { allBandChats } = useSelector((state) => state.bandchat)
    // const [allBandChats, setAllBandChats] = useState([])
    const [showTaskChat, setShowTaskChat] = useState(false)
    const [showEventChat, setShowEventChat] = useState(true)
    const [chatName, setChatName] = useState("")

    useEffect(() => {
        dispatch(getSongs())
        getChatList()
        getBandMusicianChatlist()
        getTaskChatlist()
    }, [])
    function formatDate(input) {
        var datePart = input.match(/\d+/g),
            year = datePart[0],
            month = datePart[1], day = datePart[2];

        return day + '.' + month + '.' + year;
    }
    const handleMessage = (e) => {
        setMessage(e.target.value)
    }
    const handleMessage2 = (e) => {
        setMessage2(e.target.value)
    }
    const handleMessage3 = (e) => {
        setMessage3(e.target.value)
    }
    const getChatList = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-band-leader-event-chat-list`,
            data:
            {
                "page_no": 1
            }
        })
            .then(res => {
                setChatlist(res.data.data)
            })
    }
    const onKeyPressBand = (e) => {
        if (e.which === 13) {
            handleBandSendMessage();
        }
    }
    const onKeyPressEvent = (e) => {
        if (e.which === 13) {
            handleSendMessage();
        }
    }
    const valid = () => {
        if (message.length === 0) {
            return false
        }
        else {
            return true
        }
    }
    const handleSendMessage = () => {
        if (valid()) {
            axios({
                method: 'post',
                headers: {
                    "Accept": "/",
                    "Content-Type": "application/json",
                    "auth_token": token
                },
                url: `${process.env.REACT_APP_URL}/band-leader-musician-event-chat-send-message`,
                data:
                {
                    "event_id": eventId,
                    "message": message,
                    "user_id": "",
                    "message_type": "T",
                    "media_url": "",
                    "user_type": 2
                }
            })
                .then(res => {
                    //  console.log("res", res.data)
                    if (res.data.message === "Message Sent Successfully!.") {
                        dispatch(getEventChats({ eventid: eventId }))
                        setMessage("")
                    }
                })
        }
    }
    const handleChatClick = (eventid, name) => {
        setShowEventChat(true)
        setShowTaskChat(false)
        setEventId(eventid)
        setChatName(name)
        dispatch(getEventChats({ eventid: eventid }))
        setInterval(function () { dispatch(getEventChats({ eventid: eventid })) }, 3000)

    }
    const handleChatTaskClick = (taskid, name) => {
        setShowEventChat(false)
        setShowTaskChat(true)
        setTaskid(taskid)
        setChatName(name)
        dispatch(getTaskChats({ taskid: taskid }))
        setInterval(function () { dispatch(getTaskChats({ taskid: taskid })) }, 3000)

    }

    const getBandMusicianChatlist = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-band-leader-musician-chat-list`,
            data:
            {
                "page_no": 1
            }
        })
            .then(res => {
                // console.log("res", res.data)
                setBandMusician(res.data.data)
            })
    }
    const getTaskChatlist = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-band-leader-task-chat-list`,
            data:
            {
                "page_no": 1
            }
        })
            .then(res => {
                // console.log("res", res.data)
                setTaskChatlist(res.data.data)
            })
    }

    const handleClick = (id) => {
        dispatch(getBandChats())
        setInterval(function () { dispatch(getBandChats()) }, 3000)
        setUserId2(id)
    }
    const handleBandSendMessage = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/band-leader-send-message`,
            data:
            {
                "user_type": 2,
                "message": message3,
                "message_type": "T",
                "media_url": ""
            }
        })
            .then(res => {
                //  console.log("res", res.data)
                if (res.data.message === "Message Sent Successfully!.") {
                    dispatch(getBandChats())
                    setMessage3("")
                }
            })
    }
    const onKeyPressTask = (e) => {
        if (e.which === 13) {
            handleTaskSendMessage();
        }
    }
    const validtask = () => {
        if (message2.length === 0) {
            return false
        }
        else {
            return true
        }
    }
    const handleTaskSendMessage = () => {
        if (validtask()) {
            axios({
                method: 'post',
                headers: {
                    "Accept": "/",
                    "Content-Type": "application/json",
                    "auth_token": token
                },
                url: `${process.env.REACT_APP_URL}/band-leader-musician-task-chat-send-message`,
                data:
                {
                    "task_id": taskid,
                    "user_type": 2,
                    "message": message2,
                    "message_type": "T",
                    "media_url": ""
                }
            })
                .then(res => {
                    //  console.log("res", res.data)
                    if (res.data.message === "Message Sent Successfully!.") {
                        dispatch(getTaskChats({ taskid: taskid }))
                        setMessage2("")
                    }
                })
        }
    }
    return (
        <article className="col-lg-9">
            <div className="article-body store-bd-gy">
                <section className="chatFlex">
                    <div className="bgChatSec">
                        <h5 className='text-center'>Band Chats</h5>
                        <hr />
                        <div className="">
                            {allBandChats?.length !== 0 ? allBandChats?.map((res, i) => {
                                return <>

                                    {res.user_type === 1 ? <>
                                        {/* <img src={allEventChats?.userProfileDetails[0]?.profile_pic} alt="" className="chatImg headerImgProfilePic" /> <b>{allEventChats?.userProfileDetails[0]?.first_name}</b> */}
                                        <div className="chatText chatTextWidthWhite">
                                            {res.media_url ? <img className="mb-0 d-flex align-items-center gap-3 justify-content-end" src={res.media_url} alt="" style={{ width: "300px" }} /> : ""}
                                            <b style={{ textTransform: "uppercase" }}>{res.first_name} {res.last_name}</b>
                                            <p className='notranslate mt-2'>{res.message}</p>
                                            <p className="mb-0 d-flex align-items-center gap-3 justify-content-end">{formatDate(res.created_at?.substr(0, 10))} <img src="assets/images/blackDots.png" alt="" className="" /></p>
                                        </div> </> : ""}
                                    {res.user_type === 2 ? <div className="chatText chatTextWidthRed">
                                        <p className='notranslate'>{res.message}</p>
                                        <p className="mb-0 d-flex align-items-center gap-3 justify-content-end">{formatDate(res.created_at?.substr(0, 10))} <img src="assets/images/whiteDots.png" alt="" className="" /></p>
                                    </div> : ""}
                                </>
                            }) : <p>No Messages!!</p>}

                        </div>

                        <div className="ChatSendInput">
                            <div className="position-relative">
                                <input type="text" value={message3} onKeyPress={onKeyPressBand} className="form-control" placeholder="Write a message" onChange={handleMessage3} />
                                {/* <img src="assets/images/laugh.png" alt="" className="laughIcon" /> */}
                                <div className="InputIconRight">
                                    {/* <img src="assets/images/mic.2.png" alt="" className="iconImages" />
                                    <img src="assets/images/gallery.png" alt="" className="iconImages" />
                                    <img src="assets/images/attachmentGray.png" alt="" className="iconImages" /> */}
                                    <img src="assets/images/send-message.png" alt="" className="iconImages" onClick={handleBandSendMessage} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">

                        <div className="listChat">
                            <img src="assets/images/down-filled-triangular-arrow.png" alt="" className="downFilledIcon" />
                            <h5>Appointment chats</h5>
                            {chatlist.map((res, i) => {
                                const input = res.chat_name
                                const [name, chatname] = input.split("-")
                                return <p className="theme-color" onClick={() => handleChatClick(res.event_id, res.chat_name)}>{res.chat_name.substr(12, 20)}</p>
                            })}
                        </div>
                        <div className="listChat">
                            <img src="assets/images/down-filled-triangular-arrow.png" alt="" className="downFilledIcon" />
                            <h5>Taskchats</h5>
                            {taskChatlist.map((res, i) => {
                                return <p className="theme-color" onClick={() => handleChatTaskClick(res.task_id, res.task_chat_name)}>{res.task_chat_name?.substr(12, 20)}</p>
                            })}
                        </div>
                        <div className="listChat">
                            <img src="assets/images/down-filled-triangular-arrow.png" alt="" className="downFilledIcon" />
                            <h5>Bandchats</h5>
                            <p className="theme-color" onClick={handleClick}>Band Chat</p>
                            {/* {bandMusician.map((res, i) => {
                                return <p className="theme-color" onClick={() => handleClick(res.user_id)}>{res.first_name}</p>

                            })} */}
                        </div>
                    </div>

                    {showEventChat ?
                        <div className="bgChatSec">
                            <h5 className='text-center'>Event Chats</h5>
                            <h6 className='text-center'>{chatName?.substr()}</h6>
                            <hr />
                            <div className="">
                                {allEventChats?.length !== 0 ? allEventChats?.map((res, i) => {
                                    return <>

                                        {res.user_type === 1 ? <>
                                            {/* <img src={allEventChats?.userProfileDetails[0]?.profile_pic} alt="" className="chatImg headerImgProfilePic" /> <b>{allEventChats?.userProfileDetails[0]?.first_name}</b> */}
                                            <div className="chatText chatTextWidthWhite">
                                                {res.media_url ? <img className="mb-0 d-flex align-items-center gap-3 justify-content-end" src={res.media_url} alt="" style={{ width: "300px" }} /> : ""}
                                                <b style={{ textTransform: "uppercase" }}>{res.first_name} {res.last_name}</b>
                                                <p className='mt-2'>{res.message}</p>
                                                <p className="mb-0 d-flex align-items-center gap-3 justify-content-end"> {formatDate(res.created_at?.substr(0, 10))} <img src="assets/images/blackDots.png" alt="" className="" /></p>
                                            </div> </> : ""}
                                        {res.user_type === 2 ? <div className="chatText chatTextWidthRed">
                                            <p>{res.message}</p>
                                            <p className="mb-0 d-flex align-items-center gap-3 justify-content-end"> {formatDate(res.created_at?.substr(0, 10))}<img src="assets/images/whiteDots.png" alt="" className="" /></p>
                                        </div> : ""}
                                    </>
                                }) : <p>No Messages!!</p>}

                            </div>

                            <div className="ChatSendInput">
                                <div className="position-relative">
                                    <input type="text" value={message} onKeyPress={onKeyPressEvent} className="form-control" placeholder="Write a message" onChange={handleMessage} />
                                    {/* <img src="assets/images/laugh.png" alt="" className="laughIcon" /> */}
                                    <div className="InputIconRight">
                                        {/* <img src="assets/images/mic.2.png" alt="" className="iconImages" />
             <img src="assets/images/gallery.png" alt="" className="iconImages" />
             <img src="assets/images/attachmentGray.png" alt="" className="iconImages" /> */}
                                        <img src="assets/images/send-message.png" alt="" className="iconImages" onClick={handleSendMessage} />
                                    </div>
                                </div>
                            </div>
                        </div> : ""}

                    {showTaskChat ?
                        <div className="bgChatSec">
                            <h5 className='text-center'>Task Chats</h5>
                            <h6 className='text-center'>{chatName}</h6>
                            <hr />
                            {allTaskChats?.length !== 0 ? allTaskChats?.map((res, i) => {
                                return <div className="">
                                    {res.user_type === 1 ? <>
                                        {/* <img src={allBandChats?.userProfileDetails[0]?.profile_pic ? allBandChats?.userProfileDetails[0]?.profile_pic : "assets/images/blackDots.png"}
                                        alt="" className="chatImg headerImgProfilePic" /> <b>{allBandChats?.userProfileDetails[0]?.first_name || ""}</b> */}
                                        <div className="chatText chatTextWidthWhite">
                                        <b style={{ textTransform: "uppercase" }}>{res.first_name} {res.last_name}</b>
                                            <p>{res.message}</p>
                                            {res.media_url ? <img className="mb-0 d-flex align-items-center gap-3 justify-content-end" src={res.media_url} alt="" style={{ width: "300px" }} /> : ""}
                                            <p className="mb-0 d-flex align-items-center gap-3 justify-content-end">{formatDate(res.created_at?.substr(0, 10))} <img src="assets/images/blackDots.png" alt="" className="" /></p>
                                        </div>
                                    </> : ""}
                                    {res.user_type === 2 ?
                                        <div className="chatText chatTextWidthRed">

                                            <p className='mt-2'>{res.message}</p>
                                            <p className="mb-0 d-flex align-items-center gap-3 justify-content-end"> {formatDate(res.created_at?.substr(0, 10))} <img src="assets/images/whiteDots.png" alt="" className="" /></p>
                                        </div> : ""}
                                </div>

                            }) : <p>No Messages!!</p>}


                            <div className="ChatSendInput">
                                <div className="position-relative">
                                    <input type="text" value={message2} onKeyPress={onKeyPressTask} className="form-control" placeholder="Write a message" onChange={handleMessage2} />
                                    <div className="InputIconRight">
                                        {/* <img src="assets/images/gallery.png" alt="" className="iconImages" /> */}
                                        <img src="assets/images/send-message.png" alt="" className="iconImages" onClick={handleTaskSendMessage} />
                                    </div>
                                </div>
                            </div>
                        </div> : ""}
                </section>
            </div >

        </article >

    )
}

export default Chat