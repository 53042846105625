import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import OTPInput from "otp-input-react";
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie'
import { Modal } from 'react-bootstrap'



const OtpEmailModal = ({ show, handleShow, email, setShowPhoneOtp, number }) => {
    const cookee = new Cookies();
    const [OTP, setOTP] = useState("");
    const [counter, setCounter] = useState(60)
    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer)
    }, [counter])

    const handleSendOtpPhone = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/send-musician-otp-with-mobile-number`,
            data:
            {

                "country_code": number?.substr(0, 3),
                "phone_number": number?.substr(3, 12)
            }
        })
            .then(res => {
                if (res.data.message === "OTP Sent on Registered Phone Number") {
                    // navigate('/otp-verification')
                    setShowPhoneOtp(true)
                    handleShow()
                }

            })
            .catch((error) => {
                if (error.response.data.message === 'Invalid Phone Number! Retry.') {
                    toast.error("Invalid Phone Number.", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
    }

    const handleOtpVerify = (e) => {
        e.preventDefault();
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/verify-musician-otp-with-email`,
            data:
            {

                "email": email,
                "verify_otp": OTP
            }
        })
            .then(res => {
                if (res.data.message === "OTP Verified Successfully!") {
                    toast.success("Email Verified Successfully.", {
                        position: "bottom-center",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    handleSendOtpPhone()
                }
                if (res.data.message === "OTP Verfied Successfully!.") {
                    toast.success("Email Verified Successfully.", {
                        theme: 'colored',
                        position: "bottom-center",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    handleSendOtpPhone()

                }
            })
            .catch((error) => {
                if (error.response.data.message === "OTP Required") {
                    toast.error("Enter valid otp", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                if (error.response.data.message === "OTP Icorrect!.") {
                    toast.error("Enter valid otp", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                if (error.response.data.message === "OTP Incorrect") {
                    toast.error("Enter valid otp", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
    }
    const handleSendOtp = (e) => {
        setCounter(60)
        e.preventDefault()
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/send-musician-otp-with-email`,
            data:
            {

                "email": email,
            }
        })
            .then(res => {
                if (res.data.message === "OTP Sent on Registered Phone Number") {
                    // navigate('/otp-verification')
                    toast.success("OTP sent successfully.", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }

            })
            .catch((error) => {
                if (error.response.data.message === 'Invalid Phone Number! Retry.') {
                    toast.error("Invalid Phone Number.", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
    }


    return (
        <>
            <Modal show={show} onHide={handleShow} size="md" >
                <Modal.Body className="p-0">
                    <div >
                            <div className="">
                                <div className="row">
                                    {/* <div className="col-sm-12 col-md-6 col-lg-7 ps-0 position-relative">
                                    <img src="assets/images/long-hair-guitar-guy-singing-low.png" className="img-fluid" alt="" />
                                        <Link to="/"> <img src="assets/images/logo.png" className="musiLogo" alt="" /></Link>
                                    </div> */}
                                    <div className="col-sm-12 col-md-12 col-lg-12 ">
                                        <div className="d-table h-100">
                                            <form className="form-stylee">
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-12 text-center mb-4">
                                                        <h3 className="theme-color">Email OTP Verification</h3>
                                                        <p>Please enter 6 digits code</p>
                                                    </div>
                                                    <div className="form-group col-sm-12">
                                                        <div className="passcode-wrapper">
                                                            {/* <input id="codeBox1" type="text" maxLength="1" placeholder="" />
                                            <input id="codeBox2" type="text" maxLength="1" placeholder="" />
                                            <input id="codeBox3" type="text" maxLength="1" placeholder="" />
                                            <input id="codeBox4" type="text" maxLength="1" placeholder="" />
                                            <input id="codeBox5" type="text" maxLength="1" placeholder="" />
                                            <input id="codeBox6" type="text" maxLength="1" placeholder="" /> */}
                                                            <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} placeholder="000000" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12 text-center mb-3">
                                                        <span className="fw-bold">00:{counter}</span>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12 text-center mb-5">
                                                        <button className="btn btn-save w-75" onClick={handleOtpVerify} >VERIFY</button>
                                                        {/* <Link to="/signup" className="btn btn-save w-75"><b>VERIFY</b></Link> */}

                                                    </div>
                                                    {counter === 0 ?
                                                        <div className="form-group mb-4 text-center">
                                                            <div><a href="#" className="forgot-pass p-0" onClick={handleSendOtp}>Resend Code</a></div>
                                                        </div>
                                                        : ""}

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default OtpEmailModal