import { useEffect } from "react";
import Signup from "./Components/Register/signup";
import { Routes, Route } from "react-router-dom";
import MainSection from "./Layout/mainSection";
import EventDates from "./Components/Content/Eventdates/eventDates";
import EventBand from "./Components/Content/Eventdates/eventBand";
import EventTransport from "./Components/Content/Eventdates/eventTransport";
import EventSetlist from "./Components/Content/Eventdates/eventSetlist";
import EventAttachments from "./Components/Content/Eventdates/eventAttachments";
import ScrollToTop from "./scrollToTop";
import Songs from "./Components/Songs/songs";
import Setlist2 from "./Components/SetList/setList2";
import Bandstaff from "./Components/Bandstaff/bandstaff";
import Gema from "./Components/Gema/gema";
import Tasklist from "./Components/Tasklist/tasklist";
import TaskListInProgress from "./Components/Tasklist/taskListInProgress";
import TaskListCompleted from "./Components/Tasklist/taskListCompleted";
import AddNewTask from "./Components/Tasklist/addNewTask";
import Chat from "./Components/Chat/chat";
import Setlist from "./Components/SetList/setlist";
import Login from "./Components/Register/login";
import OtpVerification from "./Components/Register/otpVerification";
import Dashboard from "./Components/Dashboard/dashboard";
import UpcomingEvents from "./Components/Events/upcomingEvents";
import AddNewEvent from "./Components/Content/addNewEvent";
import UnconfirmedEvents from "./Components/Events/unconfirmedEvents";
import PastEvents from "./Components/Events/pastsEvents";
import AddBandStaff from "./Components/Bandstaff/addBandStaff";
import AddRehearealEvent from "./Components/Content/Eventdates/addRehearsalEvent";
import AddOtherEvent from "./Components/Content/Eventdates/addOtherAppointments";
import LoginEmail from "./Components/Register/loginEmail";
import ForgotPassword from "./Components/Register/forgotPassword";
import OtpForgotPassword from "./Components/Register/otpForgotPassword";
import ResetPassword from "./Components/Register/resetPassword";
import EditEventData from "./Components/Content/editEventData";
import CalendarView from "./Components/Calendar/calendar";
import AddSong from "./Components/Songs/addSong";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import EditSong from "./Components/Songs/editSong";
import ChangePassword from "./Components/Register/changePassword";
import Profile from "./Components/Profile/profile";
import BandstaffDetail from "./Components/Bandstaff/bandstaffDetail";
import AddSetListSong from "./Components/SetList/addSetlistSong";
import EditSetlist from "./Components/SetList/editSetlist";
import SinglePage from "./Layout/singlePage";
import AboutUs from "./Components/Aboutus/aboutUs";
import GemaEdit from "./Components/Gema/gemaEdit";
import TaskOverview from "./Components/Tasklist/taskDetail";
import SetlistPrintDetails from "./Components/SetList/setlistPrintDetails";
import GemaWithoutDeal from "./Components/Gema/gemaWithoutDeal";
import TermAndCondition from "./Components/Aboutus/termAndCondition";
import ChatEvent from "./Components/Chat/chatEvent";
import ChatTask from "./Components/Chat/chatTask";
import GemaSetlistPrint from "./Components/SetList/gemaSetlistPrint";
import TaskOverviewMusician from "./Components/Tasklist/taskDetailMusician";
import "./firebase";

import AllNotification from "./Components/Notification/notification";
import { ToastContainer } from "react-toastify";
import { useState } from "react";
import EditEventDataOthers from "./Components/Content/editReahOther";
import ChatList from "./Components/Chat/chatList";
import EventChatList from "./Components/Chat/eventChats";
import TaskChatList from "./Components/Chat/taskChatlist";
import Invitation from "./Components/BandInvitation/invitation";
import TaskOverviewAdmin from "./Components/Tasklist/taskDetailAdmin";
import PrivacyPolicy from "./Components/Aboutus/privacyAndPolicy";
import ConcertPdf from "./Components/Events/concertPdf";
import UploadExcelSongs from "./Components/Songs/uploadSongs";

function App() {
  const cookie = new Cookies();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const user = queryParams.get("user");

  useEffect(() => {
    if (token) {
      cookie.remove("email");
      cookie.remove("bandname");
      cookie.remove("phonenumber");
      cookie.set("redirect_userid", user);
    }
  }, []);

  useEffect(() => {
    if (splitLocation[1] === "") {
      window.location = "https://cleverbandassist.com";
    }
    if (splitLocation[1] === "login-email") {
      window.location = "https://cleverbandassist.com";
    }
    if (splitLocation[1] === "login") {
      window.location = "https://cleverbandassist.com";
    }
    if (splitLocation[1] === "forgot-password") {
      window.location = "https://cleverbandassist.com";
    }
  }, []);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login-email" element={<LoginEmail />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/otp-verification" element={<OtpVerification />} />
        <Route path="/otp-forgot-password" element={<OtpForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/calendar" element={<CalendarView />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/event-dates-info" element={<MainSection />}>
          <Route path="/event-dates-info" element={<EventDates />} />
        </Route>
        <Route path="/event-dates-info" element={<MainSection />}>
          <Route path="/event-dates-info" element={<EventDates />} />
        </Route>
        <Route path="/edit-event" element={<MainSection />}>
          <Route path="/edit-event" element={<EditEventData />} />
        </Route>
        <Route path="/edit-event-others" element={<MainSection />}>
          <Route path="/edit-event-others" element={<EditEventDataOthers />} />
        </Route>
        <Route path="/events" element={<MainSection />}>
          <Route path="/events" element={<UpcomingEvents />} />
        </Route>
        <Route path="/unconfirmed-events" element={<MainSection />}>
          <Route path="/unconfirmed-events" element={<UnconfirmedEvents />} />
        </Route>
        <Route path="/pasts-events" element={<MainSection />}>
          <Route path="/pasts-events" element={<PastEvents />} />
        </Route>
        <Route path="/add-new-event" element={<MainSection />}>
          <Route path="/add-new-event" element={<AddNewEvent />} />
        </Route>
        <Route path="/add-rehearsal-event" element={<MainSection />}>
          <Route path="/add-rehearsal-event" element={<AddRehearealEvent />} />
        </Route>
        <Route path="/add-other-event" element={<MainSection />}>
          <Route path="/add-other-event" element={<AddOtherEvent />} />
        </Route>
        <Route path="/dashboard" element={<MainSection />}>
          <Route index element={<Dashboard switchtoken={token} />} />
          <Route path={`?token=${token}`} element={<Dashboard />} />
        </Route>
        {/* <Route path={`/dashboard-${splitLocation[1]}`} element={<MainSection />}>
          <Route path={`/dashboard-${splitLocation[1]}`} element={<Dashboard />} />
        </Route>
        <Route path={`/dashboard/admin?token=${token}`} element={<MainSection />}>
          <Route path={`/dashboard/admin?token=${token}`} element={<Dashboard />} />
        </Route> */}
        <Route path="/event-band" element={<MainSection />}>
          <Route path="/event-band" element={<EventBand />} />
        </Route>
        <Route path="/event-transport" element={<MainSection />}>
          <Route index element={<EventTransport />} />
        </Route>
        <Route path="/event-setlist" element={<MainSection />}>
          <Route index element={<EventSetlist />} />
        </Route>
        <Route path="/event-attachment" element={<MainSection />}>
          <Route index element={<EventAttachments />} />
        </Route>
        <Route path="/songs" element={<MainSection />}>
          <Route path="/songs" element={<Songs />} />
        </Route>
        <Route path="/songs-upload" element={<MainSection />}>
          <Route path="/songs-upload" element={<UploadExcelSongs />} />
        </Route>
        <Route path="/add-song" element={<MainSection />}>
          <Route path="/add-song" element={<AddSong />} />
        </Route>
        <Route path="/edit-song" element={<MainSection />}>
          <Route path="/edit-song" element={<EditSong />} />
        </Route>
        <Route path="/setlist" element={<MainSection />}>
          <Route path="/setlist" element={<Setlist2 />} />
        </Route>
        <Route path="/add-setlist" element={<MainSection />}>
          <Route path="/add-setlist" element={<Setlist />} />
        </Route>
        <Route path="/setlist-print" element={<MainSection />}>
          <Route path="/setlist-print" element={<SetlistPrintDetails />} />
        </Route>
        <Route path="/gema-setlist" element={<MainSection />}>
          <Route path="/gema-setlist" element={<GemaSetlistPrint />} />
        </Route>
        <Route path="/add-setlist-song" element={<MainSection />}>
          <Route path="/add-setlist-song" element={<AddSetListSong />} />
        </Route>
        <Route path="/edit-setlist" element={<MainSection />}>
          <Route path="/edit-setlist" element={<EditSetlist />} />
        </Route>
        <Route path="/band-staff" element={<MainSection />}>
          <Route index element={<Bandstaff />} />
        </Route>
        <Route path="/add-band-staff" element={<MainSection />}>
          <Route index element={<AddBandStaff />} />
        </Route>
        <Route path="/band-invitation" element={<MainSection />}>
          <Route index element={<Invitation />} />
        </Route>
        <Route path="/band-staff-detail" element={<MainSection />}>
          <Route index element={<BandstaffDetail />} />
        </Route>
        <Route path="/gema-deal" element={<MainSection />}>
          <Route index element={<Gema />} />
        </Route>
        <Route path="/gema" element={<MainSection />}>
          <Route index element={<GemaWithoutDeal />} />
        </Route>
        <Route path="/gema-edit" element={<MainSection />}>
          <Route index element={<GemaEdit />} />
        </Route>
        {/* <Route path="/gema-info" element={<MainSection />}>
          <Route index element={<GemaWithoutContract />} />
        </Route> */}
        <Route path="/all-notification" element={<MainSection />}>
          <Route index element={<AllNotification />} />
        </Route>
        <Route path="/task-list" element={<MainSection />}>
          <Route index element={<Tasklist />} />
        </Route>
        <Route path="/task-list-inprogress" element={<MainSection />}>
          <Route index element={<TaskListInProgress />} />
        </Route>
        <Route path="/task-list-completed" element={<MainSection />}>
          <Route index element={<TaskListCompleted />} />
        </Route>
        <Route path="/task-overview" element={<MainSection />}>
          <Route index element={<TaskOverview />} />
        </Route>
        <Route path="/task-overview-admin" element={<MainSection />}>
          <Route index element={<TaskOverviewAdmin />} />
        </Route>
        <Route path="/task-overview-assigned" element={<MainSection />}>
          <Route index element={<TaskOverviewMusician />} />
        </Route>
        <Route path="/add-new-task" element={<MainSection />}>
          <Route index element={<AddNewTask />} />
        </Route>
        <Route path="/chat" element={<MainSection />}>
          <Route index element={<Chat />} />
        </Route>
        <Route path="/chat-list" element={<MainSection />}>
          <Route index element={<ChatList />} />
        </Route>
        <Route path="/chatlistEvent" element={<MainSection />}>
          <Route index element={<EventChatList />} />
        </Route>
        <Route path="/chatlistTask" element={<MainSection />}>
          <Route index element={<TaskChatList />} />
        </Route>
        <Route path="/chat-event" element={<MainSection />}>
          <Route index element={<ChatEvent />} />
        </Route>
        <Route path="/chat-task" element={<MainSection />}>
          <Route index element={<ChatTask />} />
        </Route>
        <Route path="/edit-profile" element={<MainSection />}>
          <Route index element={<Profile />} />
        </Route>
        <Route path="/about-us" element={<SinglePage />}>
          <Route index element={<AboutUs />} />
        </Route>
        <Route path="/term-and-condition" element={<SinglePage />}>
          <Route index element={<TermAndCondition />} />
        </Route>
        <Route path="/privacyPolicy" element={<SinglePage />}>
          <Route index element={<PrivacyPolicy />} />
        </Route>
        <Route path="/event-pdf-concert" element={<MainSection />}>
          <Route index element={<ConcertPdf />} />
        </Route>
        {/* <Route path="/*" element={<PageNotFound />} /> */}
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
