import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { signUpSchema } from '../signUpSchema'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom'
import { loginEmailSchema } from '../loginEmailSchema'
import Cookies from 'universal-cookie'

const initialValues = {
    email: "",
    password: ""
}
const LoginEmail = () => {
    const cookee = new Cookies();
    let navigate = useNavigate()

    const devicetoken = cookee.get("devicetoken")
    
    const [show, setShow] = useState(false)
    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: loginEmailSchema,
        onSubmit: (values, action) => {
            handleLogin();
        }
    })
    const handleShow = () => {
        setShow(!show)
    }
    const handleLogin = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/band-leader-sign-in-with-email`,
            data:
            {
                "email": values.email,
                "password": values.password,
                "device_type": "W",
                "device_token": devicetoken ? devicetoken : "web"
            }
        })
            .then(res => {
                // console.log("RESPONSE", res.data)
                if (res.data.message === "Band sign-in succesfully!") {
                    cookee.set('token', res.data.data.auth_token)
                    cookee.set('bandid', res.data.data.band_id)
                    cookee.set('email', res.data.data.email)
                    cookee.set('phonenumber', res.data.data.phone_number)
                    cookee.set('bandname', res.data.data.band_name)
                    cookee.set('countrycode', res.data.data.country_code)

                    alert("Band sign-in succesfully!")
                    toast.success("Band sign-in succesfully!", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    navigate('/dashboard')

                }

            })
            .catch((error) => {
                if (error.response.data.message === "Icorrect Password! Please Retry") {
                    toast.error('Incorrect Password.', {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                if (error.response.data.message === "Account doesn't Exists!") {
                    toast.error("Account doesn't Exists!", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }

            })
    }


    return (
        <>
            <section className="">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-sm-12 col-md-6 col-lg-6 form-rightShift">
                            <div className="">
                                <form className="form-style">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 text-center mb-5">
                                            <div className="logo-bg text-center">
                                                <div className="mt-2"><img src="assets/images/logo.png" className="img-fluid logo-admin" alt="logo" /></div>
                                            </div>
                                            <h5 className="regitraLogo">Login/Signin</h5>
                                        </div>

                                        <div className="col-sm-12 col-md-12 mb-4">
                                            <div className="form-group">
                                                <label className="theme-color fw-bold">Email</label>
                                                <div className="input-container">
                                                    <input type="text" className="form-control p-0" placeholder="Your email" name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} />
                                                    <img src="assets/images/arroba.png" className="input-img" alt="" />
                                                </div>
                                                {errors.email && touched.email ? <p style={{ color: 'red' }}>{errors.email}</p> : ""}

                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 mb-4">
                                            <div className="form-group">
                                                <label className="theme-color fw-bold">Password</label>
                                                <div className="input-container">
                                                    <input type={show ? "text" : "password"} className="form-control p-0" placeholder="Your password" name="password" value={values.password} onChange={handleChange} onBlur={handleBlur} />
                                                    <img src="assets/images/view.png" className="input-img" alt="" onClick={handleShow} />
                                                </div>
                                                {errors.password && touched.password ? <p style={{ color: 'red' }}>{errors.password}</p> : ""}

                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-12 text-end mb-5">
                                            <Link to="/forgot-password" className="text-dark">Forgot password</Link>
                                        </div>
                                        <div className="col-sm-12 col-md-12 text-center">
                                            <button className="btn btn-save w-50" onClick={handleSubmit}>LOGIN</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center mb-3 mt-3">
                                        Login with <Link to="/login" className="text-dark"><b>Phone number</b></Link>
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center mb-5 mt-2">
                                        Don't have an account? <Link to="/" className="text-dark"><b>Sign Up!</b></Link>
                                    </div>
                                </form>

                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 py-3">
                            <img src="assets/images/long-hair-guitar-guy-singing-low.png" className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default LoginEmail