import React from 'react'
import { useFormik } from 'formik'
import { forgotPasswordSchema } from '../forgotPasswordSchema'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'


const initialValues = {
    email: "",

}
const ForgotPassword = () => {
    let navigate = useNavigate()
    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: (values, action) => {
            handleSendOtp();
        }
    })
    const handleSendOtp = () => {
        //  e.preventDefault();
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/send-musician-otp-with-email`,
            data:
            {
                "email": values.email,
            }
        })
            .then(res => {

                if (res.data.message === "OTP Sent on Registered Email") {
                    localStorage.setItem("email", values.email)
                    alert("otp sent..")
                    toast.success("OTP sent successfully.", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    navigate('/otp-forgot-password')
                }

            })
            .catch((error) => {
                if (error.response.data.message === 'Invalid Phone Number! Retry.') {
                    toast.error("Invalid Phone Number.", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
    }
    return (
        <section className="bg-image">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-sm-12 col-md-12 col-lg-12 mr-0 pr-0">
                        <div className="form-bg">
                            <form className="form-style">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 text-center mb-4">
                                        <div className="logo-bg text-center">
                                            <div className="mt-2"><img src="assets/images/logo.png" className="img-fluid logo-admin" alt="logo" /></div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center mb-4">
                                        <img src="assets/images/forgot-password.png" className="img-fluid iconIMg" alt="" />
                                        <h2 className="mdl-ttl">Forgot Password</h2>
                                        <p className="mdl-sbttl">In order to retrieve your password, please enter registered email id.</p>
                                    </div>
                                    <div className="col-sm-12 col-md-12 mb-4">
                                        <div className="form-group">
                                            <div className="input-container">
                                                <input type="email" className="form-control pl-5" name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} placeholder="Email ID" />
                                                <img src="assets/images/Email-Address.png" className="input-img" alt="" />
                                            </div>
                                            {errors.email && touched.email ? <p style={{ color: 'red' }}>{errors.email}</p> : ""}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center">
                                        <button className="btn btn-save w-50" onClick={handleSubmit}>Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ForgotPassword