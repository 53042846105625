import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import ReactAudioPlayer from 'react-audio-player';
import S3FileUpload from 'react-s3';
window.Buffer = window.Buffer || require("buffer").Buffer;


const Audiofile = ({ show, handleShow, audio, name, id, songName, songTime, copyrightCover, bpm, gemaNumber, isrc, texter, texterLastname, composer, composerLastname, url }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const cookee = new Cookies();
    const token = cookee.get('token')
    const [loader, setLoader] = useState(false)
    const [setList, setSetList] = useState([])
    const [audioData, setAudioData] = useState()

    useEffect(() => {
        getSetList();
    }, [])
    const getSetList = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-band-leader-set-list-by-band-id`,
            data:
            {
                "page_no": 1
            }
        })
            .then(res => {
                setSetList(res.data.data)
            })
    }

    const handleFile = (e, ids) => {
        setLoader(true)
        let file = e.target.files[0];
        const config = {
            bucketName: 'clever-band-assist-s3',
            region: 'eu-west-3',
            accessKeyId: 'AKIASTLKHDND66NQZXFJ',
            secretAccessKey: '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',
        }
        S3FileUpload
            .uploadFile(file, config)
            .then(data => {
                // console.log("RES", data)
                setAudioData(data.location)
                handleUpdateSong(data.location)
                setLoader(false)

            })
            .catch(err => console.error("ERR", err))
    }

    const handleUpdateSong = (i) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/update-band-leader-song`,
            data:
            {
                "song_id": id,
                "user_type": 2,
                "song_name": songName,
                "time_length": "00:" + songTime,
                "is_copyright": copyrightCover,
                "bpm": bpm,
                "gemma_number": gemaNumber,
                "isrc_code": isrc,
                "texter": texter,
                "texter_last_name": texterLastname,
                "composer": composer,
                "composer_last_name": composerLastname,
                "song_url": i,
                "text_sheet_url": "",
                "music_sheet_url": "",
                "external_url": url,
            }
        })
            .then(res => {
                // console.log("res", res.data)
                if (res.status === 200) {
                    toast.success(res.data.message, {
                        theme: "colored",
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })

                    navigate("/songs")
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    theme: "colored",
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }



    return (
        <>
            <Modal show={show} onHide={handleShow} size="lg" >
                <Modal.Body className="p-0">
                    <div className="modal-body">
                        <button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className=" text-center">{name}</h4>
                                <p className=" text-center">Audio File</p>
                                <div className="pt-3 px-1 bg-white">
                                    <div className="table-responsive">
                                        <table className="table storetable text-center">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col">name</th>
                                                    <th scope="col">Upload</th>
                                                    <th scope="col">play</th>
                                                </tr>
                                                <tr className="border-0 bg-none invisible"><td className="border-0 bg-none"></td></tr>
                                            </thead>
                                            <tbody>
                                                <td>{name}</td>
                                                <td >
                                                    <div className="upload-btn-wrapper Attachment" >
                                                        {loader ?
                                                            <div className="spinner-border" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div> : <> <img src="assets/images/gallery.png" width="25px" alt="" />
                                                                <input type="file" onChange={(e) => handleFile(e)} /></>}


                                                    </div>
                                                </td>
                                                <td> <ReactAudioPlayer
                                                    src={audio}
                                                    controls
                                                /></td>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default Audiofile