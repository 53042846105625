import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";

const GenerateLink = ({ show, handleShow, link, tab14 }) => {
  const handleClick = () => {
    navigator.clipboard.writeText(link);
    toast("Copied to clipboard", {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  return (
    <Modal show={show} onHide={handleShow} size="md" centered>
      <Modal.Body className="p-0">
        <div className="modal-body">
          <button
            type="button"
            className="btn-close close-Icon"
            onClick={handleShow}
          ></button>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
              <div className="text-center mx-2 mb-4">
                <h4 className="mb-0 theme-color">{tab14}</h4>
              </div>
              <div
                className=" text-center"
                onClick={() => handleClick()}
                style={{ cursor: "pointer" }}
              >
                <h6>{link?.substr(0, 54)}</h6>
                <img src="assets/images/copytoclipbord.png" width="" alt="" />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GenerateLink;
