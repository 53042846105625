import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { addSongSchema } from "../addSongSchema";
import { ToastContainer, toast } from "react-toastify";
import S3FileUpload from "react-s3";
import ButtonLoader from "../Loader/loading";
import { useTranslation } from "react-i18next";

window.Buffer = window.Buffer || require("buffer").Buffer;

const initialValues = {
  songName: "",
  songTime: "",
  bpm: "",
  gemaNumber: "",
  isrc: "",
  composer: "",
  composerlastname: "",
  texter: "",
  texterlastname: "",
  url: "",
};
const AddSong = () => {
  const { t } = useTranslation();
  const cookee = new Cookies();
  const token = cookee.get("token");
  const navigate = useNavigate();
  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const [file3, setFile3] = useState("");
  const [person, setPerson] = useState([]);
  const [showCover, setShowCover] = useState(true);
  const [showCopyright, setShowCopyright] = useState(false);
  const [copyrightCover, setCopyrightCover] = useState(0);
  const [composerSelect, setComposerSelect] = useState("");
  const [composerLastname, setComposerLastname] = useState("");
  const [testerSelect, setTesterSelect] = useState("");
  const [testerLastname, setTesterLastname] = useState("");
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [load, setLoad] = useState(false);
  const {
    tab1,
    tab2,
    tab3,
    tab4,
    tab5,
    tab6,
    tab7,
    tab8,
    tab9,
    tab10,
    tab11,
    tab14,
    tab15,
  } = t("songs");

  useEffect(() => {
    getPersons();
  }, []);

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: addSongSchema,
      onSubmit: (values, action) => {
        handleAddSong();
      },
    });
  const handleSelect = (e, setState) => {
    setState(e.target.value);
  };

  const handleCopyright = () => {
    setShowCopyright(true);
    setShowCover(false);
  };
  const handleCover = () => {
    setShowCover(true);
    setShowCopyright(false);
  };

  const getPersons = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-staff-members-with-membership`,
      data: {
        page_no: 1,
      },
    }).then((res) => {
      // console.log(res.data)
      setPerson(res.data.data);
    });
  };

  const handleFileUpload1 = (e) => {
    setLoader1(true);
    let file = e.target.files[0];
    const config = {
      bucketName: "clever-band-assist-s3",
      region: "eu-west-3",
      accessKeyId: "AKIASTLKHDND66NQZXFJ",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        setFile1(data.location);
        setLoader1(false);
      })
      .catch((err) => console.error("ERR", err));
  };
  const handleFileUpload2 = (e) => {
    setLoader2(true);
    let file = e.target.files[0];
    const config = {
      bucketName: "clever-band-assist-s3",
      region: "eu-west-3",
      accessKeyId: "AKIASTLKHDND66NQZXFJ",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        setFile2(data.location);
        setLoader2(false);
      })
      .catch((err) => console.error("ERR", err));
  };
  const handleFileUpload3 = (e) => {
    setLoader3(true);
    let file = e.target.files[0];
    const config = {
      bucketName: "clever-band-assist-s3",
      region: "eu-west-3",
      accessKeyId: "AKIASTLKHDND66NQZXFJ",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        setFile3(data.location);
        setLoader3(false);
      })
      .catch((err) => console.error("ERR", err));
  };

  const handleAddSong = () => {
    setLoad(true);
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/band-leader-add-song`,
      data: {
        user_type: 2,
        song_name: values.songName,
        time_length: values.songTime ? "00:" + values.songTime : "00:00",
        is_copyright: copyrightCover,
        bpm: values.bpm,
        gemma_number: values.gemaNumber,
        isrc_code: values.isrc,
        song_url: file3,
        text_sheet_url: file1,
        music_sheet_url: file2,
        texter: showCopyright ? testerSelect?.split(" ")[0] : values.texter,
        texter_last_name: showCopyright
          ? testerSelect?.split(" ")[1]
          : values.texterlastname,
        composer: showCopyright
          ? composerSelect.split(" ")[0]
          : values.composer,
        composer_last_name: showCopyright
          ? composerSelect.split(" ")[1]
          : values.composerlastname,
        external_url: values.url,
      },
    })
      .then((res) => {
        // console.log("res", res.data)
        if (res.status === 200) {
          setLoad(false);
          toast.success("Song Added Successfully ", {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate("/songs");
        }
      })
      .catch((error) => {
        setLoad(false);
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row mt-3 align-items-center">
          <div className="col-sm-12">
            <form className="row my-5 py-5 addNAme mx-width">
              <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                <input
                  type="text"
                  name="songName"
                  value={values.songName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                  placeholder={tab5}
                />
                {errors.songName && touched.songName ? (
                  <p style={{ color: "red" }}>{errors.songName}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                <input
                  type="text"
                  name="songTime"
                  value={values.songTime}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                  placeholder={`${tab2} [00:00]`}
                />
                {errors.songTime && touched.songTime ? (
                  <p style={{ color: "red" }}>{errors.songTime}</p>
                ) : (
                  ""
                )}
              </div>

              <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                <input
                  type="text"
                  name="bpm"
                  value={values.bpm}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                  placeholder="Bpm"
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                <input
                  type="text"
                  name="gemaNumber"
                  value={values.gemaNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                  placeholder={tab6}
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                <input
                  type="text"
                  name="isrc"
                  value={values.isrc}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                  placeholder="ISRC"
                />
              </div>

              <div
                className="col-sm-6 col-md-3 col-lg-3 mb-5 my-4"
                onClick={handleCopyright}
                style={{ cursor: "pointer" }}
              >
                <input
                  type="radio"
                  name="song status"
                  value="1"
                  style={{ width: "50px", height: "20px", cursor: "pointer" }}
                  onChange={(e) => setCopyrightCover(e.target.value)}
                />
                <label className="">
                  {tab3}{" "}
                  <img
                    src="assets/images/Infoblack.png"
                    alt=""
                    className="mb-5"
                    title="Wählen Sie diese Option, wenn Urheberechte des Songs bei einem oder mehreren Bandmitgliedern liegen. Anschließend können Sie für die Rechte am Text und der Komposition ein Bandmtiglied als Urheber auswählen. Bei einem bestehen GEMA-Abwicklungsvertrag mit CleverMusik, werden diese Informationen, bei Konzertmeldungen, an die GEMA übermittelt. Select this option if the song is owned by one or more band members. You can then select a band member as the author for the rights to the text and the composition. If there is a GEMA processing agreement with CleverMusik, this information will be sent to GEMA for concert reports."
                  />
                </label>
              </div>
              <div
                className="col-sm-6 col-md-3 col-lg-3 mb-5 my-4"
                onClick={handleCover}
                style={{ cursor: "pointer" }}
              >
                <input
                  type="radio"
                  name="song status"
                  value="0"
                  style={{ width: "50px", height: "20px", cursor: "pointer" }}
                  onChange={(e) => setCopyrightCover(e.target.value)}
                />
                <label>
                  Coversong{" "}
                  <img
                    src="assets/images/Infoblack.png"
                    alt=""
                    className="mb-5"
                    title="Wählen Sie diese Option, wenn keine Urheberechte des Songs bei einem Bandmitglied liegen. Anschließend können Sie die Namen Urheber für die Rechte am Text und der Komposition eintragen. Bei einem bestehen GEMA-Abwicklungsvertrag mit CleverMusik, werden diese Informationen, bei Konzertmeldungen, an die GEMA übermittelt.Select this option if no band member owns the copyright to the song. You can then enter the names of the authors for the rights to the text and the composition. If there is a GEMA processing agreement with CleverMusik, this information will be sent to GEMA for concert reports."
                  />
                </label>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                <img
                  src="assets/images/Infoblack.png"
                  alt=""
                  className="mx-2"
                  title="Hier können Sie die Textdateien aller Bandmitglieder zu diesem Song verwalten. Sie können Dateien löschen oder für Bandmitglieder hochladen. Diese Dateien können die Bandmitglieder anschließend in Ihrer App einsehen, downloaden, löschen oder auch neue Dateien uploaden. Here you can manage the text files of all band members for this song. You can delete files or upload for band members. The band members can then view, download, delete or upload new files in their app"
                />
                <div className="upload-btn-wrapper Attachment">
                  <button className="btn" style={{ cursor: "pointer" }}>
                    {tab7}
                  </button>
                  <input
                    type="file"
                    onChange={handleFileUpload1}
                    name="myfile"
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    src="assets/images/live-file.png"
                    alt=""
                    className="inputImgRight"
                    style={{ cursor: "pointer" }}
                  />
                  {loader1 ? (
                    <div className="spinner-border text-danger" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <p style={{ fontWeight: "bold" }}>{file1 ? file1 : ""}</p>
                  )}
                </div>
              </div>

              {showCover ? (
                <>
                  <div className="col-sm-3 col-md-3 col-lg-3 mb-5 mt-2">
                    <p className="notranslate">
                      <img
                        src="assets/images/Infoblack.png"
                        alt=""
                        className=""
                        title="Wenn Sie die Option „Urheberechte in der Band“ ausgewählt haben, können Sie ein Bandmitglied im Dropdown-Menü auswählen. Wenn Sie die Option „Coversong“ ausgewählt haben, können Sie den Namen eines Textdichters hier manuell eintragen. If you selected the Copyrights in the Band option, you can select a band member from the drop-down menu. If you selected the Cover Song option, you can enter the name of a lyricist here manually"
                      />
                      Texter
                    </p>
                    <div className="upload-btn-wrapper Attachment">
                      <input
                        type="text"
                        name="texter"
                        value={values.texter}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        placeholder="First Name"
                      />
                    </div>
                  </div>

                  <div className="col-sm-3 col-md-3 col-lg-3 mb-5 ">
                    <p className="mt-5"></p>
                    <div className="upload-btn-wrapper Attachment">
                      <input
                        type="text"
                        name="texterlastname"
                        value={values.texterlastname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {showCopyright ? (
                <>
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-5 ">
                    <div className="upload-btn-wrapper Attachment">
                      <p>
                        <img
                          src="assets/images/Infoblack.png"
                          alt=""
                          className=""
                          title="Wenn Sie die Option „Urheberechte in der Band“ ausgewählt haben, können Sie ein Bandmitglied im Dropdown-Menü auswählen. Wenn Sie die Option „Coversong“ ausgewählt haben, können Sie den Namen eines Textdichters hier manuell eintragen.If you selected the Copyrights in the Band option, you can select a band member from the drop-down menu. If you selected the Cover Song option, you can enter the name of a lyricist here manually"
                        />
                        Texter
                      </p>
                      <select
                        className="form-control p-0"
                        value={testerSelect}
                        onChange={(e) => handleSelect(e, setTesterSelect)}
                        data-nested="nested"
                        name="user_id"
                      >
                        <option value="op">{tab14} </option>
                        {person.map((res, key) => {
                          return (
                            <option
                              value={`${res.first_name} ${res.last_name}`}
                            >{`${res.first_name} ${res.last_name}`}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-sm-3 col-md-3 col-lg-3 mb-5 mt-3 ">
                                        <div className="upload-btn-wrapper Attachment">
                                            <p className='mt-3'></p>
                                            <select className="form-control p-0" value={testerLastname} onChange={(e) => handleSelect(e, setTesterLastname)}
                                                data-nested="nested" name='user_id' >
                                                <option>Last name</option>
                                                {person.map((res, key) => {
                                                    return <option value={res.last_name}>{res.last_name}</option>
                                                })}
                                            </select>

                                        </div>
                                    </div> */}
                </>
              ) : (
                ""
              )}

              <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                <img
                  src="assets/images/Infoblack.png"
                  alt=""
                  className="mx-2"
                  title="Hier können Sie die Notenblätter aller Bandmitglieder zu diesem Song verwalten. Sie können Dateien löschen oder für Bandmitglieder hochladen. Diese Dateien können die Bandmitglieder anschließend in Ihrer App einsehen, downloaden, löschen oder auch neue Dateien uploaden. Here you can manage the sheet music of all band members for this song. You can delete files or upload for band members. The band members can then view, download, delete or upload new files in your app"
                />
                <div className="upload-btn-wrapper Attachment">
                  <button className="btn" style={{ cursor: "pointer" }}>
                    {tab8}
                  </button>
                  <input
                    type="file"
                    name="myfile"
                    onChange={handleFileUpload2}
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    src="assets/images/attachment.png"
                    alt=""
                    className="inputImgRight"
                    style={{ cursor: "pointer" }}
                  />
                  {loader2 ? (
                    <div className="spinner-border text-danger" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <p style={{ fontWeight: "bold" }}>{file2 ? file2 : ""}</p>
                  )}
                </div>
              </div>
              {showCover ? (
                <>
                  <div className="col-sm-3 col-md-3 col-lg-3 mb-5 mt-2 ">
                    <p className="notranslate">
                      <img
                        src="assets/images/Infoblack.png"
                        alt=""
                        className=""
                        title="Wenn Sie die Option „Urheberechte in der Band“ ausgewählt haben, können Sie ein Bandmitglied im Dropdown-Menü auswählen. Wenn Sie die Option „Coversong“ ausgewählt haben, können Sie den Namen eines Komponisten hier manuell eintragen. If you selected the Copyrights in the Band option, you can select a band member from the drop-down menu. If you selected the Cover Song option, you can enter the name of a lyricist here manually"
                      />
                      {tab10}
                    </p>
                    <div className="upload-btn-wrapper Attachment">
                      <input
                        type="text"
                        name="composer"
                        value={values.composer}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        placeholder="First Name"
                      />
                    </div>
                  </div>

                  <div className="col-sm-3 col-md-3 col-lg-3 mb-5 ">
                    <p className="mt-5"></p>
                    <div className="upload-btn-wrapper Attachment">
                      <input
                        type="text"
                        name="composerlastname"
                        value={values.composerlastname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {showCopyright ? (
                <>
                  <div className="col-sm-6 col-md-6 col-lg-6 mb-5 ">
                    <div className="upload-btn-wrapper Attachment">
                      <p>
                        <img
                          src="assets/images/Infoblack.png"
                          alt=""
                          className=""
                          title="Wenn Sie die Option „Urheberechte in der Band“ ausgewählt haben, können Sie ein Bandmitglied im Dropdown-Menü auswählen. Wenn Sie die Option „Coversong“ ausgewählt haben, können Sie den Namen eines Komponisten hier manuell eintragen.If you selected the Copyrights in the Band option, you can select a band member from the drop-down menu. If you selected the Cover Song option, you can enter the name of a lyricist here manually"
                        />
                        {tab10}
                      </p>
                      <select
                        className="form-control p-0"
                        value={composerSelect}
                        onChange={(e) => handleSelect(e, setComposerSelect)}
                        data-nested="nested"
                        name="user_id"
                      >
                        <option value="op">{tab14}</option>
                        {person.map((res, key) => {
                          return (
                            <option
                              value={`${res.first_name} ${res.last_name}`}
                            >{`${res.first_name} ${res.last_name}`}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-sm-3 col-md-3 col-lg-3 mb-5 mt-3 ">
                                        <div className="upload-btn-wrapper Attachment">
                                            <p></p>
                                            <select className="form-control p-0" value={composerLastname} onChange={(e) => handleSelect(e, setComposerLastname)}
                                                data-nested="nested" name='user_id' >
                                                <option>Last name</option>
                                                {person.map((res, key) => {
                                                    return <option value={res.last_name}>{res.last_name}</option>
                                                })}
                                            </select>

                                        </div>
                                    </div> */}
                </>
              ) : (
                ""
              )}
              {/* {showCopyright ? "" :
                                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                                    <div className="upload-btn-wrapper Attachment">
                                        
                                    </div>
                                </div>} */}
              <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                <img
                  src="assets/images/Infoblack.png"
                  alt=""
                  className="mx-2"
                  title="Hier können Sie eine Audiodatei hochladen. Diese können alle Bandmitglieder in deren App anhören. Here you can upload an audio file. All band members can listen to them in their app."
                />
                <div className="upload-btn-wrapper Attachment">
                  <button className="btn" style={{ cursor: "pointer" }}>
                    {tab9}
                  </button>
                  <input
                    type="file"
                    name="myfile"
                    onChange={handleFileUpload3}
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    src="assets/images/live-mic.png"
                    alt=""
                    className="inputImgRight"
                    style={{ cursor: "pointer" }}
                  />
                  {loader3 ? (
                    <div className="spinner-border text-danger" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <p style={{ fontWeight: "bold" }}>{file3 ? file3 : ""}</p>
                  )}{" "}
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                <div className="upload-btn-wrapper Attachment"></div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                <img
                  src="assets/images/Infoblack.png"
                  alt=""
                  className="mx-2"
                  title={
                    "Hier können Sie einen Link hinterlegen, welchen die Bandmitglieder öffnen können. Here you can add a link which the band members can open."
                  }
                />
                <input
                  type="text"
                  className="form-control"
                  name="url"
                  value={values.url}
                  onChange={handleChange}
                  placeholder="Link"
                />
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <div className="d-flex justify-content-center my-3">
                  {load ? (
                    <ButtonLoader view="btn add-new" />
                  ) : loader1 || loader2 || loader3 ? (
                    ""
                  ) : (
                    <button className="btn add-new" onClick={handleSubmit}>
                      {tab1}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </article>
  );
};

export default AddSong;
