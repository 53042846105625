import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { getFiles } from '../../app_redux/slice/files'
import S3FileUpload from 'react-s3';
window.Buffer = window.Buffer || require("buffer").Buffer;



const ChecklistView = ({ show, handleShow, id, data, userid, taskid }) => {
    const dispatch = useDispatch()
    const cookee = new Cookies();
    const token = cookee.get('token')
    const [checklistStatus, setChecklistStatus] = useState([])
    const [userId, setUserId] = useState("")


    useEffect(() => {
        getChecklistStatus();
    }, [])
    const getChecklistStatus = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/band-leader-task-check-list-detail`,
            data:
            {
                "band_leader_task_id": taskid,
                "user_id": userid
            }
        })
            .then(res => {
                setChecklistStatus(res.data.data)
            })
    }


    return (
        <>
            <Modal show={show} onHide={handleShow} size="lg" >
                <Modal.Body className="p-0">
                    <div className="modal-body">
                        <button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className=" text-center">Task Checklist</h4>
                                <div className="pt-3 px-1 bg-white">
                                    <div className="table-responsive">
                                        <table className="table storetable text-center">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col">name</th>
                                                    <th scope="col">Deadline</th>
                                                    <th scope="col">status</th>

                                                </tr>
                                                <tr className="border-0 bg-none invisible"><td className="border-0 bg-none"></td></tr>
                                            </thead>
                                            <tbody>
                                                <>
                                                    {data?.length !== 0 ? data.map((res, i) => {
                                                        return <tr>
                                                            <td>{res.check_list_item_name}</td>
                                                            <td>{res.check_list_item_dead_line?.substr(0, 10)}</td>
                                                            <td >
                                                                <button className="btn add-new" style={{ width: "150px" }}>new</button>
                                                            </td>
                                                        </tr>
                                                    }) : <p className='mt-5'>No CheckList Found!!</p>}
                                                    {/* {checklistStatus.map((item, i) => {
                                                        return <tr>
                                                            <td>
                                                                {item.status === 1 ? <button className="btn add-new" style={{ width: "150px" }}>new</button> : ""}
                                                            </td>
                                                        </tr>
                                                    })} */}
                                                </>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default ChecklistView