import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getSongs = createAsyncThunk("songs", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('token')
    let songs =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/band-leader-song-list`,
            data: {
                "page_no": obj?.pageno
            }
        })
            .then(res => res.data.data)
    return songs
})

const listSongs = createSlice(({
    name: "all songs",
    initialState: {
        allSongs: [],
        loadingSongs: false
    },
    extraReducers: {
        [getSongs.pending]: (state, action) => {
            state.loadingSongs = true;
        },
        [getSongs.fulfilled]: (state, action) => {
            state.allSongs = action.payload;
            state.loadingSongs = false

        },
        [getSongs.rejected]: (state, action) => {
            state.loadingSongs = false;
        }

    }
}))

export default listSongs.reducer;