import * as Yup from "yup";


export const signUpSchema = Yup.object({
    bandName: Yup.string().min(2).max(45).required("please enter band name"),
    email: Yup.string().email().required("please enter your email"),
    // phone: Yup.number().min(10).required("please enter phone number"),
    password: Yup.string().matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ).min(8).max(15).required("please enter password"),
    confirmpassword: Yup.string().required("please enter").oneOf([Yup.ref("password"), null], "Password must match"),
    street: Yup.string().min(2).max(45).required("Please enter"),
    housenumber: Yup.string().min(2).max(45).required("Please enter"),
    zipcode: Yup.string().min(2).max(45).required("Please enter"),
    town: Yup.string().min(2).max(45).required("Please enter"),
    country: Yup.string().required("Please enter").matches(/Germany/, "For legal reasons, we are currently only able to offer German users")
});