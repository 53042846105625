import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ButtonLoader from "../../Loader/loading";
import { useTranslation } from "react-i18next";

const CancelEventModal = ({ show, handleShow, id }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cookee = new Cookies();
  const token = cookee.get("token");
  const [loader, setLoader] = useState(false);
  const [cancelInfo, setCancelInfo] = useState("");

  const { tab78, tab79 } = t("event");

  const handleTextArea = (e) => {
    setCancelInfo(e.target.value);
  };
  const handleCancelEvent = () => {
    setLoader(true);
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      data: {
        event_id: id,
        is_confirmed: "",
        is_cancelled: 1,
        cancel_reason: cancelInfo,
      },
      url: `${process.env.REACT_APP_URL}/confirm-band-leader-event`,
    }).then((res) => {
      //console.log(res.data)
      if (res.data.message === "Event Cancelled!.") {
        toast.success("Event cancelled", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoader(false);
        handleShow();
        // navigate("/events");
        navigate("/chat-event", { state: { eventid: id || "" } });
      }
    });
  };
  return (
    <Modal show={show} onHide={handleShow} size="md" centered>
      <Modal.Body className="p-0">
        <div className="modal-body">
          <button
            type="button"
            className="btn-close close-Icon"
            onClick={handleShow}
          ></button>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
              <img
                src="assets/images/chatBlackRed.png"
                alt=""
                className="mr-4"
              />
              <div className="d-flex align-items-center lg-justify-content-end mx-3">
                <textarea
                  className="form-control p-0 ml-3"
                  value={cancelInfo}
                  onChange={handleTextArea}
                  placeholder={tab78}
                  style={{ height: "200px", borderRadius: "10px" }}
                />
              </div>
              <div className="align-items-center my-3">
                {loader ? (
                  <ButtonLoader view="btn add-new" />
                ) : (
                  <button
                    className="btn add-new"
                    style={{ marginLeft: "160px" }}
                    onClick={handleCancelEvent}
                  >
                    {tab79}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Modal>
  );
};

export default CancelEventModal;
