import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import AllEditSong from "./allEditSong";
import { useDrop } from "react-dnd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getSetlistDetail } from "../../app_redux/slice/setlistDetail";
import { useDispatch, useSelector } from "react-redux";
import AllSelectedSong from "./selectedSongs";
import update from "immutability-helper";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";

const EditSetlist = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const cookee = new Cookies();
  const token = cookee.get("token");
  const lng = cookee.get("googtrans");

  const bandId = cookee.get("bandId");

  const [editSetlistData, setEditSetlistData] = useState([]);
  const [songs, setSongs] = useState([]);
  const [existingSongs, setExistingSongs] = useState([]);
  const { setlistDetails, loading } = useSelector(
    (state) => state.setlistdetail
  );
  const [setList, setSetList] = useState(setlistDetails?.set_list_name);
  const [setlistData, setSetListData] = useState([]);
  const [count, setCount] = useState("");
  const [page, setPage] = useState(1);
  const [setlistOrder, setSetListOrder] = useState([
    { id: "", display_seq: "" },
  ]);
  const { tab1, tab10, tab12, tab7 } = t("setlist");

  useEffect(() => {
    getSongs();
    getSetList();
    handleEdit();
    dispatch(getSetlistDetail());
  }, []);

  useEffect(() => {
    getSongs();
  }, [page]);

  useEffect(() => {
    manageDisplaySeq();
  }, [count]);
  const [{ isOver }, addToTeamRef] = useDrop({
    accept: "player",
    collect: (monitor) => ({ isOver: !!monitor.isOver() }),
  });

  // console.log(isOver);
  const [{ isOver: isPlayerOver }, removeFromTeamRef] = useDrop({
    accept: "team",
    collect: (monitor) => ({ isOver: !!monitor.isOver() }),
  });

  const movePlayerToTeam = (item, index) => {
    //  setSongs((prev) => prev.filter((_, i) => item.index !== i));
    setCount(count + 1);
    setExistingSongs((prev) => [...prev, item]);
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/add-song-to-band-leader-set-list`,
      data: {
        set_list_id: localStorage.getItem("setlist_id") || "",
        set_list: [
          {
            song_id: item.m_song_id,
            display_seq: count + 1,
          },
        ],
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch(getSetlistDetail());
          manageDisplaySeq();
          handleEdit();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleSetList = (e) => {
    setSetList(e.target.value);
  };
  const getSongs = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/band-leader-song-list`,
      data: {
        page_no: page,
      },
    }).then((res) => {
      // console.log("res", res.data)
      setSongs(res.data.data);
    });
  };
  const fetchMoreData = () => {
    setPage(page + 1);
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/band-leader-song-list`,
      data: {
        page_no: page + 1,
      },
    }).then((res) => {
      // console.log("res", res.data)
      setSongs(songs.concat(res.data.data));
    });
  };
  //console.log("songs", songs)
  const getSetList = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-set-list-by-band-id`,
      data: {
        page_no: 1,
      },
    }).then((res) => {
      //console.log("res", res.data)
      setSetListData(res.data.data);
    });
  };
  const handleEdit = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-set-list-details`,
      data: {
        set_list_id: localStorage.getItem("setlist_id") || "",
      },
    }).then((res) => {
      setEditSetlistData(res.data.data);
      setExistingSongs(res.data.data2);
      setSetList(res.data.data.set_list_name);
    });
  };
  const handleNext = (e) => {
    e.preventDefault();
    setPage(page + 1);
  };
  const handlePrevious = (e) => {
    e.preventDefault();
    setPage(page - 1);
  };

  const updateSetList = (e) => {
    e.preventDefault();
    // manageDisplaySeq()
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/update-band-leader-set-list`,
      data: {
        set_list_id: localStorage.getItem("setlist_id") || "",
        set_list_name: setList || editSetlistData?.set_list_name,
        set_list: setlistOrder,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          alert("Updated successfully");
          navigate("/setlist");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    console.log("NEW edit call");
    setCount(dragIndex);
    setExistingSongs((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
    // manageDisplaySeq()
  }, []);
  const manageDisplaySeq = () => {
    setSetListOrder(
      existingSongs.map((res, i) => {
        console.log(res);
        return { id: res.id, display_seq: i };
      })
    );
  };

  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row mt-3">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3"></div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 h-100">
              <h4 className="notranslate">
                Setlist Songs{" "}
                <img
                  src="assets/images/Infoblack.png"
                  alt=""
                  className="mx-2"
                  title="All the setlist songs come here, and you can drag and drop more songs from all songs section."
                />{" "}
              </h4>
              <div className="pt-3 px-1 bg-white shadow">
                <div className="table-responsive setlistTable2">
                  <table className="table storetable mb-0" ref={addToTeamRef}>
                    <thead className="">
                      <tr>
                        <th scope="col">name</th>
                        <th scope="col">{tab1}</th>
                        <th scope="col">{tab10}</th>
                      </tr>
                      <tr className="border-0 bg-none invisible">
                        <td className="border-0 bg-none"></td>
                      </tr>
                    </thead>

                    <tbody>
                      {existingSongs?.map((res, i) => {
                        return (
                          <AllSelectedSong
                            item={res}
                            key={i}
                            playerType="team"
                            moveCard={moveCard}
                            index={i}
                            setExistingSongs={setExistingSongs}
                            existingSongs={existingSongs}
                          />
                        );
                      })}
                      <tr>
                        <td class="bg-td">{tab12}</td>
                        <td class="bg-td">
                          {setlistDetails?.total_length?.substr(0, 2) === "00"
                            ? setlistDetails?.total_length?.substr(3, 6)
                            : setlistDetails?.total_length}
                        </td>
                        <td class="bg-td"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 h-100">
              <h4>
                Songs{" "}
                <img
                  src="assets/images/Infoblack.png"
                  alt=""
                  className="mx-2"
                  title="All the band songs come here, and you can drag and drop into setlist."
                />{" "}
              </h4>
              <div className="pt-3 px-1 bg-white shadow">
                <div className="table-responsive setlistTable2 setlist_scrollbar">
                  <InfiniteScroll
                    dataLength={songs?.length}
                    hasMore={songs?.length >= 1}
                    // loader={<h4>Loading...</h4>}
                  >
                    <table
                      className="table storetable mb-0"
                      ref={removeFromTeamRef}
                    >
                      <thead className="">
                        <tr>
                          <th scope="col">Song List</th>
                          <th scope="col"></th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>

                      <tbody>
                        {songs.map((item, i) => {
                          return (
                            <AllEditSong
                              item={item}
                              key={i}
                              playerType="player"
                              onDropPlayer={movePlayerToTeam}
                              index={i}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </InfiniteScroll>
                </div>
              </div>
            </div>
            {page === 1 && songs?.length <= 9 ? (
              ""
            ) : (
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                <div className="d-flex align-items-center lg-justify-content-end">
                  <nav aria-label="...">
                    <ul className="pagination">
                      <li
                        className={
                          page === 1 ? "page-item disabled" : "page-item"
                        }
                      >
                        <a
                          className="page-link"
                          href="#"
                          onClick={handlePrevious}
                        >
                          Previous
                        </a>
                      </li>
                      <li class="page-item active">
                        <a className="page-link" href="#">
                          {page} <span className="sr-only">(current)</span>
                        </a>
                      </li>
                      <li
                        class={
                          songs?.length <= 9
                            ? "page-item disabled"
                            : "page-item"
                        }
                      >
                        <a className="page-link" href="#" onClick={handleNext}>
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
          <form className="row mt-5 addNAme">
            <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
              <input
                type="text"
                value={setList}
                onChange={handleSetList}
                className="form-control"
                placeholder="Name setlist"
              />
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
              <div className="d-flex justify-content-end my-3">
                <button className="btn add-new" onClick={updateSetList}>
                  {tab7}
                </button>
              </div>
            </div>
          </form>
        </div>
      </article>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default EditSetlist;
