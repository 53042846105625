import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

const cookee = new Cookies();

export const getTaskChats = createAsyncThunk("taskchats", async (obj) => {
    let token = cookee.get('token');
    // console.log("RED", obj?.page)
    let chats = await axios({
        method: 'post',
        headers: {
            "Accept": "/",
            "Content-Type": "application/json",
            "auth_token": token
        },
        url: `${process.env.REACT_APP_URL}/get-band-leader-task-chat-by-task-id`,
        data: {
            "task_id": obj?.taskid,
        }
    })
        .then(res => res.data.data)
    return chats
})

const listTaskChats = createSlice(({
    name: "taskchat",
    initialState: {
        allTaskChats: [],
        loading: false
    },
    extraReducers: {
        [getTaskChats.pending]: (state, action) => {
            state.loading = true;
        },
        [getTaskChats.fulfilled]: (state, action) => {
            state.allTaskChats = action.payload
        },
        [getTaskChats.rejected]: (state, action) => {
            state.loading = false;
        }
    }
}))

export default listTaskChats.reducer;