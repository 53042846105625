import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { addTaskSchema } from "../addTaskSchema";
import axios from "axios";
import Cookies from "universal-cookie";
import { SketchPicker, BlockPicker } from "react-color";
import { useNavigate, Link } from "react-router-dom";
import CheckList from "./checklistModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getTaskDetailMusician } from "../../app_redux/slice/gettaskDetailtwo";
import { useDispatch, useSelector } from "react-redux";
import ChecklistView from "./checklistViewModal";
import { useTranslation } from "react-i18next";

const TaskOverviewMusician = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const cookee = new Cookies();
  const dispatch = useDispatch();
  const token = cookee.get("token");
  const { taskMusicianDetail, loading } = useSelector(
    (state) => state.taskdetailtwo
  );
  const [showChecklistModal, setShowChecklistModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [dateData, setDateData] = useState("");
  const lng = cookee.get("googtrans");
  const { tab12, tab13, tab14, tab15, tab16, tab17, tab18 } = t("tasks");

  useEffect(() => {
    dispatch(getTaskDetailMusician());
  }, []);
  const handleShowCancel = () => {
    setShowChecklistModal(!showChecklistModal);
  };
  const handleChecklist = (e, id) => {
    e.preventDefault();
    setUserId(id);
    handleShowCancel();
  };
  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];

    return day + "." + month + "." + year;
  }
  const updateInprogressTask = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/update-leader-task-detail`,
      data: {
        band_leader_task_id: taskMusicianDetail?.band_leader_task_id,
        task_name: taskMusicianDetail?.task_name,
        description_info: taskMusicianDetail?.description_info,
        attachment_url:
          "https://s3.us-east-2.amazonaws.com/media-appsinvo/IMG_20220723_125516_002.jpg",
        band_id: taskMusicianDetail?.band_id,
        member_id: "",
        check_list: taskMusicianDetail?.task_checklist_details,
        labels: taskMusicianDetail?.labels,
        label_color: taskMusicianDetail?.label_color,
        dead_line: taskMusicianDetail?.dead_line,
        status: 2,
      },
    }).then((res) => {
      if (res.data.message === "Task Updated Successfully!.") {
        toast.success("Task Updated Successfully !", {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };
  const updateCompletedTask = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/update-leader-task-detail`,
      data: {
        band_leader_task_id: taskMusicianDetail?.band_leader_task_id,
        task_name: taskMusicianDetail?.task_name,
        description_info: taskMusicianDetail?.description_info,
        attachment_url:
          "https://s3.us-east-2.amazonaws.com/media-appsinvo/IMG_20220723_125516_002.jpg",
        band_id: taskMusicianDetail?.band_id,
        member_id: "",
        check_list: taskMusicianDetail?.task_checklist_details,
        labels: taskMusicianDetail?.labels,
        label_color: taskMusicianDetail?.label_color,
        dead_line: taskMusicianDetail?.dead_line,
        status: 3,
      },
    }).then((res) => {
      //console.log(res.data)
      if (res.data.message === "Task Updated Successfully!.") {
        toast.success("Task Updated Successfully !", {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };
  const handleChecklistStatus = (e, id, s) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/update-band-leader-tasks-check-list`,
      data: {
        band_leader_task_id: taskMusicianDetail?.band_leader_task_id,
        is_self: 1,
        cheklist_item_id: id,
        status: s,
      },
    }).then((res) => {
      //console.log(res.data)
      if (res.data.message === "Task Updated Successfully!.") {
        toast.success("Task Updated Successfully !", {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(getTaskDetailMusician());
      }
    });
  };

  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <div className="d-flex align-items-center  gap-3 mx-2">
              <Link to="/task-list" className="btn add-new notiIcon filterIcon">
                {tab18}
              </Link>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12">
              <form className="row mt-5 addNAme mx-width">
                <div className="col-sm-9 col-md-9 col-lg-9 mb-5">
                  <h4 className="notranslate">
                    {taskMusicianDetail?.task_name?.toUpperCase()}
                  </h4>
                  <p className="mt-5 notranslate">
                    {taskMusicianDetail?.description_info}
                  </p>
                </div>
                <div className="col-sm-3 col-md-3 col-lg-3 mb-5">
                  <h5>Deadline </h5>
                  <p>
                    {taskMusicianDetail?.dead_line
                      ? formatDate(taskMusicianDetail?.dead_line.substr(0, 10))
                      : ""}
                  </p>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <div className="upload-btn-wrapper Attachment">
                    <button className="btn">{tab12}</button>
                    <input type="file" name="myfile" />
                    <img
                      src="assets/images/attachment.png"
                      alt=""
                      className="inputImgRight"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5"></div>
                <div className="col-sm-12 col-md-12 col-lg-12 mt-4 mb-5">
                  <div className="table-responsive">
                    <table className="table storetable text-center">
                      <thead className="">
                        <tr>
                          <th scope="col">{tab13}</th>
                          <th scope="col">{tab14}</th>
                          <th scope="col">Deadline</th>
                          <th scope="col">Checklist status</th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {taskMusicianDetail?.task_users_allocated?.length !==
                        0 ? (
                          taskMusicianDetail?.task_users_allocated?.map(
                            (res, i) => {
                              return (
                                <tr>
                                  <td>
                                    {res.first_name} {res.last_name}
                                  </td>
                                  <td>
                                    {res.status === "1" ? (
                                      <button
                                        className="btn add-new"
                                        style={{ width: "180px" }}
                                      >
                                        Not in progress
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                    {res.status === "2" ? (
                                      <button
                                        className="btn add-new"
                                        style={{ width: "150px" }}
                                      >
                                        in progress
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                    {res.status === "3" ? (
                                      <button
                                        className="btn add-new"
                                        style={{
                                          background: "#30ab21",
                                          border: "none",
                                          width: "150px",
                                        }}
                                      >
                                        completed
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {taskMusicianDetail?.dead_line?.substr(
                                      0,
                                      10
                                    )}
                                  </td>
                                  <td
                                    onClick={(e) =>
                                      handleChecklist(e, res.user_id)
                                    }
                                    style={{
                                      color: "blue",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    view
                                  </td>
                                  <td>
                                    {res?.check_list_item_dead_line?.substr(
                                      0,
                                      10
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <td colSpan={4} style={{ padding: "10px" }}>
                            {tab15}
                          </td>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                    <div className="d-flex justify-content-end my-3">
                                        <button className="btn add-new" onClick={updateInprogressTask}>inprogress</button>
                                        <button className="btn add-new" style={{ background: "#30ab21", border: "none" }} onClick={updateCompletedTask}>done</button>
                                    </div>
                                </div> */}

                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="d-flex align-items-center lg-justify-content-end gap-3 mx-2">
                    <Link
                      to="/task-list"
                      className="btn add-new notiIcon filterIcon"
                    >
                      {lng === "/auto/de" || "/en/de" ? "Speichern" : "safe"}
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {showChecklistModal ? (
          <ChecklistView
            show={showChecklistModal}
            handleShow={handleShowCancel}
            data={taskMusicianDetail?.task_checklist_details}
            userid={userId}
            taskid={taskMusicianDetail?.users_task_id}
          />
        ) : (
          ""
        )}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </article>
    </>
  );
};

export default TaskOverviewMusician;
