import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

const cookee = new Cookies();

export const getGemaSubmitted = createAsyncThunk("submitted", async (obj) => {
    let token = cookee.get('token');
    // console.log("RED", obj?.page)
    let submitteddata = await axios({
        method: 'get',
        headers: {
            "Accept": "/",
            "Content-Type": "application/json",
            "auth_token": token
        },
        url: `${process.env.REACT_APP_URL}/get-band-leader-submitted-events?page_no=${obj?.pageno}`,

    })
        .then(res => res.data.data)
    return submitteddata
})

const listSubmitted = createSlice(({
    name: "submitted",
    initialState: {
        submittedEvents: [],
        loading: false
    },
    extraReducers: {
        [getGemaSubmitted.pending]: (state, action) => {
            state.loading = true;
        },
        [getGemaSubmitted.fulfilled]: (state, action) => {
            state.submittedEvents = action.payload
        },
        [getGemaSubmitted.rejected]: (state, action) => {
            state.loading = false;
        }
    }
}))

export default listSubmitted.reducer;