import React, { useState } from 'react'
import ChatListItems from './chatListItems'
import AllChats from './allChats'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getBandChats } from '../../app_redux/slice/bandChat'
import { useEffect } from 'react'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useTranslation } from 'react-i18next'


const ChatList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const cookee = new Cookies();
    const navigate = useNavigate()
    const lng = cookee.get('googtrans')
    const [eventId, setEventId] = useState("")
    const [band, setBand] = useState(false)
    const [event, setEvent] = useState(false)
    const [task, setTask] = useState(false)
    const [taskId, setTaskid] = useState("")
    const [message3, setMessage3] = useState()
    const { allEventChats } = useSelector((state) => state.eventchat)
    const { allBandChats } = useSelector((state) => state.bandchat)
    const { allTaskChats } = useSelector((state) => state.taskchat)
    const { tab1, tab2 } = t("chat")

    const token = cookee.get('token')

    useEffect(() => {
        dispatch(getBandChats())
    }, [])
    const handleBand = () => {

        navigate("/chat-list")
    }
    const handleEvent = () => {

        navigate("/chatlistEvent")
    }
    const handleTask = () => {

        navigate("/chatlistTask")
    }
    const handleMessage3 = (e) => {
        setMessage3(e.target.value)
    }
    const onKeyPressBand = (e) => {
        if (e.which === 13) {
            handleBandSendMessage();
        }
    }
     function formatDate(input) {
        var datePart = input.match(/\d+/g),
            year = datePart[0],
            month = datePart[1], day = datePart[2];

        return day + '.' + month + '.' + year;
    }
    const handleBandSendMessage = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/band-leader-send-message`,
            data:
            {
                "user_type": 2,
                "message": message3,
                "message_type": "T",
                "media_url": ""
            }
        })
            .then(res => {
                //  console.log("res", res.data)
                if (res.data.message === "Message Sent Successfully!.") {
                    dispatch(getBandChats())
                    setMessage3("")
                }
            })
    }

    return (

        <article className="col-lg-9">
            <div className="article-body store-bd-gy">
                <div className="bg-white">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4 ledtBorder pe-0">
                            <h6 className="d-flex align-items-center justify-content-between px-2 pt-2 mb-0">
                                <div className="">
                                    <ul className="nav nav-pills group-nav bandUserTab chatBtnTab mt-0" id="pills-tab" role="tablist">
                                        <li className="nav-item me-3">
                                        <img src="assets/images/Infoblack.png" alt="" className=""  title={lng === "/auto/de" || "/en/de" ? "Hier haben Sie die Möglichkeit, mit allen Bandmitgliedern in einem Gruppenchat Nachrichten auszutauschen. Bandmitglieder mit der Freigabe „Mitarbeiter“ haben keinen Zugriff auf diesen Chat." : "Here you have the possibility to exchange messages with all band members in a group chat. Band members with staff permission do not have access to this chat."} />
                                            <button className="nav-link notranslate active mt-2" onClick={handleBand}>Band </button>
                                        </li>
                                        <li className="nav-item me-3">
                                        <img src="assets/images/Infoblack.png" alt="" className=""  title={lng === "/auto/de" || "/en/de" ? "Hier finden Sie alle bereits eröffneten Event-Chats. Sie können einen Eventchat eröffnen, indem Sie in dem jeweiligen Event den Chat-Button klicken und einen Text senden. Dieser Chat taucht nun in der Auflistung auf." : "Here you will find all already opened event chats. You can open an event chat by clicking the chat button in the respective event and sending a text. This chat now appears in the list."} />
                                            <button className="nav-link notranslate mt-2" onClick={handleEvent}>Event</button>
                                        </li>
                                        <li className="nav-item">
                                        <img src="assets/images/Infoblack.png" alt="" className=""  title={lng === "/auto/de" || "/en/de" ? "Hier finden Sie alle bereits eröffneten Task-Chats. Sie können einen Task-Chat eröffnen, indem Sie in dem jeweiligen Task den Chat-Button klicken und einen Text senden. Dieser Chat taucht nun in der Auflistung auf." : "Here you will find all already opened task chats. You can open a task chat by clicking the chat button in the respective task and sending a text. This chat now appears in the list."} />
                                            <button className="nav-link notranslate mt-2" onClick={handleTask}>Task </button>
                                        </li>
                                    </ul>
                                </div>
                            </h6>
                            <hr />
                            <ChatListItems setEventId={setEventId} band={setBand} event={event} task={task} setTaskid={setTaskid} />
                        </div>
                        <AllChats allBandChats={allBandChats}  />
                     
                    </div>
                </div>
            </div>

        </article>

    )
}

export default ChatList