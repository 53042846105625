import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import NotificationBadge from "react-notification-badge";
import { Effect } from "react-notification-badge";
import CalendarView from "../Calendar/calendar";
import { getEventDetail } from "../../app_redux/slice/eventDetail";
import { useDispatch, useSelector } from "react-redux";
import { getPastEvents } from "../../app_redux/slice/pastEvents";
import DeleteEvent from "./deleteEvent";
import CalendarViewPasts from "../Calendar/calendarPasts";
import { useTranslation } from "react-i18next";
import DataLoader from "../Loader/dataLoader";
import { getPermissionInfo } from "../../app_redux/slice/permissionInfo";

const PastEvents = () => {
  const { t } = useTranslation();
  const cookee = new Cookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [unconfirmed, setUnconfirmedEvents] = useState([]);
  const [view, setView] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [eventId, setEventId] = useState("");

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const { allPastEvents, loading } = useSelector((state) => state.pastEvents);
  const {
    tab1,
    tab2,
    tab3,
    tab19,
    tab5,
    tab6,
    tab7,
    tab4,
    tab37,
    tab55,
    tab56,
    tab57,
    tab58,
    tab59,
    tab52,
  } = t("event");
  const { permissionDetail } = useSelector((state) => state.permission);

  useEffect(() => {
    dispatch(getPermissionInfo({ id: cookee.get("redirect_userid") }));
  }, []);

  useEffect(() => {
    if (permissionDetail?.permissionDetails?.permission_type == 5) {
      dispatch(
        getPastEvents({ pageno: pageNo, id: permissionDetail?.user_id })
      );
    } else {
      dispatch(getPastEvents({ pageno: pageNo }));
    }
  }, [permissionDetail]);

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];

    return day + "." + month + "." + year;
  }
  const handleCalendarView = () => {
    setView(!view);
  };
  const handleDeleteShow = () => {
    setShowDelete(!showDelete);
  };
  const handleDelete = (id) => {
    setEventId(id);
    handleDeleteShow();
  };
  const handleNext = (e) => {
    e.preventDefault();
    setPageNo(pageNo + 1);
    dispatch(getPastEvents({ pageno: pageNo + 1 }));
  };
  const handlePrevious = (e) => {
    e.preventDefault();
    setPageNo(pageNo - 1);
    dispatch(getPastEvents({ pageno: pageNo - 1 }));
  };
  const token = cookee.get("token");

  const handleEventClick = (id, type) => {
    dispatch(getEventDetail({ eventid: id }));
    if (type === 1) {
      axios({
        method: "post",
        headers: {
          Accept: "/",
          "Content-Type": "application/json",
          auth_token: token,
        },
        url: `${process.env.REACT_APP_URL}/get-event-details-event-id`,
        data: {
          event_id: id,
        },
      }).then((res) => {
        navigate("/edit-event", { state: { eventid: id } });
      });
    } else {
      axios({
        method: "post",
        headers: {
          Accept: "/",
          "Content-Type": "application/json",
          auth_token: token,
        },
        url: `${process.env.REACT_APP_URL}/get-event-details-event-id`,
        data: {
          event_id: id,
        },
      }).then((res) => {
        navigate("/edit-event-others", { state: { eventid: id } });
      });
    }
  };

  // console.log("set", events)
  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row mt-3">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6"></div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <ul
                className="nav nav-pills group-nav tabNabs taskListTab"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <Link
                    to="/events"
                    className={
                      splitLocation[1] === "events"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    {tab1}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/unconfirmed-events"
                    className={
                      splitLocation[1] === "unconfirmed-events"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <div>
                      <NotificationBadge
                        count={unconfirmed.length}
                        effect={Effect.SCALE}
                      />
                    </div>
                    {tab2}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/pasts-events"
                    className={
                      splitLocation[1] === "pasts-events"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    {tab3}
                  </Link>
                </li>
              </ul>
              <div className="d-flex align-items-center lg-justify-content-end">
                <Link to="#" className="btn notiIcon filterIcon">
                  <img
                    src={
                      view
                        ? "assets/images/calendar.png"
                        : "assets/images/calendarGrey.png"
                    }
                    alt=""
                    onClick={handleCalendarView}
                  />
                </Link>

                <Link
                  to="/add-new-event"
                  className="btn add-new notiIcon filterIcon"
                >
                  {tab4}{" "}
                </Link>
              </div>
            </div>
            {view ? <CalendarViewPasts /> : ""}
            {loading ? (
              <DataLoader />
            ) : !view ? (
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="pt-3 px-1 bg-white">
                    <div className="table-responsive">
                      <table className="table storetable text-center">
                        <thead className="">
                          <tr>
                            <th scope="col">Status</th>
                            <th scope="col">{tab5}</th>
                            <th scope="col">{tab6}</th>
                            <th scope="col">{tab7}</th>
                            <th scope="col">{tab19}</th>
                            {permissionDetail?.permissionDetails
                              ?.permission_type == 5 ? (
                              ""
                            ) : (
                              <th scope="col">{tab52}</th>
                            )}
                            {/* <th scope="col">Country</th>
                                                <th scope="col">Event chat</th> */}
                          </tr>
                          <tr className="border-0 bg-none invisible">
                            <td className="border-0 bg-none"></td>
                          </tr>
                        </thead>

                        <tbody>
                          {allPastEvents?.length !== 0 &&
                          allPastEvents !== null ? (
                            allPastEvents.map((res, key) => {
                              return (
                                <tr key={key}>
                                  <td>
                                    {res.is_cancelled === 1 ? (
                                      <h6 style={{ color: "red" }}>{tab59}</h6>
                                    ) : (
                                      <h6 style={{ color: "green" }}>
                                        {tab58}
                                      </h6>
                                    )}
                                  </td>
                                  <td>
                                    {formatDate(res.start_date.substr(0, 10))}
                                  </td>
                                  <td
                                    onClick={() =>
                                      handleEventClick(
                                        res.event_id,
                                        res.event_type
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {res.event_type == 1 ? tab55 : ""}
                                    {res.event_type == 3 ? tab56 : ""}
                                    {res.event_type == 2 ? tab57 : ""}{" "}
                                  </td>
                                  <td
                                    className="notranslate"
                                    onClick={() =>
                                      handleEventClick(
                                        res.event_id,
                                        res.event_type
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {" "}
                                    {res.event_name}
                                  </td>
                                  <td
                                    className="notranslate"
                                    onClick={() =>
                                      handleEventClick(
                                        res.event_id,
                                        res.event_type
                                      )
                                    }
                                  >
                                    {res.town}
                                  </td>
                                  {permissionDetail?.permissionDetails
                                    ?.permission_type == 5 ? (
                                    ""
                                  ) : (
                                    <td>
                                      <img
                                        src="assets/images/delete.png"
                                        width="25px"
                                        alt=""
                                        onClick={() =>
                                          handleDelete(res.event_id)
                                        }
                                      />
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <p>{tab37}</p>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {pageNo === 1 && allPastEvents?.length <= 9 ? (
              ""
            ) : (
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                <div className="d-flex align-items-center lg-justify-content-end">
                  <nav aria-label="...">
                    <ul className="pagination">
                      <li
                        className={
                          pageNo === 1 ? "page-item disabled" : "page-item"
                        }
                      >
                        <a
                          className="page-link"
                          href="#"
                          onClick={handlePrevious}
                        >
                          Previous
                        </a>
                      </li>
                      <li class="page-item active">
                        <a className="page-link" href="#">
                          {pageNo} <span className="sr-only">(current)</span>
                        </a>
                      </li>
                      <li
                        class={
                          allPastEvents?.length <= 9
                            ? "page-item disabled"
                            : "page-item"
                        }
                      >
                        <a className="page-link" href="#" onClick={handleNext}>
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </article>

      {showDelete ? (
        <DeleteEvent
          show={showDelete}
          handleShow={handleDeleteShow}
          pageNo={pageNo}
          eventId={eventId}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default PastEvents;
