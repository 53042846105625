import React, { useState, useMemo, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import axios from 'axios';
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'
import countryList from 'react-select-country-list'
import CancelEventModal from './Eventdates/cancelEventModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { getEventDetail } from '../../app_redux/slice/eventDetail';
import { useSelector } from 'react-redux';
import SetListModal from './Eventdates/setListModal';
import { getSongs } from '../../app_redux/slice/setlistSong';
import S3FileUpload from 'react-s3';
import AddPassengers from './Eventdates/addPassengers';
import TransportDetail from './Eventdates/transportDetails';
import DeleteTransport from './Eventdates/deleteTransport';
import EditOutgoingInvoice from './Eventdates/editOutgoinginvoice';
import EditIncomingInvoice from './Eventdates/editIncominginvoice';
import EditIncomingContract from './Eventdates/editIncomingcontract';
import EditDocumentBooker from './Eventdates/editDocumentBooker';
import EditSoundEngineer from './Eventdates/editSoundengineer';
import EditOtherAttachments from './Eventdates/editOtherattachment';
import InvitationModal from './Eventdates/invitationModal';
import { getPermissionInfo } from '../../app_redux/slice/permissionInfo';


window.Buffer = window.Buffer || require("buffer").Buffer;


const EditEventDataOthers = () => {
    const dispatch = useDispatch();
    const cookee = new Cookies();
    const navigate = useNavigate();
    // const eventDetails = JSON.parse(localStorage.getItem("eventData"))
    const { eventDetails } = useSelector((state) => state.eventdetail)
    const location = useLocation();
    const { pathname } = location;
    const lng = cookee.get("googtrans")
    const splitLocation = pathname.split("/");
    const [info, setInfo] = useState(true)
    const [band, setBand] = useState(false)
    const [transport, setTransport] = useState(false)
    const [setlist, setSetList] = useState(false)
    const [attachment, setAttachemnt] = useState(false)
    const [eventName, setEventName] = useState(eventDetails[0]?.event_name || "")
    const [eventLocation, setEventLocation] = useState(eventDetails[0]?.town || "")
    const [guestList, setGuestList] = useState(eventDetails[0]?.guest_list || "")
    const [parking, setParking] = useState(eventDetails[0]?.parking || "")
    const [catering, setCatering] = useState(eventDetails[0]?.catering || "")
    const [sonitageInfo, setSonitageInfo] = useState(eventDetails[0]?.sonstige_other_info || "")

    const [date, setDate] = useState(eventDetails[0]?.start_date.substr(0, 10) || "")

    const [bandPerson, setBandPerson] = useState([])
    const [personInputId, setPersonId] = useState("")



    const [selectSetlist, setSelectSetlist] = useState(false)
    const [setlistId, setSetlistId] = useState("")

    const [getInStart, setGetInStart] = useState(eventDetails[0]?.start_time?.substr(0, 5) || "")
    const [getInEnd, setGetInEnd] = useState(eventDetails[0]?.end_time?.substr(0, 5) || "")
    const [loadStart, setLoadStart] = useState(eventDetails[0]?.load_start_time?.substr(0, 5) || "")
    const [loadEnd, setLoadEnd] = useState(eventDetails[0]?.load_end_time?.substr(0, 5) || "")
    const [soundStart, setSoundStart] = useState(eventDetails[0]?.sound_start_time?.substr(0, 5) || "")
    const [soundEnd, setSoundEnd] = useState(eventDetails[0]?.sound_end_time?.substr(0, 5) || "")

    const [stageStart, setStageStart] = useState(eventDetails[0]?.stage_start_time?.substr(0, 5) || "")
    const [stageEnd, setStageEnd] = useState(eventDetails[0]?.stage_end_time?.substr(0, 5) || "")
    const [locationName, setLocationName] = useState(eventDetails[0]?.location_name || "")
    const [locationStreet, setLocationStreet] = useState(eventDetails[0]?.location_street || "")
    const [locationPostal, setLocationPostal] = useState(eventDetails[0]?.event_zip_code || "")
    const [locationTown, setLocationTown] = useState(eventDetails[0]?.town || "")
    const [locationHouseNo, setLocationHouseNo] = useState(eventDetails[0]?.event_house_number || "")
    const [locationContactPerson, setLocationContactPerson] = useState(eventDetails[0]?.loc_name_of_person || "")
    const [eventOtherInfo, setEventOtherInfo] = useState(eventDetails[0]?.event_other_info || "")
    const [locationEmail, setLocationEmail] = useState(eventDetails[0]?.location_email || "")
    const [locationPhone, setLocationPhone] = useState(eventDetails[0]?.location_phone_number || "")
    const [locationOtherInfo, setLocationOtherInfo] = useState(eventDetails[0]?.location_other_information || "")

    const [priorityCount, setPriorityCount] = useState(2)

    const [accomodationId, setAccomodationId] = useState(eventDetails[0]?.info_accommodation[0]?.event_accomodation_id || "")
    const [organizerId, setOrganizerId] = useState(eventDetails[0]?.info_organizer[0]?.event_organizer_id || "")
    const [infoOrganizerName, setInfoOrganizerName] = useState(eventDetails[0]?.info_organizer[0]?.organizer_name || "")
    const [infoOrganizerStreet, setInfoOrganizerStreet] = useState(eventDetails[0]?.info_organizer[0]?.organizer_street || "")
    const [infoOrganizerHouseNo, setInfoOrganizerHouseNo] = useState(eventDetails[0]?.info_organizer[0]?.organizer_house_number || "")
    const [infoOrganizerPostal, setInfoOrganizerPostal] = useState(eventDetails[0]?.info_organizer[0]?.organizer_postal || "")
    const [infoOrganizerTown, setInfoOrganizerTown] = useState(eventDetails[0]?.info_organizer[0]?.organizer_town || "")
    const [infoOrganizerNameContact, setInfoOrganizerNameContact] = useState(eventDetails[0]?.info_organizer[0]?.contact_person_name || "")
    const [infoOrganizerEmail, setInfoOrganizerEmail] = useState(eventDetails[0]?.info_organizer[0]?.email
        || "")
    const [infoOrganizerPhone, setInfoOrganizerPhone] = useState(eventDetails[0]?.info_organizer[0]?.phone_number || "")
    const [infoOrganizerInfo, setInfoOrganizerInfo] = useState(eventDetails[0]?.info_organizer[0]?.organizer_other_information || "")
    const [infoOrganizerGemaNo, setInfoOrganizerGemaNo] = useState("")

    const [infoAccommodationName, setInfoAccommodationName] = useState(eventDetails[0]?.
        info_accommodation
    [0]?.accomodation_name || "")
    const [infoAccommodationStreet, setInfoAccommodationStreet] = useState(eventDetails[0]?.
        info_accommodation
    [0]?.accomodation_street || "")
    const [infoAccommodationHouseNo, setInfoAccommodationHouseNo] = useState(eventDetails[0]?.
        info_accommodation
    [0]?.accomodation_house_number || "")
    const [infoAccommodationPostal, setInfoAccommodationPostal] = useState(eventDetails[0]?.
        info_accommodation
    [0]?.accomodation_postal || "")
    const [infoAccommodationTown, setInfoAccommodationTown] = useState(eventDetails[0]?.
        info_accommodation
    [0]?.accomodation_town || "")
    const [infoAccommodationEmail, setInfoAccommodationEmail] = useState(eventDetails[0]?.
        info_accommodation
    [0]?.accomodation_email || "")
    const [infoAccommodationPhone, setInfoAccommodationPhone] = useState(eventDetails[0]?.
        info_accommodation
    [0]?.accomodation_phone || "")
    const [infoAccommodationOther, setInfoAccommodationOther] = useState(eventDetails[0]?.
        info_accommodation
    [0]?.accomodation_other_information || "")


    const [file, setFile] = useState("")
    const [value1, setValue1] = useState({ value: '', label: eventDetails[0]?.event_country || "" })
    const [value2, setValue2] = useState({ value: '', label: eventDetails[0]?.info_organizer[0]?.organizer_country || "" })
    const [personSelectedList, setPersonSelectedList] = useState([])

    const [value3, setValue3] = useState({
        value: '', label: eventDetails[0]?.
            info_accommodation
        [0]?.accomodation_country || ""
    })
    const [showPerson, setShowPerson] = useState(false)
    // const options = useMemo(() => countryList().getData(), [])
    var german = require('localized-countries')(require("localized-countries/data/de"))
    const options = german.array()
    const [showCancelModal, setShowCancelModal] = useState(false)
    const [personList, setPersonList] = useState([])

    const [bandStaffList, setBandStaffList] = useState([])
    const [localPerson, setLocalPerson] = useState(eventDetails[0]?.band_users_details || "")
    const [localBandPerson, setLocalBandPerson] = useState(eventDetails[0]?.band_staff_details || "")

    const [driverList, setDriverList] = useState([{ driver: "", vehicle: "", available_seats: "", passengers: [] }])
    const { allSetlistSongs, loading } = useSelector((state) => state.setlistsong)
    const [eventType, setEventType] = useState(eventDetails[0]?.type_of_event || "")
    const [performanceType, setPerformanceType] = useState(eventDetails[0]?.type_of_performance || "")

    const [bandmember, setBandMember] = useState([])
    const [bandmember2, setBandMember2] = useState([])
    const [cancelledInvitation, setCancelledInvitation] = useState([])
    const [disabled, setDisabled] = useState([])
    const [disabledstaff, setDisabledstaff] = useState([])
    const [showPassengerModal, setShowPassengerModal] = useState(false)
    const [showPassengerDetailModal, setShowPassengerDetailModal] = useState(false)
    const [transportDelete, setTransportDelete] = useState(false)
    const [transportId, setTransportId] = useState("")
    const [vehicle, setVehicle] = useState(false)
    const [OutgoingInvoiceFile, setOutgoingInvoiceFile] = useState(false)
    const [incomingInvoiceFile, setIncomingInvoiceFile] = useState(false)
    const [incomingContractFile, setIncomingContractFile] = useState(false)
    const [documentBookerFile, setDocumentBookerFile] = useState(false)
    const [documentSoundFile, setDocumentSoundFile] = useState(false)
    const [otherFile, setOtherFile] = useState(false)
    const [invitationShow, setInvitationShow] = useState(false)
    const { Permissions } = useSelector((state) => state.attachmentpermission)
    const { permissionDetail } = useSelector((state) => state.permission)




    useEffect(() => {
        getEvents()
        getBandMembers()
        getBandMembersTwo()
        getCancelledInvitations()
        dispatch(getPermissionInfo({ id: cookee.get("redirect_userid") }))
        // dispatch(getSongs())
    }, [])
    const handleInputLocalPerson = (e, i) => {
        const { name, value } = e.target
        const old = localPerson[i]
        const updated = { ...old, [name]: value }
        const list = [...localPerson]
        list[i] = updated
        setLocalPerson(list)
    }
    const handleShowOutgoingInvoiceFile = () => {
        setOutgoingInvoiceFile(!OutgoingInvoiceFile)
    }
    const handleOutgoingfiles = (e) => {
        e.preventDefault();
        handleShowOutgoingInvoiceFile()
    }
    const handleShowIncomingInvoiceFile = () => {
        setIncomingInvoiceFile(!incomingInvoiceFile)
    }
    const handleIncomingfiles = (e) => {
        e.preventDefault();
        handleShowIncomingInvoiceFile()
    }
    const handleShowIncomingContractFile = () => {
        setIncomingContractFile(!incomingContractFile)
    }
    const handleShowInvitation = () => {
        setInvitationShow(!invitationShow)
    }
    const handleContractfiles = (e) => {
        e.preventDefault();
        handleShowIncomingContractFile()
    }
    const handleShowDocumentBookerFile = () => {
        setDocumentBookerFile(!documentBookerFile)
    }
    const handleBookerfiles = (e) => {
        e.preventDefault();
        handleShowDocumentBookerFile()
    }
    const handleShowDocumentSoundFile = () => {
        setDocumentSoundFile(!documentSoundFile)
    }
    const handleSoundfiles = (e) => {
        e.preventDefault();
        handleShowDocumentSoundFile()
    }
    const handleShowOtherFile = () => {
        setOtherFile(!otherFile)
    }
    const handleOtherfiles = (e) => {
        e.preventDefault();
        handleShowOtherFile()
    }
    const showSetlist = () => {
        setSelectSetlist(!selectSetlist)
    }
    const handleShowPassenger = () => {
        setShowPassengerModal(!showPassengerModal)
    }
    const handleShowPassengerDetail = () => {
        setShowPassengerDetailModal(!showPassengerDetailModal)
    }
    const handleShowTransportDelete = () => {
        setTransportDelete(!transportDelete)
    }
    const handleEventType = (e) => {
        setEventType(e.target.value)
    }
    const handlePerformanceType = (e) => {
        setPerformanceType(e.target.value)
    }
    const handleFile = (e) => {
        // console.log("FILES", e.target.files[0])
        //formdata.append['file',e.target.files[0]]
        setFile(e.target.files[0])
    }

    const handleShowCancel = () => {
        setShowCancelModal(!showCancelModal)
    }
    const handleCancelEvent = () => {
        handleShowCancel()
    }




    const getEvents = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            data: {
                "page_no": 10
            },
            url: `${process.env.REACT_APP_URL}/get-all-musicians-list`,
        })
            .then(res => {
                // console.log(res.data)
                setBandPerson(res.data.data)
            })
    }
    const getBandMembers = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-band-leader-staff-members-with-membership`,
            data: {
                "page_no": 1,
                "is_staff": 0,
                "event_id": eventDetails[0]?.event_id,

            },
        })
            .then(res => {
                // console.log(res.data)
                setBandMember(res.data.data)
            })
    }
    const getBandMembersTwo = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-band-leader-staff-members-with-membership`,
            data: {
                "page_no": 1,
                "is_staff": 1,
                "event_id": eventDetails[0]?.event_id,

            },
        })
            .then(res => {
                // console.log(res.data)
                setBandMember2(res.data.data)
            })
    }
    const getCancelledInvitations = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-all-event-invitations-by-event-id`,
            data: {
                "event_id": eventDetails[0]?.event_id || "",
                "is_accepted": 0,
                "is_rejected": 1
            },
        })
            .then(res => {
                // console.log(res.data)
                setCancelledInvitation(res.data.data)
            })
    }
    const handleInputChange = (e, index) => {
        const { name, value } = e.target
        const list = [...driverList]
        list[index][name] = value;
        setDriverList(list)
    }


    const handleInputBand = (e, i, index) => {
        const { name, value } = e.target
        const list = [...bandStaffList]
        list[i][name] = value;
        setBandStaffList(list)
    }

    const handleRemoveStaffLocal = (i, id) => {
        const list = [...localBandPerson]
        list.splice(i, 1)
        setLocalBandPerson(list);
        handleDeleteStaff(id)
    }
    const handleDeleteStaff = (id) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/delete-musicians-from-event`,
            data:
            {
                "event_id": eventDetails[0]?.event_id || "",
                "id": "",
                "staff_id": id

            }
        })
            .then(res => {
                //  console.log("RESPONSE", res.data)
                if (res.data.message === "Member Deleted Successfully!.") {
                    toast.success("Deleted", {
                        theme: "colored",
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    getBandMembers()
                    getBandMembersTwo()
                    dispatch(getEventDetail({ eventid: eventDetails[0]?.event_id || "" }))


                }

            })

    }
    const handleRemoveStaff = (i, index) => {
        const list = [...bandStaffList]
        list.splice(i, 1)
        setBandStaffList(list);
    }

    const handleSendInvite = (id, i) => {
        // setDisabled([...disabled, i])
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/band-leader-event-send-invite-musician`,
            data:
            {
                "event_id": eventDetails[0]?.event_id || "",
                "user_id": id
            }
        })
            .then(res => {
                //  console.log("RESPONSE", res.data)
                if (res.data.message === "Invitation Sent Successfully!.") {
                    toast.success("Invitation Sent", {
                        theme: "colored",
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    dispatch(getEventDetail({ eventid: eventDetails[0]?.event_id || "" }))
                    setLocalPerson(eventDetails[0]?.band_users_details)
                }
            })
    }


    const handleSendInviteBandstaff = (id, i) => {
        // setDisabledstaff([...disabledstaff, i])
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/band-leader-event-send-invite-staff-member`,
            data:
            {
                "event_id": eventDetails[0]?.event_id || "",
                "staff_id": id
            }
        })
            .then(res => {
                //  console.log("RESPONSE", res.data)
                if (res.data.message === "Invitation Sent Successfully!.") {
                    toast.success("Invitation Sent", {
                        theme: "colored",
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    dispatch(getEventDetail({ eventid: eventDetails[0]?.event_id || "" }))

                }

            })
    }



    const handleAddMusicians = (e) => {
        e.preventDefault();
        if (personList[0]?.role !== "" || bandStaffList[0]?.role !== "") {
            axios({
                method: 'post',
                headers: {
                    "Accept": "/",
                    "Content-Type": "application/json",
                    "auth_token": token

                },
                url: `${process.env.REACT_APP_URL}/add-musicians-to-event`,
                data:
                {
                    "event_id": eventDetails[0]?.event_id || "",
                    "event_type": 1,
                    "person": personList,
                    "band_staff": bandStaffList?.length === 0 ? [] : bandStaffList,
                }
            })
                .then(res => {
                    //  console.log("RESPONSE", res.data)
                    if (res.data.message === "Musician Added Successfully!.") {
                        toast.success("Musician Added", {
                            theme: "colored",
                            position: "top-right",
                            autoClose: 4000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                        handleShowInvitation()
                        setTransport(true)
                        setBand(false)
                        setInfo(false)
                        setSetList(false)
                        setAttachemnt(false)
                    }
                })
        }
        else {
            setTransport(true)
            setBand(false)
            setInfo(false)
            setSetList(false)
            setAttachemnt(false)
        }
    }
    const changeHandler = value => {
        setValue1(value)
    }


    const handleInfo = () => {
        setInfo(true)
        setBand(false)
        setTransport(false)
        setSetList(false)
        setAttachemnt(false)
    }
    const handleBand = () => {
        setBand(true)
        setInfo(false)
        setTransport(false)
        setSetList(false)
        setAttachemnt(false)
    }


    const handleEventInfo = (e) => {
        e.preventDefault();
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/update-band-leader-event-info`,
            data:
            {
                "user_id": permissionDetail?.user_id,
                "event_id": eventDetails[0]?.event_id || "",
                "event_name": eventName,
                "info_organizer": [
                    {
                        "event_organizer_id": organizerId,
                        "organizer_name": infoOrganizerName,
                        "organizer_street": infoOrganizerStreet,
                        "organizer_house_number": infoOrganizerHouseNo,
                        "organizer_postal": infoOrganizerPostal,
                        "organizer_town": infoOrganizerTown,
                        "contact_person_name": infoOrganizerNameContact,
                        "email": infoOrganizerEmail,
                        "phone_number": infoOrganizerPhone,
                        "gema_customer_number": infoOrganizerGemaNo,
                        "organizer_country": value2.label,
                        "organizer_other_information": infoOrganizerInfo
                    }
                ],
                "info_accommodation": [
                    {
                        "event_accomodation_id": accomodationId,
                        "accomodation_name": infoAccommodationName,
                        "accomodation_street": infoAccommodationStreet,
                        "accomodation_house_number": infoAccommodationHouseNo,
                        "accomodation_postal": infoAccommodationPostal,
                        "accomodation_town": infoAccommodationTown,
                        "accomodation_country": value3.label,
                        "accomodation_phone_number": infoAccommodationPhone,
                        "accomodation_email": infoAccommodationEmail,
                        "accomodation_other_information": infoAccommodationOther
                    }
                ],
                "start_time": `${getInStart}:00`,
                "end_time": `${getInEnd}:00`,
                "load_start_time": loadStart,
                "load_end_time": loadEnd,
                "sound_start_time": soundStart,
                "sound_end_time": soundEnd,
                "stage_start_time": stageStart,
                "stage_end_time": stageEnd,
                "type_of_event": eventType,
                "type_of_performance": performanceType,
                "start_date": date,
                "latitude": 15,
                "longitude": 109.77,
                "location_name": locationName,
                "location_street": locationStreet,
                "loc_name_of_person": locationContactPerson,
                "location_email": locationEmail,
                "location_phone_number": locationPhone,
                "location_other_information": locationOtherInfo,
                "town": locationTown,
                "event_country": value1.label,
                "event_zip_code": locationPostal,
                "event_house_number": locationHouseNo,
                "event_other_info": eventOtherInfo,
                "event_type": eventDetails[0]?.event_type,
                "parking": parking,
                "guest_list": guestList,
                "catering": catering,
                "sonstige_other_info": sonitageInfo,
                "total_number_of_works": 0,
                "number_of_works_with_copyright": 0,
                "length_set": "00:00:00",
                "is_band": permissionDetail?.permissionDetails?.permission_type == 5 ? 0 : 1,

            }
        })
            .then(res => {
                //  console.log("RESPONSE", res.data)
                if (res.data.message === "Event Info Updated Successfully!.") {
                    toast.success("Info Updated.", {
                        theme: "colored",
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    if (permissionDetail?.permissionDetails?.permission_type === 5) {
                        navigate("/events")
                    }
                    dispatch(getEventDetail({ eventid: eventDetails[0]?.event_id || "" }))
                    //    setEvent_Id(res.data.data.event_id)
                    setBand(true)
                    setInfo(false)
                    setTransport(false)
                    setSetList(false)
                    setAttachemnt(false)
                }

            })
    }



    const handleChange = (e, setstate) => {
        setstate(e.target.value)
    }

    const handleInputPerson = (e, i, index) => {
        const { name, value } = e.target
        const list = [...personList]
        if (name === "role") {
            list[i][name] = value
        }
        else {
            list[i].person[index][name] = value;
        }
        setPersonList(list)
        setPersonSelectedList([...personSelectedList, e.target.value])

    }
    // console.log("#", personList)
    const handleInputLocal = (e, index) => {
        const { name, value } = e.target
        const list = [...localPerson]
        list[index][name] = value;
        setLocalPerson(list)
    }
    const handleAddBandPerson = () => {
        setBandStaffList([...bandStaffList, { user_id: "", amount: "", role: "", description: "" }])
    }
    const handleRemovePerson = (index) => {
        const list = [...personList]
        list.splice(index, 1)
        setPersonList(list);
    }
    const handleRemovePersonLocal = (index, id) => {
        const list = [...localPerson]
        list.splice(index, 1)
        setLocalPerson(list);
        handleDeletePerson(id)
    }

    const handleDeletePerson = (id) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/delete-musicians-from-event`,
            data:
            {
                "event_id": eventDetails[0]?.event_id || "",
                "id": id,
                "staff_id": ""

            }
        })
            .then(res => {
                //  console.log("RESPONSE", res.data)
                if (res.data.message === "Member Deleted Successfully!.") {
                    toast.success("Deleted", {
                        theme: "colored",
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })

                }

            })

    }

    const handleAddPerson = () => {

        // setPersonList([...personList, { user_id: "", role: "", priority: "", amount: "", additional_information: "" }])
        setPriorityCount(2)
        setPersonList([...personList, { role: "", person: [{ user_id: "", priority: 1, amount: "", additional_information: "" }] }])
    }
    const handleAddPriority = (e, role, i) => {
        setPriorityCount(priorityCount + 1)
        const { name, value } = e.target
        // setPriorityShow(true)
        // setIdentity(i)
        let list = [...personList]
        list[i].person = [...personList[i].person, { user_id: "", priority: priorityCount, amount: "", additional_information: "" }]

        // setPersonList([ { person: [{ user_id: "", priority: "", amount: "", additional_information: "" }] }])
        setPersonList(list)
    }
    const handleAddPriorityLocal = (e, role, i) => {
        setPriorityCount(priorityCount + 1)
        const { name, value } = e.target
        // setPriorityShow(true)
        // setIdentity(i)
        console.log('0', i)
        let list = [...localPerson]
        list = [...localPerson, { user_id: "", priority: priorityCount, amount: "", additional_information: "", }]

        // setPersonList([ { person: [{ user_id: "", priority: "", amount: "", additional_information: "" }] }])
        setLocalPerson(list)
    }
    const token = cookee.get('token')

    const handleChat = () => {
        navigate('/chat-event', { state: { eventid: eventDetails[0]?.event_id || "" } })
    }
    return (
        <>
            <article className="col-lg-9">
                <div className="article-body store-bd-gy">
                    <div className="row mt-3 align-items-center">

                        <div className="col-12 col-sm-9 col-md-9 col-lg-10">
                            <ul className="nav nav-pills group-nav tabNabs" id="pills-tab" role="tablist">
                                <li className="nav-item" >
                                    <Link to='#' className={info ? "nav-link  active" : "nav-link"} onClick={handleInfo} >information</Link>
                                </li>
                                {permissionDetail?.permissionDetails?.permission_type == 5 ? "" :

                                    <li className="nav-item" >
                                        <Link to="#" className={band ? "nav-link  active" : "nav-link"} onClick={handleBand}>band</Link>
                                    </li>
                                }

                            </ul>
                        </div>

                        <div class="col-12 col-sm-3 col-md-3 col-lg-2">
                            <div class="d-flex align-items-center lg-justify-content-end">
                                {/* <button class="btn notiIcon filterIcon"><img src="assets/images/calendarGrey.png" alt="" />
                                </button> */}
                                <button className="btn notiIcon filterIcon" onClick={handleChat}><img src="assets/images/chatBlackRed.png" alt="" />
                                </button>
                            </div>
                        </div>
                        {info ?
                            <div className="col-sm-12">
                                <div className="pt-3 px-1 bg-white">
                                    <div className="table-responsive">
                                        <table className="table storetable infoEventTable">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col" title={lng === "/auto/de" || "/en/de" ? "Zum erstellen einer Veranstaltung, müssen Sie den Namen und Datum eintragen. Alle weiteren Informationen können Sie zu einem späteren Zeitpunkt ergänzen. Den zu der Veranstaltung eingeladenen Bandmitglieder werden diese Informationen angezeigt." : "To create an event, you must enter the name and date. You can add any further information at a later date. Band members invited to the event will see this information."}>{lng === "/auto/de" || "/en/de" ? "Info-Veranstaltung" : "info event"}<img src="assets/images/Infoblack.png" alt="" className="mx-1 mb-4" /></th>
                                                    <th scope="col" title={lng === "/auto/de" || "/en/de" ? "Den zu der Veranstaltung eingeladenen Personen werden diese Informationen angezeigt. Ausgenommen hiervon sind der Name der Kontaktperson, Emailadresse sowie Telefon." : "The people invited to the event will see this information. Exceptions to this are the name of the contact person, email address and telephone."}>info location <img src="assets/images/Infoblack.png" alt="" className="mx-1 mb-4" /></th>
                                                    <th scope="col"></th>
                                                </tr>
                                                <tr className="border-0 bg-none invisible"><td className="border-0 bg-none"></td></tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="form-group mb-3">
                                                            <label className='header-label'>Name</label>
                                                            <div className="input-container">
                                                                <input type="text" value={eventName} onChange={(e) => handleChange(e, setEventName)} className="form-control p-0" placeholder="Name" name="confirmpassword" style={{ height: "40px" }} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label className='header-label'>Date</label>
                                                            <div className="input-container">
                                                                <input type="date" value={date} onChange={(e) => handleChange(e, setDate)} className="form-control p-0" placeholder="Name" name="confirmpassword" style={{ height: "40px" }} />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <p className="notranslate header-label">Get in :
                                                                <input type="text" className="form-control p-0" value={getInStart} onChange={(e) => handleChange(e, setGetInStart)} placeholder='start' style={{ height: "40px", width: '100px' }} />
                                                            </p>
                                                            <p className='notranslate header-label'>Ende:
                                                                <input type="text" className="form-control p-0" value={getInEnd} onChange={(e) => handleChange(e, setGetInEnd)} placeholder='end time' style={{ height: "40px", width: '100px' }} />
                                                            </p></div>

                                                        <p>
                                                            <label className='header-label'>Information</label>
                                                            <textarea className="form-control p-0" placeholder='other information' value={eventOtherInfo} onChange={(e) => handleChange(e, setEventOtherInfo)} style={{ height: '100px' }} /></p>

                                                    </td>
                                                    <td>
                                                        <p>
                                                            <label className='header-label'>Name</label>
                                                            <input type="text" className="form-control p-0" value={locationName} onChange={(e) => handleChange(e, setLocationName)} placeholder="Name" style={{ height: "40px" }} /></p>
                                                        <p>
                                                            <label className='header-label'>Street</label><input type="text" className="form-control p-0" value={locationStreet} onChange={(e) => handleChange(e, setLocationStreet)} placeholder="Street" style={{ height: "40px" }} /></p>
                                                        <p><label className='header-label'>House-Number</label>
                                                            <input type="text" className="form-control p-0" value={locationHouseNo} onChange={(e) => handleChange(e, setLocationHouseNo)} placeholder="House-number" style={{ height: "40px" }} /></p>

                                                        <p>
                                                            <label className='header-label'>Zip-code</label><input type="text" className="form-control p-0" value={locationPostal} onChange={(e) => handleChange(e, setLocationPostal)} placeholder="Zip-code" style={{ height: "40px" }} /> </p>
                                                        <p>
                                                            <label className='header-label'>Town</label><input type="text" className="form-control p-0" value={locationTown} onChange={(e) => handleChange(e, setLocationTown)} placeholder="Town" style={{ height: "40px" }} /></p>
                                                        <p>
                                                            <label className='header-label notranslate'>Land</label>
                                                            <Select className="form-control p-0 notranslate" options={options} value={value1} placeholder="Land" onChange={changeHandler} /></p>

                                                        {/* <p><input type="text" className="form-control p-0" value={locationContactPerson} onChange={(e) => handleChange(e, setLocationContactPerson)} placeholder="Name of contact person" style={{ height: "40px" }} /></p>
                                                        <p><input type="text" className="form-control p-0" value={locationEmail} onChange={(e) => handleChange(e, setLocationEmail)} placeholder="Email" style={{ height: "40px" }} /></p>
                                                        <p><input type="text" className="form-control p-0" value={locationPhone} onChange={(e) => handleChange(e, setLocationPhone)} placeholder="Phone" style={{ height: "40px" }} /></p>

                                                        <p><textarea className="form-control p-0" value={locationOtherInfo} onChange={(e) => handleChange(e, setLocationOtherInfo)} placeholder='Other information' style={{ height: '150px' }} /></p> */}

                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="d-flex my-3">
                                    <button className="btn add-new" onClick={handleCancelEvent}>cancel event</button>
                                </div>
                                <div className="d-flex justify-content-end my-3">
                                    <button className="btn add-new" onClick={handleEventInfo}>{lng === "/auto/de" || "/en/de" ? "SPEICHERN" : "Safe"}</button>
                                </div>
                            </div> : ""}

                        {band ? <><div className="col-sm-12">
                            <div className="pt-3 px-1 bg-white">
                                <div className="table-responsive">
                                    <table className="table storetable">
                                        <thead className="">
                                            <tr>
                                                <th scope="col" title={lng === "/auto/de" || "/en/de" ? "Benenen Sie hier die Rolle, in welcher Sie das Bandmitglied, für diese Veranstaltungen einladen möchten. Zum Beispiel Gesang, Bass, Gitarre usw. Die Rolle wir dem jeweiligen Bandmitglied angezeigt." : "Name the role in which you want to invite the band member for this events. For example, vocals, bass, guitar, etc. The role is displayed to each band member."}>Role<img src="assets/images/Infoblack.png" alt="" className="mx-1 mb-4" /></th>
                                                <th scope="col" title={lng === "/auto/de" || "/en/de" ? "Wählen Sie die Person aus, welche Sie für diese Rolle einladen möchten. Jede Person kann nur für jeweils eine Rolle eingeladen werden." : "Select the person you want to invite for this role. Each person can only be invited to one role at a time."}>Person<img src="assets/images/Infoblack.png" alt="" className="mx-1 mb-4" /></th>
                                                <th scope="col" title={lng === "/auto/de" || "/en/de" ? "Sie können für eine Rolle mehrere Bandmitglieder nach priorisierung einladen, indem Sie mit dem Button „Musiker hinzufügen“ weitere Personen hinzufügen - dieses Bandmitglied wird nun mit Priorität 2 für diese Rolle angezeigt. Die Einladung wird vorerst nur an die Person mit der Priorisierung 1 versendet. Erst wenn diese Person die Terminanfrage ablehnt, erhält die Person mit Priorisierung 2 eine Einladung." : "You can invite several band members for a role by prioritizing them by adding more people with the Add musicians button - this band member is now displayed with priority 2 for this role. Initially, the invitation will only be sent to the person with priority 1. Only when this person rejects the appointment request does the person with priority 2 receive an invitation."}>Priority<img src="assets/images/Infoblack.png" alt="" className="mx-1 mb-4" /></th>

                                                <th scope="col" title={lng === "/auto/de" || "/en/de" ? "Hier haben Sie die Möglichkeit, weitere Informationen mit der Terminanfrage, an die jeweiligen Bandmitglieder zu versenden. Diese Information ist nur für Sie und das jeweilige Bandmitglied sichtbar." : "Here you have the opportunity to send further information with the appointment request to the respective band members. This information is only visible to you and the respective band member."}>Additional Information<img src="assets/images/Infoblack.png" alt="" className="mx-1 mb-4" /></th>
                                                <th scope="col">Action</th>
                                                <th scope=""></th>

                                            </tr>
                                            <tr className="border-0 bg-none invisible"><td className="border-0 bg-none"></td></tr>
                                        </thead>
                                        <tbody>
                                            {eventDetails[0]?.band_users_details?.length !== 0 ? eventDetails[0]?.band_users_details.map((res, i) => {
                                                return <> <tr key={i}>
                                                    {res?.role === "" || res?.role ? <td className="white-space tdWidth">
                                                        <p><input type="text" className="form-control p-0" name="role" data-id="test"
                                                            data-nested="" value={res.role} placeholder='Role' style={{ height: "40px", width: "250px" }} /></p>
                                                    </td> : <td><p></p></td>}
                                                    <td className="white-space tdWidth">
                                                        <p><input type="text" className="form-control p-0" name="role" data-id="test"
                                                            data-nested="" value={res.first_name} placeholder='Role' style={{ height: "40px", width: "250px" }} /></p>
                                                    </td>
                                                    <td className=""><div className='d-flex'> <input type="text" className="form-control p-0" name="priority" data-id="test"
                                                        data-nested="nested" value={res.priority} placeholder="1" style={{ height: "40px", width: "150px", border: 'none' }} /> <div className="">
                                                            {/* <button className="btn add-new notiIcon filterIcon" style={{ width: "180px" }} onClick={(e) => handleAddPriorityLocal(e, res.role, i)}>add musician</button> */}
                                                        </div></div></td>

                                                    <td className="tdWidth"><div> <input type="text" className="form-control p-0" name="additional_information" value={res.additional_information} placeholder='additional information' style={{ height: "40px", width: "250px" }} /></div></td>
                                                    <td onClick={() => handleRemovePersonLocal(i, res.id)}><img src="assets/images/delete.png" width="25px" alt="" /></td>

                                                    <td>
                                                        {res.priority === 1 ?
                                                            <div className="d-flex justify-content-end my-3">
                                                                {res.is_invited === 0 ?
                                                                    <button className="btn add-new" disabled={disabled.indexOf(i) !== -1} onClick={() => handleSendInvite(res.user_id, i)} style={{ width: "200px" }}>Send Invitation</button> : ""}
                                                                {res.is_invited === 1 && res.is_rejected === 0 && res.is_accepted === 0 ?
                                                                    <button className="btn add-new" style={{ width: "220px", background: "orange", border: 'none' }}> Invitation was Send</button> : ""}
                                                                {res.is_accepted === 1 ?
                                                                    <button className="btn add-new" style={{ width: "200px", background: "green", border: 'none' }}>Confirmed</button> : ""}
                                                                {res.is_rejected === 1 ?
                                                                    <button className="btn add-new" style={{ width: "200px", background: "black", border: 'none' }}>Cancelled</button> : ""}
                                                            </div> : <div className="d-flex justify-content-end my-3">
                                                                {res.is_invited === 1 && res.is_rejected === 0 && res.is_accepted === 0 ?
                                                                    <button className="btn add-new" style={{ width: "220px", background: "orange", border: 'none' }}> Invitation was Send</button> : ""}
                                                                {res.is_accepted === 1 ?
                                                                    <button className="btn add-new" style={{ width: "200px", background: "green", border: 'none' }}>Confirmed</button> : ""}
                                                                {res.is_rejected === 1 ?
                                                                    <button className="btn add-new" style={{ width: "200px", background: "black", border: 'none' }}>Cancelled</button> : ""}
                                                            </div>}
                                                    </td>


                                                </tr>
                                                </>
                                            }) : ""}


                                            {personList.map((res, i) => {
                                                return <> <tr key={i}>
                                                    {res?.role === "" || res?.role ? <td className="white-space tdWidth">
                                                        <p><input type="text" className="form-control p-0" name="role" data-id="test"
                                                            data-nested="" value={res.role} onChange={(e) => handleInputPerson(e, i)} placeholder='Role' style={{ height: "40px", width: "250px" }} /></p>
                                                    </td> : <td><p></p></td>}
                                                    {res.person.map((item, index) => {
                                                        if (index === 0) {
                                                            return <>
                                                                <td className="white-space tdWidth"><p>

                                                                    <select className="form-control p-0 notranslate" data-id="test"
                                                                        data-nested="nested" name='user_id' value={item.first_name} onChange={(e) => handleInputPerson(e, i, index)} style={{ height: "40px", width: "250px" }}>
                                                                        <option>{lng === "/auto/de" || "/en/de" ? "--Auswählen--" : "--Select--"}</option>
                                                                        {bandmember.map((res, key) => {
                                                                            return <option value={res.user_id} disabled={personSelectedList?.includes(res.user_id)}>{res.first_name}</option>
                                                                        })}

                                                                    </select>
                                                                </p></td>
                                                                <td className=""><div className='d-flex'> <input type="text" className="form-control p-0" name="priority" data-id="test"
                                                                    data-nested="nested" value={item.priority} onChange={(e) => handleInputPerson(e, i, index)} placeholder="1" style={{ height: "40px", width: "150px", border: 'none' }} /> <div className="">
                                                                        <button className="btn add-new notiIcon filterIcon" style={{ width: "180px" }} onClick={(e) => handleAddPriority(e, res.role, i)}>add musician</button>
                                                                    </div></div></td>



                                                                <td className="tdWidth"><div> <input type="text" className="form-control p-0" name="additional_information" value={item.additional_information} onChange={(e) => handleInputPerson(e, i, index)} placeholder='additional information' style={{ height: "40px", width: "250px" }} /></div></td>
                                                                <td onClick={() => handleRemovePerson(i, index)}><img src="assets/images/delete.png" width="25px" alt="" /></td>
                                                                <td>

                                                                    {/* <div className="d-flex justify-content-end my-3">
                                                                        <button className="btn add-new" onClick={() => handleSendInvite(res.user_id)} style={{ width: "200px" }}>Send Invitation</button>
                                                                    </div> */}
                                                                </td>

                                                            </>
                                                        }
                                                    })
                                                    }
                                                </tr>
                                                    {res.person.map((item, index) => {
                                                        if (index > 0) {
                                                            return <tr>
                                                                <td></td>
                                                                <td className="white-space tdWidth"><p> <select className="form-control p-0 notranslate" data-id="test"
                                                                    data-nested="nested" name='user_id' value={item.first_name} onChange={(e) => handleInputPerson(e, i, index)} style={{ height: "40px", width: "250px" }}>
                                                                    <option>{lng === "/auto/de" || "/en/de" ? "--Auswählen--" : "--Select--"}</option>
                                                                    {bandmember.map((res, key) => {
                                                                        return <option value={res.user_id} disabled={personSelectedList?.includes(res.user_id)}>{res.first_name}</option>
                                                                    })}
                                                                </select></p></td>
                                                                <td className=""><div className='d-flex'> <input type="text" className="form-control p-0" name="priority" data-id="test"
                                                                    data-nested="nested" value={item.priority} onChange={(e) => handleInputPerson(e, i, index)} placeholder="1" style={{ height: "40px", width: "150px", border: 'none' }} /> <div className="">
                                                                        {/* <button className="btn add-new notiIcon filterIcon" style={{ width: "180px" }} onClick={(e) => handleAddPriority(e, res.role, i)}>add musician</button> */}
                                                                    </div></div></td>


                                                                <td className="tdWidth"><div> <input type="text" className="form-control p-0" name="additional_information" value={item.additional_information} onChange={(e) => handleInputPerson(e, i, index)} placeholder='additional information' style={{ height: "40px", width: "250px" }} /></div></td>
                                                                <td onClick={() => handleRemovePerson(i, index)}><img src="assets/images/delete.png" width="25px" alt="" /></td>
                                                                <td>

                                                                    {/* <div className="d-flex justify-content-end my-3">
                                                                        <button className="btn add-new" onClick={() => handleSendInvite(res.user_id)} style={{ width: "200px" }}>Send Invitation</button>
                                                                    </div> */}
                                                                </td>

                                                            </tr>
                                                        }
                                                    })
                                                    }
                                                </>
                                            })}

                                            <button className="btn add-new notiIcon filterIcon" onClick={handleAddPerson}>add instrument</button>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                            <div className="row mt-3 align-items-center">
                                <div className="col-8 col-sm-9 col-md-9 col-lg-9">
                                    <ul className="nav nav-pills group-nav tabNabs" id="pills-tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active">staff</a>
                                        </li>
                                    </ul>

                                </div>

                                <div className="col-sm-12">
                                    <div className="pt-3 px-1 bg-white">
                                        <div className="table-responsive">
                                            <table className="table storetable">
                                                <thead className="">
                                                    <tr>
                                                        <th scope="col" title={lng === "/auto/de" || "/en/de" ? "Benenen Sie hier die Rolle, in welcher Sie das Bandmitglied, für diese Veranstaltungen einladen möchten. Zum Beispiel Gesang, Bass, Gitarre usw. Die Rolle wir dem jeweiligen Bandmitglied angezeigt." : "Name the role in which you want to invite the band member for this events. For example, vocals, bass, guitar, etc. The role is displayed to each band member."}>Role<img src="assets/images/Infoblack.png" alt="" className="mx-1 mb-4" /></th>
                                                        <th scope="col" title={lng === "/auto/de" || "/en/de" ? "Wählen Sie die Person aus, welche Sie für diese Rolle einladen möchten. Jede Person kann nur für jeweils eine Rolle eingeladen werden." : "Select the person you want to invite for this role. Each person can only be invited to one role at a time."}>Person<img src="assets/images/Infoblack.png" alt="" className="mx-1 mb-4" /></th>

                                                        <th scope="col" title={lng === "/auto/de" || "/en/de" ? "Hier haben Sie die Möglichkeit, weitere Informationen mit der Terminanfrage, an die jeweiligen Bandmitglieder zu versenden. Diese Information ist nur für Sie und das jeweilige Bandmitglied sichtbar." : "Here you have the opportunity to send further information with the appointment request to the respective band members. This information is only visible to you and the respective band member."}>Additional Information<img src="assets/images/Infoblack.png" alt="" className="mx-1 mb-4" /></th>
                                                        <th scope="col">Action</th>
                                                        <th scope=""></th>
                                                    </tr>
                                                    <tr className="border-0 bg-none invisible"><td className="border-0 bg-none"></td></tr>
                                                </thead>
                                                <tbody>

                                                    {eventDetails[0]?.band_staff_details?.length !== 0 ? eventDetails[0]?.band_staff_details.map((res, i) => {
                                                        return <>
                                                            <tr>
                                                                {res?.role === "" || res?.role ? <td className="white-space tdWidth"><input type="text" name="role" value={res.role} className="form-control p-0" style={{ height: "40px", width: "250px" }} placeholder="Role" /></td> : <td><p></p></td>}
                                                                <td className="white-space tdWidth">
                                                                    <select className="form-control p-0 notranslate" data-id="test"
                                                                        data-nested="nested" name='user_id' value={res.user_id} style={{ height: "40px", width: "250px" }}>
                                                                        {bandmember2.map((res, key) => {
                                                                            return <option value={res.user_id}>{res.first_name}</option>
                                                                        })}
                                                                    </select>
                                                                </td>

                                                                <td className="tdWidth"><div> <input type="text" className="form-control p-0" value={res.description} name="description" placeholder='additional information' style={{ height: "40px", width: "250px" }} /></div></td>
                                                                <td onClick={() => handleRemoveStaffLocal(i, res.staff_id)}><img src="assets/images/delete.png" width="25px" alt="" /></td>
                                                                {/* <div className="d-flex justify-content-end my-3">
                                                                    <button className="btn add-new" onClick={() => handleSendInviteBandstaff(res.staff_id)} style={{ width: "200px" }}>Send Invitation</button>
                                                                </div> */}
                                                                <td>
                                                                    <div className="d-flex justify-content-end my-3">
                                                                        {res.is_invited === "0" ?
                                                                            <button className="btn add-new" disabled={disabledstaff.indexOf(i) !== -1} onClick={() => handleSendInviteBandstaff(res.staff_id, i)} style={{ width: "200px" }}>Send Invitation</button> : ""}
                                                                        {res.is_invited === "1" && res.is_rejected === "0" && res.is_accepted === "0" ?
                                                                            <button className="btn add-new" style={{ width: "220px", background: "orange", border: 'none' }}> Invitation was Send</button> : ""}
                                                                        {res.is_accepted === "1" ?
                                                                            <button className="btn add-new" style={{ width: "200px", background: "green", border: 'none' }}>Confirmed</button> : ""}
                                                                        {res.is_rejected === "1" ?
                                                                            <button className="btn add-new" style={{ width: "200px", background: "black", border: 'none' }}>Cancelled</button> : ""}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    }) : ""}
                                                    {bandStaffList.map((res, i) => {
                                                        return <>
                                                            <tr>
                                                                {res?.role === "" || res?.role ? <td className="white-space tdWidth"><input type="text" name="role" value={res.role} onChange={(e) => handleInputBand(e, i)} className="form-control p-0" style={{ height: "40px", width: "250px" }} placeholder="Role" /></td> : <td><p></p></td>}
                                                                <td className="white-space tdWidth">
                                                                    <select className="form-control p-0 notranslate" data-id="test"
                                                                        data-nested="nested" name='user_id' value={res.first_name} onChange={(e) => handleInputBand(e, i,)} style={{ height: "40px", width: "250px" }}>
                                                                        <option>{lng === "/auto/de" || "/en/de" ? "--Auswählen--" : "--Select--"}</option>
                                                                        {bandmember2.map((res, key) => {
                                                                            return <option value={res.user_id}>{res.first_name}</option>
                                                                        })}
                                                                    </select>
                                                                </td>

                                                                <td className="tdWidth"><div> <input type="text" className="form-control p-0" name="description" value={res.description} placeholder='additional information' style={{ height: "40px", width: "250px" }} /></div></td>
                                                                <td onClick={() => handleRemoveStaff(i)}><img src="assets/images/delete.png" width="25px" alt="" /></td>

                                                            </tr>
                                                        </>
                                                    })}
                                                </tbody>
                                                <button className="btn add-new notiIcon filterIcon mt-3" onClick={handleAddBandPerson} >add staff</button>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-end my-3">
                                        <button className="btn add-new" onClick={handleAddMusicians}>{lng === "/auto/de" || "/en/de" ? "SPEICHERN" : "Safe"}</button>

                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3 align-items-center">
                                <div className="col-8 col-sm-9 col-md-9 col-lg-9">
                                    <ul className="nav nav-pills group-nav tabNabs" id="pills-tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active">Cancelled Invitations</a>
                                        </li>
                                    </ul>

                                </div>

                                <div className="col-sm-12">
                                    <div className="pt-3 px-1 bg-white">
                                        <div className="table-responsive">
                                            <table className="table storetable">
                                                <thead className="">
                                                    <tr>
                                                        <th scope="col">role</th>
                                                        <th scope="col">first name</th>
                                                        <th scope="col">last name</th>
                                                        <th scope="col">payment</th>
                                                    </tr>
                                                    <tr className="border-0 bg-none invisible"><td className="border-0 bg-none"></td></tr>
                                                </thead>
                                                <tbody>

                                                    {cancelledInvitation.map((res, i) => {
                                                        return <tr>
                                                            <td className="white-space tdWidth">{res.role}</td>
                                                            <td className="white-space tdWidth">{res.first_name}</td>
                                                            <td className="white-space tdWidth">{res.last_name}</td>
                                                            <td className="white-space tdWidth">{res.amount}</td>

                                                        </tr>
                                                    })}
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </>
                            : ""}


                    </div>
                </div>
                <ToastContainer position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />
            </article>
            {showCancelModal ? <CancelEventModal show={showCancelModal} handleShow={handleShowCancel} id={eventDetails[0]?.event_id} /> : ""}
            {showPassengerModal ? <AddPassengers show={showPassengerModal} handleShow={handleShowPassenger} driverList={driverList} transportId={transportId} eventid={eventDetails[0]?.event_id} /> : ""}
            {showPassengerDetailModal ? <TransportDetail show={showPassengerDetailModal} handleShow={handleShowPassengerDetail} transportId={transportId} eventid={eventDetails[0]?.event_id} /> : ""}
            {transportDelete ? <DeleteTransport show={transportDelete} handleShow={handleShowTransportDelete} transportId={transportId} eventId={eventDetails[0]?.event_id || ""} /> : ""}
            {OutgoingInvoiceFile ? <EditOutgoingInvoice show={OutgoingInvoiceFile} handleShow={handleShowOutgoingInvoiceFile} eventId={eventDetails[0]?.event_id} /> : ""}
            {incomingInvoiceFile ? <EditIncomingInvoice show={incomingInvoiceFile} handleShow={handleShowIncomingInvoiceFile} eventId={eventDetails[0]?.event_id} /> : ""}
            {incomingContractFile ? <EditIncomingContract show={incomingContractFile} handleShow={handleShowIncomingContractFile} eventId={eventDetails[0]?.event_id} /> : ""}
            {documentBookerFile ? <EditDocumentBooker show={documentBookerFile} handleShow={handleShowDocumentBookerFile} eventId={eventDetails[0]?.event_id} /> : ""}
            {documentSoundFile ? <EditSoundEngineer show={documentSoundFile} handleShow={handleShowDocumentSoundFile} eventId={eventDetails[0]?.event_id} /> : ""}
            {otherFile ? <EditOtherAttachments show={otherFile} handleShow={handleShowOtherFile} eventId={eventDetails[0]?.event_id} /> : ""}
            {invitationShow ? <InvitationModal show={invitationShow} handleShow={handleShowInvitation} eventid={eventDetails[0]?.event_id || ""} /> : ""}
        </>
    )
}

export default EditEventDataOthers;