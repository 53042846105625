import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getNotification } from "../../app_redux/slice/notification";
import { useSelector, useDispatch } from "react-redux";
import { getEventDetail } from "../../app_redux/slice/eventDetail";
import DeleteAccount from "./deleteAccount";
import { getMusicianInfo } from "../../app_redux/slice/getMusicianInfo";
import { useTranslation } from "react-i18next";
import { getPermissionInfo } from "../../app_redux/slice/permissionInfo";
import { getAllNotificationCounts } from "../../app_redux/slice/notificationCounts";
import NotificationBadge from "react-notification-badge";
import { Effect } from "react-notification-badge";

const Header = ({ switchtoken }) => {
  const { i18n, t } = useTranslation();
  let navigate = useNavigate();
  const cookie = new Cookies();
  const dispatch = useDispatch();
  const token = cookie.get("token");
  const user_id = cookie.get("redirect_userid");
  const lng = cookie.get("googtrans");
  const [deleteAccountShow, setdeleteAccountShow] = useState(false);
  const domaintype = window.location.origin;
  const [pageNo, setPageNo] = useState(1);
  const { musicianDetail } = useSelector((state) => state.musician);
  const { allNotification, loading } = useSelector(
    (state) => state.notification
  );
  const queryParams = new URLSearchParams(window.location.search);
  const tokenQuery = queryParams.get("token");
  const { tab1, tab2, tab3, tab4, tab7, tab6 } = t("setting");
  const { allNotificationCount } = useSelector((state) => state.counts);

  useEffect(() => {
    dispatch(getNotification({ pageno: pageNo }));
    dispatch(getAllNotificationCounts());
  }, []);

  console.log("all counts", allNotificationCount);
  useEffect(() => {
    if (tokenQuery) {
      cookie.set("token", queryParams.get("token"));
      cookie.set("redirect_userid", queryParams.get("user"));
      dispatch(getMusicianInfo());
      dispatch(getPermissionInfo({ id: queryParams.get("user") }));
    } else {
      dispatch(getMusicianInfo());
      dispatch(getPermissionInfo({ id: cookie.get("redirect_userid") }));
    }
  }, [tokenQuery]);

  const handleChangeLanguage = () => {
    console.log("langu", i18n.language);
    i18n.changeLanguage(i18n.language === "en" ? "de" : "en");
  };

  useEffect(() => {
    if (musicianDetail) {
      cookie.set("email", musicianDetail.email);
      cookie.set("phonenumber", musicianDetail.phone_number);
      cookie.set("countrycode", musicianDetail.country_code);
    }
  }, [musicianDetail]);

  const handleNotificationClick = () => {
    dispatch(getNotification({ pageno: pageNo }));
  };

  const handleDeleteAccount = () => {
    setdeleteAccountShow(!deleteAccountShow);
  };

  const handleSwitch = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_URL}/switch-to-musician-from-band-side`,
      data: {
        user_id: user_id,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          cookie.remove("token");
          cookie.remove("email");
          cookie.remove("phonenumber");
          cookie.remove("bandname");
          cookie.remove("userid_musician");
          cookie.remove("redirect_userid");
          let authToken = res.data.data.auth_token;
          // cookie.set('token', res.data.data.auth_token)
          // window.location = `http://localhost:3001/clever-band-musician/build/appointments/?token=${authToken}`
          // window.location = `http://localhost:3000/clever-band-musician-dev/appointments?token=${authToken}`
          window.location = domaintype.includes(".com")
            ? `https://cleverbandassist.com/appointments?token=${authToken}`
            : `https://cleverbandassist.de/appointments?token=${authToken}`;
          // window.location = `http://localhost:3001/appointments?token=${authToken}`
        }
      })

      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleNotificationRedirect = (type, id) => {
    const data = Object.values(id);
    if (type === 11) {
      handleEventClick(data[0]);
    }
    if (type === 12) {
      handleEventClick(data[0]);
    }
    // if (type === 9) {
    //     handleChat(data[0])
    // }
    // if (type === 8) {
    //     handleTaskChat(data[0])
    // }
  };
  const handleEventClick = (id) => {
    dispatch(getEventDetail({ eventid: id }));
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-event-details-event-id`,
      data: {
        event_id: id,
      },
    }).then((res) => {
      // localStorage.setItem("eventData", JSON.stringify(res.data.data))
      navigate("/edit-event");
    });
  };

  const handleLogout = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/log-out-band-leader`,
      data: {
        device_token: "web",
      },
    }).then((res) => {
      // console.log("res", res.data)
      if (res.status === 200) {
        // alert("Logout successfully!!")
        cookie.remove("token");
        cookie.remove("email");
        cookie.remove("phonenumber");
        cookie.remove("bandname");
        cookie.remove("devicetoken");
        cookie.remove("redirect_userid");

        // navigate("/login-email")
        window.location = domaintype.includes(".com")
          ? "https://cleverbandassist.com"
          : "https://cleverbandassist.de";
      }
    });
  };

  return (
    <>
      <div className="header bg-black px-3 d-flex justify-content-between align-items-center fixed-top">
        <span className="navbar-brand bg-none d-lg-block">
          <div className="d-flex">
            <Link to="/dashboard">
              {" "}
              <img
                src="assets/images/logoWhite.png"
                className="img-fluid"
                alt="img"
              />
            </Link>
            <h6
              className="text-capitalize mb-2 mt-4 mx-4 notranslate"
              style={{ color: "white" }}
            >
              {musicianDetail?.band_name || "NA"}
            </h6>
          </div>
        </span>

        <div className="d-flex gap-2 align-items-center">
          <div className="position-relative filterDrop">
            <NotificationBadge
              count={allNotificationCount}
              effect={Effect.SCALE}
              style={{ backgroundColor: "white", color: "black" }}
              className=""
            />
            <div className="d-flex mt-3">
              <img
                src="assets/images/notifi.png"
                className="headerImg cursor-pointer"
                alt=""
                onClick={handleNotificationClick}
              />
            </div>

            <div className="filterDropSelect">
              <div className="d-flex justify-content-between">
                <div>
                  <h3>Latest Notification</h3>
                </div>
              </div>
              <div className="mt-3 notificationsall">
                {allNotification?.length !== 0 ? (
                  allNotification.map((res, key) => {
                    return (
                      <div className="notiList">
                        <p
                          onClick={() =>
                            handleNotificationRedirect(
                              res.notification_type,
                              res.notification_data
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {res.title}{" "}
                          {res?.created_at
                            ? new Date(res?.created_at).toLocaleString()
                            : "-"}
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <p>No Notification Found !!</p>
                )}
              </div>
              <div>
                <Link to="/all-notification">
                  {" "}
                  <p className="text-center mt-3">View all</p>
                </Link>
              </div>
            </div>
          </div>

          <div class="btn-group">
            <button
              type="button"
              className="btn btn-danger dropdown-toggle"
              data-bs-toggle="dropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ width: "130px", height: "30px" }}
            >
              {" "}
              Bandleader
            </button>

            <div className="dropdown-menu">
              <button className="dropdown-item" onClick={handleSwitch}>
                {tab7}
              </button>
            </div>
          </div>
          <button
            className="btn btn-blue float-end menu-toggle d-lg-none"
            data-bs-target="#sidebar"
            data-bs-toggle="collapse"
          >
            {" "}
            <i className="fa fa-bars"></i>
          </button>
          {/* <div id="google_translate_element"></div> */}
          <div>
            <div className="position-relative filterDrop">
              <img
                src="assets/images/settings.png"
                className="headerImg"
                alt=""
              />
              <div className="filterDropSelect settingDropSelect">
                <div className="d-flex justify-content-between">
                  <div>
                    <h3>Settings</h3>
                  </div>
                </div>
                <div className="notiList mt-2 notranslate">
                  <Link to="/dashboard" style={{ color: "gray" }}>
                    Home
                  </Link>
                </div>
                <div className="notiList mt-2">
                  <Link to="/band-staff" style={{ color: "gray" }}>
                    {tab1}
                  </Link>
                </div>
                <div className="notiList mt-2">
                  <Link to="/term-and-condition" style={{ color: "gray" }}>
                    {tab2}
                  </Link>
                </div>
                <div className="notiList mt-2">
                  <Link to="/privacyPolicy" style={{ color: "gray" }}>
                    {tab3}
                  </Link>
                </div>
                <div className="notiList mt-2">
                  <Link to="/about-us" style={{ color: "gray" }}>
                    {tab4}
                  </Link>
                </div>

                <div className="notiList mt-2">
                  <span onClick={handleDeleteAccount} style={{ color: "gray" }}>
                    {tab6}
                  </span>
                </div>

                {/* <div className="notiList mt-2">
                                    <Link to="/change-password" style={{ color: "gray" }}>Change Password</Link>
                                </div> */}
                <div className="mt-3">
                  <button className="btn btn-save w-75" onClick={handleLogout}>
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>

          <img
            src={
              i18n.language === "en"
                ? "assets/images/english-select.png"
                : "assets/images/german-select.png"
            }
            alt=""
            className="img-fluid"
            onClick={handleChangeLanguage}
          />
          <button
            className="btn btn-blue float-end menu-toggle d-lg-none"
            data-bs-target="#sidebar"
            data-bs-toggle="collapse"
          >
            {" "}
            <i className="fa fa-bars"></i>
          </button>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {deleteAccountShow ? (
        <DeleteAccount
          show={deleteAccountShow}
          handleShow={handleDeleteAccount}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Header;
