import React, { useState } from 'react'
import OTPInput from "otp-input-react";
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'



const OtpVerification = () => {
    const navigate = useNavigate();
    const cookee = new Cookies();
    const [OTP, setOTP] = useState("");
    const phone = localStorage.getItem("phone")
    const handleLogin = (e) => {
        e.preventDefault();
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/band-leader-sign-in`,
            data:
            {
                "phone_number": phone,
                "verify_otp": OTP,
                "device_type": "W",
                "device_token": "web",
                "country_code":"+91",
            }
        })
            .then(res => {
                if (res.data.message === "Band signed-in succesfully!") {
                    cookee.set('token', res.data.data.auth_token)
                    cookee.set('bandid', res.data.data.band_id)
                    cookee.set('email', res.data.data.email)
                    cookee.set('phonenumber', res.data.data.phone_number)
                    alert("Band sign in successfully.")
                    toast.success("Band signed-in succesfully!", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    navigate('/dashboard')

                }

            })
            .catch((error) => {
                if (error.response.data.message === 'Invalid OTP! Retry.') {
                    toast.error('Invalid OTP! Retry.', {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                // if (error.response.data.message === 'Band signed-in succesfully!') {
                //     toast.error("Signed-in successfully", {
                //         position: "top-right",
                //         autoClose: 4000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         draggable: true,
                //         progress: undefined,
                //     })
                // }
            })

    }

    return (
        <section className="bg-image">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-sm-12 col-md-12 col-lg-12 mr-0 pr-0">
                        <div className="form-bg">
                            <form className="row form-style">
                                <div className="col-sm-12 col-md-12 text-center mb-4">
                                    <div className="logo-bg text-center">
                                        <div className="mt-2"><img src="assets/images/logo.png" className="img-fluid logo-admin" alt="logo" /></div>
                                    </div>
                                </div>
                                <div className="col-sm-12 text-center">
                                    <img src="assets/images/otp.png" className="img-fluid  iconIMg my-3" alt="" />
                                    <h2 className="mdl-ttl">OTP Verification</h2>
                                    <p className="mdl-sbttl">Please enter 6 digit code, which has been sent on your registered email address</p>
                                </div>
                                <div className="form-group col-sm-12">
                                    <div className="passcode-wrapper mx-4 ">
                                        {/* <input id="codeBox1" type="text" maxlength="1" placeholder="0" />
                                        <input id="codeBox2" type="text" maxlength="1" placeholder="0" />
                                        <input id="codeBox3" type="text" maxlength="1" placeholder="0" />
                                        <input id="codeBox4" type="text" maxlength="1" placeholder="0" />
                                        <input id="codeBox5" type="text" maxlength="1" placeholder="0" />
                                        <input id="codeBox6" type="text" maxlength="1" placeholder="0" /> */}
                                        <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} placeholder="000000" />

                                    </div>
                                </div>
                                <div className="col-sm-12 text-center">
                                    <span>00:34</span>
                                </div>
                                <div className="col-sm-12 mt-4 text-center">
                                    <button className="btn btn-save w-50" onClick={handleLogin}>Verify</button>
                                </div>
                                <div className="col-sm-12 text-center mt-4">
                                    <div><a href="#" className="forgot-pass p-0">Resend Code</a></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </section>

    )
}

export default OtpVerification