import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import CalendarView from "../Calendar/calendar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotificationBadge from "react-notification-badge";
import { Effect } from "react-notification-badge";
import { useDispatch, useSelector } from "react-redux";
import { getEventDetail } from "../../app_redux/slice/eventDetail";
import { getUnconfirmedEvents } from "../../app_redux/slice/unconfirmedEvents";
import CalendarViewUnconfirmed from "../Calendar/calendarUnconfirmed";
import ButtonLoader from "../Loader/loading";
import { useTranslation } from "react-i18next";
import DataLoader from "../Loader/dataLoader";
import RejectEventModal from "../Content/Eventdates/rejectEvent";
import { getPermissionInfo } from "../../app_redux/slice/permissionInfo";

const localizer = momentLocalizer(moment);

const UnconfirmedEvents = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cookee = new Cookies();
  const navigate = useNavigate();
  const lng = cookee.get("googtrans");
  const [unconfirmed, setUnconfirmedEvents] = useState([]);
  const [show, setShow] = useState(false);
  const [view, setView] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [rejectShow, setRejectShow] = useState(false);
  const [id, setId] = useState("");
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const { allUnconfirmedEvents, loading } = useSelector(
    (state) => state.unconfirmedEvents
  );
  const [pageNo, setPageNo] = useState(1);
  const [position, setPosition] = useState("");
  const {
    tab1,
    tab2,
    tab3,
    tab19,
    tab5,
    tab6,
    tab7,
    tab4,
    tab37,
    tab52,
    tab53,
    tab54,
    tab55,
    tab56,
    tab57,
    tab78,
    tab79,
  } = t("event");
  const { permissionDetail } = useSelector((state) => state.permission);

  useEffect(() => {
    dispatch(getPermissionInfo({ id: cookee.get("redirect_userid") }));
  }, []);

  useEffect(() => {
    if (permissionDetail?.permissionDetails?.permission_type == 5) {
      dispatch(
        getUnconfirmedEvents({ pageno: pageNo, id: permissionDetail?.user_id })
      );
    } else {
      dispatch(getUnconfirmedEvents({ pageno: pageNo }));
    }
  }, [permissionDetail]);
  const handleReject = (id) => {
    setId(id);
    setRejectShow(!rejectShow);
  };

  console.log("permission", permissionDetail);

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];

    return day + "." + month + "." + year;
  }
  const handleNext = (e) => {
    e.preventDefault();
    setPageNo(pageNo + 1);
    dispatch(getUnconfirmedEvents({ pageno: pageNo + 1 }));
  };
  const handlePrevious = (e) => {
    e.preventDefault();
    setPageNo(pageNo - 1);
    dispatch(getUnconfirmedEvents({ pageno: pageNo - 1 }));
  };
  const handleCalendar = () => {
    setShow(!show);
  };
  const handleCalendarView = () => {
    setView(!view);
  };
  const token = cookee.get("token");

  const handleAccept = (id, i) => {
    setPosition(i);
    setLoading1(true);
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/confirm-band-leader-event`,
      data: {
        event_id: id,
        is_confirmed: "1",
        is_cancelled: "",
      },
    }).then((res) => {
      if (res.data.message === "Event Confirmed!.") {
        setLoading1(false);

        toast.success("Event confirmed", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(getUnconfirmedEvents());
      }
    });
  };
  // const handleReject = (id, i) => {
  //     setPosition(i)
  //     setLoading2(true)
  //     axios({
  //         method: 'post',
  //         headers: {
  //             "Accept": "/",
  //             "Content-Type": "application/json",
  //             "auth_token": token
  //         },
  //         url: `${process.env.REACT_APP_URL}/confirm-band-leader-event`,
  //         data:
  //         {
  //             "event_id": id,
  //             "is_confirmed": "",
  //             "is_cancelled": "1"
  //         }
  //     })
  //         .then(res => {
  //             //console.log("res", res.data)
  //             if (res.data.message === "Event Cancelled!.") {
  //                 setLoading2(false)
  //                 toast.success("Event cancelled", {
  //                     position: "top-right",
  //                     autoClose: 4000,
  //                     hideProgressBar: false,
  //                     closeOnClick: true,
  //                     pauseOnHover: true,
  //                     draggable: true,
  //                     progress: undefined,
  //                 })
  //                 dispatch(getUnconfirmedEvents())
  //             }
  //         })
  // }
  const handleEventClick = (id, type) => {
    dispatch(getEventDetail({ eventid: id }));
    if (type === 1) {
      axios({
        method: "post",
        headers: {
          Accept: "/",
          "Content-Type": "application/json",
          auth_token: token,
        },
        url: `${process.env.REACT_APP_URL}/get-event-details-event-id`,
        data: {
          event_id: id,
        },
      }).then((res) => {
        navigate("/edit-event", { state: { eventid: id } });
      });
    } else {
      axios({
        method: "post",
        headers: {
          Accept: "/",
          "Content-Type": "application/json",
          auth_token: token,
        },
        url: `${process.env.REACT_APP_URL}/get-event-details-event-id`,
        data: {
          event_id: id,
        },
      }).then((res) => {
        navigate("/edit-event-others", { state: { eventid: id } });
      });
    }
  };

  // console.log("set", events)
  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row mt-3">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <ul
                className="nav nav-pills group-nav tabNabs taskListTab"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <Link
                    to="/events"
                    className={
                      splitLocation[1] === "events"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    {tab1}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/unconfirmed-events"
                    className={
                      splitLocation[1] === "unconfirmed-events"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <div>
                      <NotificationBadge
                        count={unconfirmed.length}
                        effect={Effect.SCALE}
                      />
                    </div>
                    {tab2}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/pasts-events"
                    className={
                      splitLocation[1] === "pasts-events"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    {tab3}
                  </Link>
                </li>
              </ul>
              <div className="d-flex align-items-center lg-justify-content-end">
                <Link to="#" className="btn notiIcon filterIcon">
                  <img
                    src={
                      view
                        ? "assets/images/calendar.png"
                        : "assets/images/calendarGrey.png"
                    }
                    alt=""
                    onClick={handleCalendarView}
                  />
                  <img
                    src="assets/images/Infoblack.png"
                    alt=""
                    className="mx-1 mb-5"
                    title={
                      lng === "/auto/de" || "/en/de"
                        ? "Wechseln Sie in die Kalender Ansicht"
                        : "Switch to the calendar view"
                    }
                  />
                </Link>
                <Link
                  to="/add-new-event"
                  className="btn add-new notiIcon filterIcon"
                >
                  {tab4}{" "}
                </Link>
              </div>
            </div>

            {view ? <CalendarViewUnconfirmed /> : ""}
            {loading ? (
              <DataLoader />
            ) : !view ? (
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="pt-3 px-1 bg-white">
                    <div className="table-responsive">
                      <table className="table storetable text-center">
                        <thead className="">
                          <tr>
                            <th scope="col">{tab5}</th>
                            <th scope="col">{tab6}</th>
                            <th scope="col">{tab7}</th>
                            <th scope="col">{tab19}</th>
                            {/* <th scope="col">Country</th>
                                                    <th scope="col">Event chat</th> */}
                            {permissionDetail?.permissionDetails
                              ?.permission_type == 5 ? (
                              ""
                            ) : (
                              <th scope="col">
                                {tab52}{" "}
                                <img
                                  src="assets/images/Infoblack.png"
                                  alt=""
                                  className="mx-1 mb-5"
                                  title={
                                    lng === "/auto/de" || "/en/de"
                                      ? "In der Auflistung finden Sie alle von Ihnen erstellten Veranstaltungen, welche noch bestätigt oder abgelehnt werden müssen. Sie können dieses hier bestätigen oder absagen. Bandmitgliedern, welche eingeladen wurden und der Terminanfrage zugestimmt haben, wird die Bestätitung mit einem grünen Punkt in der Veranstaltungsübersicht in deren Web-Version signalisiert"
                                      : "In the list you will find all the events you have created that still need to be confirmed or rejected. You can confirm or cancel this here. Band members who have been invited and have agreed to the appointment request will see the confirmation with a green dot in the event overview in their web version"
                                  }
                                />
                              </th>
                            )}
                          </tr>
                          <tr className="border-0 bg-none invisible">
                            <td className="border-0 bg-none"></td>
                          </tr>
                        </thead>

                        <tbody>
                          {allUnconfirmedEvents?.length > 0 ? (
                            allUnconfirmedEvents.map((res, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    onClick={() =>
                                      handleEventClick(
                                        res.event_id,
                                        res.event_type
                                      )
                                    }
                                  >
                                    {formatDate(res.start_date.substr(0, 10))}
                                  </td>
                                  <td
                                    onClick={() =>
                                      handleEventClick(
                                        res.event_id,
                                        res.event_type
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {res.event_type == 1 ? tab55 : ""}
                                    {res.event_type == 3 ? tab56 : ""}
                                    {res.event_type == 2 ? tab57 : ""}
                                  </td>
                                  <td
                                    className="notranslate"
                                    onClick={() =>
                                      handleEventClick(
                                        res.event_id,
                                        res.event_type
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {res.event_name}
                                  </td>
                                  <td
                                    className="notranslate"
                                    onClick={() =>
                                      handleEventClick(
                                        res.event_id,
                                        res.event_type
                                      )
                                    }
                                  >
                                    {res.town}
                                  </td>
                                  {/* <td>{res.country}</td>
                                                        <td></td> */}
                                  {permissionDetail?.permissionDetails
                                    ?.permission_type == 5 ? (
                                    ""
                                  ) : (
                                    <td>
                                      {loading1 && position == i ? (
                                        <ButtonLoader view="btn  add-new " />
                                      ) : (
                                        <button
                                          className="btn  add-new  "
                                          onClick={() =>
                                            handleAccept(res.event_id, i)
                                          }
                                          style={{
                                            background: "gray",
                                            border: "none",
                                          }}
                                        >
                                          {tab53}
                                        </button>
                                      )}
                                      {loading2 && position == i ? (
                                        <ButtonLoader view="btn  add-new " />
                                      ) : (
                                        <button
                                          className="btn add-new "
                                          onClick={() =>
                                            handleReject(res.event_id, i)
                                          }
                                        >
                                          {tab54}
                                        </button>
                                      )}
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <p>{tab37}</p>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {pageNo === 1 && allUnconfirmedEvents?.length <= 9 ? (
              ""
            ) : (
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                <div className="d-flex align-items-center lg-justify-content-end">
                  <nav aria-label="...">
                    <ul className="pagination">
                      <li
                        className={
                          pageNo === 1 ? "page-item disabled" : "page-item"
                        }
                      >
                        <a
                          className="page-link"
                          href="#"
                          onClick={handlePrevious}
                        >
                          Previous
                        </a>
                      </li>
                      <li class="page-item active">
                        <a className="page-link" href="#">
                          {pageNo} <span className="sr-only">(current)</span>
                        </a>
                      </li>
                      <li
                        class={
                          allUnconfirmedEvents?.length <= 9
                            ? "page-item disabled"
                            : "page-item"
                        }
                      >
                        <a className="page-link" href="#" onClick={handleNext}>
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </article>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {rejectShow ? (
        <RejectEventModal show={rejectShow} handleShow={handleReject} id={id} />
      ) : (
        ""
      )}
    </>
  );
};

export default UnconfirmedEvents;
