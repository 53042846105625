import React, { useState, useMemo, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import countryList from "react-select-country-list";
import CancelEventModal from "./Eventdates/cancelEventModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { getEventDetail } from "../../app_redux/slice/eventDetail";
import { useSelector } from "react-redux";
import SetListModal from "./Eventdates/setListModal";
import S3FileUpload from "react-s3";
import AddPassengers from "./Eventdates/addPassengers";
import TransportDetail from "./Eventdates/transportDetails";
import DeleteTransport from "./Eventdates/deleteTransport";
import EditOutgoingInvoice from "./Eventdates/editOutgoinginvoice";
import EditIncomingInvoice from "./Eventdates/editIncominginvoice";
import EditIncomingContract from "./Eventdates/editIncomingcontract";
import EditDocumentBooker from "./Eventdates/editDocumentBooker";
import EditSoundEngineer from "./Eventdates/editSoundengineer";
import EditOtherAttachments from "./Eventdates/editOtherattachment";
import InvitationModal from "./Eventdates/invitationModal";
import { getAttachmentPermission } from "../../app_redux/slice/getAttachments";
import { getSongs } from "../../app_redux/slice/setlistSong";
import PassengersList from "./Eventdates/passengerList";
import { getPermissionInfo } from "../../app_redux/slice/permissionInfo";
import { useTranslation } from "react-i18next";

window.Buffer = window.Buffer || require("buffer").Buffer;

const EditEventData = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cookee = new Cookies();
  const navigate = useNavigate();
  // const eventDetails = JSON.parse(localStorage.getItem("eventData"))
  const { eventDetails } = useSelector((state) => state.eventdetail);
  const location = useLocation();
  const { pathname } = location;
  const lng = cookee.get("googtrans");
  const splitLocation = pathname.split("/");
  const [info, setInfo] = useState(true);
  const [band, setBand] = useState(false);
  const [transport, setTransport] = useState(false);
  const [setlist, setSetList] = useState(false);
  const [attachment, setAttachemnt] = useState(false);
  const [eventName, setEventName] = useState(eventDetails[0]?.event_name || "");
  const [guestList, setGuestList] = useState(eventDetails[0]?.guest_list || "");
  const [parking, setParking] = useState(eventDetails[0]?.parking || "");
  const [catering, setCatering] = useState(eventDetails[0]?.catering || "");
  const [sonitageInfo, setSonitageInfo] = useState(
    eventDetails[0]?.sonstige_other_info || ""
  );

  const [addBandStaff, setAddBandStaff] = useState(false);
  const [date, setDate] = useState(
    eventDetails[0]?.start_date.substr(0, 10) || ""
  );

  const [bandPerson, setBandPerson] = useState([]);

  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const [file3, setFile3] = useState("");
  const [file4, setFile4] = useState("");
  const [file5, setFile5] = useState("");
  const [file6, setFile6] = useState("");

  const [selectSetlist, setSelectSetlist] = useState(false);
  const [setlistId, setSetlistId] = useState("");

  const [getInStart, setGetInStart] = useState(
    eventDetails[0]?.start_time?.substr(0, 5) || ""
  );
  const [getInEnd, setGetInEnd] = useState("");
  const [loadStart, setLoadStart] = useState(
    eventDetails[0]?.load_start_time?.substr(0, 5) || ""
  );
  const [loadEnd, setLoadEnd] = useState(
    eventDetails[0]?.load_end_time?.substr(0, 5) || ""
  );
  const [soundStart, setSoundStart] = useState(
    eventDetails[0]?.sound_start_time?.substr(0, 5) || ""
  );
  const [soundEnd, setSoundEnd] = useState(
    eventDetails[0]?.sound_end_time?.substr(0, 5) || ""
  );

  const [stageStart, setStageStart] = useState(
    eventDetails[0]?.stage_start_time?.substr(0, 5) || ""
  );
  const [stageEnd, setStageEnd] = useState(
    eventDetails[0]?.stage_end_time?.substr(0, 5) || ""
  );
  const [locationName, setLocationName] = useState(
    eventDetails[0]?.location_name || ""
  );
  const [locationStreet, setLocationStreet] = useState(
    eventDetails[0]?.location_street || ""
  );
  const [locationPostal, setLocationPostal] = useState(
    eventDetails[0]?.event_zip_code || ""
  );
  const [locationTown, setLocationTown] = useState(eventDetails[0]?.town || "");
  const [locationHouseNo, setLocationHouseNo] = useState(
    eventDetails[0]?.event_house_number || ""
  );
  const [locationContactPerson, setLocationContactPerson] = useState(
    eventDetails[0]?.loc_name_of_person || ""
  );
  const [eventOtherInfo, setEventOtherInfo] = useState(
    eventDetails[0]?.event_other_info || ""
  );
  const [locationEmail, setLocationEmail] = useState(
    eventDetails[0]?.location_email || ""
  );
  const [locationPhone, setLocationPhone] = useState(
    eventDetails[0]?.location_phone_number || ""
  );
  const [locationOtherInfo, setLocationOtherInfo] = useState(
    eventDetails[0]?.location_other_information || ""
  );
  const [passenger, setPassenger] = useState([{ passenger_name: "" }]);
  const [setListData, setSetListData] = useState([]);
  const [priorityCount, setPriorityCount] = useState(2);

  const [accomodationId, setAccomodationId] = useState(
    eventDetails[0]?.info_accommodation[0]?.event_accomodation_id || ""
  );
  const [organizerId, setOrganizerId] = useState(
    eventDetails[0]?.info_organizer[0]?.event_organizer_id || ""
  );
  const [infoOrganizerName, setInfoOrganizerName] = useState(
    eventDetails[0]?.info_organizer[0]?.organizer_name || ""
  );
  const [infoOrganizerStreet, setInfoOrganizerStreet] = useState(
    eventDetails[0]?.info_organizer[0]?.organizer_street || ""
  );
  const [infoOrganizerHouseNo, setInfoOrganizerHouseNo] = useState(
    eventDetails[0]?.info_organizer[0]?.organizer_house_number || ""
  );
  const [infoOrganizerPostal, setInfoOrganizerPostal] = useState(
    eventDetails[0]?.info_organizer[0]?.organizer_postal || ""
  );
  const [infoOrganizerTown, setInfoOrganizerTown] = useState(
    eventDetails[0]?.info_organizer[0]?.organizer_town || ""
  );
  const [infoOrganizerNameContact, setInfoOrganizerNameContact] = useState(
    eventDetails[0]?.info_organizer[0]?.contact_person_name || ""
  );
  const [infoOrganizerEmail, setInfoOrganizerEmail] = useState(
    eventDetails[0]?.info_organizer[0]?.email || ""
  );
  const [infoOrganizerPhone, setInfoOrganizerPhone] = useState(
    eventDetails[0]?.info_organizer[0]?.phone_number || ""
  );
  const [infoOrganizerInfo, setInfoOrganizerInfo] = useState(
    eventDetails[0]?.info_organizer[0]?.organizer_other_information || ""
  );
  const [infoOrganizerGemaNo, setInfoOrganizerGemaNo] = useState("");

  const [infoAccommodationName, setInfoAccommodationName] = useState(
    eventDetails[0]?.info_accommodation[0]?.accomodation_name || ""
  );
  const [infoAccommodationStreet, setInfoAccommodationStreet] = useState(
    eventDetails[0]?.info_accommodation[0]?.accomodation_street || ""
  );
  const [infoAccommodationHouseNo, setInfoAccommodationHouseNo] = useState(
    eventDetails[0]?.info_accommodation[0]?.accomodation_house_number || ""
  );
  const [infoAccommodationPostal, setInfoAccommodationPostal] = useState(
    eventDetails[0]?.info_accommodation[0]?.accomodation_postal || ""
  );
  const [infoAccommodationTown, setInfoAccommodationTown] = useState(
    eventDetails[0]?.info_accommodation[0]?.accomodation_town || ""
  );
  const [infoAccommodationEmail, setInfoAccommodationEmail] = useState(
    eventDetails[0]?.info_accommodation[0]?.accomodation_email || ""
  );
  const [infoAccommodationPhone, setInfoAccommodationPhone] = useState(
    eventDetails[0]?.info_accommodation[0]?.accomodation_phone || ""
  );
  const [infoAccommodationOther, setInfoAccommodationOther] = useState(
    eventDetails[0]?.info_accommodation[0]?.accomodation_other_information || ""
  );

  const [file, setFile] = useState("");
  const [value1, setValue1] = useState({
    value: "",
    label: eventDetails[0]?.event_country || "",
  });
  const [value2, setValue2] = useState({
    value: "",
    label: eventDetails[0]?.info_organizer[0]?.organizer_country || "",
  });
  const [value3, setValue3] = useState({
    value: "",
    label: eventDetails[0]?.info_accommodation[0]?.accomodation_country || "",
  });

  var german = require("localized-countries")(
    require("localized-countries/data/de")
  );
  const options = german.array();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [personList, setPersonList] = useState([]);
  const [bandStaffList, setBandStaffList] = useState([]);
  const [localPerson, setLocalPerson] = useState(
    eventDetails[0]?.band_users_details || ""
  );
  const [localBandPerson, setLocalBandPerson] = useState(
    eventDetails[0]?.band_staff_details || ""
  );

  const [driverList, setDriverList] = useState([
    { driver: "", vehicle: "", available_seats: "", passengers: [] },
  ]);
  const { allSetlistSongs, loading } = useSelector(
    (state) => state.setlistsong
  );
  const [eventType, setEventType] = useState(
    eventDetails[0]?.type_of_event || ""
  );
  const [performanceType, setPerformanceType] = useState(
    eventDetails[0]?.type_of_performance || ""
  );

  const [bandmember, setBandMember] = useState([]);
  const [bandmember2, setBandMember2] = useState([]);
  const [cancelledInvitation, setCancelledInvitation] = useState([]);
  const [disabled, setDisabled] = useState([]);
  const [disabledstaff, setDisabledstaff] = useState([]);
  const [showPassengerModal, setShowPassengerModal] = useState(false);
  const [showPassengerDetailModal, setShowPassengerDetailModal] =
    useState(false);
  const [transportDelete, setTransportDelete] = useState(false);
  const [transportId, setTransportId] = useState("");
  const [vehicle, setVehicle] = useState(false);
  const [OutgoingInvoiceFile, setOutgoingInvoiceFile] = useState(false);
  const [incomingInvoiceFile, setIncomingInvoiceFile] = useState(false);
  const [incomingContractFile, setIncomingContractFile] = useState(false);
  const [documentBookerFile, setDocumentBookerFile] = useState(false);
  const [documentSoundFile, setDocumentSoundFile] = useState(false);
  const [otherFile, setOtherFile] = useState(false);
  const [invitationShow, setInvitationShow] = useState(false);
  const { Permissions } = useSelector((state) => state.attachmentpermission);
  const [personSelectedList, setPersonSelectedList] = useState([]);
  const [passengerList, setPassengerList] = useState(false);
  const [vehicleId, setVehicleId] = useState("");
  const { permissionDetail } = useSelector((state) => state.permission);
  const [showData, setShowData] = useState("");
  const {
    tab1,
    tab2,
    tab3,
    tab70,
    tab5,
    tab6,
    tab7,
    tab8,
    tab9,
    tab10,
    tab11,
    tab12,
    tab13,
    tab14,
    tab15,
    tab16,
    tab17,
    tab18,
    tab19,
    tab20,
    tab21,
    tab22,
    tab23,
    tab24,
    tab25,
    tab26,
    tab27,
    tab28,
    tab29,
    tab30,
    tab31,
    tab32,
    tab33,
    tab34,
    tab35,
    tab36,
    tab43,
    tab44,
    tab45,
    tab46,
    tab47,
    tab48,
    tab49,
    tab51,
    tab60,
    tab52,
    tab61,
    tab62,
    tab66,
    tab67,
    tab68,
    tab71,
    tab72,
    tab73,
    tab74,
    tab75,
    tab76,
    tab77,
  } = t("event");

  useEffect(() => {
    getEvents();
    getBandMembers();
    getBandMembersTwo();
    getCancelledInvitations();
    dispatch(getSongs({ id: "0" }));
    dispatch(getAttachmentPermission({ id: eventDetails[0]?.event_id }));
    dispatch(getPermissionInfo({ id: cookee.get("redirect_userid") }));
  }, []);

  const handleShowOutgoingInvoiceFile = () => {
    setOutgoingInvoiceFile(!OutgoingInvoiceFile);
  };
  const handleOutgoingfiles = (e) => {
    e.preventDefault();
    handleShowOutgoingInvoiceFile();
  };
  const handleShowIncomingInvoiceFile = () => {
    setIncomingInvoiceFile(!incomingInvoiceFile);
  };
  const handleIncomingfiles = (e) => {
    e.preventDefault();
    handleShowIncomingInvoiceFile();
  };
  const handleShowIncomingContractFile = () => {
    setIncomingContractFile(!incomingContractFile);
  };
  const handleShowInvitation = () => {
    setInvitationShow(!invitationShow);
  };
  const handleContractfiles = (e) => {
    e.preventDefault();
    handleShowIncomingContractFile();
  };
  const handleShowDocumentBookerFile = () => {
    setDocumentBookerFile(!documentBookerFile);
  };
  const handleBookerfiles = (e) => {
    e.preventDefault();
    handleShowDocumentBookerFile();
  };
  const handleShowDocumentSoundFile = () => {
    setDocumentSoundFile(!documentSoundFile);
  };
  const handleSoundfiles = (e) => {
    e.preventDefault();
    handleShowDocumentSoundFile();
  };
  const handleShowOtherFile = () => {
    setOtherFile(!otherFile);
  };
  const handleOtherfiles = (e) => {
    e.preventDefault();
    handleShowOtherFile();
  };
  const showSetlist = () => {
    setSelectSetlist(!selectSetlist);
  };
  const handleShowPassenger = () => {
    setShowPassengerModal(!showPassengerModal);
  };
  const handleShowPassengerDetail = () => {
    setShowPassengerDetailModal(!showPassengerDetailModal);
  };
  const handleShowTransportDelete = (id) => {
    setTransportId(id);
    setTransportDelete(!transportDelete);
  };
  const handleEventType = (e) => {
    setEventType(e.target.value);
  };
  const handlePerformanceType = (e) => {
    setPerformanceType(e.target.value);
  };

  const handleAddVehicle = () => {
    setVehicle(true);
  };
  const handleShowCancel = () => {
    setShowCancelModal(!showCancelModal);
  };
  const handleCancelEvent = () => {
    handleShowCancel();
  };
  const handlePassengers = (id) => {
    setTransportId(id);
    handleShowPassenger();
  };

  const handleShowPassengers = (i) => {
    setVehicleId(i);
    setPassengerList(!passengerList);
  };

  const handleRemove = (index) => {
    const list = [...driverList];
    list.splice(index, 1);
    setDriverList(list);
  };
  const getEvents = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      data: {
        page_no: 10,
      },
      url: `${process.env.REACT_APP_URL}/get-all-musicians-list`,
    }).then((res) => {
      // console.log(res.data)
      setBandPerson(res.data.data);
    });
  };
  const getBandMembers = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-staff-members-with-membership`,
      data: {
        page_no: 1,
        is_staff: 0,
        event_id: eventDetails[0]?.event_id,
      },
    }).then((res) => {
      // console.log(res.data)
      setBandMember(res.data.data);
    });
  };
  const getBandMembersTwo = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-staff-members-with-membership`,
      data: {
        page_no: 1,
        is_staff: 1,
        event_id: eventDetails[0]?.event_id,
      },
    }).then((res) => {
      // console.log(res.data)
      setBandMember2(res.data.data);
    });
  };
  const getCancelledInvitations = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-all-event-invitations-by-event-id`,
      data: {
        event_id: eventDetails[0]?.event_id || "",
        is_accepted: 0,
        is_rejected: 1,
      },
    }).then((res) => {
      // console.log(res.data)
      setCancelledInvitation(res.data.data);
    });
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...driverList];
    list[index][name] = value;
    setDriverList(list);
  };

  const handleAddAttachment = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/add-attachment-to-event`,
      data: {
        event_id: eventDetails[0]?.event_id || "",
        event_type: 1,
        event_attachments: [
          {
            attachment_type: 1,
            attachment_url: file1 ? file1 : "",
          },
          {
            attachment_type: 2,
            attachment_url: file2 ? file2 : "",
          },
          {
            attachment_type: 3,
            attachment_url: file3 ? file3 : "",
          },
          {
            attachment_type: 4,
            attachment_url: file4 ? file4 : "",
          },
          {
            attachment_type: 5,
            attachment_url: file5 ? file5 : "",
          },
          {
            attachment_type: 6,
            attachment_url: file6 ? file6 : "",
          },
        ],
      },
    }).then((res) => {
      //  console.log("RESPONSE", res.data)
      if (res.data.message === "Attachements Added Successfully!.") {
        alert("Added!!");
        dispatch(getEventDetail({ eventid: eventDetails[0]?.event_id || "" }));
      }
    });
  };

  const handleInputBand = (e, i, index) => {
    const { name, value } = e.target;
    const list = [...bandStaffList];
    list[i][name] = value;
    setBandStaffList(list);
  };

  const handleAddClick = () => {
    setDriverList([
      ...driverList,
      { driver: "", vehicle: "", available_seats: "", passengers: [] },
    ]);
  };
  const handleRemoveStaffLocal = (i, id) => {
    const list = [...localBandPerson];
    list.splice(i, 1);
    setLocalBandPerson(list);
    handleDeleteStaff(id);
  };
  const handleDeleteStaff = (id) => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/delete-musicians-from-event`,
      data: {
        event_id: eventDetails[0]?.event_id || "",
        id: "",
        staff_id: id,
      },
    }).then((res) => {
      //  console.log("RESPONSE", res.data)
      if (res.data.message === "Member Deleted Successfully!.") {
        toast.success("Deleted", {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(getEventDetail({ eventid: eventDetails[0]?.event_id || "" }));
        getBandMembers();
        getBandMembersTwo();
      }
    });
  };
  const handleRemoveStaff = (i, index) => {
    const list = [...bandStaffList];
    list.splice(i, 1);
    setBandStaffList(list);
  };

  const handleSendInvite = (id, i) => {
    // setDisabled([...disabled, i])
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/band-leader-event-send-invite-musician`,
      data: {
        event_id: eventDetails[0]?.event_id || "",
        user_id: id,
      },
    }).then((res) => {
      //  console.log("RESPONSE", res.data)
      if (res.data.message === "Invitation Sent Successfully!.") {
        toast.success("Invitation Sent", {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(getEventDetail({ eventid: eventDetails[0]?.event_id || "" }));
        setLocalPerson(eventDetails[0]?.band_users_details);
      }
    });
  };

  const handleSendInviteBandstaff = (id, i) => {
    // setDisabledstaff([...disabledstaff, i])
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/band-leader-event-send-invite-staff-member`,
      data: {
        event_id: eventDetails[0]?.event_id || "",
        staff_id: id,
      },
    }).then((res) => {
      //  console.log("RESPONSE", res.data)
      if (res.data.message === "Invitation Sent Successfully!.") {
        toast.success("Invitation Sent", {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(getEventDetail({ eventid: eventDetails[0]?.event_id || "" }));
      }
    });
  };
  const handleAddTransport = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/add-transport-to-event`,
      data: {
        event_id: eventDetails[0]?.event_id || "",
        event_type: 1,
        transport: driverList,
      },
    }).then((res) => {
      //  console.log("RESPONSE", res.data)
      if (res.data.message === "Transport Added Successfully!.") {
        toast.success("Transport Added", {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTransport(false);
        setBand(false);
        setInfo(false);
        setSetList(true);
        setAttachemnt(false);
      }
    });
  };

  const handleAddMusicians = (e) => {
    e.preventDefault();
    if (personList[0]?.role !== "" || bandStaffList[0]?.role !== "") {
      axios({
        method: "post",
        headers: {
          Accept: "/",
          "Content-Type": "application/json",
          auth_token: token,
        },
        url: `${process.env.REACT_APP_URL}/add-musicians-to-event`,
        data: {
          event_id: eventDetails[0]?.event_id || "",
          event_type: 1,
          person: personList,
          band_staff: bandStaffList?.length === 0 ? [] : bandStaffList,
        },
      }).then((res) => {
        //  console.log("RESPONSE", res.data)
        if (res.data.message === "Musician Added Successfully!.") {
          toast.success("Musician Added", {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // dispatch(getEventDetail({ eventid: eventDetails[0]?.event_id || "" }))
          handleShowInvitation();
          setTransport(true);
          setBand(false);
          setInfo(false);
          setSetList(false);
          setAttachemnt(false);
        }
      });
    } else {
      setTransport(true);
      setBand(false);
      setInfo(false);
      setSetList(false);
      setAttachemnt(false);
    }
  };
  const changeHandler = (value) => {
    setValue1(value);
  };
  const changeHandler1 = (value) => {
    setValue2(value);
  };
  const changeHandler2 = (value) => {
    setValue3(value);
  };
  const handleAddBandStaff = () => {
    setAddBandStaff(true);
  };

  const handleInfo = () => {
    setInfo(true);
    setBand(false);
    setTransport(false);
    setSetList(false);
    setAttachemnt(false);
  };
  const handleBand = () => {
    setBand(true);
    setInfo(false);
    setTransport(false);
    setSetList(false);
    setAttachemnt(false);
  };

  const showDelete = () => {
    // setSetlistId("")
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/delete-band-leader-event-setlist`,
      data: {
        event_id: eventDetails?.[0]?.event_id || "",
        event_type: 1,
        set_list_id: eventDetails?.[0]?.set_list_id || "",
      },
    }).then((res) => {
      if (res.data.message === "Event SetList Deleted Successfully!.") {
        toast.success("Deleted Setlist", {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(getEventDetail({ eventid: eventDetails[0]?.event_id || "" }));
      }
    });
  };
  const handleEventInfo = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/update-band-leader-event-info`,
      data: {
        user_id: permissionDetail?.user_id,
        event_id: eventDetails[0]?.event_id || "",
        event_name: eventName,
        info_organizer: [
          {
            event_organizer_id: organizerId,
            organizer_name: infoOrganizerName,
            organizer_street: infoOrganizerStreet,
            organizer_house_number: infoOrganizerHouseNo,
            organizer_postal: infoOrganizerPostal,
            organizer_town: infoOrganizerTown,
            contact_person_name: infoOrganizerNameContact,
            email: infoOrganizerEmail,
            phone_number: infoOrganizerPhone,
            gema_customer_number: infoOrganizerGemaNo,
            organizer_country: value2.label,
            organizer_other_information: infoOrganizerInfo,
          },
        ],
        info_accommodation: [
          {
            event_accomodation_id: accomodationId,
            accomodation_name: infoAccommodationName,
            accomodation_street: infoAccommodationStreet,
            accomodation_house_number: infoAccommodationHouseNo,
            accomodation_postal: infoAccommodationPostal,
            accomodation_town: infoAccommodationTown,
            accomodation_country: value3.label,
            accomodation_phone_number: infoAccommodationPhone,
            accomodation_email: infoAccommodationEmail,
            accomodation_other_information: infoAccommodationOther,
          },
        ],
        start_time: getInStart,
        end_time: "20:00:00",
        load_start_time: loadStart,
        load_end_time: loadEnd,
        sound_start_time: soundStart,
        sound_end_time: soundEnd,
        stage_start_time: stageStart,
        stage_end_time: stageEnd,
        type_of_event: eventType,
        type_of_performance: performanceType,
        start_date: date,
        latitude: 15,
        longitude: 109.77,
        location_name: locationName,
        location_street: locationStreet,
        loc_name_of_person: locationContactPerson,
        location_email: locationEmail,
        location_phone_number: locationPhone,
        location_other_information: locationOtherInfo,
        town: locationTown,
        event_country: value1.label,
        event_zip_code: locationPostal,
        event_house_number: locationHouseNo,
        event_other_info: eventOtherInfo,
        event_type: 1,
        parking: parking,
        guest_list: guestList,
        catering: catering,
        sonstige_other_info: sonitageInfo,
        total_number_of_works: 0,
        number_of_works_with_copyright: 0,
        length_set: "00:00:00",
        is_band:
          permissionDetail?.permissionDetails?.permission_type == 5 ? 0 : 1,
      },
    }).then((res) => {
      //  console.log("RESPONSE", res.data)
      if (res.data.message === "Event Info Updated Successfully!.") {
        toast.success("Info Updated.", {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(getEventDetail({ eventid: eventDetails[0]?.event_id || "" }));
        //    setEvent_Id(res.data.data.event_id)
        if (permissionDetail?.permissionDetails?.permission_type === 5) {
          navigate("/events");
        }
        setBand(true);
        setInfo(false);
        setTransport(false);
        setSetList(false);
        setAttachemnt(false);
      }
    });
  };

  const handleAddSetlist = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/add-setlist-to-event`,
      data: {
        event_id: eventDetails[0]?.event_id || "",
        event_type: 1,
        set_list: [
          {
            set_list_id: setlistId,
          },
        ],
      },
    }).then((res) => {
      //  console.log("RESPONSE", res.data)
      if (res.data.message === "SetList Added Successfully!.") {
        toast.success("Setlist Added", {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTransport(false);
        setBand(false);
        setInfo(false);
        setSetList(false);
        setAttachemnt(true);
      }
    });
  };
  const handleTransport = () => {
    setTransport(true);
    setBand(false);
    setInfo(false);
    setSetList(false);
    setAttachemnt(false);
  };
  const handleSetlist = () => {
    setSetList(true);
    setBand(false);
    setInfo(false);
    setTransport(false);
    setAttachemnt(false);
  };
  const handleAttachment = () => {
    setAttachemnt(true);
    setBand(false);
    setInfo(false);
    setTransport(false);
    setSetList(false);
  };
  const handleChange = (e, setstate) => {
    setstate(e.target.value);
  };

  const handleInputPerson = (e, i, index) => {
    const { name, value } = e.target;
    const list = [...personList];
    if (name === "role") {
      list[i][name] = value;
    } else {
      list[i].person[index][name] = value;
    }
    setPersonList(list);
    setPersonSelectedList([...personSelectedList, e.target.value]);
  };

  const handleAddBandPerson = () => {
    setBandStaffList([
      ...bandStaffList,
      { user_id: "", amount: "", role: "", description: "" },
    ]);
  };
  const handleRemovePerson = (index) => {
    const list = [...personList];
    list.splice(index, 1);
    setPersonList(list);
  };
  const handleRemovePersonLocal = (index, id) => {
    const list = [...localPerson];
    list.splice(index, 1);
    setLocalPerson(list);
    handleDeletePerson(id);
  };

  const handleDeletePerson = (id) => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/delete-musicians-from-event`,
      data: {
        event_id: eventDetails[0]?.event_id || "",
        id: id,
        staff_id: "",
      },
    }).then((res) => {
      //  console.log("RESPONSE", res.data)
      if (res.data.message === "Member Deleted Successfully!.") {
        toast.success("Deleted", {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(getEventDetail({ eventid: eventDetails[0]?.event_id || "" }));
        getBandMembers();
        getBandMembersTwo();
      }
    });
  };

  const handleAddPerson = () => {
    // setPersonList([...personList, { user_id: "", role: "", priority: "", amount: "", additional_information: "" }])
    setPriorityCount(2);
    setPersonList([
      ...personList,
      {
        role: "",
        person: [
          { user_id: "", priority: 1, amount: "", additional_information: "" },
        ],
      },
    ]);
  };
  const handleAddPriority = (e, role, i) => {
    setPriorityCount(priorityCount + 1);
    const { name, value } = e.target;
    // setPriorityShow(true)
    // setIdentity(i)
    let list = [...personList];
    list[i].person = [
      ...personList[i].person,
      {
        user_id: "",
        priority: priorityCount,
        amount: "",
        additional_information: "",
      },
    ];

    // setPersonList([ { person: [{ user_id: "", priority: "", amount: "", additional_information: "" }] }])
    setPersonList(list);
  };
  const handleAddPriorityLocal = (e, role, i) => {
    setPriorityCount(priorityCount + 1);
    const { name, value } = e.target;
    // setPriorityShow(true)
    // setIdentity(i)
    console.log("0", i);
    let list = [...localPerson];
    list = [
      ...localPerson,
      {
        user_id: "",
        priority: priorityCount,
        amount: "",
        additional_information: "",
      },
    ];

    // setPersonList([ { person: [{ user_id: "", priority: "", amount: "", additional_information: "" }] }])
    setLocalPerson(list);
  };
  const token = cookee.get("token");

  const handleChat = () => {
    navigate("/chat-event", {
      state: { eventid: eventDetails[0]?.event_id || "" },
    });
  };

  const handlePrint = () => {
    navigate("/event-pdf-concert", {
      state: { eventid: eventDetails[0]?.event_id || "" },
    });
  };
  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row mt-3 align-items-center">
            <div className="col-12 col-sm-9 col-md-9 col-lg-10">
              <ul
                className="nav nav-pills group-nav tabNabs"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <Link
                    to="#"
                    className={info ? "nav-link  active" : "nav-link"}
                    onClick={handleInfo}
                  >
                    information
                  </Link>
                </li>
                {permissionDetail?.permissionDetails?.permission_type == 5 ? (
                  ""
                ) : (
                  <>
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={band ? "nav-link  active" : "nav-link"}
                        onClick={handleBand}
                      >
                        band
                      </Link>
                    </li>
                    {eventDetails[0]?.event_type === 1 ? (
                      <>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={
                              transport ? "nav-link  active" : "nav-link"
                            }
                            onClick={handleTransport}
                          >
                            transport
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={
                              setlist ? "nav-link  active" : "nav-link"
                            }
                            onClick={handleSetlist}
                          >
                            setlist
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={
                              attachment ? "nav-link  active" : "nav-link"
                            }
                            onClick={handleAttachment}
                          >
                            {tab9}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </ul>
            </div>

            <div class="col-12 col-sm-3 col-md-3 col-lg-2">
              <div class="d-flex align-items-center lg-justify-content-end">
                <button class="btn notiIcon filterIcon" onClick={handlePrint}>
                  <img src="assets/images/printing.png" alt="" />
                </button>
                <button
                  className="btn notiIcon filterIcon"
                  onClick={handleChat}
                >
                  <img src="assets/images/chatBlackRed.png" alt="" />
                </button>
              </div>
            </div>

            {info ? (
              <div className="col-sm-12">
                <div className="pt-3 px-1 bg-white">
                  <div className="table-responsive">
                    <table className="table storetable infoEventTable">
                      <thead className="">
                        <tr>
                          <th
                            scope="col"
                            title={
                              lng === "/auto/de" || "/en/de"
                                ? "Zum erstellen einer Veranstaltung, müssen Sie den Namen und Datum eintragen. Alle weiteren Informationen können Sie zu einem späteren Zeitpunkt ergänzen. Den zu der Veranstaltung eingeladenen Bandmitglieder werden diese Informationen angezeigt."
                                : "To create an event, you must enter the name and date. You can add any further information at a later date. Band members invited to the event will see this information."
                            }
                          >
                            {lng === "/auto/de" || "/en/de"
                              ? "Info-Veranstaltung"
                              : "info event"}
                            <img
                              src="assets/images/Infoblack.png"
                              alt=""
                              className="mx-1 mb-4"
                            />
                          </th>
                          <th
                            scope="col"
                            title={
                              lng === "/auto/de" || "/en/de"
                                ? "Den zu der Veranstaltung eingeladenen Personen werden diese Informationen angezeigt. Ausgenommen hiervon sind der Name der Kontaktperson, Emailadresse sowie Telefon."
                                : "The people invited to the event will see this information. Exceptions to this are the name of the contact person, email address and telephone."
                            }
                          >
                            info location{" "}
                            <img
                              src="assets/images/Infoblack.png"
                              alt=""
                              className="mx-1 mb-4"
                            />
                          </th>
                          <th
                            scope="col"
                            title={
                              lng === "/auto/de" || "/en/de"
                                ? "Den zu der Veranstaltung eingeladenen Personen werden diese Informationen NICHT angezeigt."
                                : "This information will NOT be visible to those invited to the event."
                            }
                          >
                            info organizer
                            <img
                              src="assets/images/Infoblack.png"
                              alt=""
                              className="mx-1 mb-4"
                            />
                          </th>
                          <th
                            scope="col"
                            title={
                              lng === "/auto/de" || "/en/de"
                                ? "Den zu der Veranstaltung eingeladenen Personen werden diese Informationen angezeigt"
                                : "The people invited to the event will see this information"
                            }
                          >
                            {" "}
                            info accommodation
                            <img
                              src="assets/images/Infoblack.png"
                              alt=""
                              className="mx-1 mb-4"
                            />
                          </th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="form-group mb-3">
                              <label className="header-label">Name</label>
                              <div className="input-container">
                                <input
                                  type="text"
                                  value={eventName}
                                  onChange={(e) =>
                                    handleChange(e, setEventName)
                                  }
                                  className="form-control p-0"
                                  placeholder="Name"
                                  name="confirmpassword"
                                  style={{ height: "40px" }}
                                />
                              </div>
                            </div>
                            <div className="form-group mb-3">
                              <label className="header-label">{tab5}</label>
                              <div className="input-container">
                                <input
                                  type="date"
                                  value={date}
                                  onChange={(e) => handleChange(e, setDate)}
                                  className="form-control p-0"
                                  placeholder={tab5}
                                  name="confirmpassword"
                                  style={{ height: "40px" }}
                                />
                              </div>
                            </div>

                            <p className="'notranslate header-label">
                              Get in :
                              <div className="d-flex justify-content-between">
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={getInStart}
                                  onChange={(e) =>
                                    handleChange(e, setGetInStart)
                                  }
                                  placeholder="start"
                                  style={{ height: "40px", width: "100px" }}
                                />{" "}
                              </div>
                            </p>

                            <p className="notranslate header-label">
                              Load :{" "}
                              <div className="d-flex justify-content-between">
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={loadStart}
                                  onChange={(e) =>
                                    handleChange(e, setLoadStart)
                                  }
                                  placeholder="start"
                                  style={{ height: "40px", width: "100px" }}
                                />{" "}
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={loadEnd}
                                  onChange={(e) => handleChange(e, setLoadEnd)}
                                  placeholder="end"
                                  style={{ height: "40px", width: "100px" }}
                                />
                              </div>
                            </p>

                            <p className="notranslate header-label">
                              Soundcheck:{" "}
                              <div className="d-flex justify-content-between">
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={soundStart}
                                  onChange={(e) =>
                                    handleChange(e, setSoundStart)
                                  }
                                  placeholder="start"
                                  style={{ height: "40px", width: "100px" }}
                                />{" "}
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={soundEnd}
                                  onChange={(e) => handleChange(e, setSoundEnd)}
                                  placeholder="end"
                                  style={{ height: "40px", width: "100px" }}
                                />
                              </div>
                            </p>

                            <p className="notranslate header-label">
                              Stagetime:{" "}
                              <div className="d-flex justify-content-between">
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={stageStart}
                                  onChange={(e) =>
                                    handleChange(e, setStageStart)
                                  }
                                  placeholder="start"
                                  style={{ height: "40px", width: "100px" }}
                                />{" "}
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={stageEnd}
                                  onChange={(e) => handleChange(e, setStageEnd)}
                                  placeholder="end"
                                  style={{ height: "40px", width: "100px" }}
                                />
                              </div>
                            </p>

                            <p>
                              <label className="header-label">{tab6}</label>
                              <select
                                className="form-control p-0 notranslate"
                                value={eventType}
                                onChange={(e) => handleEventType(e)}
                                style={{ height: "40px" }}
                              >
                                <option value="Fasching/Karneval">
                                  Fasching/Karneval
                                </option>
                                <option value="Fest-/Straßenumzüge">
                                  Fest-/Straßenumzüge
                                </option>
                                <option value="Hintergrundmusik">
                                  Hintergrundmusik
                                </option>
                                <option value="Kabarett">Kabarett</option>
                                <option value=" Klassisches Konzert">
                                  {" "}
                                  Klassisches Konzert
                                </option>
                                <option value="Konzert">Konzert</option>
                                <option value="OpenAir Konzert">
                                  OpenAir Konzert
                                </option>
                                <option value="Party/Feier">Party/Feier</option>
                                <option value="Sonstiges">Sonstiges</option>
                                <option value="Tanz/Bälle">Tanz/Bälle</option>
                                <option value="Bühnen-/Theatermusik">
                                  Bühnen-/Theatermusik
                                </option>
                              </select>
                            </p>
                            <p>
                              <label className="header-label">
                                {lng === "/auto/de" || "/en/de"
                                  ? "Art der Aufführung"
                                  : "type of performance"}
                              </label>
                              <select
                                className="form-control p-0 notranslate"
                                value={performanceType}
                                onChange={(e) => handlePerformanceType(e)}
                                style={{ height: "40px", width: "250px" }}
                              >
                                <option>{tab44}</option>
                                <option value="Hauptgruppe">Hauptgruppe</option>
                                <option value="Vorgruppe">Vorgruppe</option>
                                <option value="Alleinige Band / Esemble">
                                  Alleinige Band
                                </option>
                                <option value="Mehrer gleichberechtigte Bands / Künstler">
                                  Mehrer gleichberechtigte Bands / Künstler
                                </option>
                                <option value="DJ-Live Act">DJ-Live Act</option>
                                <option value="Bühnen-/Theateraufführung">
                                  Theateraufführung
                                </option>
                              </select>
                            </p>
                            <p>
                              <label className="header-label">
                                Information
                              </label>
                              <textarea
                                className="form-control p-0"
                                placeholder={tab45}
                                value={eventOtherInfo}
                                onChange={(e) =>
                                  handleChange(e, setEventOtherInfo)
                                }
                                style={{ height: "100px" }}
                              />
                            </p>
                          </td>
                          <td>
                            <p>
                              <label className="header-label">Name</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={locationName}
                                onChange={(e) =>
                                  handleChange(e, setLocationName)
                                }
                                placeholder="Name"
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab16}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={locationStreet}
                                onChange={(e) =>
                                  handleChange(e, setLocationStreet)
                                }
                                placeholder={tab16}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab17}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={locationHouseNo}
                                onChange={(e) =>
                                  handleChange(e, setLocationHouseNo)
                                }
                                placeholder={tab17}
                                style={{ height: "40px" }}
                              />
                            </p>

                            <p>
                              <label className="header-label">{tab18}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={locationPostal}
                                onChange={(e) =>
                                  handleChange(e, setLocationPostal)
                                }
                                placeholder={tab18}
                                style={{ height: "40px" }}
                              />{" "}
                            </p>
                            <p>
                              <label className="header-label">{tab8}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={locationTown}
                                onChange={(e) =>
                                  handleChange(e, setLocationTown)
                                }
                                placeholder={tab8}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label notranslate">
                                Land
                              </label>
                              <Select
                                className="form-control p-0"
                                options={options}
                                value={value1}
                                placeholder="Country"
                                onChange={changeHandler}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab43}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={locationContactPerson}
                                onChange={(e) =>
                                  handleChange(e, setLocationContactPerson)
                                }
                                placeholder={tab43}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">Email</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={locationEmail}
                                onChange={(e) =>
                                  handleChange(e, setLocationEmail)
                                }
                                placeholder="Email"
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab60}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={locationPhone}
                                onChange={(e) =>
                                  handleChange(e, setLocationPhone)
                                }
                                placeholder={tab60}
                                style={{ height: "40px" }}
                              />
                            </p>

                            <p>
                              <label className="header-label">
                                Information
                              </label>
                              <textarea
                                className="form-control p-0"
                                value={locationOtherInfo}
                                onChange={(e) =>
                                  handleChange(e, setLocationOtherInfo)
                                }
                                placeholder={tab45}
                                style={{ height: "150px" }}
                              />
                            </p>
                          </td>
                          <td>
                            <p>
                              <label className="header-label">Name</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoOrganizerName}
                                onChange={(e) =>
                                  handleChange(e, setInfoOrganizerName)
                                }
                                placeholder="Name"
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab16}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoOrganizerStreet}
                                onChange={(e) =>
                                  handleChange(e, setInfoOrganizerStreet)
                                }
                                placeholder={tab16}
                                style={{ height: "40px" }}
                              />
                            </p>

                            <p>
                              <label className="header-label">{tab17}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoOrganizerHouseNo}
                                onChange={(e) =>
                                  handleChange(e, setInfoOrganizerHouseNo)
                                }
                                placeholder={tab17}
                                style={{ height: "40px" }}
                              />
                            </p>

                            <p>
                              <label className="header-label">{tab18}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                placeholder={tab18}
                                value={infoOrganizerPostal}
                                onChange={(e) =>
                                  handleChange(e, setInfoOrganizerPostal)
                                }
                                style={{ height: "40px" }}
                              />{" "}
                            </p>
                            <p>
                              <label className="header-label">{tab8}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoOrganizerTown}
                                onChange={(e) =>
                                  handleChange(e, setInfoOrganizerTown)
                                }
                                placeholder={tab8}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label notranslate">
                                Land
                              </label>
                              <Select
                                className="form-control p-0 notranslate"
                                options={options}
                                value={value2}
                                placeholder="Land"
                                onChange={changeHandler1}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab43}</label>{" "}
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoOrganizerNameContact}
                                onChange={(e) =>
                                  handleChange(e, setInfoOrganizerNameContact)
                                }
                                placeholder={tab43}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">Email</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoOrganizerEmail}
                                onChange={(e) =>
                                  handleChange(e, setInfoOrganizerEmail)
                                }
                                placeholder="Email"
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab60}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoOrganizerPhone}
                                onChange={(e) =>
                                  handleChange(e, setInfoOrganizerPhone)
                                }
                                placeholder={tab60}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">
                                {lng === "/auto/de" || "/en/de"
                                  ? "GEMA Kundennummer Veranstalter"
                                  : "Gema no organizer"}
                              </label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoOrganizerGemaNo}
                                onChange={(e) =>
                                  handleChange(e, setInfoOrganizerGemaNo)
                                }
                                placeholder="Gema no"
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">
                                Information
                              </label>
                              <textarea
                                className="form-control p-0"
                                placeholder={tab45}
                                value={infoOrganizerInfo}
                                onChange={(e) =>
                                  handleChange(e, setInfoOrganizerInfo)
                                }
                                style={{ height: "100px" }}
                              />
                            </p>
                          </td>
                          <td>
                            <label className="header-label">Name</label>
                            <p>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoAccommodationName}
                                onChange={(e) =>
                                  handleChange(e, setInfoAccommodationName)
                                }
                                placeholder="Name"
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab16}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoAccommodationStreet}
                                onChange={(e) =>
                                  handleChange(e, setInfoAccommodationStreet)
                                }
                                placeholder={tab16}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab17}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoAccommodationHouseNo}
                                onChange={(e) =>
                                  handleChange(e, setInfoAccommodationHouseNo)
                                }
                                placeholder={tab17}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab18}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoAccommodationPostal}
                                onChange={(e) =>
                                  handleChange(e, setInfoAccommodationPostal)
                                }
                                placeholder={tab18}
                                style={{ height: "40px" }}
                              />{" "}
                            </p>
                            <p>
                              <label className="header-label">{tab8}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoAccommodationTown}
                                onChange={(e) =>
                                  handleChange(e, setInfoAccommodationTown)
                                }
                                placeholder={tab8}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">Land</label>{" "}
                              <Select
                                className="form-control p-0 notranslate"
                                options={options}
                                value={value3}
                                placeholder="Land"
                                onChange={changeHandler2}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab60}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoAccommodationPhone}
                                onChange={(e) =>
                                  handleChange(e, setInfoAccommodationPhone)
                                }
                                placeholder={tab60}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">Email</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoAccommodationEmail}
                                onChange={(e) =>
                                  handleChange(e, setInfoAccommodationEmail)
                                }
                                placeholder="Email"
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">
                                Information
                              </label>
                              <textarea
                                className="form-control p-0"
                                value={infoAccommodationOther}
                                onChange={(e) =>
                                  handleChange(e, setInfoAccommodationOther)
                                }
                                placeholder={tab45}
                                style={{ height: "80px" }}
                              />
                            </p>
                            <p>
                              <b
                                title={
                                  lng === "/auto/de" || "/en/de"
                                    ? "Den zu der Veranstaltung eingeladenen Personen werden diese Informationen angezeigt"
                                    : "The people invited to the event will see this information"
                                }
                              >
                                sonstige info
                                <img
                                  src="assets/images/Infoblack.png"
                                  alt=""
                                  className="mx-1 mb-4"
                                />
                              </b>
                            </p>
                            <p>
                              {" "}
                              <label className="header-label">{tab20}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={parking}
                                onChange={(e) => handleChange(e, setParking)}
                                placeholder={tab20}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab21}</label>

                              <input
                                type="text"
                                className="form-control p-0"
                                value={guestList}
                                onChange={(e) => handleChange(e, setGuestList)}
                                placeholder="Guest list"
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p className="notranslate">
                              <label className="header-label">Catering</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={catering}
                                onChange={(e) => handleChange(e, setCatering)}
                                placeholder="Catering"
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">
                                Information
                              </label>
                              <textarea
                                className="form-control p-0"
                                placeholder={tab45}
                                value={sonitageInfo}
                                onChange={(e) =>
                                  handleChange(e, setSonitageInfo)
                                }
                                style={{ height: "80px" }}
                              />
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex my-3">
                  <button className="btn add-new" onClick={handleCancelEvent}>
                    {tab71}
                  </button>
                </div>
                <div className="d-flex justify-content-end my-3">
                  <button className="btn add-new" onClick={handleEventInfo}>
                    {lng === "/auto/de" || "/en/de" ? "SPEICHERN" : "Safe"}
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}

            {band ? (
              <>
                <div className="col-sm-12">
                  <div className="pt-3 px-1 bg-white">
                    <div className="table-responsive">
                      <table className="table storetable">
                        <thead className="">
                          <tr>
                            <th
                              scope="col"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Benenen Sie hier die Rolle, in welcher Sie das Bandmitglied, für diese Veranstaltungen einladen möchten. Zum Beispiel Gesang, Bass, Gitarre usw. Die Rolle wir dem jeweiligen Bandmitglied angezeigt."
                                  : "Name the role in which you want to invite the band member for this events. For example, vocals, bass, guitar, etc. The role is displayed to each band member."
                              }
                            >
                              {tab22}
                              <img
                                src="assets/images/Infoblack.png"
                                alt=""
                                className="mx-1 mb-4"
                              />
                            </th>
                            <th
                              scope="col"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Wählen Sie die Person aus, welche Sie für diese Rolle einladen möchten. Jede Person kann nur für jeweils eine Rolle eingeladen werden."
                                  : "Select the person you want to invite for this role. Each person can only be invited to one role at a time."
                              }
                            >
                              Person
                              <img
                                src="assets/images/Infoblack.png"
                                alt=""
                                className="mx-1 mb-4"
                              />
                            </th>
                            <th
                              scope="col"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Sie können für eine Rolle mehrere Bandmitglieder nach priorisierung einladen, indem Sie mit dem Button „Musiker hinzufügen“ weitere Personen hinzufügen - dieses Bandmitglied wird nun mit Priorität 2 für diese Rolle angezeigt. Die Einladung wird vorerst nur an die Person mit der Priorisierung 1 versendet. Erst wenn diese Person die Terminanfrage ablehnt, erhält die Person mit Priorisierung 2 eine Einladung."
                                  : "You can invite several band members for a role by prioritizing them by adding more people with the Add musicians button - this band member is now displayed with priority 2 for this role. Initially, the invitation will only be sent to the person with priority 1. Only when this person rejects the appointment request does the person with priority 2 receive an invitation."
                              }
                            >
                              {tab46}
                              <img
                                src="assets/images/Infoblack.png"
                                alt=""
                                className="mx-1 mb-4"
                              />
                            </th>
                            <th
                              scope="col"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Geben Sie hier die Gage für dieses Bandmitglied ein. Diese Information ist nur für Sie und das jeweiligen Bandmitglied sichtbar."
                                  : "Enter the fee for this band member here. This information is only visible to you and the respective band member."
                              }
                            >
                              {tab23}
                              <img
                                src="assets/images/Infoblack.png"
                                alt=""
                                className="mx-1 mb-4"
                              />
                            </th>
                            <th
                              scope="col"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Hier haben Sie die Möglichkeit, weitere Informationen mit der Terminanfrage, an die jeweiligen Bandmitglieder zu versenden. Diese Information ist nur für Sie und das jeweilige Bandmitglied sichtbar."
                                  : "Here you have the opportunity to send further information with the appointment request to the respective band members. This information is only visible to you and the respective band member."
                              }
                            >
                              {tab45}
                              <img
                                src="assets/images/Infoblack.png"
                                alt=""
                                className="mx-1 mb-4"
                              />
                            </th>
                            <th scope="col">{tab52}</th>
                            <th scope=""></th>
                          </tr>
                          <tr className="border-0 bg-none invisible">
                            <td className="border-0 bg-none"></td>
                          </tr>
                        </thead>
                        <tbody>
                          {eventDetails[0]?.band_users_details?.length !== 0
                            ? eventDetails[0]?.band_users_details.map(
                                (res, i) => {
                                  return (
                                    <>
                                      {" "}
                                      <tr key={i}>
                                        {res?.role === "" || res?.role ? (
                                          <td className="white-space tdWidth">
                                            <p>
                                              <input
                                                type="text"
                                                className="form-control p-0"
                                                name="role"
                                                data-id="test"
                                                data-nested=""
                                                value={res.role}
                                                placeholder={tab22}
                                                style={{
                                                  height: "40px",
                                                  width: "250px",
                                                }}
                                              />
                                            </p>
                                          </td>
                                        ) : (
                                          <td>
                                            <p></p>
                                          </td>
                                        )}
                                        <td className="white-space tdWidth">
                                          <p>
                                            <input
                                              type="text"
                                              className="form-control p-0"
                                              name="role"
                                              data-id="test"
                                              data-nested=""
                                              value={res.first_name}
                                              placeholder={tab22}
                                              style={{
                                                height: "40px",
                                                width: "250px",
                                              }}
                                            />
                                          </p>
                                        </td>
                                        <td className="">
                                          <div className="d-flex">
                                            {" "}
                                            <input
                                              type="text"
                                              className="form-control p-0"
                                              name="priority"
                                              data-id="test"
                                              data-nested="nested"
                                              value={res.priority}
                                              placeholder="1"
                                              style={{
                                                height: "40px",
                                                width: "150px",
                                                border: "none",
                                              }}
                                            />{" "}
                                            <div className="">
                                              {/* <button className="btn add-new notiIcon filterIcon" style={{ width: "180px" }} onClick={(e) => handleAddPriorityLocal(e, res.role, i)}>add musician</button> */}
                                            </div>
                                          </div>
                                        </td>

                                        <td className="white-space tdWidth">
                                          <div>
                                            <input
                                              type="text"
                                              className="form-control p-0"
                                              name="amount"
                                              value={res.amount}
                                              placeholder="150 Euro"
                                              style={{
                                                height: "40px",
                                                width: "250px",
                                              }}
                                            />
                                          </div>
                                        </td>

                                        <td className="tdWidth">
                                          <div>
                                            {" "}
                                            <input
                                              type="text"
                                              className="form-control p-0"
                                              name="additional_information"
                                              value={res.additional_information}
                                              placeholder={tab45}
                                              style={{
                                                height: "40px",
                                                width: "250px",
                                              }}
                                            />
                                          </div>
                                        </td>
                                        <td
                                          onClick={() =>
                                            handleRemovePersonLocal(i, res.id)
                                          }
                                        >
                                          <img
                                            src="assets/images/delete.png"
                                            width="25px"
                                            alt=""
                                            style={{ cursor: "pointer" }}
                                          />
                                        </td>

                                        <td>
                                          {res.priority === 1 ? (
                                            <div className="d-flex justify-content-end my-3">
                                              {res.is_invited === 0 ? (
                                                <button
                                                  className="btn add-new"
                                                  disabled={
                                                    disabled.indexOf(i) !== -1
                                                  }
                                                  onClick={() =>
                                                    handleSendInvite(
                                                      res.user_id,
                                                      i
                                                    )
                                                  }
                                                  style={{ width: "200px" }}
                                                >
                                                  Send Invitation
                                                </button>
                                              ) : (
                                                ""
                                              )}
                                              {res.is_invited === 1 &&
                                              res.is_rejected === 0 &&
                                              res.is_accepted === 0 ? (
                                                <button
                                                  className="btn add-new"
                                                  style={{
                                                    width: "220px",
                                                    background: "orange",
                                                    border: "none",
                                                  }}
                                                >
                                                  {" "}
                                                  Invitation was Send
                                                </button>
                                              ) : (
                                                ""
                                              )}
                                              {res.is_accepted === 1 ? (
                                                <button
                                                  className="btn add-new"
                                                  style={{
                                                    width: "200px",
                                                    background: "green",
                                                    border: "none",
                                                  }}
                                                >
                                                  Confirmed
                                                </button>
                                              ) : (
                                                ""
                                              )}
                                              {res.is_rejected === 1 ? (
                                                <button
                                                  className="btn add-new"
                                                  style={{
                                                    width: "200px",
                                                    background: "black",
                                                    border: "none",
                                                  }}
                                                >
                                                  Cancelled
                                                </button>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          ) : (
                                            <div className="d-flex justify-content-end my-3">
                                              {res.is_invited === 1 &&
                                              res.is_rejected === 0 &&
                                              res.is_accepted === 0 ? (
                                                <button
                                                  className="btn add-new"
                                                  style={{
                                                    width: "220px",
                                                    background: "orange",
                                                    border: "none",
                                                  }}
                                                >
                                                  {" "}
                                                  Invitation was Send
                                                </button>
                                              ) : (
                                                ""
                                              )}
                                              {res.is_accepted === 1 ? (
                                                <button
                                                  className="btn add-new"
                                                  style={{
                                                    width: "200px",
                                                    background: "green",
                                                    border: "none",
                                                  }}
                                                >
                                                  Confirmed
                                                </button>
                                              ) : (
                                                ""
                                              )}
                                              {res.is_rejected === 1 ? (
                                                <button
                                                  className="btn add-new"
                                                  style={{
                                                    width: "200px",
                                                    background: "black",
                                                    border: "none",
                                                  }}
                                                >
                                                  Cancelled
                                                </button>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                }
                              )
                            : ""}

                          {personList.map((res, i) => {
                            return (
                              <>
                                {" "}
                                <tr key={i}>
                                  {res?.role === "" || res?.role ? (
                                    <td className="white-space tdWidth">
                                      <p>
                                        <input
                                          type="text"
                                          className="form-control p-0"
                                          name="role"
                                          data-id="test"
                                          data-nested=""
                                          value={res.role}
                                          onChange={(e) =>
                                            handleInputPerson(e, i)
                                          }
                                          placeholder={tab22}
                                          style={{
                                            height: "40px",
                                            width: "250px",
                                          }}
                                        />
                                      </p>
                                    </td>
                                  ) : (
                                    <td>
                                      <p></p>
                                    </td>
                                  )}
                                  {res.person.map((item, index) => {
                                    if (index === 0) {
                                      return (
                                        <>
                                          <td className="white-space tdWidth">
                                            <p>
                                              <select
                                                className="form-control p-0 notranslate"
                                                data-id="test"
                                                data-nested="nested"
                                                name="user_id"
                                                value={item.first_name}
                                                onChange={(e) =>
                                                  handleInputPerson(e, i, index)
                                                }
                                                style={{
                                                  height: "40px",
                                                  width: "250px",
                                                }}
                                              >
                                                <option>
                                                  {lng === "/auto/de" ||
                                                  "/en/de"
                                                    ? "--Auswählen--"
                                                    : "--Select--"}
                                                </option>
                                                {bandmember.map((res, key) => {
                                                  return (
                                                    <option
                                                      value={res.user_id}
                                                      disabled={personSelectedList?.includes(
                                                        res.user_id
                                                      )}
                                                    >
                                                      {res.first_name}
                                                    </option>
                                                  );
                                                })}
                                              </select>
                                            </p>
                                          </td>
                                          <td className="">
                                            <div className="d-flex">
                                              {" "}
                                              <input
                                                type="text"
                                                className="form-control p-0"
                                                name="priority"
                                                data-id="test"
                                                data-nested="nested"
                                                value={item.priority}
                                                onChange={(e) =>
                                                  handleInputPerson(e, i, index)
                                                }
                                                placeholder="1"
                                                style={{
                                                  height: "40px",
                                                  width: "150px",
                                                  border: "none",
                                                }}
                                              />{" "}
                                              <div className="">
                                                <button
                                                  className="btn add-new notiIcon filterIcon"
                                                  style={{ width: "200px" }}
                                                  onClick={(e) =>
                                                    handleAddPriority(
                                                      e,
                                                      res.role,
                                                      i
                                                    )
                                                  }
                                                >
                                                  {tab48}
                                                </button>
                                              </div>
                                            </div>
                                          </td>

                                          <td className="white-space tdWidth">
                                            <div>
                                              <input
                                                type="text"
                                                className="form-control p-0"
                                                name="amount"
                                                value={item.amount}
                                                onChange={(e) =>
                                                  handleInputPerson(e, i, index)
                                                }
                                                placeholder="150 Euro"
                                                style={{
                                                  height: "40px",
                                                  width: "250px",
                                                }}
                                              />
                                            </div>
                                          </td>

                                          <td className="tdWidth">
                                            <div>
                                              {" "}
                                              <input
                                                type="text"
                                                className="form-control p-0"
                                                name={tab45}
                                                value={
                                                  item.additional_information
                                                }
                                                onChange={(e) =>
                                                  handleInputPerson(e, i, index)
                                                }
                                                placeholder={tab45}
                                                style={{
                                                  height: "40px",
                                                  width: "250px",
                                                }}
                                              />
                                            </div>
                                          </td>
                                          <td
                                            onClick={() =>
                                              handleRemovePerson(i, index)
                                            }
                                          >
                                            <img
                                              src="assets/images/delete.png"
                                              width="25px"
                                              alt=""
                                            />
                                          </td>
                                          <td>
                                            {/* <div className="d-flex justify-content-end my-3">
                                                                        <button className="btn add-new" onClick={() => handleSendInvite(res.user_id)} style={{ width: "200px" }}>Send Invitation</button>
                                                                    </div> */}
                                          </td>
                                        </>
                                      );
                                    }
                                  })}
                                </tr>
                                {res.person.map((item, index) => {
                                  if (index > 0) {
                                    return (
                                      <tr>
                                        <td></td>
                                        <td className="white-space tdWidth">
                                          <p>
                                            {" "}
                                            <select
                                              className="form-control p-0 notranslate"
                                              data-id="test"
                                              data-nested="nested"
                                              name="user_id"
                                              value={item.first_name}
                                              onChange={(e) =>
                                                handleInputPerson(e, i, index)
                                              }
                                              style={{
                                                height: "40px",
                                                width: "250px",
                                              }}
                                            >
                                              <option>
                                                {lng === "/auto/de" || "/en/de"
                                                  ? "--Auswählen--"
                                                  : "--Select--"}
                                              </option>
                                              {bandmember.map((res, key) => {
                                                return (
                                                  <option value={res.user_id}>
                                                    {res.first_name}
                                                  </option>
                                                );
                                              })}
                                            </select>
                                          </p>
                                        </td>
                                        <td className="">
                                          <div className="d-flex">
                                            {" "}
                                            <input
                                              type="text"
                                              className="form-control p-0"
                                              name="priority"
                                              data-id="test"
                                              data-nested="nested"
                                              value={item.priority}
                                              onChange={(e) =>
                                                handleInputPerson(e, i, index)
                                              }
                                              placeholder="1"
                                              style={{
                                                height: "40px",
                                                width: "150px",
                                                border: "none",
                                              }}
                                            />{" "}
                                            <div className="">
                                              {/* <button className="btn add-new notiIcon filterIcon" style={{ width: "180px" }} onClick={(e) => handleAddPriority(e, res.role, i)}>add musician</button> */}
                                            </div>
                                          </div>
                                        </td>

                                        <td className="white-space tdWidth">
                                          <div>
                                            <input
                                              type="text"
                                              className="form-control p-0"
                                              name="amount"
                                              value={item.amount}
                                              onChange={(e) =>
                                                handleInputPerson(e, i, index)
                                              }
                                              placeholder="150 Euro"
                                              style={{
                                                height: "40px",
                                                width: "250px",
                                              }}
                                            />
                                          </div>
                                        </td>

                                        <td className="tdWidth">
                                          <div>
                                            {" "}
                                            <input
                                              type="text"
                                              className="form-control p-0"
                                              name="additional_information"
                                              value={
                                                item.additional_information
                                              }
                                              onChange={(e) =>
                                                handleInputPerson(e, i, index)
                                              }
                                              placeholder={tab45}
                                              style={{
                                                height: "40px",
                                                width: "250px",
                                              }}
                                            />
                                          </div>
                                        </td>
                                        <td
                                          onClick={() =>
                                            handleRemovePerson(i, index)
                                          }
                                        >
                                          <img
                                            src="assets/images/delete.png"
                                            width="25px"
                                            alt=""
                                          />
                                        </td>
                                        <td></td>
                                      </tr>
                                    );
                                  }
                                })}
                              </>
                            );
                          })}

                          <button
                            className="btn add-new notiIcon filterIcon"
                            onClick={handleAddPerson}
                          >
                            {tab47}
                          </button>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 align-items-center">
                  <div className="col-8 col-sm-9 col-md-9 col-lg-9">
                    <ul
                      className="nav nav-pills group-nav tabNabs"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a className="nav-link active">{tab49}</a>
                      </li>
                    </ul>
                  </div>

                  <div className="col-sm-12">
                    <div className="pt-3 px-1 bg-white">
                      <div className="table-responsive">
                        <table className="table storetable">
                          <thead className="">
                            <tr>
                              <th
                                scope="col"
                                title={
                                  lng === "/auto/de" || "/en/de"
                                    ? "Benenen Sie hier die Rolle, in welcher Sie das Bandmitglied, für diese Veranstaltungen einladen möchten. Zum Beispiel Gesang, Bass, Gitarre usw. Die Rolle wir dem jeweiligen Bandmitglied angezeigt."
                                    : "Name the role in which you want to invite the band member for this events. For example, vocals, bass, guitar, etc. The role is displayed to each band member."
                                }
                              >
                                {tab22}
                                <img
                                  src="assets/images/Infoblack.png"
                                  alt=""
                                  className="mx-1 mb-4"
                                />
                              </th>
                              <th
                                scope="col"
                                title={
                                  lng === "/auto/de" || "/en/de"
                                    ? "Wählen Sie die Person aus, welche Sie für diese Rolle einladen möchten. Jede Person kann nur für jeweils eine Rolle eingeladen werden."
                                    : "Select the person you want to invite for this role. Each person can only be invited to one role at a time."
                                }
                              >
                                Person
                                <img
                                  src="assets/images/Infoblack.png"
                                  alt=""
                                  className="mx-1 mb-4"
                                />
                              </th>

                              <th
                                scope="col"
                                title={
                                  lng === "/auto/de" || "/en/de"
                                    ? "Geben Sie hier die Gage für dieses Bandmitglied ein. Diese Information ist nur für Sie und das jeweiligen Bandmitglied sichtbar."
                                    : "Enter the fee for this band member here. This information is only visible to you and the respective band member."
                                }
                              >
                                {lng === "/auto/de" || "/en/de"
                                  ? "Bezahlung"
                                  : "Payment"}
                                <img
                                  src="assets/images/Infoblack.png"
                                  alt=""
                                  className="mx-1 mb-4"
                                />
                              </th>

                              <th
                                scope="col"
                                title={
                                  lng === "/auto/de" || "/en/de"
                                    ? "Hier haben Sie die Möglichkeit, weitere Informationen mit der Terminanfrage, an die jeweiligen Bandmitglieder zu versenden. Diese Information ist nur für Sie und das jeweilige Bandmitglied sichtbar."
                                    : "Here you have the opportunity to send further information with the appointment request to the respective band members. This information is only visible to you and the respective band member."
                                }
                              >
                                {tab45}
                                <img
                                  src="assets/images/Infoblack.png"
                                  alt=""
                                  className="mx-1 mb-4"
                                />
                              </th>
                              <th scope="col">{tab52}</th>
                              <th scope=""></th>
                            </tr>
                            <tr className="border-0 bg-none invisible">
                              <td className="border-0 bg-none"></td>
                            </tr>
                          </thead>
                          <tbody>
                            {eventDetails[0]?.band_staff_details?.length !== 0
                              ? eventDetails[0]?.band_staff_details.map(
                                  (res, i) => {
                                    return (
                                      <>
                                        <tr>
                                          {res?.role === "" || res?.role ? (
                                            <td className="white-space tdWidth">
                                              <input
                                                type="text"
                                                name="role"
                                                value={res.role}
                                                className="form-control p-0"
                                                style={{
                                                  height: "40px",
                                                  width: "250px",
                                                }}
                                                placeholder="Role"
                                              />
                                            </td>
                                          ) : (
                                            <td>
                                              <p></p>
                                            </td>
                                          )}
                                          <td className="white-space tdWidth">
                                            <select
                                              className="form-control p-0 notranslate"
                                              data-id="test"
                                              data-nested="nested"
                                              name="user_id"
                                              value={res.user_id}
                                              style={{
                                                height: "40px",
                                                width: "250px",
                                              }}
                                            >
                                              {bandmember2.map((res, key) => {
                                                return (
                                                  <option value={res.user_id}>
                                                    {res.first_name}
                                                  </option>
                                                );
                                              })}
                                            </select>
                                          </td>
                                          <td className="white-space tdWidth">
                                            <input
                                              type="text"
                                              name="amount"
                                              value={res.amount}
                                              className="form-control p-0"
                                              style={{
                                                height: "40px",
                                                width: "250px",
                                              }}
                                              placeholder="Amount"
                                            />
                                          </td>
                                          <td className="tdWidth">
                                            <div>
                                              {" "}
                                              <input
                                                type="text"
                                                className="form-control p-0"
                                                value={res.description}
                                                name="description"
                                                placeholder={tab45}
                                                style={{
                                                  height: "40px",
                                                  width: "250px",
                                                }}
                                              />
                                            </div>
                                          </td>
                                          <td
                                            onClick={() =>
                                              handleRemoveStaffLocal(
                                                i,
                                                res.staff_id
                                              )
                                            }
                                          >
                                            <img
                                              src="assets/images/delete.png"
                                              width="25px"
                                              alt=""
                                            />
                                          </td>
                                          {/* <div className="d-flex justify-content-end my-3">
                                                                    <button className="btn add-new" onClick={() => handleSendInviteBandstaff(res.staff_id)} style={{ width: "200px" }}>Send Invitation</button>
                                                                </div> */}
                                          <td>
                                            <div className="d-flex justify-content-end my-3">
                                              {res.is_invited === "0" ? (
                                                <button
                                                  className="btn add-new"
                                                  disabled={
                                                    disabledstaff.indexOf(i) !==
                                                    -1
                                                  }
                                                  onClick={() =>
                                                    handleSendInviteBandstaff(
                                                      res.staff_id,
                                                      i
                                                    )
                                                  }
                                                  style={{ width: "200px" }}
                                                >
                                                  Send Invitation
                                                </button>
                                              ) : (
                                                ""
                                              )}
                                              {res.is_invited === "1" &&
                                              res.is_rejected === "0" &&
                                              res.is_accepted === "0" ? (
                                                <button
                                                  className="btn add-new"
                                                  style={{
                                                    width: "220px",
                                                    background: "orange",
                                                    border: "none",
                                                  }}
                                                >
                                                  {" "}
                                                  Invitation was Send
                                                </button>
                                              ) : (
                                                ""
                                              )}
                                              {res.is_accepted === "1" ? (
                                                <button
                                                  className="btn add-new"
                                                  style={{
                                                    width: "200px",
                                                    background: "green",
                                                    border: "none",
                                                  }}
                                                >
                                                  Confirmed
                                                </button>
                                              ) : (
                                                ""
                                              )}
                                              {res.is_rejected === "1" ? (
                                                <button
                                                  className="btn add-new"
                                                  style={{
                                                    width: "200px",
                                                    background: "black",
                                                    border: "none",
                                                  }}
                                                >
                                                  Cancelled
                                                </button>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  }
                                )
                              : ""}
                            {bandStaffList.map((res, i) => {
                              return (
                                <>
                                  <tr>
                                    {res?.role === "" || res?.role ? (
                                      <td className="white-space tdWidth">
                                        <input
                                          type="text"
                                          name="role"
                                          value={res.role}
                                          onChange={(e) =>
                                            handleInputBand(e, i)
                                          }
                                          className="form-control p-0"
                                          style={{
                                            height: "40px",
                                            width: "250px",
                                          }}
                                          placeholder={tab22}
                                        />
                                      </td>
                                    ) : (
                                      <td>
                                        <p></p>
                                      </td>
                                    )}
                                    <td className="white-space tdWidth">
                                      <select
                                        className="form-control p-0 notranslate"
                                        data-id="test"
                                        data-nested="nested"
                                        name="user_id"
                                        value={res.first_name}
                                        onChange={(e) => handleInputBand(e, i)}
                                        style={{
                                          height: "40px",
                                          width: "250px",
                                        }}
                                      >
                                        <option>
                                          {lng === "/auto/de" || "/en/de"
                                            ? "--Auswählen--"
                                            : "--Select-"}
                                        </option>
                                        {bandmember2.map((res, key) => {
                                          return (
                                            <option value={res.user_id}>
                                              {res.first_name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </td>
                                    {/* <td className="white-space tdWidth"><input type="text" name="email" value={res.email} onChange={(e) => handleInputBand(e, i)} className="form-control p-0" style={{ height: "40px", width: "250px" }} placeholder="Email" /></td> */}
                                    <td className="white-space tdWidth">
                                      <input
                                        type="text"
                                        name="amount"
                                        value={res.amount}
                                        onChange={(e) => handleInputBand(e, i)}
                                        className="form-control p-0"
                                        style={{
                                          height: "40px",
                                          width: "250px",
                                        }}
                                        placeholder="150 Euro"
                                      />
                                    </td>
                                    <td className="tdWidth">
                                      <div>
                                        {" "}
                                        <input
                                          type="text"
                                          className="form-control p-0"
                                          name="description"
                                          value={res.description}
                                          placeholder={tab45}
                                          style={{
                                            height: "40px",
                                            width: "250px",
                                          }}
                                        />
                                      </div>
                                    </td>
                                    <td onClick={() => handleRemoveStaff(i)}>
                                      <img
                                        src="assets/images/delete.png"
                                        width="25px"
                                        alt=""
                                      />
                                    </td>
                                    {/* <div className="d-flex justify-content-end my-3">
                                                                    <button className="btn add-new" onClick={() => handleSendInviteBandstaff(res.staff_id)} style={{ width: "200px" }}>Send Invitation</button>
                                                                </div> */}
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                          <button
                            className="btn add-new notiIcon filterIcon mt-3"
                            onClick={handleAddBandPerson}
                          >
                            {tab61}
                          </button>
                        </table>
                      </div>
                    </div>
                    {/* <div className="d-flex my-3">
                                        <button className="btn add-new1" onClick={handleCancelEvent}>Safe setup</button>
                                        <button className="btn add-new1" onClick={handleShowLoadsetup}>Load setup</button>
                                    </div> */}
                    {/* <div className="d-flex justify-content-end my-3">
                                        <button className="btn add-new" onClick={handleSendinviteAll}>Send Invitation To All</button>
                                    </div> */}
                    <div className="d-flex justify-content-end my-3">
                      <button
                        className="btn add-new"
                        onClick={handleAddMusicians}
                      >
                        {lng === "/auto/de" || "/en/de" ? "SPEICHERN" : "Safe"}
                      </button>
                      {/* <button className="btn add-new" onClick={handleUpdateMusicians}>update</button> */}
                    </div>
                  </div>
                </div>
                <div className="row mt-3 align-items-center">
                  <div className="col-8 col-sm-9 col-md-9 col-lg-9">
                    <ul
                      className="nav nav-pills group-nav tabNabs"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a className="nav-link active">{tab75}</a>
                      </li>
                    </ul>
                  </div>

                  <div className="col-sm-12">
                    <div className="pt-3 px-1 bg-white">
                      <div className="table-responsive">
                        <table className="table storetable">
                          <thead className="">
                            <tr>
                              <th scope="col">{tab22}</th>
                              <th scope="col">{tab76}</th>
                              <th scope="col">{tab77}</th>
                              <th scope="col">{tab23}</th>
                            </tr>
                            <tr className="border-0 bg-none invisible">
                              <td className="border-0 bg-none"></td>
                            </tr>
                          </thead>
                          <tbody>
                            {cancelledInvitation.map((res, i) => {
                              return (
                                <tr>
                                  <td className="white-space tdWidth">
                                    {res.role}
                                  </td>
                                  <td className="white-space tdWidth">
                                    {res.first_name}
                                  </td>
                                  <td className="white-space tdWidth">
                                    {res.last_name}
                                  </td>
                                  <td className="white-space tdWidth">
                                    {res.amount}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {transport ? (
              <div className="col-sm-12">
                <div className="pt-3 px-1 bg-white">
                  <div className="table-responsive">
                    <table className="table storetable">
                      <thead className="">
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">
                            {lng === "/auto/de" || "/en/de"
                              ? "Fahrer"
                              : "Driver"}{" "}
                            <img
                              src="assets/images/Infoblack.png"
                              alt=""
                              className="mx-1 mb-4"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Tragen Sie den Namen des Fahrers ein. Alle eingeladenen Bandmitglieder können diese Information einsehen."
                                  : "Enter the driver's name. All invited band members can see this information."
                              }
                            />
                          </th>
                          <th scope="col">
                            {tab30}{" "}
                            <img
                              src="assets/images/Infoblack.png"
                              alt=""
                              className="mx-1 mb-4"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Tragen Sie den Namen des Fahrzeuges ein."
                                  : "Enter the name of the vehicle."
                              }
                            />
                          </th>
                          <th scope="col">
                            {tab31}{" "}
                            <img
                              src="assets/images/Infoblack.png"
                              alt=""
                              className="mx-1 mb-4"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Tragen Sie hier die Anzahl der für Passagiere verfügbaren Plätze ein. Dies ist die maximale Anzahl der Mitfahrer, welche sich für dieses Fahrzeug eintragen können."
                                  : "Enter the number of seats available for passengers here. This is the maximum number of passengers who can register for this vehicle."
                              }
                            />
                          </th>
                          <th scope="col">
                            {tab68}{" "}
                            <img
                              src="assets/images/Infoblack.png"
                              alt=""
                              className="mx-1 mb-4"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Hier finden Sie die Namen der Personen, welche sich bereits für das Fahrzeug als Passagier eingetragen haben."
                                  : "Here you will find the names of the people who have already registered for the vehicle as passengers."
                              }
                            />
                          </th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {eventDetails[0]?.transport_details.map((res, i) => {
                          return (
                            <tr>
                              {" "}
                              <td className="mx-4">
                                {/* {driverList.length - 1 === i &&
                                                        <img src="assets/images/moregray.png" alt="" onClick={handleAddClick} />} */}
                                <img
                                  src="assets/images/delete.png"
                                  alt=""
                                  onClick={() =>
                                    handleShowTransportDelete(res?.transport_id)
                                  }
                                />
                              </td>
                              <td>
                                {" "}
                                <p>
                                  <input
                                    type="text"
                                    className="form-control p-0"
                                    name="driver"
                                    value={res.driver}
                                    placeholder={
                                      lng === "/auto/de" || "/en/de"
                                        ? "Fahrer"
                                        : "driver"
                                    }
                                    style={{ height: "40px", border: "none" }}
                                  />
                                </p>
                              </td>
                              <td>
                                <p>
                                  <input
                                    type="text"
                                    className="form-control p-0"
                                    name="vehicle"
                                    value={res.vehicle}
                                    placeholder="Vehicle"
                                    style={{ height: "40px", border: "none" }}
                                  />
                                </p>
                              </td>
                              <td>
                                <p>
                                  <input
                                    type="text"
                                    className="form-control p-0"
                                    name="available_seats"
                                    value={res.available_seats}
                                    placeholder="Available seats"
                                    style={{ height: "40px", border: "none" }}
                                  />
                                </p>
                              </td>
                              <td>
                                {" "}
                                <img
                                  src="assets/images/user-profile.png"
                                  alt=""
                                  onClick={() =>
                                    handleShowPassengers(res?.transport_id)
                                  }
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    cursor: "pointer",
                                  }}
                                />{" "}
                                <button
                                  className="btn add-new mx-4"
                                  onClick={() =>
                                    handlePassengers(res.transport_id)
                                  }
                                >
                                  {tab67}
                                </button>
                              </td>
                            </tr>
                          );
                        })}

                        {vehicle ? (
                          driverList.map((res, i) => {
                            return (
                              <tr>
                                {" "}
                                <td className="mx-4">
                                  {driverList.length !== 1 && (
                                    <img
                                      src="assets/images/delete.png"
                                      alt=""
                                      onClick={() => handleRemove(i)}
                                    />
                                  )}
                                  {driverList.length - 1 === i && (
                                    <img
                                      src="assets/images/moregray.png"
                                      alt=""
                                      onClick={handleAddClick}
                                    />
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  <p>
                                    <input
                                      type="text"
                                      className="form-control p-0"
                                      name="driver"
                                      value={res.driver}
                                      onChange={(e) => handleInputChange(e, i)}
                                      placeholder={
                                        lng === "/auto/de" || "/en/de"
                                          ? "Fahrer"
                                          : "driver"
                                      }
                                      style={{ height: "40px" }}
                                    />
                                  </p>
                                </td>
                                <td>
                                  <p>
                                    <input
                                      type="text"
                                      className="form-control p-0"
                                      name="vehicle"
                                      value={res.vehicle}
                                      onChange={(e) => handleInputChange(e, i)}
                                      placeholder={
                                        lng === "/auto/de" || "/en/de"
                                          ? "Fahrzeug"
                                          : "Vehicle"
                                      }
                                      style={{ height: "40px" }}
                                    />
                                  </p>
                                </td>
                                <td>
                                  <p>
                                    {/* <input type="text" className="form-control p-0" name="available_seats" value={res.available_seats} onChange={(e) => handleInputChange(e, i)} placeholder={lng === "/auto/de" || "/en/de" ? "Verfügbare Sitzplätze" : "Available seats"} style={{ height: "40px" }} /> */}

                                    <select
                                      className="form-control p-0"
                                      name="available_seats"
                                      onChange={(e) => handleInputChange(e, i)}
                                      style={{ height: "40px" }}
                                    >
                                      <option>{tab62}</option>
                                      <option values="1">1</option>
                                      <option values="2">2</option>
                                      <option values="3">3</option>
                                      <option values="4">4</option>
                                      <option values="5">5</option>
                                      <option values="6">6</option>
                                      <option values="7">7</option>
                                      <option values="8">8</option>
                                      <option values="9">9</option>
                                      <option values="10">10</option>
                                      <option values="11">11</option>
                                      <option values="12">12</option>
                                      <option values="13">13</option>
                                      <option values="14">14</option>
                                      <option values="15">15</option>
                                      <option values="16">16</option>
                                    </select>
                                  </p>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <button
                            className="btn add-new"
                            onClick={handleAddVehicle}
                          >
                            {tab66}
                          </button>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex justify-content-end my-3">
                  <button className="btn add-new" onClick={handleAddTransport}>
                    {lng === "/auto/de" || "/en/de" ? "SPEICHERN" : "Safe"}
                  </button>
                  {/* <button className="btn add-new" onClick={handleUpdateTransport}>update</button> */}
                </div>
              </div>
            ) : (
              ""
            )}

            {setlist ? (
              <div className="col-sm-12">
                <div className="pt-3 px-1 bg-white">
                  <h4 className="text-center notranslate">
                    {eventDetails[0]?.set_list_name !== null
                      ? eventDetails[0]?.set_list_name
                      : allSetlistSongs?.data?.set_list_name}
                  </h4>
                  <div className="table-responsive setlistTable">
                    <table className="table storetable">
                      <thead className="">
                        <tr>
                          <th scope="col">name</th>
                          <th scope="col" className="text-center">
                            {lng === "/auto/de" || "/en/de"
                              ? "Zeit"
                              : "time of song"}
                          </th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {allSetlistSongs?.data2 !== undefined && allSetlistSongs?.data2 !== null ? allSetlistSongs?.data2.map((item, key) => {
                                                return <tr>
                                                    <td><input type="text" value={item?.song_name} style={{ border: "none" }} /></td>
                                                    <td><input type="text" value={item?.time_length?.substr(3, 6)} style={{ border: "none" }} /></td>
                                                </tr>
                                            }) : ""} */}
                        {eventDetails?.[0]?.set_list_details?.length > 0
                          ? eventDetails?.[0]?.set_list_details.map(
                              (item, key) => {
                                return (
                                  <tr>
                                    <td className="notranslate">
                                      <input
                                        type="text"
                                        value={item?.song_name}
                                        style={{ border: "none" }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={item?.time_length?.substr(3, 6)}
                                        style={{ border: "none" }}
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          : allSetlistSongs?.data2?.length > 0
                          ? allSetlistSongs?.data2.map((item, key) => {
                              return (
                                <tr>
                                  <td>
                                    <input
                                      type="text"
                                      value={item?.song_name}
                                      style={{ border: "none" }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={item?.time_length?.substr(3, 6)}
                                      style={{ border: "none" }}
                                    />
                                  </td>
                                </tr>
                              );
                            })
                          : ""}
                        {eventDetails[0]?.total_length ? (
                          <tr>
                            <td className="bg-td">{tab51}</td>
                            <td className="bg-td">
                              {lng === "/auto/de" || "/en/de"
                                ? "Gesamtzeit der Setlist"
                                : "time of setlist "}{" "}
                              {eventDetails[0]?.total_length
                                ? eventDetails[0]?.total_length
                                : ""}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {allSetlistSongs?.data?.total_length ? (
                          <tr>
                            <td className="bg-td">in total</td>
                            <td className="bg-td">
                              {lng === "/auto/de" || "/en/de"
                                ? "Gesamtzeit der Setlist"
                                : "time of setlist "}{" "}
                              {allSetlistSongs?.data?.total_length
                                ? allSetlistSongs?.data?.total_length
                                : ""}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}

                        {/* {eventDetails?.[0]?.total_length !== null ?
                                                <tr>
                                                    <td className="bg-td">in total</td>
                                                    <td className="bg-td">time of setlist: {eventDetails?.[0]?.total_length || ""}</td>
                                                </tr> : <tr>
                                                    <td className="bg-td">in total</td>
                                                    <td className="bg-td">time of setlist: {allSetlistSongs?.data?.total_length !== null ? allSetlistSongs?.data?.total_length : ""}</td>
                                                </tr>} */}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <h5 className=""> {eventDetails[0]?.total_length !== "" ? eventDetails[0]?.total_length : ""}</h5> */}

                <div className="d-flex justify-content-end my-3">
                  {eventDetails?.[0]?.event_setlist_id ? (
                    <button className="btn" onClick={showDelete}>
                      <img src="assets/images/deleteBlack1.png" alt="" />
                    </button>
                  ) : (
                    ""
                  )}
                  {/* <button className="btn" onClick={showDelete}><img src="assets/images/deleteBlack1.png" alt="" /></button> */}
                  <button className="btn" onClick={showSetlist}>
                    <img src="assets/images/attachment.png" alt="" />{" "}
                    <img
                      src="assets/images/Infoblack.png"
                      alt=""
                      className="mx-1 mb-4"
                      title={
                        lng === "/auto/de" || "/en/de"
                          ? "Mit diesem Button öffnen Sie die Auflistung der von Ihnen erstellten Setlisten"
                          : "This button opens the list of set lists you have created"
                      }
                    />
                  </button>
                </div>
                {selectSetlist ? (
                  <SetListModal
                    setListData={setListData}
                    setSetListData={setSetListData}
                    setSetlistId={setSetlistId}
                    event={eventDetails[0]?.event_id}
                  />
                ) : (
                  ""
                )}

                {allSetlistSongs?.data2 ? (
                  <div className="d-flex justify-content-end my-3">
                    <button className="btn add-new" onClick={handleAddSetlist}>
                      {lng === "/auto/de" || "/en/de" ? "SPEICHERN" : "Safe"}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}

            {attachment ? (
              <div className="col-sm-12">
                <div className="pt-3 px-1 bg-white">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="pt-3 px-1 bg-white">
                        <div className="table-responsive">
                          <table className="table storetable text-center">
                            <thead className="">
                              <tr>
                                <th
                                  scope="col"
                                  title={
                                    lng === "/auto/de" || "/en/de"
                                      ? "Klicken Sie auf die jeweiligen Dokumente ,um Freigaben für den Up- und Download zu erteilen"
                                      : "Click on the respective documents to grant approval for uploading and downloading"
                                  }
                                >
                                  {tab70}
                                  <img
                                    src="assets/images/Infoblack.png"
                                    alt=""
                                    className="mx-2"
                                  />
                                </th>
                              </tr>
                              <tr className="border-0 bg-none invisible">
                                <td className="border-0 bg-none"></td>
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                <tr>
                                  <td
                                    style={{ cursor: "pointer" }}
                                    onClick={handleOutgoingfiles}
                                  >
                                    {tab32}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    onClick={handleIncomingfiles}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {tab33}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    onClick={handleContractfiles}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Dokumente von Veranstalter
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    onClick={handleContractfiles}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {tab34}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    onClick={handleSoundfiles}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {tab35}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    onClick={handleOtherfiles}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {tab36}
                                  </td>
                                </tr>
                              </>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end my-3">
                  <button className="btn add-new" onClick={handleAddAttachment}>
                    {lng === "/auto/de" || "/en/de" ? "SPEICHERN" : "Safe"}
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </article>
      {showCancelModal ? (
        <CancelEventModal
          show={showCancelModal}
          handleShow={handleShowCancel}
          id={eventDetails[0]?.event_id}
        />
      ) : (
        ""
      )}
      {showPassengerModal ? (
        <AddPassengers
          show={showPassengerModal}
          handleShow={handleShowPassenger}
          driverList={driverList}
          transportId={transportId}
          eventid={eventDetails[0]?.event_id}
        />
      ) : (
        ""
      )}
      {showPassengerDetailModal ? (
        <TransportDetail
          show={showPassengerDetailModal}
          handleShow={handleShowPassengerDetail}
          transportId={transportId}
          eventid={eventDetails[0]?.event_id}
        />
      ) : (
        ""
      )}
      {transportDelete ? (
        <DeleteTransport
          show={transportDelete}
          handleShow={handleShowTransportDelete}
          transportId={transportId}
          eventId={eventDetails[0]?.event_id || ""}
          tab74={tab74}
          tab72={tab72}
          tab73={tab73}
        />
      ) : (
        ""
      )}
      {OutgoingInvoiceFile ? (
        <EditOutgoingInvoice
          show={OutgoingInvoiceFile}
          handleShow={handleShowOutgoingInvoiceFile}
          eventId={eventDetails[0]?.event_id}
          showtext={tab32}
        />
      ) : (
        ""
      )}
      {incomingInvoiceFile ? (
        <EditIncomingInvoice
          show={incomingInvoiceFile}
          handleShow={handleShowIncomingInvoiceFile}
          eventId={eventDetails[0]?.event_id}
          showtext={tab33}
        />
      ) : (
        ""
      )}
      {incomingContractFile ? (
        <EditIncomingContract
          show={incomingContractFile}
          handleShow={handleShowIncomingContractFile}
          eventId={eventDetails[0]?.event_id}
          showtext={"Dokumente von Veranstalte"}
        />
      ) : (
        ""
      )}
      {documentBookerFile ? (
        <EditDocumentBooker
          show={documentBookerFile}
          handleShow={handleShowDocumentBookerFile}
          eventId={eventDetails[0]?.event_id}
          showtext={tab34}
        />
      ) : (
        ""
      )}
      {documentSoundFile ? (
        <EditSoundEngineer
          show={documentSoundFile}
          handleShow={handleShowDocumentSoundFile}
          eventId={eventDetails[0]?.event_id}
          showtext={tab35}
        />
      ) : (
        ""
      )}
      {otherFile ? (
        <EditOtherAttachments
          show={otherFile}
          handleShow={handleShowOtherFile}
          eventId={eventDetails[0]?.event_id}
          showtext={tab36}
        />
      ) : (
        ""
      )}
      {invitationShow ? (
        <InvitationModal
          show={invitationShow}
          handleShow={handleShowInvitation}
          eventid={eventDetails[0]?.event_id || ""}
        />
      ) : (
        ""
      )}

      {passengerList ? (
        <PassengersList
          show={passengerList}
          handleShow={handleShowPassengers}
          vehicleId={vehicleId}
          eventId={eventDetails[0]?.event_id}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default EditEventData;
