import React, { useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useLocation } from "react-router-dom";
import { getEventDetail } from "../../app_redux/slice/eventDetail";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import { getMusicianInfo } from "../../app_redux/slice/getMusicianInfo";
import { useTranslation } from "react-i18next";

const ConcertPdf = () => {
  const { t } = useTranslation();

  const cookie = new Cookies();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const pdfref = useRef();
  const { eventDetails } = useSelector((state) => state.eventdetail);
  const { musicianDetail } = useSelector((state) => state.musician);
  const { tab16, tab17, tab18, tab19, tab20, tab21 } = t("event");

  useEffect(() => {
    dispatch(getEventDetail({ eventid: state?.eventid }));
    dispatch(getMusicianInfo());
  }, []);

  console.log("detail pdf", eventDetails);

  const handleDownload = () => {
    const input = pdfref.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgHeight = canvas.height;
      const imgWidth = canvas.width;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("event.pdf");
    });
  };

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center">
          <div className="d-flex justify-content-end">
            <button className="btn add-new" onClick={handleDownload}>
              Download
            </button>
          </div>
          <div ref={pdfref}>
            <h2 className="text-center pdf-text mb-3">
              {musicianDetail?.band_name}
            </h2>
            <div className="info-box mb-5">
              <p className="para-pdf">Event</p>
              <h4 className="pdf-text">{eventDetails[0]?.event_name || ""}</h4>
              <h4>{eventDetails[0]?.start_date?.substr(0, 10) || ""}</h4>
              <div className="inside-card d-flex justify-content-between mt-4">
                <h4>Get in: </h4>
                <p className="para-pdf">
                  {eventDetails[0]?.start_time?.substr(0, 5) || ""}
                </p>
              </div>
              <div className="inside-card d-flex justify-content-between">
                <h4>Load: </h4>
                <p className="para-pdf">
                  {eventDetails[0]?.load_start_time?.substr(0, 5) || ""}-{" "}
                  {eventDetails[0]?.load_end_time?.substr(0, 5) || ""}
                </p>
              </div>
              <div className="inside-card d-flex justify-content-between">
                <h4>Soundcheck: </h4>
                <p className="para-pdf">
                  {eventDetails[0]?.sound_start_time?.substr(0, 5) || ""}-{" "}
                  {eventDetails[0]?.sound_end_time?.substr(0, 5) || ""}
                </p>
              </div>
              <div className="inside-card d-flex justify-content-between">
                <h4 className="notranslate">Stagetime: </h4>
                <p className="para-pdf">
                  {eventDetails[0]?.stage_start_time?.substr(0, 5) || ""}-{" "}
                  {eventDetails[0]?.stage_end_time?.substr(0, 5) || ""}
                </p>
              </div>
              <h4 className="mt-4">Information</h4>
              <p className="para-pdf">{eventDetails[0]?.event_other_info}</p>
            </div>
            <div className="info-box mb-5">
              <p className="para-pdf">Location</p>
              <h4 className="pdf-text">{eventDetails[0]?.location_name}</h4>
              <div className="inside-card d-flex justify-content-between mt-4">
                <h4>{tab16}: </h4>
                <p className="para-pdf pdf-text">
                  {eventDetails[0]?.location_street}{" "}
                </p>
              </div>
              <div className="inside-card d-flex justify-content-between">
                <h4>{tab17}: </h4>
                <p className="para-pdf pdf-text">
                  {eventDetails[0]?.event_house_number}{" "}
                </p>
              </div>
              <div className="inside-card d-flex justify-content-between">
                <h4>{tab18}: </h4>
                <p className="para-pdf pdf-text">
                  {eventDetails[0]?.event_zip_code}{" "}
                </p>
              </div>
              <div className="inside-card d-flex justify-content-between">
                <h4>{tab19}: </h4>
                <p className="para-pdf pdf-text">{eventDetails[0]?.town} </p>
              </div>
              <div className="inside-card d-flex justify-content-between">
                <h4>Land: </h4>
                <p className="para-pdf pdf-text">
                  {eventDetails[0]?.event_country}{" "}
                </p>
              </div>
              <h4 className="mt-4">Information</h4>
              <p className="para-pdf">
                {eventDetails[0]?.location_other_information}
              </p>
            </div>

            <div className="info-box mb-5">
              <p className="para-pdf">Accomodation</p>
              <h4 className="pdf-text">
                {eventDetails[0]?.info_accommodation[0]?.accomodation_name ||
                  ""}
              </h4>
              <div className="inside-card d-flex justify-content-between mt-4">
                <h4>{tab16}: </h4>
                <p className="para-pdf pdf-text">
                  {eventDetails[0]?.info_accommodation[0]
                    ?.accomodation_street || ""}{" "}
                </p>
              </div>
              <div className="inside-card d-flex justify-content-between">
                <h4>{tab17}: </h4>
                <p className="para-pdf pdf-text">
                  {eventDetails[0]?.info_accommodation[0]
                    ?.accomodation_house_number || ""}{" "}
                </p>
              </div>
              <div className="inside-card d-flex justify-content-between">
                <h4>{tab18}: </h4>
                <p className="para-pdf pdf-text">
                  {eventDetails[0]?.info_accommodation[0]
                    ?.accomodation_postal || ""}{" "}
                </p>
              </div>
              <div className="inside-card d-flex justify-content-between">
                <h4>{tab19}: </h4>
                <p className="para-pdf pdf-text">
                  {eventDetails[0]?.info_accommodation[0]?.accomodation_town ||
                    ""}{" "}
                </p>
              </div>
              <div className="inside-card d-flex justify-content-between">
                <h4>Land: </h4>
                <p className="para-pdf pdf-text">
                  {eventDetails[0]?.info_accommodation[0]
                    ?.accomodation_country || ""}{" "}
                </p>{" "}
              </div>
              <div className="inside-card d-flex justify-content-between mt-4">
                <h4>Phone: </h4>
                <p className="para-pdf pdf-text">
                  {eventDetails[0]?.info_accommodation[0]
                    ?.accomodation_phone_number || ""}{" "}
                </p>
              </div>
              <h4 className="mt-4">Information</h4>
              <p className="para-pdf">
                {eventDetails[0]?.info_accommodation[0]
                  ?.accomodation_other_information || ""}
              </p>
            </div>

            <div className="info-box mb-5">
              <p className="para-pdf">Other Info</p>
              {/* <h4>New Accomodation</h4> */}
              <div className="mt-4">
                <h4>{tab20}: </h4>
                <p className="para-pdf">{eventDetails[0]?.parking || ""}</p>
              </div>
              <div className="mt-4">
                <h4>{tab21}: </h4>
                <p className="para-pdf">{eventDetails[0]?.guest_list || ""}</p>
              </div>
              <div className="mt-4">
                <h4>Catering: </h4>
                <p className="para-pdf">{eventDetails[0]?.catering || ""}</p>
              </div>
            </div>

            <div className="info-box mb-5">
              <p className="para-pdf">Setlist</p>
              <h4 className="pdf-text">
                {eventDetails[0]?.set_list_name || ""}
              </h4>
              <div className="mt-4">
                {eventDetails[0]?.set_list_details?.length > 0 ? (
                  eventDetails[0]?.set_list_details?.map((res) => {
                    return <p className="para-pdf"> {res?.song_name}</p>;
                  })
                ) : (
                  <p>No Data Found</p>
                )}
              </div>
            </div>

            <div className="info-box mb-5">
              {/* <p className='para-pdf'>Location</p> */}
              <h4>Band</h4>
              {eventDetails[0]?.band_users_details?.map((res, i) => {
                return (
                  <div className="inside-card d-flex justify-content-between mt-1">
                    <h4>{res?.role} </h4>
                    <p className="para-pdf">
                      {res?.first_name} {res?.last_name}{" "}
                    </p>
                  </div>
                );
              })}

              <h4 className="mt-5">Personal</h4>
              {eventDetails[0]?.band_staff_details?.map((res, i) => {
                return (
                  <div className="inside-card d-flex justify-content-between mt-1">
                    <h4>{res?.role} </h4>
                    <p className="para-pdf">
                      {res?.first_name} {res?.last_name}{" "}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default ConcertPdf;
