import React, { useState, useEffect } from "react";
import GenerateLink from "./generateLink";
import ApproveModal from "./approveModal";
import RejectModal from "./rejectModal";
import Cookies from "universal-cookie";
import { getBandStaff } from "../../app_redux/slice/bandstaffList";
import { useSelector, useDispatch } from "react-redux";
import ChangePermissionModal from "../Bandstaff/changePermission";
import StaffDetailModal from "../Bandstaff/staffDetailModal";
import axios from "axios";
import RemoveModal from "./removeModal";
import { useTranslation } from "react-i18next";
import DataLoader from "../Loader/dataLoader";

const Invitation = () => {
  const { t } = useTranslation();
  const cookie = new Cookies();
  const dispatch = useDispatch();
  const token = cookie.get("token");
  const [showGenerateLink, setShowGenerateLink] = useState(false);
  const [showApprove, setApproveShow] = useState(false);
  const [showReject, setRejectShow] = useState(false);
  const [showRemove, setRemoveShow] = useState(false);
  const { allBandStaff, loading } = useSelector((state) => state.bandstaff);
  const [staffId, setStaffId] = useState("");
  const [permissionId, setPermissionId] = useState("");
  const [detailModal, setDetailModal] = useState(false);
  const [showPermission, setShowPermission] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [search, setSearch] = useState("");
  const [link, setLink] = useState("");
  const {
    tab1,
    tab2,
    tab3,
    tab4,
    tab5,
    tab6,
    tab7,
    tab8,
    tab9,
    tab10,
    tab12,
    tab14,
  } = t("band");

  const lng = cookie.get("googtrans");

  useEffect(() => {
    dispatch(getBandStaff({ pageno: pageNo, search: "" }));
  }, []);
  const handleLinkGenerate = () => {
    axios({
      method: "get",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/band-generate-referral-link`,
    }).then((res) => {
      console.log(res);
      if (res.status === 200) {
        setLink(res?.data?.data?.prod);
        setShowGenerateLink(true);
      }
    });
  };
  const handlegenerateLink = () => {
    setShowGenerateLink(!showGenerateLink);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    dispatch(getBandStaff({ pageno: pageNo, search: e.target.value }));
  };
  const handleApprove = () => {
    setApproveShow(!showApprove);
  };
  const handleReject = (id, ids) => {
    setStaffId(id);
    setRejectShow(!showReject);
  };
  const handleRemoveData = () => {
    setRemoveShow(!showRemove);
  };
  const handleRemove = (id, ids) => {
    setStaffId(id);
    setPermissionId(ids);
    setRemoveShow(!showReject);
  };
  const handleShowPermission = () => {
    setShowPermission(!showPermission);
  };

  const handleChangePermission = (id, ids, p) => {
    setStaffId(id);
    setPermissionId(ids);
    handleShowPermission();
  };
  const handlePermission = (id, ids, p) => {
    setStaffId(id);
    setPermissionId(ids);
    handleApprove();
  };
  const handleShowDetail = () => {
    setDetailModal(!detailModal);
  };
  const detailBandStaff = (id, ids) => {
    if (ids === 1) {
      setStaffId(id);
      handleShowDetail();
    }
  };
  const handlePrevious = (e) => {
    e.preventDefault();
    setPageNo(pageNo - 1);
    dispatch(getBandStaff({ pageno: pageNo - 1, search: "" }));
  };
  const handleNext = (e) => {
    e.preventDefault();
    setPageNo(pageNo + 1);
    dispatch(getBandStaff({ pageno: pageNo + 1, search: "" }));
  };
  const onKeyPressEvent = (e) => {
    if (e.which === 13) {
      e.preventDefault();
      dispatch(getBandStaff({ pageno: pageNo, search: search }));
    }
  };

  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className=" py-2 px-3">
                <h4 className="mb-0 theme-color">{tab1}</h4>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="d-flex align-items-center lg-justify-content-end gap-3">
                <form className="has-search">
                  <span className="form-search-icon form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    value={search}
                    onChange={handleSearch}
                    placeholder="Search.."
                    onKeyPress={onKeyPressEvent}
                  />
                </form>
              </div>
            </div>
          </div>
          <div className="row mt-3 align-items-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 position-relative">
              {/* <ul className="nav nav-pills group-nav tabNabs" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="pills-active-tab" data-bs-toggle="pill" data-bs-target="#pills-active" type="button" role="tab" aria-controls="pills-active" aria-selected="true">new</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-inactive-tab" data-bs-toggle="pill" data-bs-target="#pills-inactive" type="button" role="tab" aria-controls="pills-inactive" aria-selected="false">approved</button>
                                </li>
                            </ul> */}
              <div className="d-flex align-items-center lg-justify-content-end">
                <button className="btn add-new" onClick={handleLinkGenerate}>
                  {tab7}
                </button>
                <img
                  src="assets/images/Infoblack.png"
                  alt=""
                  className="mx-1 mb-4"
                  title="Hier können Sie einen Einladungslink generieren. Senden Sie diesen an Personen, welche Sie gerne zu Ihrer Band einladen möchten. Der Link bleibt nach dem Generieren für 4 Wochen aktiv.Here you can generate an invitation link. Send this to people you would like to invite to your band. The link remains active for 4 weeks after generation"
                />
              </div>
            </div>
            {loading ? (
              <DataLoader />
            ) : (
              <div className="col-sm-12">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-active"
                    role="tabpanel"
                    aria-labelledby="pills-active-tab"
                  >
                    <div className="table-responsive text-center">
                      <table className="table storetable">
                        <thead className="text-center">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">{tab2}</th>
                            <th scope="col">{tab3}</th>
                            <th scope="col">Email</th>
                            <th scope="col">{tab4}</th>
                            <th scope="col">
                              {tab5}{" "}
                              <img
                                src="assets/images/Infoblack.png"
                                alt=""
                                className="mx-1 mb-4"
                                title={
                                  lng === "/auto/de" || "/en/de"
                                    ? "BANDLEADER:- Hat Zugriff auf den Bandaccount und kann in vollem Umfang agieren , Hat alle weitere Rechte über seinen Account wie unter Musiker aufgeführt. MUSIKER:- Hat keinen Zugriff auf den Bandaccount, kann lediglich über seinen Persönlichen Account Informationen empfangen und senden,Kann zu Events eingeladen werden und Informationen hierzu empfangen, Kann am Gruppenchat der Band teilnehmen, Kann Tasks zugeordnet werden und den Status ändern, Kann Songs der Band einsehen und Dateien hierzu uploaden/downloaden, Kann Setlisten der Band einsehen und ausdrucken, Eingetragene Abwesenheiten werden an Bandaccount übertragen MITARBEITER :- Hat keinen Zugriff auf den Bandaccount, kann lediglich über seinen Persönlichen Account Informationen empfangen und senden,Kann zu Events eingeladen werden und Informationen hierzu empfangen,Kann NICHT am Gruppenchat der Band teilnehmen, Kann Tasks zugeordnet werden und den Status ändern, Kann Songs der Band NICHT einsehen, Kann Setlisten der Band einsehen und ausdrucken,Eingetragene Abwesenheiten werden an Bandaccount übertragen, MANAGER: Hat Zugriff auf den Bandaccount und kann in vollem Umfang agieren, Hat alle weitere Rechte über seinen Account wie unter Musiker aufgeführt"
                                    : "BANDLEADER:-Has access to the band account and can act in full scope, Has all other rights over his account as listed under musicians. Musician:- Has no access to the band account, can only receive and send information via his personal account, Can be invited to events and receive information about them, Can participate in the band's group chat, Can be assigned to tasks and change status, Can view the band's songs and upload/download files related to them, Can view and print band setlists, Entered absences are transferred to Bandaccount. Employees:-Has no access to the band account, can only receive and send information via his personal account,Can be invited to events and receive information about them,Can NOT join the band's group chat, Can view and print band setlists, Entered absences are transferred to Bandaccount. Manager:- Has access to the band account and can act in full scope, Has all other rights to his account as listed under musicians"
                                }
                              />
                            </th>
                            <th scop="col">
                              {tab6}{" "}
                              <img
                                src="assets/images/Infoblack.png"
                                alt=""
                                className="mx-1 mb-4"
                                title={
                                  lng === "/auto/de" || "/en/de"
                                    ? "Hier sehen Sie den Status der Mitgliedsanfrage"
                                    : "Here you can see the status of the membership request"
                                }
                              />
                            </th>
                            <th scope="col">
                              {tab12}{" "}
                              <img
                                src="assets/images/Infoblack.png"
                                alt=""
                                className="mx-1 mb-4"
                                title={
                                  lng === "/auto/de" || "/en/de"
                                    ? "Hier können Sie ein Mitglied aus Ihrer Band entfernen, Einladungsanfragen akzeptieren oder ablehnen."
                                    : "Here you can remove a member from your band, accept or decline invitation requests."
                                }
                              />
                            </th>
                          </tr>
                          <tr className="border-0 bg-none invisible">
                            <td className="border-0 bg-none"></td>
                          </tr>
                        </thead>
                        <tbody>
                          {allBandStaff?.data2?.map((res, i) => {
                            return (
                              <tr key={i}>
                                {res?.is_creator == 1 ? (
                                  <td
                                    onClick={() =>
                                      detailBandStaff(
                                        res.staff_id,
                                        res.is_creator
                                      )
                                    }
                                  >
                                    <img
                                      src="assets/images/down-filled-triangular-arrow.png"
                                      width=""
                                      alt=""
                                    />
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                                <td>{res?.first_name}</td>
                                <td>{res?.last_name}</td>
                                <td>{res?.email}</td>
                                <td>
                                  {res?.country_code} {res?.phone_number}
                                </td>

                                <td style={{ cursor: "pointer" }}>
                                  <span className="StatusBtn approvedStatus">
                                    {" "}
                                    Creator
                                  </span>
                                </td>

                                <td></td>
                              </tr>
                            );
                          })}
                          {allBandStaff?.data?.length > 0
                            ? allBandStaff?.data?.map((res, i) => {
                                return (
                                  <tr key={i}>
                                    {res?.is_approved_band ? (
                                      <td
                                        onClick={() =>
                                          detailBandStaff(
                                            res.staff_id,
                                            res.is_approved_band
                                          )
                                        }
                                      >
                                        <img
                                          src="assets/images/down-filled-triangular-arrow.png"
                                          width=""
                                          alt=""
                                        />
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}

                                    <td>{res?.first_name}</td>
                                    <td>{res?.last_name}</td>
                                    <td>{res?.email}</td>
                                    <td>
                                      {res?.country_code} {res?.phone_number}
                                    </td>

                                    <td
                                      onClick={() =>
                                        handleChangePermission(
                                          res.staff_id,
                                          res.permission_detail_id,
                                          res.permission_type
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {res.permission_type === 1 ? (
                                        <span className="StatusBtn permissionStatus">
                                          {" "}
                                          Musician
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {res.permission_type === 2 ? (
                                        <span className="StatusBtn permissionStatus">
                                          Bandleader
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {res.permission_type === 3 ? (
                                        <span className="StatusBtn permissionStatus">
                                          Staff
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {res.permission_type === 4 ? (
                                        <span className="StatusBtn permissionStatus">
                                          Manager
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {res.permission_type === 5 ? (
                                        <span className="StatusBtn permissionStatus">
                                          Booker
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>
                                      {res?.is_approved_band === 0 ? (
                                        <span className="StatusBtn pendingStatus">
                                          Pending
                                        </span>
                                      ) : (
                                        <span className="StatusBtn approvedStatus">
                                          Accepted{" "}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <div
                                        className="d-flex justify-content-center action"
                                        style={{ cursor: "pointer" }}
                                      >
                                        {res?.is_approved_band === 0 ? (
                                          <>
                                            <img
                                              src="assets/images/approve.png"
                                              alt=""
                                              onClick={() =>
                                                handlePermission(
                                                  res.staff_id,
                                                  res.permission_detail_id,
                                                  res.permission_type
                                                )
                                              }
                                            />
                                            <img
                                              src="assets/images/reject.png"
                                              alt=""
                                              onClick={() =>
                                                handleRemove(
                                                  res.staff_id,
                                                  res.permission_detail_id,
                                                  res.permission_type
                                                )
                                              }
                                            />
                                          </>
                                        ) : (
                                          <img
                                            src="assets/images/deletenew.png"
                                            alt=""
                                            onClick={() =>
                                              handleReject(res.staff_id)
                                            }
                                          />
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {pageNo === 1 && allBandStaff?.length <= 9 ? (
                  ""
                ) : (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                    <div className="d-flex align-items-center lg-justify-content-end">
                      <nav aria-label="...">
                        <ul className="pagination">
                          <li
                            className={
                              pageNo === 1 ? "page-item disabled" : "page-item"
                            }
                          >
                            <a
                              className="page-link"
                              href="#"
                              onClick={handlePrevious}
                            >
                              Previous
                            </a>
                          </li>
                          <li class="page-item active">
                            <button className="page-link" href="#">
                              {pageNo}{" "}
                              <span className="sr-only">(current)</span>
                            </button>
                          </li>
                          <li
                            class={
                              allBandStaff?.length <= 9
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <a
                              className="page-link"
                              href="#"
                              onClick={handleNext}
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </article>
      {showGenerateLink ? (
        <GenerateLink
          show={showGenerateLink}
          handleShow={handlegenerateLink}
          link={link}
          tab14={tab14}
        />
      ) : (
        ""
      )}
      {showApprove ? (
        <ApproveModal
          show={showApprove}
          handleShow={handleApprove}
          id={staffId}
          permissionId={permissionId}
        />
      ) : (
        ""
      )}
      {showRemove ? (
        <RemoveModal
          show={showRemove}
          handleShow={handleRemoveData}
          id={staffId}
          permissionId={permissionId}
        />
      ) : (
        ""
      )}
      {showReject ? (
        <RejectModal show={showReject} handleShow={handleReject} id={staffId} />
      ) : (
        ""
      )}
      {showPermission ? (
        <ChangePermissionModal
          show={showPermission}
          handleShow={handleShowPermission}
          id={staffId}
          permissionId={permissionId}
          tab8={tab8}
          tab9={tab9}
          tab10={tab10}
        />
      ) : (
        ""
      )}
      {detailModal ? (
        <StaffDetailModal
          show={detailModal}
          handleShow={handleShowDetail}
          id={staffId}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Invitation;
