import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getLoadSetup = createAsyncThunk("setup", async () => {
    const cookee = new Cookies();
    const token = cookee.get('token')
    let setup =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/get-load-setup`,
            data:{
                "event_type": 1
            }
        })
            .then(res => res.data.data)
    return setup
})

const listSongs = createSlice(({
    name: "all setup",
    initialState: {
        loadSetup: [],
        loading: false
    },
    extraReducers: {
        [getLoadSetup.pending]: (state, action) => {
            state.loading = true;
        },
        [getLoadSetup.fulfilled]: (state, action) => {
            state.loadSetup = action.payload
        },
        [getLoadSetup.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listSongs.reducer;