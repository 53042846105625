import React, { useEffect, useState } from 'react'
import { getSongs } from '../../app_redux/slice/songList'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { getTaskChats } from '../../app_redux/slice/taskChat'
import { getBandChats } from '../../app_redux/slice/bandChat'
import { useLocation } from 'react-router-dom';


const ChatTask = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    const cookee = new Cookies();
    const token = cookee.get('token')
    const [message, setMessage] = useState("")
    const [message2, setMessage2] = useState("")
    const [chatlist, setChatlist] = useState([])
    const [bandMusician, setBandMusician] = useState([])
    const [eventId, setEventId] = useState("")
    const [userId, setUserId] = useState("")
    const [userId2, setUserId2] = useState("")
    // const { allEventChats, loading } = useSelector((state) => state.eventchat)
    const { allBandChats } = useSelector((state) => state.bandchat)
    const [allEventChats, setAllEventChats] = useState([])
    const { allTaskChats } = useSelector((state) => state.taskchat)


    useEffect(() => {
        // dispatch(getTaskChats({ taskid: location.state.taskid }))
        setInterval(handleAutoRefresh, 5000)
    }, [])
    const onKeyPress = (e) => {
        if (e.which === 13) {
            handleSendMessage();
        }
    }
    const handleAutoRefresh = () => {
        dispatch(getTaskChats({ taskid: location.state.taskid }))
    }
    const handleMessage = (e) => {
        setMessage(e.target.value)
    }
    const handleMessage2 = (e) => {
        setMessage2(e.target.value)
    }
    const getChatList = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-band-leader-event-chat-list`,
            data:
            {
                "page_no": 1
            }
        })
            .then(res => {
                setChatlist(res.data.data)
            })
    }
    const valid = () => {
        if (message.length === 0) {
            return false
        }
        else {
            return true
        }
    }
    const handleSendMessage = () => {
        if(valid()){
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/band-leader-musician-task-chat-send-message`,
            data:
            {
                "task_id": location.state.taskid,
                "message": message,
                "message_type": "T",
                "media_url": "",
                "user_type": 2
            }
        })
            .then(res => {
                //  console.log("res", res.data)
                if (res.data.message === "Message Sent Successfully!.") {
                    dispatch(getTaskChats({ taskid: location.state.taskid }))
                    setMessage("")
                }
            })
        }
    }



    return (
        <article className="col-lg-9">
            <div className="article-body store-bd-gy">
                <section className="chatFlex">
                    <div className="bgChatSec">
                        <div className=''>
                            {allTaskChats?.length !== 0 ? allTaskChats?.map((res, i) => {
                                return <>

                                    {res.user_type === 1 ? <>
                                        {/* <img src={allEventChats?.userProfileDetails[0]?.profile_pic} alt="" className="chatImg headerImgProfilePic" /> <b>{allEventChats?.userProfileDetails[0]?.first_name}</b> */}
                                        <div className="chatText chatTextWidthWhite">
                                            <p>{res.message}</p>
                                            {res.media_url ? <img className="mb-0 d-flex align-items-center gap-3 justify-content-end" src={res.media_url} alt="" style={{ width: "300px" }} /> : ""}
                                            <p className="mb-0 d-flex align-items-center gap-3 justify-content-end"> <img src="assets/images/blackDots.png" alt="" className="" /></p>
                                        </div> </> : ""}
                                    {res.user_type === 2 ? <div className="chatText chatTextWidthRed">
                                        <p>{res.message}</p>
                                        <p className="mb-0 d-flex align-items-center gap-3 justify-content-end"> <img src="assets/images/whiteDots.png" alt="" className="" /></p>
                                    </div> : ""}
                                </>
                            }) : <p>No Messages!!</p>}

                        </div>

                        <div className="ChatSendInput">
                            <div className="position-relative">
                                <input type="text" value={message} onKeyPress={onKeyPress} className="form-control" placeholder="Write a message" onChange={handleMessage} />
                                {/* <img src="assets/images/laugh.png" alt="" className="laughIcon" /> */}
                                <div className="InputIconRight">
                                    <img src="assets/images/send-message.png" alt="" className="iconImages" onClick={handleSendMessage} />
                                </div>
                            </div>
                        </div>
                    </div>


                </section>
            </div >

        </article >

    )
}

export default ChatTask