import React from 'react'
import Header from '../Components/Header/header'
import Sidebar from '../Components/Sidebar/sidebar'
import { Outlet } from 'react-router-dom'
import AboutUs from '../Components/Aboutus/aboutUs'

const SinglePage = () => {
    return (
        <>
            <div className="admin-panel">
                <Header />
                <Outlet />
            </div>
        </>
    )
}

export default SinglePage