import React, { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getGemaSubmitted } from "../../app_redux/slice/gemaSubmitted";
import { getEvents } from "../../app_redux/slice/gemaEvents";
import { gemaEventDetails } from "../../app_redux/slice/gemaEventDetail";
import { useFormik } from "formik";
import { gemaSchema } from "../gemashema";
import { getEventDetail } from "../../app_redux/slice/eventDetail";
import ButtonLoader from "../Loader/loading";
import { useTranslation } from "react-i18next";

const initialValues = {
  tandc: false,
};
const ReportEventModal = ({ show, handleShow, id }) => {
  const dispatch = useDispatch();
  const cookee = new Cookies();
  const token = cookee.get("token");
  const lng = cookee.get("googtrans");
  const { gemaDetails } = useSelector((state) => state.gemaevents);
  const { eventDetails } = useSelector((state) => state.eventdetail);

  const [eventName, setEventName] = useState("");
  const [location, setLocation] = useState("");
  const [date, setDate] = useState("");
  const [stageStartTime, setStageStartTime] = useState("");
  const [stageEndTime, setStageEndTime] = useState("");
  const [organizerName, setOrganizerName] = useState("");
  const [organizerStreet, setOrganizerStreet] = useState("");
  const [organizerHouseNo, setOrganizerHouseNo] = useState("");
  const [organizerZipcode, setOrganizerZipcode] = useState("");
  const [organizerTown, setOrganizerTown] = useState("");
  const [organizerCountry, setOrganizerCountry] = useState("");
  const [loader, setLoader] = useState(false);
  const [organizerId, setOrganizerId] = useState("");
  const [works, setworks] = useState("");
  const [copyright, setCopyright] = useState("");
  const [lengthSet, setLengthSet] = useState("");
  const [eventType, setEventType] = useState("");
  const [performanceType, setPerformanceType] = useState("");
  const [setlistId, setSetlistId] = useState("");
  const [eventCountry, setEventCountry] = useState("");
  const [eventZipcode, setEventZipcode] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [houseNumber, setHousenumber] = useState("");
  const [country, setCountry] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [town, setTown] = useState("");
  const [locationStreet, setLocationStreet] = useState("");
  const [locationHouseNo, setLocationHouseNo] = useState("");
  const [load, setLoad] = useState(false);
  const { t } = useTranslation();

  const {
    tab14,
    tab15,
    tab16,
    tab17,
    tab18,
    tab19,
    tab20,
    tab21,
    tab22,
    tab23,
    tab24,
  } = t("gema");

  useEffect(() => {
    dispatch(gemaEventDetails());
    dispatch(getEventDetail({ eventid: id }));
    getData();
    getMusicianInfo();
  }, []);
  const { errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: gemaSchema,
    onSubmit: () => {
      // console.log("data", values)
      handleReport();
    },
  });
  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];

    return day + "/" + month + "/" + year;
  }
  const getMusicianInfo = () => {
    axios({
      method: "get",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-profile`,
    }).then((res) => {
      setCity(res.data.data.city);
      setTown(res.data.data.town);
      setCountry(res.data.data.country);
      setStreet(res.data.data.street);
      setZipcode(res.data.data.zip_code);
      setHousenumber(res.data.data.house_number);
      setName(res.data.data.band_leader_first_name);
      setLastName(res.data.data.band_leader_last_name);
    });
  };
  const getData = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-event-details-event-id`,
      data: {
        event_id: id,
      },
    }).then((res) => {
      setEventName(res.data.data[0].event_name);
      setLocation(res.data.data[0].location_name);
      setLocationStreet(res.data.data[0].location_street);
      setDate(formatDate(res.data.data[0].start_date?.substr(0, 10)));
      setSetlistId(res.data.data[0].set_list_id);
      setStageStartTime(res.data.data[0].stage_start_time);
      setStageEndTime(res.data.data[0].stage_end_time);
      setOrganizerName(res.data.data[0]?.info_organizer[0]?.organizer_name);
      setOrganizerTown(res.data.data[0]?.info_organizer[0]?.organizer_town);
      setOrganizerStreet(res.data.data[0]?.info_organizer[0]?.organizer_street);
      setOrganizerHouseNo(
        res.data.data[0]?.info_organizer[0]?.organizer_house_number
      );
      setOrganizerZipcode(
        res.data.data[0]?.info_organizer[0]?.organizer_postal
      );
      setOrganizerId(res.data.data[0]?.info_organizer[0]?.event_organizer_id);
      setOrganizerCountry(
        res.data.data[0]?.info_organizer[0]?.organizer_country
      );

      setworks(res.data.data[0]?.total_number_of_works);
      setLengthSet(res.data.data[0]?.length_set);
      setCopyright(res.data.data[0]?.number_of_works_with_copyright);
      setEventType(res.data.data[0]?.type_of_event);
      setPerformanceType(res.data.data[0]?.type_of_performance);
      setEventCountry(res.data.data[0]?.event_country);
      setLocationHouseNo(res.data.data[0]?.event_house_number);
      setEventZipcode(res.data.data[0]?.event_zip_code);
      // setTown(res.data.data[0]?.town)
      setSetlistId(res.data.data[0].set_list_details);
    });
  };

  const valid = () => {
    if (eventName.length === 0) {
      return false;
    } else if (eventCountry.length === 0) {
      return false;
    } else if (eventZipcode.length === 0) {
      return false;
    } else if (eventType.length === 0) {
      return false;
    } else if (performanceType.length === 0) {
      return false;
    } else if (stageStartTime.length === 0) {
      return false;
    }
    // else if (works.length === 0) {
    //     return false
    // }
    // else if (copyright.length === 0) {
    //     return false
    // }
    // else if (lengthSet.length === 0) {
    //     return false
    // }
    else if (organizerName.length === 0) {
      return false;
    } else if (organizerStreet.length === 0) {
      return false;
    } else if (organizerTown.length === 0) {
      return false;
    } else if (organizerCountry.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleReport = () => {
    setLoader(true);
    if (valid()) {
      setLoad(true);
      axios({
        method: "post",
        headers: {
          Accept: "/",
          "Content-Type": "application/json",
          auth_token: token,
        },
        url: `${process.env.REACT_APP_URL}/band-leader-report-concert-event`,
        data: {
          event_id: id,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.message, {
              theme: "colored",
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoader(false);
            setLoad(false);
            dispatch(getGemaSubmitted({ pageno: 1 }));
            dispatch(getEvents({ pageno: 1 }));

            handleShow();
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoader(false);
          setLoad(false);
        });
    } else {
      toast.error("All Information Must Be Needed!.", {
        theme: "colored",
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <>
      <Modal show={show} onHide={handleShow} size="xl">
        <Modal.Body className="p-0">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close close-Icon"
              onClick={handleShow}
            ></button>
            <div className="row">
              <form className=" row col-sm-6 col-md-6 col-lg-6 h-100 my-5 py-5 addNAme mx-width">
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={eventName}
                    placeholder="Name of Event"
                    readOnly
                    style={{ cursor: " context-menu" }}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={eventType}
                    placeholder="Type of Event"
                    readOnly
                    style={{ cursor: " context-menu" }}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={stageStartTime?.substr(0, 5)}
                    placeholder="Stage Start Time"
                    readOnly
                    style={{ cursor: " context-menu" }}
                  />
                </div>
                {/* <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                                    <input type="text" className="form-control" value={stageEndTime} placeholder="Stage End Time" />
                                </div> */}
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={date}
                    placeholder="Date of Event"
                    readOnly
                    style={{ cursor: " context-menu" }}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={performanceType}
                    placeholder={
                      lng === "/auto/de" || "/en/de"
                        ? "Art der Aufführung"
                        : "Type of Performance"
                    }
                    readOnly
                    style={{ cursor: " context-menu" }}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5"></div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5 mt-3">
                  <h6 className="notranslate">{tab14}</h6>
                  <input
                    type="text"
                    className="form-control"
                    value={location}
                    placeholder={
                      lng === "/auto/de" || "/en/de"
                        ? "Name Location"
                        : "Location"
                    }
                    readOnly
                    style={{ cursor: " context-menu" }}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5 mt-3">
                  <h6>{tab17}</h6>
                  <input
                    type="text"
                    className="form-control"
                    value={organizerName}
                    placeholder={tab15}
                    readOnly
                    style={{ cursor: " context-menu" }}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5 mt-5">
                  {/* <input type="text" className="form-control" value={works} placeholder="Number of Works" readOnly style={{ cursor: " context-menu" }} /> */}
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={locationStreet}
                    placeholder={tab18}
                    readOnly
                    style={{ cursor: " context-menu" }}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={organizerStreet}
                    placeholder="Street"
                    readOnly
                    style={{ cursor: " context-menu" }}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  {/* <input type="text" className="form-control" value={copyright} placeholder="Number of Works with Copyright" readOnly style={{ cursor: " context-menu" }} /> */}
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={locationHouseNo}
                    placeholder={tab19}
                    readOnly
                    style={{ cursor: " context-menu" }}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={organizerHouseNo}
                    placeholder={tab19}
                    readOnly
                    style={{ cursor: " context-menu" }}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  {/* <input type="text" className="form-control" value={lengthSet?.substr(0, 5)} placeholder="Long Set" readOnly style={{ cursor: " context-menu" }} /> */}
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={eventZipcode}
                    placeholder={tab20}
                    readOnly
                    style={{ cursor: " context-menu" }}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={organizerZipcode}
                    placeholder={tab20}
                    readOnly
                    style={{ cursor: " context-menu" }}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5"></div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={town}
                    placeholder={tab21}
                    readOnly
                    style={{ cursor: " context-menu" }}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={organizerTown}
                    placeholder={tab21}
                    readOnly
                    style={{ cursor: " context-menu" }}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5"></div>

                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={eventCountry}
                    placeholder={tab22}
                    readOnly
                    style={{ cursor: " context-menu" }}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                  <input
                    type="text"
                    className="form-control"
                    value={organizerCountry}
                    placeholder={tab22}
                    readOnly
                    style={{ cursor: " context-menu" }}
                  />
                </div>
              </form>
              <div className="col-sm-6 col-md-6 col-lg-6 h-100 mt-5">
                <form className="row mt-5 addNAme mx-width">
                  <h4>Bandleader Information</h4>
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      placeholder={tab16}
                      readOnly
                      style={{ cursor: " context-menu" }}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                    <input
                      type="text"
                      className="form-control"
                      value={lastName}
                      placeholder={tab17}
                      readOnly
                      style={{ cursor: " context-menu" }}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                    <input
                      type="text"
                      className="form-control"
                      value={street}
                      placeholder={tab18}
                      readOnly
                      style={{ cursor: " context-menu" }}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                    <input
                      type="text"
                      className="form-control"
                      value={houseNumber}
                      placeholder="House-number"
                      readOnly
                      style={{ cursor: " context-menu" }}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                    <input
                      type="text"
                      className="form-control"
                      value={zipcode}
                      placeholder="Zip-Code"
                      readOnly
                      style={{ cursor: " context-menu" }}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                    <input
                      type="text"
                      className="form-control"
                      value={town}
                      placeholder="Town"
                      readOnly
                      style={{ cursor: " context-menu" }}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                    <input
                      type="text"
                      className="form-control"
                      value={country}
                      placeholder={tab22}
                      readOnly
                      style={{ cursor: " context-menu" }}
                    />
                  </div>
                </form>
                <h4 className="mt-5">Setlist </h4>
                <div className="pt-3 px-1 bg-white shadow">
                  <div className="table-responsive setlistTable2">
                    <table className="table storetable mb-0">
                      <thead className="">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">{tab23}</th>
                          <th scope="col">Copyright</th>
                          <th scope="col">{tab24}</th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>

                      <tbody>
                        {eventDetails[0]?.set_list_details?.length !== 0 ? (
                          eventDetails[0]?.set_list_details.map((res, i) => {
                            return (
                              <tr>
                                <td>{res.song_name}</td>
                                <td>{res.gemma_number}</td>
                                <td>
                                  <img
                                    src={
                                      res.is_copyright === 1
                                        ? "assets/images/copyRed.png"
                                        : "assets/images/copyBlack.png"
                                    }
                                    width="25px"
                                    alt=""
                                  />
                                </td>
                                <td>{res.time_length?.substr(0, 8)}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <p className="mt-3">No Record Found !!</p>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <div className="d-flex align-items-center lg-justify-content-end mx-5">
                                    <p>Are you sure , you want to report this event?</p>
                                </div> */}
              <div className="col-sm-12 col-md-12 col-lg-12 mx-5 mb-3">
                <div className="form-group">
                  <div className="form-check ">
                    <input
                      className="form-check-input"
                      name="tandc"
                      type="checkbox"
                      id="gridCheck"
                      onChange={handleChange}
                    />
                    <label className="form-check-label" for="gridCheck">
                      {lng === "/auto/de" || "/en/de"
                        ? "Es wird versichert, dass alle Angaben über die Musikaufführung nach bestem Wissen gemacht worden sind."
                        : "It is assured that all information about the music performances has been given to the best of our knowledge."}
                    </label>
                  </div>
                </div>
                {errors.tandc && touched.tandc ? (
                  <p style={{ color: "red", marginTop: "10px" }}>
                    {errors.tandc}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3 mx-5">
                <div className="text-center my-3 mt-5 mx-5">
                  <button className="btn add-new-cancel " onClick={handleShow}>
                    {lng === "/auto/de" || "/en/de" ? "Abbrechen" : "cancel"}
                  </button>
                  {load ? (
                    <ButtonLoader view="btn add-new-del " />
                  ) : (
                    <button className="btn add-new-del " onClick={handleSubmit}>
                      {lng === "/auto/de" || "/en/de" ? "Senden" : "confirm"}
                    </button>
                  )}
                  {/* {loader ? <button className="btn add-new-del" type="submit" disabled> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span className="sr-only">Loading...</span></button> :
                                        } */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ReportEventModal;
