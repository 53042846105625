import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getAllTasks = createAsyncThunk("all tasks", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('token')
    let tasks =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/get-band-leader-task-list`,
            data:{
                "page_no":1,
                "is_self":3,
                "status":1
            }
        })
            .then(res => res.data.data)
    return tasks
})

const listTasks = createSlice(({
    name: "all tasks",
    initialState: {
        allTasks: [],
        loading: false
    },
    extraReducers: {
        [getAllTasks.pending]: (state, action) => {
            state.loading = true;
        },
        [getAllTasks.fulfilled]: (state, action) => {
            state.allTasks = action.payload
        },
        [getAllTasks.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listTasks.reducer;