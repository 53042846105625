import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

const cookee = new Cookies();

export const getAttachmentPermission = createAsyncThunk("submitted", async (obj) => {
    let token = cookee.get('token');
    // console.log("RED", obj?.page)
    let permissiondata = await axios({
        method: 'get',
        headers: {
            "Accept": "/",
            "Content-Type": "application/json",
            "auth_token": token
        },
        url: `${process.env.REACT_APP_URL}/get-all-attachment-permission-for-an-event?event_id=${obj?.id}`,

    })
        .then(res => res.data.data)
    return permissiondata
})

const listPermission= createSlice(({
    name: "permissions",
    initialState: {
        Permissions: [],
        loading: false
    },
    extraReducers: {
        [getAttachmentPermission.pending]: (state, action) => {
            state.loading = true;
        },
        [getAttachmentPermission.fulfilled]: (state, action) => {
            state.Permissions = action.payload
        },
        [getAttachmentPermission.rejected]: (state, action) => {
            state.loading = false;
        }
    }
}))

export default listPermission.reducer;