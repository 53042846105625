import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getTaskDetailAdmin = createAsyncThunk("taskdetailadmin", async () => {
    const cookee = new Cookies();
    const token = cookee.get('token')
    let detail =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/band-leader-task-detail`,
            data: {
                "band_leader_task_id": localStorage.getItem("taskid"),
                "is_self":2
            }
        })
            .then(res => res.data.data)
    return detail
})

const taskAdminDetail = createSlice(({
    name: "task detail",
    initialState: {
        taskAdminDetail: [],
        loading: false
    },
    extraReducers: {
        [getTaskDetailAdmin.pending]: (state, action) => {
            state.loading = true;
        },
        [getTaskDetailAdmin.fulfilled]: (state, action) => {
            state.taskAdminDetail = action.payload
        },
        [getTaskDetailAdmin.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default taskAdminDetail.reducer;