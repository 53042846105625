import React from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import { useFormik } from 'formik'
import { resetPasswordSchema } from '../resetPasswordSchema';

const initialValues = {
    newpassword: "",
    confirmpassword: ""
}
const ResetPassword = () => {
    const email = localStorage.getItem("email")
    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: resetPasswordSchema,
        onSubmit: (values, action) => {
            //  console.log("data", values)
            handleResetPassword()
        }
    })
    const handleResetPassword = () => {
        //  e.preventDefault();
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
            },
            url: `${process.env.REACT_APP_URL}/band-leader-forgot-password`,
            data:
            {
                "country_code": "",
                "phone_number": "",
                "email": email,
                "new_password": values.newpassword
            }
        })
            .then(res => {

                if (res.data.message === "Password Reset Successfully!.") {
                    toast.success("Password Reset Successfully!.", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })

                }

            })
            .catch((error) => {
                if (error.response.data.message === 'Invalid Phone Number! Retry.') {
                    toast.error("Invalid Phone Number.", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
    }
    return (
        <section className="bg-image">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-sm-12 col-md-12 col-lg-12 mr-0 pr-0">
                        <div className="form-bg">
                            <form className="form-style">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 text-center mb-4">
                                        <div className="logo-bg text-center">
                                            <div className="mt-2"><img src="assets/images/logo.png" className="img-fluid logo-admin" alt="logo" /></div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center mb-4">
                                        <img src="assets/images/change-password.png" className="img-fluid iconIMg" alt="" />
                                        <h2 className="mdl-ttl">Reset Password</h2>
                                        <p className="mdl-sbttl">Please reset your password here</p>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                        <div className="form-group">
                                            <div className="input-container">
                                                <input type="text" className="form-control pl-5" placeholder="Set New Password" name="newpassword" value={values.newpassword} onChange={handleChange} onBlur={handleBlur} />
                                                <img src="assets/images/password.png" className="input-img" alt="" />
                                            </div>
                                            {errors.newpassword && touched.newpassword ? <p style={{ color: 'red' }}>{errors.newpassword}</p> : ""}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                        <div className="form-group">
                                            <div className="input-container">
                                                <input type="text" className="form-control pl-5" placeholder="Set Confirm New Password" name='confirmpassword' value={values.confirmpassword} onChange={handleChange} onBlur={handleBlur} />
                                                <img src="assets/images/password.png" className="input-img" alt="" />
                                            </div>
                                            {errors.confirmpassword && touched.confirmpassword ? <p style={{ color: 'red' }}>{errors.confirmpassword}</p> : ""}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 text-center">
                                        <button className="btn btn-save w-50" onClick={handleSubmit}>RESET</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </section>
    )
}

export default ResetPassword