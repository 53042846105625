import React from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useDispatch } from 'react-redux'
import { getSetlist } from '../../app_redux/slice/setlistData'
import { ToastContainer, toast } from 'react-toastify';


const DeleteSetlistModal = ({ show, handleShow, id, pageNo, tab2, tab3, tab4 }) => {
    const dispatch = useDispatch()
    const cookee = new Cookies();
    const token = cookee.get('token')

    const handleDeleteEvent = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/delete-band-leader-set-list`,
            data:
            {
                "set_list_id": id
            }
        })
            .then(res => {
                if (res.status === 200) {
                    toast.success(res.data.message, {
                        theme: 'colored',
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    dispatch(getSetlist({ pageno: pageNo }))
                    handleShow()
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    theme: "colored",
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }
    return (
        <>
            <Modal show={show} onHide={handleShow} size="md" centered >
                <Modal.Body className="p-0">
                    <div className="modal-body">
                        <button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
                                <div className="text-center mx-4">
                                    <h5>{tab2}</h5>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                                <div className=" text-center mt-5">
                                    <button className="btn add-new-cancel" onClick={handleShow}>{tab3}</button>
                                    <button className="btn add-new-del" onClick={handleDeleteEvent} >{tab4}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default DeleteSetlistModal