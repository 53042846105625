import React from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FileUploader } from "react-drag-drop-files";
import { getSongs } from '../../app_redux/slice/songList';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Cookies from 'universal-cookie'
import { ToastContainer, toast } from 'react-toastify';
import ButtonLoader from '../Loader/loading';



const fileTypes = ["xlsm", "xlsx"];

const ExcelUpload = ({ show, handleShow,  pageNo }) => {
    const dispatch = useDispatch()
    const cookie = new Cookies();
    const token = cookie.get('token')

    const handleChange = (file) => {
        console.log("file", file)
        const formData = new FormData();
        formData.append("file", file);

        axios
            .post(`${process.env.REACT_APP_URL}/band-leader-songs-upload-by-excel`, formData, {
                headers: {
                    "Accept": "/",
                    "Content-Type": "application/json",
                    "auth_token": token
                },
            })
            .then(res => {
                console.log("response", res)
                if (res.status === 200) {
                    toast.success("Song Added Successfully ", {
                        theme: 'colored',
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    handleShow()
                    dispatch(getSongs({ pageno: pageNo }))
                    

                }
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    theme: "colored",
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    };

   
    return (
        <Modal show={show} onHide={handleShow} centered size="md" >
            <Modal.Body className="p-0">
                <div className="modal-body">
                    <button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 mt-4 mb-4">
                            <h4 className="text-center">Excel Upload</h4>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                            <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
                        </div>
                        {/* <div className="col-sm-12 col-md-12 col-lg-12 text-center mt-5">
                            {file ?
                                load ? <ButtonLoader view="btn add-new" /> :
                                    <button className="btn add-new" onClick={handleUpload}>upload</button> : ""}
                        </div> */}
                    </div>
                </div>
            </Modal.Body>
        </Modal>


    )
}

export default ExcelUpload