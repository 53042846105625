import React, { useState } from 'react'
import ChatListItems from './chatListItems'
import AllChats from './allChats'
import { useSelector } from 'react-redux'
import { getEventChatList } from '../../app_redux/slice/eventChatlist'
import { getTaskChatList } from '../../app_redux/slice/taskChatlist'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ChatListItemsEvent from './eventList'
import EventChats from './eventAllChats'
import Cookies from 'universal-cookie'


const EventChatList = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const cookee = new Cookies();
    const lng = cookee.get('googtrans')
    const [eventId, setEventId] = useState("")
    const [band, setBand] = useState(false)
    const [event, setEvent] = useState(false)
    const [task, setTask] = useState(false)
    const [taskId, setTaskid] = useState("")
    const [chatName, setChatName] = useState("")

    const { allEventChats } = useSelector((state) => state.eventchat)
    const { allBandChats } = useSelector((state) => state.bandchat)
    const { allTaskChats } = useSelector((state) => state.taskchat)
    const handleBand = () => {
        navigate("/chat-list")
    }
    const handleEvent = () => {
        navigate("/chatlistEvent")
    }
    const handleTask = () => {
        navigate("/chatlistTask")
    }

    return (

        <article className="col-lg-9">
            <div className="article-body store-bd-gy">
                <div className="bg-white">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4 ledtBorder pe-0">
                            <h6 className="d-flex align-items-center justify-content-between px-2 pt-2 mb-0">
                                <div className="">
                                    <ul className="nav nav-pills group-nav bandUserTab chatBtnTab mt-0" id="pills-tab" role="tablist">
                                        <li className="nav-item me-3">
                                        <img src="assets/images/Infoblack.png" alt="" className=""  title={lng === "/auto/de" || "/en/de" ? "Hier haben Sie die Möglichkeit, mit allen Bandmitgliedern in einem Gruppenchat Nachrichten auszutauschen. Bandmitglieder mit der Freigabe „Mitarbeiter“ haben keinen Zugriff auf diesen Chat." : "Here you have the possibility to exchange messages with all band members in a group chat. Band members with staff permission do not have access to this chat."} />
                                            <button className="nav-link notranslate mt-2" onClick={handleBand}>Band </button>
                                        </li>
                                        <li className="nav-item me-3">
                                        <img src="assets/images/Infoblack.png" alt="" className=""  title={lng === "/auto/de" || "/en/de" ? "Hier finden Sie alle bereits eröffneten Event-Chats. Sie können einen Eventchat eröffnen, indem Sie in dem jeweiligen Event den Chat-Button klicken und einen Text senden. Dieser Chat taucht nun in der Auflistung auf." : "Here you will find all already opened event chats. You can open an event chat by clicking the chat button in the respective event and sending a text. This chat now appears in the list."} />
                                            <button className="nav-link notranslate active mt-2" onClick={handleEvent}>Event </button>
                                        </li>
                                        <li className="nav-item">
                                        <img src="assets/images/Infoblack.png" alt="" className=""  title={lng === "/auto/de" || "/en/de" ? "Hier finden Sie alle bereits eröffneten Task-Chats. Sie können einen Task-Chat eröffnen, indem Sie in dem jeweiligen Task den Chat-Button klicken und einen Text senden. Dieser Chat taucht nun in der Auflistung auf." : "Here you will find all already opened task chats. You can open a task chat by clicking the chat button in the respective task and sending a text. This chat now appears in the list."} />
                                            <button className="nav-link notranslate mt-2" onClick={handleTask}>Task </button>
                                        </li>
                                    </ul>
                                </div>
                            </h6>
                            <hr />
                            <ChatListItemsEvent setEventId={setEventId} band={band} event={event} task={task} setTaskid={setTaskid} setChatName={setChatName} />
                        </div>
                        <EventChats eventId={eventId} taskId={taskId} band={band} event={event} task={task} allEventChats={allEventChats} allBandChats={allBandChats} allTaskChats={allTaskChats} chatName={chatName} />
                    </div>
                </div>
            </div>

        </article>

    )
}

export default EventChatList