import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "de",
    lng: "de",
    returnObjects: true,
    resources: {
      en: {
        translation: {
          sidebar: {
            tab1: "Band/Invitation",
            tab2: "Band/Profile",
          },
          appointments: {
            tab1: "Upcoming events",
            tab2: "Invitations to event",
            tab3: "No Record found",
            tab4: "Past events",
            tab5: "Date",
            tab6: "Money",
          },
          setlist: {
            tab1: "Length of setlist",
            tab2: "Are you sure, you want to delete this setlist",
            tab3: "Cancel",
            tab4: "Delete",
            tab5: "No Record found",
            tab6: "Drag & Drop your songs here",
            tab7: "Save",
            tab8: "Song list",
            tab9: "Setlist name",
            tab10: "Action",
            tab11: "Add Setlist",
            tab12: "Total Time Setlist",
          },
          songs: {
            tab1: "Add Song",
            tab2: "Time length",
            tab3: "Copyright",
            tab4: "No Record found",
            tab5: "Name of song",
            tab6: "Gema Worknumber",
            tab7: "text files",
            tab8: "Music files",
            tab9: "Audio files",
            tab10: "composer",
            tab11: "Are you sure, you want to delete ?",
            tab12: "Cancel",
            tab13: "Delete",
            tab14: "Select",
            tab15: "Composer",
          },
          tasks: {
            tab1: "New",
            tab2: "Inprogress",
            tab3: "Completed",
            tab4: "Add new task",
            tab5: "Choose colour",
            tab6: "No Record found",
            tab7: "Done",
            tab8: "Assign to",
            tab9: "Create task",
            tab10: "Attachment",
            tab11: "Select",
            tab12: "Attachment",
            tab13: "Assigned To",
            tab14: "Current Status",
            tab15: "No Data Found",
            tab16: "Inprogress",
            tab17: "Done",
            tab18: "Back",
            tab19: "Save",
            tab20: "Select User",
            tab21: "Are you sure, you want to delete this task?",
            tab22: "Cancel",
            tab23: "Delete",
          },
          profile: {
            tab1: "Band Address",
            tab2: "Street",
            tab3: "House Number",
            tab4: "Zip code",
            tab5: "Town",
            tab6: "Country",
            tab7: "First Name",
            tab8: "Last Name",
          },
          setting: {
            tab1: "Profile",
            tab2: "Terms & Conditions",
            tab3: "Privacy Policy",
            tab4: "About Us",
            tab5: "Change Password",
            tab6: "Delete Account",
            tab7: "Switch to musician",
          },
          band: {
            tab1: "Band members",
            tab2: "First name",
            tab3: "Last name",
            tab4: "Phone number",
            tab5: "Permission",
            tab6: "Membership status",
            tab7: "Generate Invitation Link",
            tab8: "Change Permission",
            tab9: "Cancel",
            tab10: "Change",
            tab11: "Select permission",
            tab12: "Action",
            tab13: "No Record Found",
            tab14: "Invitaton Link",
          },
          gema: {
            tab1: "Played concerts",
            tab2: "Overview of submitted concerts",
            tab3: "date concert",
            tab4: "name concert",
            tab5: "country town",
            tab6: "date of report",
            tab7: "report a music without an appointment",
            tab8: "Length",
            tab9: "Payed Out",
            tab10: "Partial payed out",
            tab11: "Not payed out",
            tab12: "No Record Found",
            tab13: "Gema Number",
            tab14: "Location",
            tab15: "Organizer",
            tab16: "Name",
            tab17: "Last Name",
            tab18: "Street",
            tab19: "House Number",
            tab20: "Zip code",
            tab21: "Town",
            tab22: "Country",
            tab23: "Gema Number",
            tab24: "Length",
            tab25: "You need to accept",
            tab26: "Type of event",
            tab27: "Type of performance",
            tab28: "Played Songs",
            tab29: "Action",
            tab30: "Copyright Band",
            tab31: "Delete List",
            tab32: "Length",
            tab33: "Gema",
            tab34:
              "Because your music is worth a lot! Around 40% of the royalties are missing for our customers on the first payout. With Clever BandAssist you have a competent partner at your side who takes care of your GEMA processing.",
            tab35: "USE CLEVER BANDASSIST FOR YOUR PROCESSING!",
            tab36: "Get More Information",
            tab37: "Name of event",
            tab38: "Load List",
            tab39: "Are you sure, you want to delete this event ?",
            tab40: "Cancel",
            tab41: "Delete",
            tab42: "Load",
            tab43: "Are you sure, you want to delete this song?",
            tab44: "Cancel",
            tab45: "Delete",
          },
          event: {
            tab1: "Upcoming",
            tab2: "Unconfirmed",
            tab3: "Pasts",
            tab4: "Add new",
            tab5: "date",
            tab6: "type of event",
            tab7: "name of event",
            tab8: "Town",
            tab9: "Attachments",
            tab10: "Info event",
            tab13: "Info location",
            tab14: "Info organizer",
            tab15: "Info accomodation",
            tab16: "Street",
            tab17: "House number",
            tab18: "Zip code",
            tab19: "Town",
            tab20: "parking",
            tab21: "Guest list",
            tab22: "Role",
            tab23: "Payment",
            tab24: "Save",
            tab25: "Gema No Organizer",
            tab26: "Add Musician",
            tab27: "Add Instrument",
            tab28: "Additional Info",
            tab29: "Staff",
            tab30: "Vehicle",
            tab31: "Available seats",
            tab32: "Outgoing Invoice",
            tab33: "Incoming Invoice",
            tab34: "Document Booker",
            tab35: "Document Sound Engineer",
            tab36: "Other attachments",
            tab37: "No Record Found",
            tab39: "Add new event",
            tab40: "Concert",
            tab41: "Rehearsal",
            tab42: "Other appointment",
            tab43: "Name Of Contact Person",
            tab44: "Type Of Performance",
            tab45: "Other Information",
            tab46: "Priority",
            tab47: "Add Instrument",
            tab48: "Add Musician",
            tab49: "Staff",
            tab50: "Load Setup",
            tab51: "In total",
            tab52: "Action",
            tab53: "Accept",
            tab54: "Reject",
            tab55: "Concert",
            tab56: "Other Appointment",
            tab57: "Rehearsal",
            tab58: "Played",
            tab59: "Canceled",
            tab60: "Phone",
            tab61: "Add Staff",
            tab62: "Select",
            tab63: "Permission Upload",
            tab64: "Permission Download",
            tab65: "size",
            tab66: "Add Vehicle",
            tab67: "Add",
            tab68: "Passengers",
            tab69: "Length Setlist",
            tab70: "Document Type",
            tab71: "Cancel Event",
            tab72: "Are you sure, you want to delete transport ?",
            tab73: "Cancel",
            tab74: "Delete",
            tab75: "Cancelled Invitations",
            tab76: "First Name",
            tab77: "Last Name",
            tab78: "Information for bandmembers",
            tab79: "Reject Event",
          },
          dashboard: {
            tab1: "Upcoming Events",
            tab2: "No Record Found",
          },
          chat: {
            tab1: "All message",
            tab2: "Write a message",
          },
        },
      },
      de: {
        translation: {
          sidebar: {
            tab1: "Band",
            tab2: "Bandprofil",
          },
          absent: {
            tab1: "Abwesenheit",
            tab2: "Abwesenheiten",
            tab3: "von",
            tab4: "bis",
            tab5: "Aktion",
            tab6: "Datum",
            tab7: "Kein Eintrag gefunden",
          },
          setlist: {
            tab1: "Länge",
            tab2: "Möchtest du diese Setliste wirklich löschen?",
            tab3: "Abbrechen",
            tab4: "Löschen",
            tab5: "Keine Aufnahme gefunden",
            tab6: "Zum hinzufügen, Drag & Drop deine Songs aus der rechten Liste hier rein",
            tab7: "Speichern",
            tab8: "Song Liste",
            tab9: "Name der Setliste",
            tab10: "Aktion",
            tab11: "Setliste erstellen",
            tab12: "Gesamtzeit Setliste",
          },
          songs: {
            tab1: "Song hinzufügen",
            tab2: "Länge",
            tab3: "Urheberrechte ",
            tab4: "Keine Aufnahme gefunden",
            tab5: "Name des Song",
            tab6: "GEMA Werknummer",
            tab7: "Text",
            tab8: "Notenblatt",
            tab9: "Audiodatei",
            tab10: "Komponist",
            tab11: "Möchtest du diesen Song wirklich löschen?",
            tab12: "Abbrechen",
            tab13: "Löschen",
            tab14: "Auswählen",
            tab15: "Komponist",
          },
          tasks: {
            tab1: "Neu",
            tab2: "In Bearbeitung",
            tab3: "Abgeschlossen",
            tab4: "Neuen Task erstellen",
            tab5: "Farbe auswählenr",
            tab6: "Kein Eintrag gefunden",
            tab7: "Speichern",
            tab8: "Task zuordnen",
            tab9: "Task erstellen",
            tab10: "Anhänge",
            tab11: "Auswählen",
            tab12: "Anhänge",
            tab13: "Zugeordnet zu",
            tab14: "Aktueller Status",
            tab15: "Keine Einträge gefunden",
            tab16: "IN BEARBEITUNG",
            tab17: "ERLEDIGT",
            tab18: "ZURÜCK",
            tab19: "SPEICHERN",
            tab20: "Freigabe ändern",
            tab21: "Möchtest du den Task wirklich löschen?",
            tab22: "Abbrechen",
            tab23: "LÖSCHEN",
          },
          profile: {
            tab1: "Profil",
            tab2: "Straße",
            tab3: "Hausnummer",
            tab4: "Postleitzahl",
            tab5: "Stadt",
            tab6: "Land",
            tab7: "Vorname",
            tab8: "Nachname",
          },
          band: {
            tab1: "Bandmitglieder",
            tab2: "Vorname",
            tab3: "Nachname",
            tab4: "Telefon",
            tab5: "Freigabe",
            tab6: "Mitgliedsstatus",
            tab7: "Einladungslink generieren",
            tab8: "Freigabe ändern",
            tab9: "Abbrechen ",
            tab10: "Speichern",
            tab11: "Freigabe auswählen",
            tab12: "Aktion",
            tab13: "Keine Aufnahme gefunden",
            tab14: "EINLADUNGSLINK",
          },
          setting: {
            tab1: "Profil",
            tab2: "Allgemeine Geschäftsbedinungen",
            tab3: "Datenschutz",
            tab4: "Impressum",
            tab5: "Passwort ändern",
            tab6: "Account löschen",
            tab7: "Zu meinem Musiker Account wechseln",
          },
          gema: {
            tab1: "Gespielte Konzerte",
            tab2: "Übersicht eingereichte Konzerte",
            tab3: "Datum des Konzert",
            tab4: "name ",
            tab5: "Stadt",
            tab6: "Eingereicht",
            tab7: "Setliste manuell einreichen",
            tab8: "Länge",
            tab9: "Ausbezahlt",
            tab10: "Teilweise ausbezahlt",
            tab11: "Nicht ausbezahlt",
            tab12: "Kein Eintrag gefunden",
            tab13: "GEMA Nummer",
            tab14: "Lokation",
            tab15: "Veranstalter",
            tab16: "Name",
            tab17: "Last Name",
            tab18: "Straße",
            tab19: "Hausnummer",
            tab20: "Postleitzahl",
            tab21: "Stadt",
            tab22: "Land",
            tab23: "GEMA Nummer",
            tab24: "Länge",
            tab25: "Bitte bestätige deine Angaben",
            tab26: "Art des Event",
            tab27: "Art der Aufführung",
            tab28: "Gespielte Songs",
            tab29: "Aktion",
            tab30: "Urheberrechte Band",
            tab31: "Setliste löschen",
            tab32: "Lange",
            tab33: "Weil Deine Musik viel Wert ist",
            tab34:
              "Wir verschaffen dir Uberblick uber deine GEMA-Ausschuttungen, nehmen dir die Lasr der Abwicklung ab und optimieren deine Auszahlungssumme, wodurch unsere Kunstler in Schnitt 40% mehr Tantiemen erhalten",
            tab35: "Nutze Clever Bandassist fur deine Abwicklung",
            tab36: "Mehr Informationen erhalten",
            tab37: "Name des Event",
            tab38: "LISTE LADEN ",
            tab39: "Möchtest du das Event wirklich löschen?",
            tab40: "ABBRECHEN",
            tab41: "LÖSCHEN",
            tab42: "LADEN",
            tab43: "Möchtest du den Song wirklich löschen?",
            tab44: "ABBRECHEN",
            tab45: "LÖSCHEN",
          },
          event: {
            tab1: "Bevorstehende",
            tab2: "Unbestätigte",
            tab3: "Vergangene",
            tab4: "HInzufügen",
            tab5: "Datum",
            tab6: "Art der Veranstaltung",
            tab7: "Name der Veranstaltung",
            tab8: "Stadt",
            tab9: "Anhänge",
            tab10: "Info event",
            tab13: "Info Lokation",
            tab14: "Info Veranstalter",
            tab15: "Info Unterkunft",
            tab16: "Straße",
            tab17: "Hausnummer",
            tab18: "Postleitzahl",
            tab19: "Stadt",
            tab20: "Parken",
            tab21: "Gästeliste",
            tab22: "Rolle",
            tab23: "Bezahlung",
            tab24: "Speichern",
            tab25: "GEMA Kundennummer",
            tab26: "Musiker hinzufügen",
            tab27: "Add Instrument",
            tab28: "Instrument hinzufügen",
            tab29: "Personal",
            tab30: "Fahrzeug",
            tab31: "Verfügbare Plätze",
            tab32: "Ausgehende Rechnung",
            tab33: "Eingehende Rechnung",
            tab34: "Dokumente Booker",
            tab35: "Dokumente Techniker",
            tab36: "Zusätzliche Dokumente",
            tab37: "Kein Eintrag gefunden",
            tab38: "Dokumente Booker",
            tab39: "Neues Event hinzufügen",
            tab40: "Konzert",
            tab41: "Probe",
            tab42: "Andere Termine",
            tab43: "Name Kontaktperson ",
            tab44: "Art der Aufführung",
            tab45: "Weitere Informationen",
            tab46: "Priorität",
            tab47: "Instrument hinzufügen",
            tab48: "Musiker hinzufügen",
            tab49: "Personal",
            tab50: "Setup laden",
            tab51: "Gesamt",
            tab52: "Aktion",
            tab53: "Bestätigen ",
            tab54: "Stornieren",
            tab55: "Konzert",
            tab56: "Andere Termine",
            tab57: "Probe",
            tab58: "Gespielt",
            tab59: "Abgesagt",
            tab60: "Telefon",
            tab61: "Personal hinzufügen",
            tab62: "Auswählen",
            tab63: "Freigabe Upload",
            tab64: "Freigabe Download",
            tab65: "Größe",
            tab66: "Fahrzeug hinzufügen",
            tab67: "Hinzufügen",
            tab68: "Passagiere",
            tab69: "Länge Setlist",
            tab70: "Anhänge",
            tab71: "Event stornieren",
            tab72: "Möchtest du da das Fahrzeug wirklich löschen?",
            tab73: "ABBRECHEN",
            tab74: "LÖSCHEN",
            tab75: "Abgesagte Einladungen",
            tab76: "Vorname",
            tab77: "Nachname",
            tab78: "Information für Bandmitglieder",
            tab79: "Event absagen",
          },
          dashboard: {
            tab1: "Kommende Events",
            tab2: "Keine Einträg gefunden",
          },
          chat: {
            tab1: "Alle Nachrichten",
            tab2: "Schreibe eine Nachricht",
          },
        },
      },
    },
  });
