import React from 'react'
import Header from '../Components/Header/header'
import Sidebar from '../Components/Sidebar/sidebar'
import {Outlet} from 'react-router-dom'

const MainSection = ({switchtoken}) => {
    return (
        <>
            <div className="admin-panel">
                <Header switchtoken={switchtoken}/>
                <div className="container-fluid">
                    <div className="row no-gutters">
                        <Sidebar />
                        <Outlet/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainSection