import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { getFiles } from '../../app_redux/slice/files'
import S3FileUpload from 'react-s3';
import MusicfileUser from './musicfileUser'
window.Buffer = window.Buffer || require("buffer").Buffer;


const Musicfile = ({ show, handleShow, id, name }) => {
    const dispatch = useDispatch()
    const cookee = new Cookies();
    const token = cookee.get('token')
    const [members, setMembers] = useState([])
    const [image, setImage] = useState("")
    const [loader, setLoader] = useState(false)
    const [userId, setUserId] = useState("")
    const { allFile } = useSelector((state) => state.file)
    // const [filesize, setFileSize] = useState("")
    const [showMusicfilesUser, setShowMusicfilesUser] = useState(false)
    var filesize=""

    useEffect(() => {
        getMembers();
        // dispatch(getFiles({ songid: id, userid: 196 }))
    }, [])
    const handleShowMusicfileUser = () => {
        setShowMusicfilesUser(!showMusicfilesUser)
    }
    const handleClickUser = (e, id) => {
        e.preventDefault();
        setUserId(id)
        handleShowMusicfileUser()
    }
    const getMembers = () => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/get-band-leader-staff-members-with-membership`,
            data:
            {
                "page_no": 1
            }
        })
            .then(res => {
                setMembers(res.data.data)
            })
    }
    function bytesToSize(bytes) {
        var sizes = ['B', 'K', 'M', 'G', 'T', 'P'];
        for (var i = 0; i < sizes.length; i++) {
            if (bytes <= 1024) {
                return bytes + ' ' + sizes[i];
            } else {
                bytes = parseFloat(bytes / 1024).toFixed(2)
                filesize=`${bytes} kb`
            }
        }
        return bytes + ' P';
    }

    const handleFile = (e, ids) => {
        setLoader(true)
        bytesToSize(e.target.files[0].size)
        let file = e.target.files[0];
        const config = {
            bucketName: 'clever-band-assist-s3',
            region: 'eu-west-3',
            accessKeyId: 'AKIASTLKHDND66NQZXFJ',
            secretAccessKey: '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',
        }
        S3FileUpload
            .uploadFile(file, config)
            .then(data => {
                // console.log("RES file", data)
                setImage(data.location)
                setLoader(false)
                uploadMusicfile(data.location, ids)
            })
            .catch(err => console.error("ERR", err))
    }

    const handleUserClick = (ids) => {
        setUserId(ids)
        dispatch(getFiles({ songid: id, userid: ids }))
    }

    const uploadMusicfile = (i, ids) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/band-leader-upload-song-files-for-musician`,
            data:
            {
                "m_song_id": id,
                "user_id": userId,
                "musician_song_url": "",
                "musician_text_sheet_url": "",
                "musician_music_sheet_url": i,
                "musician_music_sheet_size": filesize ? filesize : "",
                "musician_text_sheet_size": ""
            }
        })
            .then(res => {
                if (res.status === 200) {
                    toast.success(res.data.status, {
                        theme: 'colored',
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    dispatch(getFiles({ songid: id, userid: ids }))
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    theme: "colored",
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }
    const deleteMusicFile = (ids) => {
        axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token
            },
            url: `${process.env.REACT_APP_URL}/delete-band-leader-uploaded-sheet`,
            data:
            {
                "m_song_id": id,
                "user_id": ids,
                "is_music_sheet": 1
            }
        })
            .then(res => {
                if (res.data.message === "Song Files Deleted Successfully!.") {
                    toast.success("Song Files Deleted Successfully!.", {
                        theme: 'colored',
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    dispatch(getFiles({ songid: id, userid: ids }))

                }
            })
    }

    return (
        <>
            <Modal show={show} onHide={handleShow} size="lg" >
                <Modal.Body className="p-0">
                    <div className="modal-body">
                        <button type="button" className="btn-close close-Icon" onClick={handleShow}></button>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h4 className=" text-center notranslate">{name}</h4>
                                <p className=" text-center">Music Files</p>
                                <div className="pt-3 px-1 bg-white">
                                    <div className="table-responsive">
                                        <table className="table storetable text-center">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col">name</th>
                                                    <th scope="col">upload</th>
                                                    {/* <th scope="col">download</th>
                                                    <th scope="col">size</th>
                                                    <th scope="col">action</th> */}
                                                </tr>
                                                <tr className="border-0 bg-none invisible"><td className="border-0 bg-none"></td></tr>
                                            </thead>
                                            {loader ?
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div> : ""}
                                            <tbody>
                                                {members?.length !== 0 ? members.map((res, key) => {
                                                    return <tr>
                                                        <td style={{ color: "blue", textDecoration: "underline" }} onClick={(e) => handleClickUser(e, res.user_id)}>{res.first_name} {res.last_name}</td>
                                                        <td onClick={() => handleUserClick(res.user_id)}>
                                                            <div className="upload-btn-wrapper Attachment" >
                                                                <img src="assets/images/gallery.png" width="25px" alt="" />
                                                                <input type="file" onChange={(e) => handleFile(e, res.user_id)} />
                                                            </div>
                                                        </td>
                                                        {/* <td onClick={() => handleUserClick(res.user_id)}> <div className="d-flex gap-3 justify-content-center">
                                                            <a href={allFile[0]?.musician_music_sheet_url} target="_blank" download={allFile[0]?.musician_music_sheet_url}>
                                                                <img src="assets/images/live-file.png" width="25px" alt="" />   </a>
                                                        </div></td>
                                                        <td>{allFile[0]?.musician_music_sheet_size}</td>
                                                        <td>
                                                            <div className="d-flex gap-3 justify-content-center">
                                                                <img src="assets/images/delete.png" width="25px" alt="" onClick={() => deleteMusicFile(res.user_id)} />                                                         </div>
                                                        </td> */}
                                                    </tr>
                                                }) : <p className='mt-5'>No Member Found!!</p>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {showMusicfilesUser ? <MusicfileUser show={showMusicfilesUser} handleShow={handleShowMusicfileUser} id={id} name={name} userid={userId} /> : ""}
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default Musicfile