import { configureStore } from "@reduxjs/toolkit";
import songReducer from "./slice/songList"
import setlistSongReducer from './slice/setlistSong'
import eventChatReducer from './slice/eventChat'
import taskChatReducer from './slice/taskChat'
import bandChatReducer from './slice/bandChat'
import gemaSubmittedReducer from "./slice/gemaSubmitted";
import eventDetailReducer from "./slice/eventDetail";
import getEventsReducer from "./slice/gemaEvents";
import gemaEventDetailReducer from "./slice/gemaEventDetail";
import bandstaffListReducer from "./slice/bandstaffList";
import setlistDataReducer from "./slice/setlistData";
import taskDetailReducer from "./slice/taskDetail";
import taskDetailtwoReducer from "./slice/gettaskDetailtwo";
import taskDetailAdminReducer from "./slice/getTaskDetailAdmin";
import setlistDetailReducer from "./slice/setlistDetail";
import unconfirmedEventsReducer from "./slice/unconfirmedEvents";
import upcomingEventsReducer from './slice/upcomingEvents';
import pastEventsReducer from './slice/pastEvents';
import loadSetupReducer from "./slice/setupData";
import notificationReducer from "./slice/notification";
import fileReducer from "./slice/files";
import passengersReducer from './slice/passengers'
import allTaskReducer from "./slice/allTask";
import allChatCountReducer from "./slice/allChatCount";
import getAttachmentsReducer from "./slice/getAttachments";
import eventChatlistReducer from "./slice/eventChatlist";
import taskChatlistReducer from "./slice/taskChatlist";
import bandleaderProfileReducer from "./slice/bandleaderProfile";
import musicianInfoReducer from "./slice/getMusicianInfo";
import permissionInfoReducer from "./slice/permissionInfo";
import allNotificationCountsReducer from "./slice/notificationCounts";
import songDetailReducer from "./slice/songDetail";
import bookerEventCountReducer from "./slice/bookerEventCount";

export const store = configureStore({
    reducer: {
        song: songReducer,
        setlistsong: setlistSongReducer,
        eventchat: eventChatReducer,
        bandchat: bandChatReducer,
        submittedgema: gemaSubmittedReducer,
        eventdetail: eventDetailReducer,
        events: getEventsReducer,
        gemaevents: gemaEventDetailReducer,
        bandstaff: bandstaffListReducer,
        setlist: setlistDataReducer,
        taskdetail: taskDetailReducer,
        taskdetailtwo: taskDetailtwoReducer,
        taskadmin: taskDetailAdminReducer,
        setlistdetail: setlistDetailReducer,
        unconfirmedEvents: unconfirmedEventsReducer,
        upcomingEvents: upcomingEventsReducer,
        pastEvents: pastEventsReducer,
        loadSetup: loadSetupReducer,
        taskchat: taskChatReducer,
        notification: notificationReducer,
        file: fileReducer,
        passenger: passengersReducer,
        alltask: allTaskReducer,
        allchatcount: allChatCountReducer,
        attachmentpermission: getAttachmentsReducer,
        eventchatlist: eventChatlistReducer,
        taskchatlist: taskChatlistReducer,
        bandleaderprofile: bandleaderProfileReducer,
        musician: musicianInfoReducer,
        permission: permissionInfoReducer,
        counts: allNotificationCountsReducer,
        songDetail: songDetailReducer,
        bookerNotification: bookerEventCountReducer
    }
})