import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getPastEvents = createAsyncThunk("unevents", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('token')
    let events =
        await axios({
            method: 'get',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: obj?.id ? `${process.env.REACT_APP_URL}/get-band-leader-past-events?page_no=${obj?.pageno}&gemma_submitted=${"0,1"}&user_id=${obj?.id}` : `${process.env.REACT_APP_URL}/get-band-leader-past-events?page_no=${obj?.pageno}&gemma_submitted=${"0,1"}`,
        })
            .then(res => res.data.data)
    return events
})

const listEvents = createSlice(({
    name: "all songs",
    initialState: {
        allPastEvents: [],
        loading: false
    },
    extraReducers: {
        [getPastEvents.pending]: (state, action) => {
            state.loading = true;
        },
        [getPastEvents.fulfilled]: (state, action) => {
            state.allPastEvents = action.payload;
            state.loading = false;
        },
        [getPastEvents.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listEvents.reducer;