import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { Link, useNavigate } from "react-router-dom";
import AllSong from "./allSong";
import { useDrop } from "react-dnd";
import { useDrag } from "react-dnd";
import AllSelectedSong from "./selectedSongs";
import update from "immutability-helper";
import { ToastContainer, toast } from "react-toastify";
import { useInView } from "react-intersection-observer";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";

const Setlist = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const cookee = new Cookies();
  const token = cookee.get("token");
  const lng = cookee.get("googtrans");
  const bandId = cookee.get("bandId");
  const [songs, setSongs] = useState([]);
  const [addedSongs, setAddedSongs] = useState([]);
  const [setList, setSetList] = useState("");
  const [setlistData, setSetListData] = useState([]);
  const [existingSongs, setExistingSongs] = useState([]);
  const [existingSong, setExistingSong] = useState([]);
  const [count, setCount] = useState([]);
  const [totaltime, setTotaltime] = useState("");
  const [list, seList] = useState(1);
  const [number, setNumber] = useState(1);
  const [page, setPage] = useState(1);
  const [setlistOrder, setSetListOrder] = useState([
    { id: "", display_seq: "" },
  ]);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const { tab1, tab6, tab8, tab10, tab12, tab7 } = t("setlist");

  const [{ isDragging }, dragRef] = useDrag({
    type: "playerType",
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (item) {
        // console.log("Call", item)
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const movePlayerToTeam = (item, index) => {
    setNumber(number + 1);
    if (index !== undefined) {
      setExistingSongs((prev) => [...prev, item]);
      setAddedSongs((prev) => [
        ...prev,
        { song_id: item.m_song_id, display_seq: number },
      ]);
    }
    setCount([...count, item.time_length]);
    if (count.length > 0) {
      setTotaltime(sumTime([...count, item.time_length]));
    }
  };
  const moveComponent = (fromIndex, toIndex) => {
    console.log("Calltest");
    const updatedComponents = [...existingSongs];
    const [movedComponent] = updatedComponents.splice(fromIndex, 1);
    updatedComponents.splice(toIndex, 0, movedComponent);
    setExistingSongs(updatedComponents);
  };
  const [{ isOver }, addToTeamRef] = useDrop({
    accept: "player",
    collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    movePlayerToTeam,
  });

  const [{ isOver: isPlayerOver }, removeFromTeamRef] = useDrop({
    accept: "team",
    collect: (monitor) => ({ isOver: !!monitor.isOver() }),
  });

  function sumTime(count) {
    let sumSeconds = 0;
    count.forEach((time) => {
      let a = time.split(":");
      let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      sumSeconds += seconds;
    });

    return new Date(sumSeconds * 1000).toISOString().substr(11, 8);
  }
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    console.log("call", dragIndex, hoverIndex);
    setCount(dragIndex);
    setExistingSongs((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
    manageDisplaySeq();
  }, []);

  const moveSongs = useCallback((dragIndex, hoverIndex) => {
    setExistingSongs((prevCards) =>
      update(prevCards, {
        $push: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
    setAddedSongs((prevCards) =>
      update(prevCards, {
        $push: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);
  const handleSorting = (item, index) => {
    console.log("call sorting");
    existingSongs.splice(2, 0, item);
  };

  useEffect(() => {
    getSetList();
  }, []);

  useEffect(() => {
    getSongs();
  }, [page]);
  const fetchMoreData = () => {
    setPage(page + 1);
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/band-leader-song-list`,
      data: {
        page_no: page + 1,
      },
    }).then((res) => {
      // console.log("res", res.data)
      setSongs(songs.concat(res.data.data));
    });
  };

  const handleSetList = (e) => {
    setSetList(e.target.value);
  };
  const getSongs = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/band-leader-song-list`,
      data: {
        page_no: page,
      },
    }).then((res) => {
      // console.log("res", res.data)
      setSongs(res.data.data);
    });
  };
  const getSetList = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-set-list-by-band-id`,
      data: {
        page_no: 1,
      },
    }).then((res) => {
      //console.log("res", res.data)
      setSetListData(res.data.data);
    });
  };
  const manageDisplaySeq = () => {
    setSetListOrder(
      existingSongs.map((res, i) => {
        console.log(res);
        return [{ id: res.m_song_id, display_seq: i + 1 }];
      })
    );
  };
  const handleAddSetlist = (e) => {
    e.preventDefault();
    manageDisplaySeq();
    setTimeout(addSetList, 1000);
  };

  const addSetList = () => {
    // e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/create-band-leader-set-list`,
      data: {
        // "band_id": bandId,
        set_list_name: setList,
        set_list: existingSongs.map((res, i) => {
          return { song_id: res.m_song_id, display_seq: i + 1 };
        }),
      },
    })
      .then((res) => {
        // console.log("res", res.data)
        if (res.status === 200) {
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate("/setlist");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleNext = (e) => {
    e.preventDefault();
    setPage(page + 1);
  };
  const handlePrevious = (e) => {
    e.preventDefault();
    setPage(page - 1);
  };

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 h-100">
            <h4 className="notranslate">
              Setlist Songs{" "}
              <img
                src="assets/images/Infoblack.png"
                alt=""
                className="mx-1 mb-4"
                title={
                  lng === "/auto/de" || "/en/de"
                    ? "Ziehen Sie Songs aus der rechten Spalte „Alle Songs“ in dieses Feld, um eine Setliste zu erstellen. Sie können die Reihenfolge per Drag&Drop ändern, indem Sie die Songs an die richtige Position ziehen."
                    : "Drag songs from the right „All Songs“ column into this field to create a setlist. You can change the order by dragging the songs to the right position."
                }
              />{" "}
            </h4>{" "}
            <div className="pt-3 px-1 bg-white shadow">
              <div className="table-responsive setlistTable2">
                <table className="table storetable mb-0" ref={addToTeamRef}>
                  <thead className="">
                    <tr>
                      <th scope="col">name</th>
                      <th scope="col">{tab1}</th>
                      <th scope="col">{tab10}</th>
                    </tr>
                    <tr className="border-0 bg-none invisible">
                      <td className="border-0 bg-none"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {existingSongs.length !== 0 ? (
                      existingSongs.map((res, i) => {
                        return (
                          <AllSelectedSong
                            item={res}
                            key={i}
                            onDropPlayer={handleSorting}
                            moveCard={moveCard}
                            index={i}
                            setExistingSongs={setExistingSongs}
                            existingSongs={existingSongs}
                            count={count}
                            setCount={setCount}
                            sumTime={sumTime}
                            setTotaltime={setTotaltime}
                            setAddedSongs={setAddedSongs}
                            addedSongs={addedSongs}
                          />
                        );
                      })
                    ) : (
                      <h6
                        className="text-center mt-5 my-5"
                        style={{ marginLeft: "100px" }}
                      >
                        {tab6}
                      </h6>
                    )}
                    <tr>
                      <td class="bg-td">{tab12}</td>
                      <td class="bg-td">
                        {count?.length === 0 ? count : totaltime}
                      </td>
                      <td class="bg-td"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 h-100">
            <h4>
              Songs{" "}
              <img
                src="assets/images/Infoblack.png"
                alt=""
                className="mx-1 mb-4"
                title={
                  lng === "/auto/de" || "/en/de"
                    ? "Hier finden Sie alle von Ihnen angelegten Lieder, welche Sie zum erstellen einer Setliste verwenden können."
                    : "Here you will find all the songs you have created, which you can use to create a set list."
                }
              />{" "}
            </h4>
            <div className="pt-3 px-1 bg-white shadow">
              <div className="table-responsive setlistTable2 setlist_scrollbar">
                <InfiniteScroll
                  dataLength={songs?.length}
                  // next={fetchMoreData}
                  hasMore={songs?.length >= 1}
                  // loader={<h4>Loading...</h4>}
                >
                  <table className="table storetable mb-0">
                    <thead className="">
                      <tr>
                        <th scope="col">{tab8}</th>
                        <th scope="col"></th>
                      </tr>
                      <tr className="border-0 bg-none invisible">
                        <td className="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {songs.map((item, i) => {
                        return (
                          <AllSong
                            item={item}
                            key={i}
                            playerType="player"
                            onDropPlayer={movePlayerToTeam}
                            index={i}
                            ref={dragRef}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            </div>
          </div>
          {page === 1 && songs?.length <= 9 ? (
            ""
          ) : (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
              <div className="d-flex align-items-center lg-justify-content-end">
                <nav aria-label="...">
                  <ul className="pagination">
                    <li
                      className={
                        page === 1 ? "page-item disabled" : "page-item"
                      }
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={handlePrevious}
                      >
                        Previous
                      </a>
                    </li>
                    <li class="page-item active">
                      <a className="page-link" href="#">
                        {page} <span className="sr-only">(current)</span>
                      </a>
                    </li>
                    <li
                      class={
                        songs?.length <= 9 ? "page-item disabled" : "page-item"
                      }
                    >
                      <a className="page-link" href="#" onClick={handleNext}>
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
        <form className="row mt-5 addNAme">
          <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
            <input
              type="text"
              value={setList}
              onChange={handleSetList}
              className="form-control"
              placeholder="Name setlist"
            />
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
            <div className="d-flex justify-content-end my-3">
              <button className="btn add-new" onClick={handleAddSetlist}>
                {tab7}
              </button>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </article>
  );
};

export default Setlist;
