import * as Yup from "yup";

const today = new Date();
today.setHours(0, 0, 0, 0)

export const addTaskSchema = Yup.object({
    name: Yup.string().required("please enter"),
    description: Yup.string().required("please enter"),
    labels: Yup.string().required("please enter"),
    deadline: Yup.date().nullable().required('Date is required')
    .min(today,"Date cannot be in the past"),
});