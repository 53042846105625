import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Cookies from 'universal-cookie'
import NotificationBadge from 'react-notification-badge';
import { Effect } from 'react-notification-badge';
import { getAllChats } from '../../app_redux/slice/allChatCount';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { getPermissionInfo } from '../../app_redux/slice/permissionInfo';
import { getCounts } from '../../app_redux/slice/bookerEventCount';


const Sidebar = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const cookee = new Cookies();
	const { pathname } = location;
	const splitLocation = pathname.split("/");
	const token = cookee.get('token')
	const [gemaDeal, setGemaDeal] = useState("")
	const { allChatCount } = useSelector((state) => state.allchatcount)
	const { tab1, tab2 } = t("sidebar")
	const { permissionDetail } = useSelector((state) => state.permission)
	const { allCount } = useSelector((state) => state.bookerNotification)


	useEffect(() => {
		dispatch(getAllChats())
		dispatch(getCounts())
		dispatch(getPermissionInfo({ id: cookee.get("redirect_userid") }))
	}, [])

	const handleGema = () => {
		axios({
			method: 'get',
			headers: {
				"Accept": "/",
				"Content-Type": "application/json",
				"auth_token": token
			},
			url: `${process.env.REACT_APP_URL}/get-band-leader-gema-deal`,
		})
			.then(res => {
				setGemaDeal(res.data.data.is_gemma_contract)
				if (res.data.data.is_gemma_contract === 0) {
					navigate('/gema')
				}
				if (res.data.data.is_gemma_contract === 1) {
					navigate('/gema-deal')
				}
			})
	}
	const handleNotificationCount = () => {
		dispatch(getAllChats())
		dispatch(getCounts())

	}
	const updateChatCount = () => {
		axios({
			method: 'post',
			headers: {
				"Accept": "/",
				"Content-Type": "application/json",
				"auth_token": token
			},
			url: `${process.env.REACT_APP_URL}/update-band-leader-notification-status`,
			data:
			{
				"notification_id": "",
				"user_type": 2,
				"is_read": 1,
				"notification_type": ""
			}
		})
			.then(res => {
				dispatch(getAllChats())
			})

	}

	return (
		<div className="col-lg-3 store-sidebar admin-sidebar">
			<div className="shadow-part">
				<nav className=" collapse d-lg-block" id="sidebar">
					<ul className="list-unstyled mb-0">
						<li onClick={updateChatCount}><Link to="/events" className={splitLocation[1] === "events" ? "active" : splitLocation[1] === "add-rehearsal-event" ? "active" : splitLocation[1] === "add-other-event" ? "active" : splitLocation[1] === "unconfirmed-events" ? "active" : splitLocation[1] === "pasts-events" ? "active" : ""}>
							<div>
								<img src="assets/images/event-dates-menu.png" className="img-fluid sidebar-icon inactive-icon" alt="" />
								<img src="assets/images/event-dates-menu-selected.png" className="img-fluid sidebar-icon active-icon" alt="" />
							</div>
							<span>events </span>
							<NotificationBadge count={allCount} effect={Effect.SCALE} style={{ backgroundColor: "white", color: "black" }} className="mx-2" />
						</Link>

						</li>
						{permissionDetail?.permissionDetails?.permission_type == 5 ? "" :
							<li onClick={handleNotificationCount}><Link to='/songs' className={splitLocation[1] === "songs" ? "active" : ""}>
								<div>
									<img src="assets/images/songs-menu.png" className="img-fluid sidebar-icon inactive-icon" alt="" />
									<img src="assets/images/songs-menu-select.png" className="img-fluid sidebar-icon active-icon" alt="" />
								</div>
								<span className='notranslate'>songs </span></Link>
							</li>
						}


						{permissionDetail?.permissionDetails?.permission_type == 5 ? "" :
							<li onClick={handleNotificationCount}><Link to="/setlist" className={splitLocation[1] === "setlist" ? "active" : splitLocation[1] === "add-setlist" ? "active" : ""}>
								<div>
									<img src="assets/images/setlist-menu.png" className="img-fluid sidebar-icon inactive-icon" alt="" />
									<img src="assets/images/setlist-menu-selected.png" className="img-fluid sidebar-icon active-icon" alt="" />
								</div>
								<span>setlists </span></Link></li>
						}
						{permissionDetail?.permissionDetails?.permission_type == 5 ? "" :

							<li><Link to="/band-invitation" className={splitLocation[1] === "band-invitation" ? "active" : ""}>
								<div>
									<img src="assets/images/InvitationProcess.png" className="img-fluid sidebar-icon inactive-icon" alt="" />
									<img src="assets/images/InvitationProcessselected.png" className="img-fluid sidebar-icon active-icon" alt="" />
								</div>
								<span>{tab1}</span></Link></li>
						}

						{permissionDetail?.permissionDetails?.permission_type == 5 ? "" :

							<li onClick={handleNotificationCount}><Link to="/band-staff" className={splitLocation[1] === "band-staff" ? "active" : splitLocation[1] === "add-band-staff" ? "active" : ""}>
								<div>
									<img src="assets/images/band-staff.png" className="img-fluid sidebar-icon inactive-icon" alt="" />
									<img src="assets/images/band-staff-selected.png" className="img-fluid sidebar-icon active-icon" alt="" />
								</div>
								<span>{tab2}</span></Link></li>
						}

						{
							permissionDetail?.permissionDetails?.permission_type == 5 ? "" :
								<li onClick={handleNotificationCount}><Link to="/task-list" className={splitLocation[1] === "task-list" ? "active" : splitLocation[1] === "task-list-inprogress" ? "active" : splitLocation[1] === "task-list-completed" ? "active" : splitLocation[1] === "add-new-task" ? "active" : ""}>
									<div>
										<img src="assets/images/task-list.png" className="img-fluid sidebar-icon inactive-icon" alt="" />
										<img src="assets/images/task-list-selected.png" className="img-fluid sidebar-icon active-icon" alt="" />
									</div>
									<span>tasks </span></Link></li>
						}

						{/* <li onClick={updateChatCount}><Link to="/chat" className={splitLocation[1] === "chat" ? "active" : ""}>
							<div>
								<img src="assets/images/chat-menu.png" className="img-fluid sidebar-icon inactive-icon" alt="" />
								<img src="assets/images/chat-selected.png" className="img-fluid sidebar-icon active-icon" alt="" />
							</div>
							<span className='notranslate'> chat </span>
							<NotificationBadge count={allChatCount} effect={Effect.SCALE} style={{ backgroundColor: "white", color: "black" }} className="mx-2" />
						</Link></li> */}

						{permissionDetail?.permissionDetails?.permission_type == 5 ? "" :
							<li onClick={updateChatCount}><Link to="/chat-list" className={splitLocation[1] === "chat-list" || splitLocation[1] === "chatlistEvent" || splitLocation[1] === "chatlistTask" ? "active" : ""}>
								<div>
									<img src="assets/images/chat-menu.png" className="img-fluid sidebar-icon inactive-icon" alt="" />
									<img src="assets/images/chat-selected.png" className="img-fluid sidebar-icon active-icon" alt="" />
								</div>
								<span className='notranslate'> chat </span>
								<NotificationBadge count={allChatCount} effect={Effect.SCALE} style={{ backgroundColor: "white", color: "black" }} className="mx-2" />
							</Link></li>
						}

						{permissionDetail?.permissionDetails?.permission_type == 5 ? "" :
							<li onClick={handleNotificationCount}><Link to="#" className={splitLocation[1] === "gema-deal" ? "active" : ""} onClick={handleGema}>
								<div>
									<img src="assets/images/GEMA-menu.png" className="img-fluid sidebar-icon inactive-icon" alt="" />
									<img src="assets/images/GEMA-selected.png" className="img-fluid sidebar-icon active-icon" alt="" />
								</div>
								<span className="notranslate"> GEMA </span></Link></li>
						}
					</ul>
				</nav>
			</div>
		</div>
	)
}

export default Sidebar