import React, { useState} from 'react'

import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["jpg", "png", "gif"];

const UploadExcelSongs = () => {

    const [file, setFile] = useState(null);

    const handleChange = (file) => {
        setFile(file);
    };
    console.log("file", file)


    return (
        <>
            <article className="col-lg-9">
                <div className="article-body store-bd-gy">
                    <div className="row mt-3 align-items-center">

                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="d-flex align-items-center lg-justify-content-end">
                                {/* <Link to="/add-song" ><button className="btn add-new">{lng === "/auto/de" || "/en/de" ? "SONG HINZUFÜGEN" : "add song"}</button></Link> */}

                            </div>
                        </div>

                        <div className="col-sm-12">

                            <FileUploader  name="file" types={fileTypes} fileOrFiles={file} className="drop_area drop_zone"
                            />

                        </div>
                    </div>
                </div>

            </article >
        </>

    )
}

export default UploadExcelSongs