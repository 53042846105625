import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'

export const getCounts = createAsyncThunk("all counts", async (obj) => {
    const cookee = new Cookies();
    const token = cookee.get('token')
    let chats =
        await axios({
            method: 'post',
            headers: {
                "Accept": "/",
                "Content-Type": "application/json",
                "auth_token": token

            },
            url: `${process.env.REACT_APP_URL}/get-band-leader-unread-notification-count`,
            data: {
                "user_type": 2,
                "notification_type": "18",
                "is_chat": 0
            }
        })
            .then(res => res.data.data.count)
    return chats
})

const listCounts = createSlice(({
    name: "all chat count",
    initialState: {
        allCount: "",
        loading: false
    },
    extraReducers: {
        [getCounts.pending]: (state, action) => {
            state.loading = true;
        },
        [getCounts.fulfilled]: (state, action) => {
            state.allCount = action.payload
        },
        [getCounts.rejected]: (state, action) => {
            state.loading = false;
        }

    }
}))

export default listCounts.reducer;