import { yupToFormErrors } from "formik";
import * as Yup from "yup";


export const addSongSchema = Yup.object({
   songName: Yup.string().min(2).max(45).required("please enter song name"),
   // songTime: Yup.string().required("please enter song time").matches(/^(?:1[0-2]|0?[0-9]):[0-5][0-9]:[0-5][0-9]$/, "please use [hh:mm:ss] format"),
   // bpm: Yup.string().required("please enter "),
   // gemaNumber: Yup.string().required("please enter gemaNumber"),
   // isrc: Yup.string().required("please enter "),
});